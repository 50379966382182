<template>
    <div>
        <div style="display: flex;background-color: white;justify-content: space-between;">
            <div class="title_left">盘点人数:{{reviewCount}}/{{reviewTotalCount}}</div>
            <van-dropdown-menu active-color="#13d1be" class="title_right">
                <van-dropdown-item v-model="mSelected" :options="option" @change="changeValue()"/>
            </van-dropdown-menu>
        </div>
        <van-list
                v-model="loading"
                :finished="finished"
                :immediate-check="false"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="item" v-for="(item,index) in dataList" :key="index">
                <div class="item_left" v-if="item.username.length<2">
                    {{item.username.substring(item.username.length-1)}}
                </div>
                <div class="item_left" v-else>{{item.username.substring(item.username.length-2)}}</div>
                <div style="display: flex;flex-direction: column;flex: 1">
                    <div style="display: flex;justify-content: space-between">
                        <div class="item_top_left">{{item.username}}</div>
                        <div class="item_top_right">{{item.evaluationName}}</div>
                    </div>
                    <div style="display: flex;justify-content: space-between">
                        <div class="item_bottom_left">发起时间:{{item.createTime}}</div>
                        <van-button class="item_bottom_right_1" round type="info" @click="toReview(item)">
                            {{item.score==0?"开始盘点":"已盘点"}}
                        </van-button>
                        <!--                        <div class="item_bottom_right_1" @click="toReview(item)">-->
                        <!--                            {{item.status==0?"��ʼ�̵�":"���̵�"}}-->
                        <!--                        </div>-->
                    </div>
                </div>

            </div>
        </van-list>
        <no-result :title="'暂无盘点'" v-if="!dataList.length"></no-result>
    </div>
</template>

<script>
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import {startShart} from "@/common/js/tecentshare.js";
    import api from "@/apis/talentReview/talentReview.js";
    import NoResult from "../components/commonly/noResult.vue";
    import {Button, DropdownItem, DropdownMenu, List, Sticky, Tab, Tabs} from "vant";
    export default {
        name: "ValueAssessmentList",
        data(){
            return{
                mSelected: 2,//选项卡使用
                option: [
                    {text: '全部', value: 2},
                    {text: '已盘点', value: 1},
                    {text: '未盘点', value: 0}
                ],
                loading: false,
                finished: false,
                currPage: 1,
                pageSize: 10,
                totalPage: 1,
                totalCount: 0,
                dataList: [],
                reviewTotalCount: 0,
                reviewCount: 0
            }
        },
        components: {
            [List.name]: List,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Button.name]: Button,
            [Sticky.name]: Sticky,
            NoResult
        },
        created() {
            this.officeId = localStorage.getItem("m_officeId");
            this.userId = localStorage.getItem("m_userId");
            this.pcUserId = localStorage.getItem("m_pcUserId");
            this.reviewId = localStorage.getItem('m_reviewId');
            this.getData();
        },
        mounted() {
            this.shareContent();
        },
        methods:{
            getData() {

                let reqData={
                    managerType: 1,//价值观
                    status: this.mSelected,
                    pcUserId: this.pcUserId,
                    officeId: this.officeId,
                    userId: this.userId,
                    // officeId: 269,
                    // userId: 8507,
                    page: this.currPage,
                    limit: this.pageSize
                };
                if (this.reviewId) {
                    reqData.reviewId = this.reviewId
                }
                api.managerAssessmentValueAssessmentList(reqData).then(
                    response => {
                        this.loading = false;
                        if (response.code == 200) {
                            if (this.currPage == 1) {
                                this.dataList = [];
                            }
                            this.dataList.push(...response.result.list.list);
                            this.pageSize = response.result.list.pageSize;
                            this.totalPage = response.result.list.totalPage;
                            this.currPage = response.result.list.currPage;
                            this.totalCount = response.result.list.totalCount;
                            this.reviewTotalCount = response.result.totalCount;
                            this.reviewCount = response.result.reviewCount;
                        }
                    }
                )
            },
            changeValue() {
                this.currPage = 1;
                this.getData()
            },
            toReview(item) {
                this.$router.push({
                    path: '/ValueAssessmentTest',
                    query: {
                        assessUserId: item.assessUserId,
                        userId: item.userId,
                        officeId: item.officeId,
                        mType:item.type,
                        category:item.category
                    }
                })
            },
            onLoad() {
                this.loading = true;
                if (this.currPage < this.totalPage) {
                    this.currPage += 1;
                    this.getData()
                } else {
                    this.finished = true;
                    this.loading = false;
                }
            },

            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .title_left {
        padding: 1rem;
        line-height: 3rem;
        font-size: 1.5rem;
        color: #333;
    }

    .title_right {
        padding-right: 1rem;
        font-size: 1rem;
        color: #333;
    }

    .item {
        display: flex;
        align-items: center;
        opacity: 1;
        margin: 1rem;
        padding: 1rem;
        background: #ffffff;
        border-radius: 1rem;
        box-shadow: 0px .4rem 1rem 0px rgba(0, 0, 0, 0.20);
    }

    .item_left {
        width: 6rem;
        height: 6rem;
        color: white;
        display: flex;
        font-size: 2rem;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        background: linear-gradient(180deg, #6c97ff, #3f72ec);
        border-radius: 4px;
    }

    .item_top_left {
        padding: 0 0 0 1rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #333;
        line-height: 4rem;
    }

    .item_top_right {
        font-size: 1.2rem;
        color: #333;
        line-height: 4rem;
    }

    .item_bottom_left {
        padding: 1rem;
        font-size: 1.2rem;
        color: #333;
    }

    .item_bottom_right_1 {
        width: 10rem;
        height: 3rem;
        text-align: center;
        font-size: 1.1rem;
        background-color: #13d1be;
    }

    .item_bottom_right_2 {
        float: right;
        padding: 10px;
        font-size: 14px;
        color: #333;
        border-radius: 5px;
        border: 1px solid #13d1be;
    }
</style>
