import {
  get,
  post
} from '../utils/base';
import userCache from '@/common/js/userCache';

// 获取课程详情
export default {
  // 获取 普通课 详情
  getGoodsDetail(goodId,userID) {
    const opts = _opts(false);
    let goodsId = goodId;
    return post('store/selectGoodsInfoByPara', { goodsInfoId: goodsId, wechatUserId: userID }, opts)
  },
  // 获取 普通课 详情
  getGoodsDetailOther(goodId,userID) {
    const opts = _opts(false);
    let goodsId = goodId;
    return post('store/selectGoodsInfoByPara', { goodsInfoId: goodsId, wechatUserId: userID }, opts)
  },
  // 获取 分销课 详情（组件用）
  _getFxDetail: function (distributeId, goodsId) {
    const opts = _opts(true);
    let userId = userCache.getUserId();
    return post('store/selectGoodsDistributionInfoByPara', { distributionId: distributeId, wechatUserId: userId, goodsInfoId: goodsId }, opts)
  },

  // 获取 分销课 详情（页面用）
  _getFxkDetail: function (goodsId, distributeId, userId) {
    const opts = _opts(true);
    return post('store/selectGoodsDistributionInfo', { wechatUserId: userId, distributionId: distributeId, goodsInfoId: goodsId }, opts)
  },
  
  // 获取 秒杀课 详情
  getSkillDetail: function (goodId) {
    const opts = _opts(true);
    let goodsId = goodId;
    return post('store/selectGoodsKillerInfoByPara', { goodsKillerId: goodsId }, opts);
  },
  
  // 获取 团购课 详情
  getGroupDetail: function (goodId, goodSpellId) {
    const opts = _opts(true);
    let goodsId = goodId;
    let goodSepllId = goodSpellId;
    let userId = userCache.getUserId();
    return post('store/selectGoodsSpellGroupInfo', { goodsInfoId: goodsId, goodsSpellId: goodSepllId, wechatUserId: userId }, opts);
  },

  //获取 课程成交 记录
  getOrderRecord: function (lessonId) {
    const opts = _opts(true);
    let goodsId = lessonId;
    return post('store/selectGoodsRecordList', { goodsInfoId: goodsId }, opts);
  },

  //获取拼团信息
  getGroupInfo: function(goodSpellId, spelRecordId) {
    const opts = _opts(true);
    return post('store/selectSpellGroupByPara2', { goodsSpellId: goodSpellId, spellRecordId: spelRecordId }, opts);
  },

  //获取我的邀请码
  _getMyInviteCode: function (distributeId, goodsId) {
    const opts = _opts(true);
    let userId = userCache.getUserId();
    return post('store/myInvitationQrcode', { wechatUserId: userId, distributionId: distributeId, goodsInfoId: goodsId }, opts);
  },
  //获取我的学习列表
  _getMyStudyList: function (officeId,userId) {
    const opts = _opts(true);
    // let wechatUserId = userCache.getUserId();
    return post('hrCoe/study/myStudy', {
      officeId:officeId,
      userId:userId }, opts);
  },
  //获取任务
  _getMyTaskList: function (totalId,officeId,userId) {
    const opts = _opts(false);
    return post('hrCoe/study/selectTaskList', { totalId: totalId,officeId,userId }, opts);
  },
  //获取播放列表
  _getPlayList: function (totalId,type,campTaskId,detailId,officeId,userId) {
    const params = {
       totalId: totalId,
       type:type,
      officeId:officeId,
      userId:userId
    };
    if(campTaskId){
      params['campTaskId']=campTaskId
    }
    if(detailId){
      params['detailId']=detailId
    }
    const opts = _opts(true);
    return post('hrCoe/study/selectStudyWatchList',params,opts );
  }
};

function _opts (isShowLoading) {
  var opt = {
    cache: {
      exp: 60
    },
    silent:false
  };
  if (isShowLoading) {
    return Object.assign(opt, {
      progress: true
    })
  }
  return Object.assign(opt)
}