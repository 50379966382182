<template>
  <div>
    <div class="cover">
      <template v-for="(item, index) in itemArray">
        <div v-if="item.type == 1" :key="index" class="item">
          <div class="item-top">
            <div class="item-top-title">父亲/母亲</div>
          </div>
          <div class="inputCover">
            <div class="inputCover-title">父亲姓名:</div>
            <input class="m_input" v-model="item.name1" placeholder="请输入姓名" />
          </div>
          <div class="inputCover">
            <div class="inputCover-title">父亲职业:</div>
            <input class="m_input" v-model="item.occupation1" placeholder="请输入职业" />
          </div>
          <div class="inputCover">
            <div class="inputCover-title">母亲姓名:</div>
            <input class="m_input" v-model="item.name2" placeholder="请输入姓名" />
          </div>
          <div class="inputCover">
            <div class="inputCover-title">母亲职业:</div>
            <input class="m_input" v-model="item.occupation2" placeholder="请输入职业" />
          </div>
        </div>
        <div v-if="item.type == 2" :key="index" class="item">
          <div class="item-top">
            <div class="item-top-title">丈夫/妻子</div>
          </div>
          <div class="inputCover">
            <div class="inputCover-title">姓名:</div>
            <input class="m_input" v-model="item.name1" placeholder="请输入姓名" />
          </div>
          <div class="inputCover">
            <div class="inputCover-title">工作单位:</div>
            <input
              class="m_input"
              v-model="item.workUnit"
              placeholder="请输入工作单位"
            />
          </div>
          <div class="inputCover">
            <div class="inputCover-title">职业:</div>
            <input class="m_input" v-model="item.occupation1" placeholder="请输入职业" />
          </div>
        </div>
        <div v-if="item.type == 3" :key="index" class="item">
          <div class="item-top">
            <div class="item-top-title">子女{{ index-1}}</div>
            <img
              v-if="index >= 3"
              class="delImage"
              @click="delItem(index)"
              src="../../../static/image/delete_img.png"
            />
          </div>
          <div class="inputCover">
            <div class="inputCover-title">姓名:</div>
            <input class="m_input" v-model="item.name1" placeholder="请输入姓名" />
          </div>
          <div class="inputCover">
            <div class="inputCover-title">性别:</div>
            <van-radio-group v-model="item.sex" direction="horizontal">
              <van-radio name="1">
                <span class="radio_text">男</span>
                <template #icon="props">
                  <img
                    class="img-icon"
                    :src="props.checked ? activeIcon : inactiveIcon"
                  />
                </template>
              </van-radio>
              <van-radio name="0">
                <span class="radio_text">女</span>
                <template #icon="props">
                  <img
                    class="img-icon"
                    :src="props.checked ? activeIcon : inactiveIcon"
                  />
                </template>
              </van-radio>
            </van-radio-group>
          </div>
          <div class="inputCover">
            <div class="inputCover-title">生日:</div>
            <div
              class="m_input m_placeholder_1"
              :class="
                item.birthday == '请选择日期'
                  ? 'm_placeholder_1'
                  : 'm_placeholder_2'
              "
              @click="showPop(index)"
            >{{item.birthday}}<img class="arrow" src="../../../static/image/arrow_right_img.png"/>
            </div>
            <van-popup v-model="isShowPop" position="bottom">
              <van-datetime-picker
                type="date"
                title="请选择"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="popChose($event)"
                @cancel="isShowPop = false"
              />
            </van-popup>
          </div>
        </div>
      </template>
      <div class="addBtn" @click="addItem">添加子女</div>
    </div>
    <div class="btnCover">
      <div class="saveBtn" @click="saveAction">保存并进入下一步</div>
    </div>
  </div>
</template>
<script>
import { DatetimePicker, Popup, RadioGroup, Radio, Toast } from "vant";
import api from "@/apis/PersonnelFiles/personnelFiles.js";
export default {
  name: "FamilyInfo",
  data() {
    return {
      staffType:5,
      currentIndex: 0,
      isShowPop: false,
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2100, 0, 1),
      itemArray: [
        {
          type: 1,
        },
        {
          type: 2,
        },
        {
          type: 3,
          birthday: "请选择日期",
        },
      ],
      activeIcon: require("../../../static/image/circle_selected.png"),
      inactiveIcon: require("../../../static/image/circle_un_selected.png"),
    };
  },
  components: {
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      api
        .hrCoe_performance_staffInfo({
          staffType: this.staffType,
        })
        .then((response) => {
          if (response.code == 200) {
            let result = response.result;
            if(result.family){
              let family = result.family;
              if(family.length != 0){
                this.itemArray = [];
                let isHaveFirst = false;
                let isHaveSecond = false;
                let isHaveThird = false;
                family.forEach((item_) => {
                 let item = item_;
                 if(item_.type == 1){
                   isHaveFirst = true;
                 }
                 if(item_.type == 2){
                   isHaveSecond = true;
                 }
                 if(item_.type == 3){
                   isHaveThird = true;
                   if(item_.birthday && item_.birthday != ""){
                   item.birthday = item_.birthday;
                 }else{
                   item.birthday = "请选择日期";
                  }
                 }
                 this.itemArray.push(item);
               });
               if(isHaveFirst == false){
                 let item = {type:1};
                 this.itemArray.unshift(item);
               }
               if(isHaveSecond == false){
                let item = {type:2};
                 this.itemArray.splice(1, 0, item);
               }
               if(isHaveThird == false){
                 let item = {type:3,birthday:"请选择日期"};
                 this.itemArray.splice(2, 0, item);
               }
              }
            }
          }
        });
    },
    showPop(index) {
      this.isShowPop = true;
      this.currentIndex = index;
    },
    popChose(value) {
      this.itemArray[this.currentIndex].birthday = this.filterTime(value);
      this.isShowPop = false;
      this.$forceUpdate();
    },
    //添加子项
    addItem() {
      let item = {
          type: 3,
          birthday: "请选择日期",
      };
      this.itemArray.push(item);
    },
    //删除子项
    delItem(index) {
      this.itemArray.splice(index, 1);
    },
    //保存
    saveAction() {
       let list = [];
      for (let i = 0; i < this.itemArray.length; i++) {
           let item = this.itemArray[i];
           let item_ = {};
           item_.type = item.type;
           if(item.id && item.id != ""){
             item_.id = item.id;
           }
           if(item.name1 && item.name1 != ""){
               item_.name1 = item.name1;
             }
           if(item.type == 1){
             if(item.name2 && item.name2 != ""){
               item_.name2 = item.name2;
             }
             if(item.occupation1 && item.occupation1 != ""){
               item_.occupation1 = item.occupation1;
             }
             if(item.occupation2 && item.occupation2 != ""){
               item_.occupation2 = item.occupation2;
             }
           }
           if(item.type == 2){
             if(item.workUnit && item.workUnit != ""){
               item_.workUnit = item.workUnit;
             }
             if(item.occupation1 && item.occupation1 != ""){
               item_.occupation1 = item.occupation1;
             }
           }
           if(item.type == 3){
             if(item.birthday && item.birthday != "" && item.birthday != "请选择日期"){
               item_.birthday = item.birthday;
             }
             if(item.sex && item.sex != ""){
               item_.sex = item.sex;
             }
           }
           let allKeys = Object.keys(item_);
            if(allKeys != 0){
             if(allKeys.length <= 2){
               if(allKeys.length == 1){
                let key = allKeys[0];
                if(key != "type"){
                    list.push(item_);
                }
               }
               if(allKeys.length == 2){
                let key = allKeys[1];
                if(key != "id"){
                  list.push(item_);
                }
               }
          }else{
            list.push(item_);
          }
         }
        }
      api
        .hrCoe_performance_addStaff({
          staffType: this.staffType,
          dto: JSON.stringify({ family: list }),
        })
        .then((response) => {
          if (response.code == 200) {
            Toast(response.msg);
            setTimeout(() => {
              this.$bus.emit("toParent", this.staffType);
            }, 1000);
          }
        });
    },
    filterTime(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
  },
};
</script>
<style scoped>
.cover {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  margin: 3rem 2rem 7rem 2rem;
  padding: 2rem;
}
.item {
  background: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding: 1rem;
}
.item-top {
  display: flex;
}
.item-top-title {
  margin-left: 3rem;
  width: calc(100% - 6rem);
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: center;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.delImage {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
}
.inputCover {
  display: flex;
  margin: 1rem 0;
}
.inputCover-title {
  font-size: 1.4rem;
  color: #333333;
  width: 6rem;
  text-align: right;
  margin-right: 1rem;
  height: 3.6rem;
  line-height: 3.6rem;
}
.m_textarea {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 1.4rem;
  width: calc(100% - 7rem);
  height: 10rem;
}
.m_input {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 5px;
  padding-left: 0.5rem;
  font-size: 1.4rem;
  width: calc(100% - 7rem);
  height: 3.6rem;
  line-height: 3.6rem;
}
.moreHight {
  height: 10rem;
}
.m_placeholder_1 {
  position: relative;
  text-align: left;
  color: #999999;
  font-size: 1.4rem;
}
.m_placeholder_2 {
  position: relative;
  text-align: left;
  color: #333333;
  font-size: 1.4rem;
}
.arrow {
  position: absolute;
  right: 3%;
  top: 30%;
  width: 0.6rem;
}
.addBtn {
  width: 10rem;
  height: 3rem;
  line-height: 3rem;
  border-radius: 5px;
  font-size: 1.6rem;
  background-color: #13d1be;
  text-align: center;
  margin-left: auto;
  color: white;
  margin-top: 2rem;
}
.center_title {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  color: #333333;
  padding: 1rem 0;
}
.btnCover {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0rem;
  height: 5rem;
  background-color: #fff;
}
.saveBtn {
  width: 20rem;
  height: 4rem;
  background: #13d1be;
  border-radius: 5px;
  margin: auto;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 4rem;
}
.radio_text {
  font-size: 1.2rem;
  padding-top: 0.5rem;
}
.img-icon {
  height: 1.8rem;
}
</style>