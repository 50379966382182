<template>
  <div class="forcenter">
    <div class='title'>— {{dataItem[index].id}}/180 —</div>
    <div class='content-title'>{{dataItem[index].problemName}}</div>
    <div style="position: absolute; width: 100%; bottom: 180px;text-align: center;max-width: 37.5rem;">
    <div class="left-top" :class="isShow&&(allanswer.length > last)&&(allanswer[last].sort == 1)?'answers-bg-active':'answers-bg'" @click="next(1)">A. 是</div>
    <div class="left-item" :class="isShow&&(allanswer.length > last)&&(allanswer[last].sort == 0)?'answers-bg-active':'answers-bg'" @click="next(0)">B. 否</div>
    </div>
    <div class='go_start' style='position:absolute;bottom:65px;text-align:center;' v-if="isShow">
      <div
        class='botton-bg'
        @click='go_start'
      >
        {{name}}
      </div>
    </div>
  </div>
</template>
<script>
//引入分享
import { startShart } from "@/common/js/tecentshare.js";
import { ShareLink,ShareLogo } from "@/apis/const.js";
import Ninepoint from "@/apis/test/test.js";
import {Toast} from 'vant'
export default {
  components: {
    [Toast.name]: Toast
  },
  data() {
    return {
      isShow: false,
      index: 0,
      last: 0,
      name: "上一题",
      dataItem: [
        {
          id: 1
        }
      ],
      allanswer: [
        {
          type: "",
          sort: -1
        }
      ],
      isFromList: 0
    }
  },
  methods: {
    go_start() {
      let that = this;
      if (that.index == 179) {
        that.submit();
        return;
      }
      that.index--;
      that.last--;
      if (that.index > 0) {
        that.isShow = true;
      } else {
        that.isShow = false;
      }
    },
    next(a) {
      let that = this;
      var ansobj = {
        type: "",
        sort: -1
      };
      ansobj.type = that.dataItem[that.index].remark;
      ansobj.sort = a;
      that.allanswer[that.index] = ansobj;
      if (that.index == 179) {
        that.name = "提交";
        return;
      } else {
        that.name = "上一题";
      }
      that.index++;
      that.last++;
      if (that.index > 0) {
        that.isShow = true;
      } else {
        that.isShow = false;
      }
    },
    submit() {
      let that = this;
      var alldata = that.allanswer;
      var number1 = 0;
      var number2 = 0;
      var number3 = 0;
      var number4 = 0;
      var number5 = 0;
      var number6 = 0;
      var number7 = 0;
      var number8 = 0;
      var number9 = 0;
      for (let k = 0; k < alldata.length; k++) {
        switch (alldata[k].type) {
          case "number1":
            number1 += alldata[k].sort * 1;
            break;
          case "number2":
            number2 += alldata[k].sort * 1;
            break;
          case "number3":
            number3 += alldata[k].sort * 1;
            break;
          case "number4":
            number4 += alldata[k].sort * 1;
            break;
          case "number5":
            number5 += alldata[k].sort * 1;
            break;
          case "number6":
            number6 += alldata[k].sort * 1;
            break;
          case "number7":
            number7 += alldata[k].sort * 1;
            break;
          case "number8":
            number8 += alldata[k].sort * 1;
            break;
          case "number9":
            number9 += alldata[k].sort * 1;
            break;
          default:
            break;
        }
      }
      Ninepoint._updateResults(
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
        number7,
        number8,
        number9
      ).then(
        response => {
          that.$toast('测评完成');
          setTimeout(() => {
            if (this.isFromList==1){
              that.$router.go(-1);
            }else{
              that.$router.replace({
                path: "/RecruitList"
              })
            }

          }, 2000);
        },
        res => {}
      );
    },
    _getItem() {
      let that = this;
      Ninepoint._getXCXEvaluationResults().then(
        response => {
          that.dataItem = response.result;
        },
        res => {}
      );
    },
    //分享
    shareContent() {
       const titleStr = "HRCOE";
        const contentStr = "让人力资源工作更简单!";
        const linkStr = ShareLink + "type=1";
        const imgStr = ShareLogo;
        window.shareData = {
          fTitle: titleStr,
          fContent: contentStr,
          fImgUrl: imgStr,
          fLink: linkStr
        };
        window.shareIMdata = {
          fTitle: titleStr,
          fContent: contentStr,
          fImgUrl: imgStr,
          fLink: linkStr
        };
        startShart();
    }
  },
  created() {
    document.title = "测评";
    this.isFromList = this.$route.query.isFromList;
    this._getItem();
  },
  mounted() {
    this.shareContent();
  }
};
</script>
<style scoped>
.forcenter {
  width: 37.5rem;
  margin: 0 auto;
  min-height: 100vh;
  background: #fff;
  background-position: center center;
  background-color: rgb(21, 39, 125);
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://xcx.hryun818.com/service/static/img2/evaluation/major/level_test_bg.png);
}

/* .page_bg{
width:100vw;
height: 100vh;
} */
.go_start {
  width: 140px;
  height: 50px;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: 140px 50px;
  margin: 20px auto 0 auto;
  background-image: url(https://xcx.hryun818.com/service/static/img2/evaluation/major/test_level_btn.png);
}
.go_start image {
  width: 42rem;
  height: 11rem;
}

.answers-bg {
  height: 40px;
  width: 60%;
  line-height: 40px;
  border-radius: 10px;
  border: 1px solid #00aeff;
  padding: 0 0 0 3rem;
  margin: 13px auto;
  color: #fff;
  font-size: 15px;
  text-align: left;
  background-color: #032465;
}

.hide {
  display: none;
}
.pre {
  /* background-color:rgb(21, 39, 125); */
  width: 100vw;
  height: 100vh;
}
.title {
  font-size: 24px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  padding: 30px 0 20px 0;
}
.content-title {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  height: 50px;
  padding: 0 10px;
}

.botton-bg {
  font-size: 20px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  color: white;
}

.answers-bg-active {
  height: 40px;
  width: 60%;
  line-height: 40px;
  border-radius: 10px;
  padding: 0 0 0 30px;
  margin: 10px auto;
  color: #fff;
  font-size: 15px;
  text-align: left;
  background-color: #032465;
  border: 1px solid #00fff0;
}

.left-top {
  margin-bottom: 2rem;
}
</style>
