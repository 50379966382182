<template>
  <div class="train-list" @click="gotoCoure">
    <div class="list-card">
      <div class="top-state"></div>
      <div class="title-bg">
        <div class="train-name">{{coureList.title}}</div>
         <i-circle :percent="coureList.percentage|fillterPercent" :stroke-color="'#13D1BE'" style="margin-right:10px;">
            <span class="demo-Circle-inner" style="font-size:12px">{{coureList.percentage}}%</span>
        </i-circle>
      </div>
      <div class="title-bg">
        <div class="conten-left">
          <div class="coach-name">讲师:{{coureList.teacherName}}</div>
          <div class="coach-name">有效期:<span>{{coureList.cTime}}</span></div>
        </div>
        <div class="btn">
        继续学习
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
  props: {
    coureList: Object
  },
  data() {
    return {};
  },
  components: {},
  filters: {
    fillterPercent(val) {
      return parseInt(val);
    }
  },
  methods: {
    gotoCoure() {
      let that = this;
      that.$emit("gotoDitial", that.coureList, 1);
    }
  },
  mounted() {
    // this.$refs.sexSelect.style = "display:none"
  },
  created() {},
  destoryed() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../../../common/stylus/CusMinit.css";
.train-list {
  width: 100%;
}

.list-card {
  /*width: 35.5rem;*/
  height: 130px;
  margin: 15px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.top-state {
  width: 4rem;
  text-align: center;
  color: #fff;
  border-radius: 5px 0px 5px 0px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
}
.title-bg {
  padding: 0 1rem;
  font-size: 14px;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
}
.train-name {
  margin: 10px 0 0 0;
  font-size: 15px;
  color: #333;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}
.coach-name {
  margin: 5px 0;
  color: #666;
}
.btn {
  width: 65px;
  background: #13d1be;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  border-radius: 15px;
  font-size: 14px;
  margin: 20px 0 0 0;
}
</style>
