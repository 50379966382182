<template>
  <div class="aliplayerbox">
    <div class="aliplayer-container">
      <div class="zhxplayer-box play-box" >
        <div class="prism-player" id="player-con">
          <div class="mask">
            {{userName}} <br> {{cellphone}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  playVideo_ali,
  studyList_editTime,
  studyList_editTimeV2
} from "@/apis/study/index";

export default {
  data() {
    return {
      aliplay: null,
      location: 0,
      userName: "",
      cellphone: "",
      isFirstPlay: true,
      playTime: 0,
      playTimeTimerId: null
    };
  },
  props: {
    detailId: String,
    totalId: String,
    officeId: String,
    userId: String
  },
  components: {},
  created() {
    let that = this;
    that.getVideo();
  },
  activated() {
  },
  mounted() {
    let that = this;
    window.onbeforeunload = function() {
      // if (that.aliplay) {
      // }
    };
  },
  beforeDestroy() {
    if (this.aliplay) {
      clearInterval(this.playTimeTimerId);
      this.playTimeTimerId = null;
    }
  },
  methods: {
    // 实例化
    createAliPlayer(opt) {
      let that = this;
      // eslint-disable-next-line no-undef
      return new Aliplayer(opt, function(player) {
        player._switchLevel = 0;
        // console.log("播放器创建了。");
        player.seek(that.location);
      });
    },
    // 获取视频凭证，
    getVideo() {
      let that = this;
      let type = that.$route.query.type;
      playVideo_ali(that.detailId, that.totalId, type,that.officeId,that.userId).then(
        response => {
          let mediaType = "video";
          if (
            response.result.videoName &&
            response.result.videoName.split(".")[1] === "mp3"
          ) {
            mediaType = "audio";
          }
          let opt = null;
          if (mediaType == "video") {
            opt = {
              id: "player-con",
              qualitySort: "asc",
              format: "m3u8",
              encryptType: 1,
              width: "100%",
              height: "100%",
              autoplay: false,
              isLive: false,
              rePlay: false,
              playsinline: true,
              preload: true,
              controlBarVisibility: "hover",
              useH5Prism: true
            };
            opt.cover = response.result.coverUrl;
            opt.source = response.result.playUrl;
          } else {
            opt = {
              id: "player-con",
              width: "100%",
              height: "100%",
              autoplay: true,
              controlBarVisibility: "always",
              useH5Prism: true,
              skinLayout: [
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [
                    {
                      name: "progress",
                      align: "blabs",
                      x: 0,
                      y: 44
                    },
                    {
                      name: "playButton",
                      align: "tl",
                      x: 15,
                      y: 12
                    },
                    {
                      name: "timeDisplay",
                      align: "tl",
                      x: 10,
                      y: 7
                    },
                    {
                      name: "volume",
                      align: "tr",
                      x: 10,
                      y: 10
                    }
                  ]
                }
              ]
            };
            opt.cover = response.result.coverUrl;
            opt.source = response.result.playUrl;
          }
          that.location = parseInt(response.result.location);
          that.aliplay = that.createAliPlayer(opt);
          that.aliplay.on("ended", sth => {
            clearInterval(that.playTimeTimerId);
            that.playTimeTimerId = null;
          });
          that.aliplay.on("pause", sth => {
            clearInterval(that.playTimeTimerId);
            that.playTimeTimerId = null;
          });
          that.aliplay.on("playing", sth => {
            if (that.isFirstPlay) {
              let time;
              if (that.aliplay._TimeUpdateStamp) {
                time = that.aliplay._TimeUpdateStamp.toFixed(0);
              } else {
                time = that.location;
              }
              that.sendtimeV2(time);
            }
            that.isFirstPlay = false;
            if (that.playTimeTimerId) {
              return;
            } else {
              that.playTimeTimerId = setInterval(() => {
                that.playTime = that.playTime + 1;
                if (that.playTime === 10) {
                  that.playTime = 0;
                  that.sendtimeV2(that.aliplay._TimeUpdateStamp.toFixed(0));
                }
              }, 1000);
            }
          });
        },
        res => {
          // that.$emit('videodesc',res.desc);
        }
      );
    },
    // 向后台发送时间
    sendtime(time) {
      studyList_editTime({
        positionTime: this.aliplay._TimeUpdateStamp.toFixed(0),
        totalId: this.totalId,
        detailId: this.detailId,
        type: this.$route.query.type
      });
    },
    // 10s向后台发送时间
    sendtimeV2(time) {
      if (!time) {
        time = 0;
      }
      studyList_editTimeV2({
        positionTime: time,
        totalId: this.totalId,
        detailId: this.detailId,
        officeId: this.officeId,
        userId: this.userId,
        type: this.$route.query.type
      });
    }
  }
};
</script>
<style scoped >
@import "../../../../common/stylus/CusMinit.css";

.aliplayer-container {
}
.zhxplayer-box {
  height: 20rem;
  width: 100%;
  background-image: url(../../../../static/image/course_placeHolder.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.prism-player {
  position: relative;
}
.mask {
  position: absolute;
  bottom: 50px;
  left: 0;
  color: rgba(#dfdfdf, 0.4);
  font-size: 13px;
  animation: donghua 60s linear infinite alternate;
}

@keyframes donghua {
  0% {
    bottom: 0;
    left: 0;
  }
  20% {
    bottom: 20%;
    left: 93%;
  }
  40% {
    bottom: 40%;
    left: 0;
  }
  60% {
    bottom: 60%;
    left: 93%;
  }
  80% {
    bottom: 80%;
    left: 0;
  }
  100% {
    bottom: 90%;
    left: 93%;
  }
}
</style>