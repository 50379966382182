<template>
    <div class="parent">
        <div class="prompt">您还不是企业员工,请联系管理员!</div>
    </div>

</template>

<script>
    export default {
        name: "PromptPage"
    }
</script>

<style scoped>
    .parent{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
.prompt{
    margin: 0 auto;
    font-size: 16px;
    color: #666666;
}
</style>
