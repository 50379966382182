import {
    get,
    post,
    postJson,
    postform
} from "../utils/base";

// import userCache from '@/common/js/userCache'
function _opts(isShowLoading) {
    var opt = {
        cache: {
            exp: 60
        },
        silent: false
    };
    if (isShowLoading) {
        return Object.assign(opt, {
            progress: true
        })
    }
}

export default {
    //人事档案添加
    hrCoe_performance_addStaff: function (param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('hrCoe/performance/addStaff', params, opts);
    },
    //人事档案信息获取
    //staffType = 1 基本信息 2 学历信息 3 劳务合同 4 紧急联系人 5 家庭信息 6奖惩记录 7 我司工作经历 8 历史工作经历
    hrCoe_performance_staffInfo: function (param,showLoading) {
        const opts = _opts(!showLoading);
        let params = param ? param : {};
        return post('hrCoe/performance/staffInfo', params, opts);
    },
    basicData_selectEducationList: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('basicData/selectEducationList', params, opts);
    },
    basicData_insertPicture: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return postform('basicData/insertPicture', params, opts);
    },
}