<template>
  <div style="display: flex; flex-direction: column">
    <div class="main_view">
      <div class="title">信息填写</div>
      <div class="m_input">
        <van-field v-model="name" placeholder="请填写姓名" />
      </div>
      <div class="m_input">
        <van-field
          v-model="mobile"
          maxlength="11"
          type="tel"
          placeholder="请填写手机号"
        />
      </div>
      <div class="m_input">
        <van-field v-model="deptName" placeholder="请填写部门" />
      </div>
      <div class="m_input">
        <van-field v-model="postName" placeholder="请填写岗位" />
      </div>
    </div>
    <div class="btn_" @click="jump">完成</div>
  </div>
</template>
<script>
import { Toast, Picker, Popup, Field } from "vant";
import api from "@/apis/talentReview/talentReview.js";
import { ShareLink, ShareLogo } from "@/apis/const.js";
import storage from "@/common/js/cacheData";
export default {
  name: "SignInfoInput",
  data() {
    return {
      type: "",
      name: "",
      mobile: "",
      deptName: "",
      postName: "",
    };
  },
  components: {
    [Toast.name]: Toast,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Field.name]: Field,
  },
  mounted() {
    document.title = "填写个人信息";
    let openId = this.$route.query.openId;
    if (openId) {
      storage.saveData("openId", openId);
    }
    this.shareContent();
  },
  methods: {
    jump() {
      if (this.name == "") {
        Toast("请填写姓名");
        return;
      }
      if (this.mobile == "") {
        Toast("请填写手机号");
        return;
      }
      let reg = /(1[0-9]{10}$)/;
      if (!reg.test(this.mobile)) {
        Toast({
          message: "请输入正确手机号",
          position: "middle",
          duration: 1000,
        });
        return;
      }
      if (this.deptName == "") {
        Toast("请填写部门");
        return;
      }
      if (this.postName == "") {
        Toast("请填写岗位");
        return;
      }
      let reqData = {
        name: this.name,
        mobile: this.mobile,
        deptName: this.deptName,
        postName: this.postName,
      };
      api.offline_addStudent(reqData).then((response) => {
        if (response.code == 200) {
          Toast({
            message: "提交成功",
            position: "middle",
            duration: 1500,
          });
          this.$router.push({
            path: "/SignList",
          });
        }
      });
    },
    //分享
    shareContent() {
      const titleStr = "HRCOE";
      const contentStr = "让人力资源工作更简单!";
      const linkStr = ShareLink + "type=1";
      const imgStr = ShareLogo;
      window.shareData = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr,
      };
      window.shareIMdata = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr,
      };
    },
  },
};
</script>
<style scoped>
.main_view {
  margin: 5rem 2rem;
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 2rem;
}
.title {
  padding-top: 1rem;
  padding-bottom: 2rem;
  font-size: 2.4rem;
  font-weight: 400;
  text-align: center;
  color: #333333;
}
.m_input {
  background: #ffffff;
  border: 0.1rem solid #dadada;
  border-radius: 0.5rem;
  margin: 1rem 2rem;
  overflow: hidden;
}
.btn_ {
  width: 25rem;
  color: white;
  height: 4rem;
  margin: 0 auto;
  line-height: 4rem;
  font-size: 1.6rem;
  background-color: #13d1be;
  text-align: center;
  border-radius: 0.5rem;
}
</style>