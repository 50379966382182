<template>
  <div>
    <div class="cover">
      <div class="item" v-for="(item, index) in itemArray" :key="index">
        <div class="item-top">
          <div class="item-top-title">经历{{ index + 1 }}</div>
          <img
            v-if="index != 0"
            class="delImage"
            @click="delItem(index)"
            src="../../../static/image/delete_img.png"
          />
        </div>
        <div class="inputCover">
          <div class="inputCover-title">开始时间:</div>
          <div
            class="m_input m_placeholder_1"
            :class="
              item.startTime == '请选择开始时间'
                ? 'm_placeholder_1'
                : 'm_placeholder_2'
            "
            @click="showPop(1,index)"
          >{{ item.startTime }}<img class="arrow" src="../../../static/image/arrow_right_img.png"/>
          </div>
        </div>
           <div class="inputCover">
          <div class="inputCover-title">结束时间:</div>
          <div
            class="m_input m_placeholder_1"
            :class="
              item.endTime == '请选择结束时间'
                ? 'm_placeholder_1'
                : 'm_placeholder_2'
            "
            @click="showPop(2,index)"
          >{{ item.endTime }}<img class="arrow" src="../../../static/image/arrow_right_img.png"/>
          </div>
        </div>
        <div class="inputCover">
          <div class="inputCover-title">职位名称:</div>
          <input class="m_input" v-model="item.name" placeholder="请输入职位名称"/>
        </div>
        <div class="inputCover">
          <div class="inputCover-title">业绩表现:</div>
          <textarea class="m_textarea" v-model="item.content" placeholder="请输入业绩表现"/>
        </div>
          <van-popup v-model="isShowPop" position="bottom">
            <van-datetime-picker
              type="date"
              title="请选择"
              :min-date="minDate"
              :max-date="maxDate"
              :formatter="formatter"
              @confirm="popChose($event)"
              @cancel="isShowPop = false"
            />
          </van-popup>
      </div>
      <div class="addBtn" @click="addItem">添加经历</div>
    </div>
    <div class="btnCover">
      <div class="saveBtn" @click="saveAction">保存并进入下一步</div>
    </div>
  </div>
</template>

<script>
import { DatetimePicker, Popup, Toast } from "vant";
import api from "@/apis/PersonnelFiles/personnelFiles.js";
export default {
  name: "MyCompanyExperience",
  data() {
    return {
      staffType:7,
      currentIndex:0,
      timeType:1,
      isShowPop: false,
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2100, 0, 1),
      itemArray: [
        {
          startTime: "请选择开始时间",
          endTime: "请选择结束时间",
        },
      ],
    };
  },
  components: {
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      api
        .hrCoe_performance_staffInfo({
          staffType: this.staffType,
        })
        .then((response) => {
          if (response.code == 200) {
            let result = response.result;
            if(result.workExperience){
              let workExperience = result.workExperience;
              if(workExperience.length != 0){
                this.itemArray = [];
                workExperience.forEach((item_) => {
                 let item = {};
                 item = item_;
                 if(item_.startTime && item_.startTime != ""){
                   item.startTime = item_.startTime;
                 }else{
                   item.startTime = "请选择开始时间";
                 }
                 if(item_.endTime && item_.endTime != ""){
                   item.endTime = item_.endTime;
                 }else{
                   item.endTime = "请选择结束时间";
                 }
                 this.itemArray.push(item);
               });
              }
            }
          }
        });
    },
    showPop(type,index){
      this.isShowPop = true;
      this.currentIndex = index;
      this.timeType = type;
    },
    popChose(value) {
      let item = this.itemArray[this.currentIndex];
      if(this.timeType == 1){
          item.startTime = this.filterTime(value);
      }else{
         item.endTime = this.filterTime(value);
      }
      this.isShowPop = false;
      this.$forceUpdate();
    },
    //添加子项
    addItem() {
      let item = {
          startTime: "请选择开始时间",
          endTime: "请选择结束时间",
      };
      this.itemArray.push(item);
    },
    //删除子项
    delItem(index) {
      this.itemArray.splice(index, 1);
    },
    //保存
    saveAction() {
       let list = [];
      for (let i = 0; i < this.itemArray.length; i++) {
         let item = this.itemArray[i];
         let item_ = {};
        if (item.startTime && item.startTime != "" && item.startTime != "请选择开始时间") {
            item_.startTime = item.startTime;
        }
        if (item.endTime && item.endTime != "" && item.endTime != "请选择结束时间") {
            item_.endTime = item.endTime;
        }
        if(item.name && item.name != ""){
            item_.name = item.name;
        }
        if(item.content && item.content != ""){
            item_.content = item.content;
        }
        if(item.id && item.id != ""){
          item_.id = item.id;
        }
        let allKeys = Object.keys(item_);
        if(allKeys != 0){
          if(allKeys.length == 1){
             let key = allKeys[0];
             if(key != "id"){
               list.push(item_);
             }
          }else{
            list.push(item_);
          }
        }
      }
      api
        .hrCoe_performance_addStaff({
          staffType: this.staffType,
          dto: JSON.stringify({ workExperience: list }),
        })
        .then((response) => {
          if (response.code == 200) {
            Toast(response.msg);
            setTimeout(() => {
              this.$bus.emit("toParent", this.staffType);
            }, 1000);
          }
        });
    },
    filterTime(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
  },
};
</script>
<style scoped>
.cover {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  margin: 3rem 2rem 7rem 2rem;
  padding: 2rem;
}
.item {
  background: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding: 1rem;
}
.item-top {
  display: flex;
}
.item-top-title {
  margin-left: 3rem;
  width: calc(100% - 6rem);
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: center;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.delImage {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
}
.inputCover {
  display: flex;
  margin: 1rem 0;
}
.inputCover-title {
  font-size: 1.4rem;
  color: #333333;
  width: 6rem;
  text-align: right;
  margin-right: 1rem;
  height: 3.6rem;
  line-height: 3.6rem;
}
.m_textarea{
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 1.4rem;
  width: calc(100% - 7rem);
  height: 6rem;
}
.m_input {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 5px;
  padding-left: 0.5rem;
  font-size: 1.4rem;
  width: calc(100% - 7rem);
  height: 3.6rem;
  line-height: 3.6rem;
}
.moreHight{
  height: 10rem;
}
.m_placeholder_1 {
  position: relative;
  text-align: left;
  color: #999999;
  font-size: 1.4rem;
}
.m_placeholder_2 {
  position: relative;
  text-align: left;
  color: #333333;
  font-size: 1.4rem;
}
.arrow {
  position: absolute;
  right: 3%;
  top: 30%;
  width: 0.6rem;
}
.addBtn {
  width: 10rem;
  height: 3rem;
  line-height: 3rem;
  border-radius: 5px;
  font-size: 1.6rem;
  background-color: #13d1be;
  text-align: center;
  margin-left: auto;
  color: white;
  margin-top: 2rem;
}
.center_title {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  color: #333333;
  padding: 1rem 0;
}
.btnCover {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0rem;
  height: 5rem;
  background-color: #fff;
}
.saveBtn {
  width: 20rem;
  height: 4rem;
  background: #13d1be;
  border-radius: 5px;
  margin: auto;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 4rem;
}
</style>