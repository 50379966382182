<template>
  <div class="train-list" @click="gotoDitial">
    <div class="list-card">
      <div class="top-state">{{trainList.statusName}}</div>
      <div class="title-bg">
        <div class="train-name">{{trainList.title}}</div>
        <i-circle :percent="trainList.percentage|fillterPercent()" :stroke-color="'#13D1BE'" style="margin-right:10px;">
          <span class="demo-Circle-inner" style="font-size:12px">{{trainList.percentage}}%</span>
        </i-circle>
      </div>
      <div class="title-bg">
        <div class="conten-left">
          <div class="coach-name">教练:{{trainList.teacherName}} <span v-if="trainList.wechatNumber">微信号:<span style="color:#13D1BE;font-weight:bold">{{trainList.wechatNumber}}</span></span></div>
          <div class="coach-name">时间:<span style="color:#666">{{trainList.cTime}}</span></div>
        </div>
        <div class="btn">
          进班
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Main",
    props: {
      trainList: Object
    },
    data() {
      return {};
    },
    filters: {
      fillterPercent(val) {
        return parseInt(val);
      }
    },
    components: {},
    methods: {
      gotoDitial() {
        let that = this;
        this.$emit("gotoDitial", that.trainList, 0);
      }
    },
    mounted() {
      // this.$refs.sexSelect.style = "display:none"
    },
    created() {},
    destoryed() {}
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import "../../../../common/stylus/CusMinit.css";
  .train-list {
    width: 100%;
  }

  .list-card {
    /*width: 35.5rem;*/
    height: 130px;
    margin: 10px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .top-state {
    width: 4rem;
    text-align: center;
    color: #fff;
    background: #fd4e23;
    border-radius: 5px 0px 5px 0px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
  }
  .title-bg {
    padding: 0 1rem;
    font-size: 14px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .train-name {
    margin: 10px 0 0 0;
    font-size: 15px;
    color: #333;
    font-weight: bold;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .coach-name {
    margin: 5px 0;
    color: #666;
  }
  .btn {
    width: 65px;
    background: #13d1be;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    border-radius: 15px;
    font-size: 14px;
    margin: 20px 0 0 0;
  }
</style>
