<template>
  <div>
    <div class="top">
      <div class="top-title">满意度：</div>
      <van-rate
        v-model="score"
        allow-half
        void-icon="star"
        color="#13D1BE"
        void-color="#eee"
        size="30"
      />
    </div>
    <van-field
      class="field"
      v-model="experience"
      type="textarea"
      placeholder="请填写心得"
      autosize
      rows="8"
    />
    <div class="submit" @click="goSubmit()">提交</div>
  </div>
</template>
<script>
import { Toast, Rate, Field } from "vant";
import api from "@/apis/talentReview/talentReview.js";
import { ShareLink, ShareLogo } from "@/apis/const.js";
export default {
  name: "SignExperienceInput",
  data() {
    return {
      id: "",
      experience: "",
      score: 0
    };
  },
  components: {
    [Toast.name]: Toast,
    [Rate.name]: Rate,
    [Field.name]: Field
  },
  created() {
    document.title = "填写心得";
    this.id = this.$route.query.id;
    this.score = parseFloat(this.$route.query.score);
    this.experience = this.$route.query.experience
  },
  mounted() {
    this.shareContent()
  },
  methods: {
    //提交
    goSubmit() {
      if (this.score == 0) {
        Toast("请填写满意度");
        return;
      }
      if (this.experience == "") {
        Toast("请填写心得");
        return;
      }
      api
        .offline_addExperience({
          id: this.id,
          score: this.score,
          experience: this.experience
        })
        .then((response) => {
          if (response.code == 200) {
            Toast({
              message: "提交成功",
              position: "middle",
              duration: 1500
            });
            this.$router.go(-1);
          }
        });
    },
    //分享
    shareContent() {
      const titleStr = "HRCOE";
      const contentStr = "让人力资源工作更简单!";
      const linkStr = ShareLink + "type=1";
      const imgStr = ShareLogo;
      window.shareData = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      window.shareIMdata = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
    }
  }
};
</script>
<style scoped>
.top {
  display: flex;
  width: 23rem;
  margin: 2rem auto;
}
.top-title {
  height: 3rem;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #333333;
  line-height: 3rem;
}
.field {
  background: #f4f4f4;
  border-radius: 0.5rem;
  width: 30rem;
  margin: 0rem auto;
}
.submit {
  width: 20rem;
  height: 4rem;
  background: #13d1be;
  border-radius: 0.5rem;
  margin: 4rem auto;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 4rem;
}
</style>
