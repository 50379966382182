<template>
    <div>
        <div class="box_container padding-10 mrb50">
            <div class="box_container_item" v-for="(item,index) in historyWorkExperienceArray" :key="'education'+index">
                <div style="position: relative">
                    <div class="center_title">经历{{(index+1) | NoToChinese}}</div>
                    <img v-if="index!=0" class="delete_tag"
                         @click="handleDeleteItem(index)"
                         src="../../../static/image/delete_img.png"/>
                </div>

                <div class="item_container">
                    <div class="left_title">入职时间:</div>
                    <div class="m_input m_placeholder"
                         :class="item.startTime=='请选择入职时间'?'m_placeholder':'m_placeholder_2'"
                         @click="showPop(1,index)">{{item.startTime}}
                        <img class="arrow" src="../../../static/image/arrow_right_img.png"/>
                    </div>
                </div>
                <div class="item_container">
                    <div class="left_title">离职时间:</div>
                    <div class="m_input m_placeholder"
                         :class="item.endTime=='请选择离职时间'?'m_placeholder':'m_placeholder_2'"
                         @click="showPop(2,index)">{{item.endTime}}
                        <img class="arrow" src="../../../static/image/arrow_right_img.png"/>
                    </div>
                </div>
                <div class="item_container">
                    <div class="left_title">公司名称:</div>
                    <input class="m_input" v-model="item.name"
                           placeholder="请输入公司名称"/>
                </div>
                <div class="item_container">
                    <div class="left_title">最高职位:</div>
                    <input class="m_input" v-model="item.content"
                           placeholder="请输入最高职位"/>
                </div>
            </div>
            <div class="add_btn" @click="addItem">添加经历</div>
        </div>
        <div class="btnCover">
            <div class="submit_btn" @click="handleSubmit">提交档案</div>
        </div>
        <van-popup v-model="isShowTimePop" position="bottom">
            <van-datetime-picker
                    type="date"
                    title="请选择时间"
                    :min-date="minDate"
                    :formatter="formatter"
                    @confirm="popChose($event)"
                    @cancel="isShowTimePop = false"
            />
        </van-popup>
    </div>
</template>

<script>
    import {DatetimePicker, Popup, Picker, Toast} from "vant";
    import api from "@/apis/PersonnelFiles/personnelFiles.js";

    export default {
        name: "ServiceContract",
        data() {
            return {
                currentIndex: 0,
                isShowTimePop: false,
                minDate: new Date(2000, 0, 1),
                maxDate: new Date(2025, 10, 1),
                historyWorkExperienceArray: []
            }
        },
        components: {
            [Popup.name]: Popup,
            [DatetimePicker.name]: DatetimePicker,
            [Picker.name]: Picker
        },
        created() {
            this.getData()
        },
        methods: {
            getData(showLoading) {
                api.hrCoe_performance_staffInfo({
                    staffType: 8
                },showLoading).then(
                    response => {
                        if (response.code == 200) {
                            this.historyWorkExperienceArray = [...response.result.historyWorkExperience];
                            if (this.historyWorkExperienceArray.length == 0) {
                                let obj = {
                                    startTime: '请选择入职时间',
                                    endTime: '请选择离职时间',
                                    name: '',
                                    content: ''
                                };
                                this.historyWorkExperienceArray.push(obj)
                            }
                            this.setDefault()

                        }
                    }
                )
            },
            setDefault(){
                this.historyWorkExperienceArray.forEach(it => {
                    if (!it.startTime) {
                        it.startTime = '请选择入职时间'
                    }
                    if (!it.endTime) {
                        it.endTime = '请选择离职时间'
                    }
                })
            },
            showPop(type, index) {
                this.isShowTimePop = true;
                this.currentIndex = index;
                this.timeType = type;
            },
            popChose(value) {
                let item = this.historyWorkExperienceArray[this.currentIndex];
                if (this.timeType == 1) {
                    item.startTime = this.filterTime(value);
                } else {
                    item.endTime = this.filterTime(value);
                }
                this.isShowTimePop = false;
                this.$forceUpdate();
            },
            addItem() {
                let obj = {
                    startTime: '请选择入职时间',
                    endTime: '请选择离职时间',
                    name: '',
                    content: ''
                };
                this.historyWorkExperienceArray.push(obj);
                this.setDefault()
            },
            handleDeleteItem(index) {
                this.historyWorkExperienceArray.splice(index, 1)
            },
            handleSubmit() {
                for (let i = 0; i < this.historyWorkExperienceArray.length; i++) {
                    if (this.historyWorkExperienceArray[i].startTime == '请选择入职时间') {
                        delete this.historyWorkExperienceArray[i].startTime
                    }
                    if (this.historyWorkExperienceArray[i].endTime == '请选择离职时间') {
                        delete this.historyWorkExperienceArray[i].endTime
                    }
                    if (this.historyWorkExperienceArray[i].name == '') {
                        delete this.historyWorkExperienceArray[i].name
                    }
                    if (this.historyWorkExperienceArray[i].content == '') {
                        delete this.historyWorkExperienceArray[i].content
                    }
                }
                let arr=[];
                for (let j = 0; j < this.historyWorkExperienceArray.length; j++) {
                    console.log(this.historyWorkExperienceArray[j],'this.historyWorkExperienceArray')
                    if (JSON.stringify(this.historyWorkExperienceArray[j]) != '{}'){
                        arr.push(this.historyWorkExperienceArray[j])
                    }
                }
                api.hrCoe_performance_addStaff({
                    staffType: 8,
                    dto: JSON.stringify({
                        historyWorkExperience: arr
                    })
                }).then(
                    response => {
                        if (response.code == 200) {
                            Toast(response.msg);
                            setTimeout(() => {
                                this.getData(true)
                            }, 1000);

                        }
                    }
                )

            },
            filterTime(time) {
                var date = new Date(time);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                var d = date.getDate();
                d = d < 10 ? "0" + d : d;
                return y + "-" + m + "-" + d;
            },
            formatter(type, val) {
                if (type === 'year') {
                    return `${val}年`;
                } else if (type === 'month') {
                    return `${val}月`;
                } else if (type === 'day') {
                    return `${val}日`;
                }
                return val;
            }
        },
        filters:{
            NoToChinese(num) {
                if (!/^\d*(\.\d*)?$/.test(num)) {
                    alert("Number is wrong!");
                    return "Number is wrong!";
                }
                var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九");
                var BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
                var a = ("" + num).replace(/(^0*)/g, "").split("."),
                    k = 0,
                    re = "";
                for (var i = a[0].length - 1; i >= 0; i--) {
                    switch (k) {
                        case 0:
                            re = BB[7] + re;[]
                            break;
                        case 4:
                            if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
                                re = BB[4] + re;
                            break;
                        case 8:
                            re = BB[5] + re;
                            BB[7] = BB[5];
                            k = 0;
                            break;
                    }
                    if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
                    if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
                    k++;
                }
                if (a.length > 1) //加上小数部分(如果有小数部分)
                {
                    re += BB[6];
                    for (var j = 0; j < a[1].length; j++) re += AA[a[1].charAt(j)];
                }
                return re;
            }
        }
    }
</script>

<style scoped>
    .box_container {
        background: #ffffff;
        border-radius: 1rem;
        margin: 3rem 2rem 2rem 2rem;
        padding: 1rem;
        box-shadow: 0px 0px 2rem 0px rgba(0, 0, 0, 0.10);
    }
    .box_container_item {
        background: #ffffff;
        border-radius: 1rem;
        margin: 1rem 0 1rem 0;
        box-shadow: 0px 0px 2rem 0px rgba(0, 0, 0, 0.10);
    }
    .item_container {
        display: flex;
        align-items: center;
        padding: 1rem;
    }

    .left_title {
        font-size: 1.4rem;
        color: #333333;
        width: 25%;
        text-align: right;
        margin-right: 1rem;
    }

    .m_input {
        background: #ffffff;
        border: 1px solid #dadada;
        border-radius: 5px;
        padding-left: 0.5rem;
        font-size: 1.4rem;
        width: 70%;
        height: 3.6rem;
        line-height: 3.6rem;
    }

    .m_placeholder {
        position: relative;
        text-align: left;
        color: #999999;
        font-size: 1.4rem;
    }

    .m_placeholder_2 {
        position: relative;
        text-align: left;
        color: #333333;
        font-size: 1.4rem;
    }

    .arrow {
        position: absolute;
        right: 3%;
        top: 30%;
        width: 0.6rem;
    }

    .btnCover {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 6rem;
        background: #fff;
    }

    .submit_btn {
        width: 20rem;
        height: 4rem;
        line-height: 4rem;
        border-radius: 5px;
        background-color: #13d1be;
        text-align: center;
        margin: 1rem auto;
        font-size: 1.6rem;
        color: white;
    }

    .padding-10 {
        padding: 1rem;
    }

    .add_btn {
        width: 10rem;
        line-height: 3rem;
        border-radius: 5px;
        font-size: 1.4rem;
        background-color: #13d1be;
        text-align: center;
        margin-left: auto;
        color: white;
    }

    .btn_upload {
        width: 10rem;
        color: white;
        text-align: center;
        line-height: 3rem;
        border-radius: 5px;
        font-size: 1.4rem;
        background-color: #13d1be;
    }

    .center_title {
        font-size: 1.4rem;
        font-weight: 700;
        text-align: center;
        color: #333333;
        padding: 1rem 0;
    }

    .delete_tag {
        position: absolute;
        top: 1.2rem;
        right: 1rem;
        width: 2rem;
    }

    .mrb50 {
        margin-bottom: 7rem;
    }
</style>