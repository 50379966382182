<template>
    <div class="forcenter">
        <div class="m_card">
            <div class="title">
                欢迎参加“HR 诊断”测评系统！
            </div>
            <div class="content">
                您正在测试的是“HR 诊断”测评。
            </div>
            <div class="content">
                专业水平是真正决定⼀个HR在职场上晋升速度的指标,只有符合自身职位的,过硬的专业技能才能够保障各项工作的顺利开展,让我们看看你的HR专业水平到底有多高吧！
            </div>
            <div class="content">
                先不要着急！
            </div>
            <div class="content">
                测评前⼀定要仔细阅读⼀下本篇内容,以达到最好的测评效果。
            </div>
            <div class="content">
                HR专业能力包含多个方面,有些岗位对某些方面具有较⾼高要求,也有些岗位需要全面的能力。
            </div>
            <div class="content">
                我们的每⼀道题都有四个答案,按照程度高低进 行区分,在选择时请认真思考,哪个最符合你的真实情况。
            </div>
            <div class="content">
                本测评总共89道题,预计时间8-10分钟,需要点您的耐心。
            </div>

        </div>
        <van-button class="talent_button" @click="startInventory">开始诊断</van-button>

    </div>
</template>

<script>

    import {Button, Toast} from "vant";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink,ShareLogo} from "@/apis/const.js";
    import storage from "@/common/js/cacheData";
    import api from "@/apis/talentReview/talentReview.js";
    export default {
        name: "DiagnosticNotes",
        data(){
            return{

            }
        },
        components: {
            [Button.name]: Button
        },
        mounted() {
            document.title = "诊断须知";
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            this.showIfBindPhone();
            this.shareContent();
        },
        methods:{
            showIfBindPhone() {
                api._checkOfficeReviewRecord().then(
                    response => {
                        if (response.code == 200) {
                            //0 未填写  1 已经填写 2 未扫码
                            let status=response.result.status;
                            if (status == 0) {
                                Toast('请填写个人信息');
                                this.$router.push({
                                    path: '/jingzhunInfoInput'
                                })
                            } else if (status == 1) {
                                if (response.result.isAddress==1){
                                    //isAddress=1代表之前没填写行业地区
                                    Toast('请完善个人信息');
                                    this.$router.push({
                                        path: '/jingzhunInfoInput',
                                        query: {
                                            isAddress: 1,
                                            username: response.result.username,
                                            id: response.result.id
                                        }
                                    })
                                }
                                // console.log('可以进入下一步')
                            } else if (status == 2) {
                                Toast('请扫码')
                            }
                        }
                    }
                )
            },
            startInventory(){
                this.$router.push({
                    path: '/DiagnosisItem'
                })
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .forcenter {
        min-height: 100vh;
        margin: 0 auto;
        background: #fff;
        overflow-y: auto;
        text-align: center;
    }
    .m_card {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .title {
        font-size: 16px;
        line-height: 2rem;
        margin-bottom: 20px;
        color: #333;
        text-align: center;
        padding: 3rem 0 0 0;
    }

    .content {
        text-indent: 3rem;
        font-size: 14px;
        color: #333;
        text-align: left;
        line-height: 3rem;
    }

    .talent_button {
        background-color: #13d1be;
        border-radius: 3px;
        color: white;
        margin-top: 15%;
        width: 200px;
        height: 40px;
    }
</style>