<template>
    <div class="main_view">
        <div class="main_item">
            <div class="title">1.姓名:</div>
            <van-field maxlength="10"  v-model="name" placeholder="请输入姓名"/>
            <div class="title">2.手机号:</div>
            <van-field maxlength="11" type="tel" v-model="phone" placeholder="请输入手机号"/>
            <div class="title">3.职位:</div>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="postName"
                    placeholder="请选择职位"
                    @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="columns"
                        @confirm="onConfirm"
                        @cancel="showPicker = false"
                />
            </van-popup>
            <div class="title">4.累计工作年限(年):</div>
            <van-field maxlength="2" type="digit" v-model="totalWorkYears" placeholder="请输入工作年限"/>
            <div class="title">5.现公司工作年限(年):</div>
            <van-field maxlength="2" type="digit" v-model="workYears" placeholder="请输入工作年限"/>
            <div class="title">6.公司名称:</div>
            <van-field maxlength="50" v-model="companyName" placeholder="请输入公司名称"/>
<!--            <div class="title">7.行业:</div>-->
<!--            <van-field-->
<!--                    readonly-->
<!--                    clickable-->
<!--                    name="picker"-->
<!--                    :value="industry"-->
<!--                    placeholder="请选择行业"-->
<!--                    @click="showIndustryPicker = true"-->
<!--            />-->
<!--            <van-popup v-model="showIndustryPicker" position="bottom">-->
<!--                <van-picker-->
<!--                        show-toolbar-->
<!--                        :columns="industryColumns"-->
<!--                        :columns-num="2"-->
<!--                        @confirm="onConfirmIndustry"-->
<!--                        @cancel="showIndustryPicker = false">-->
<!--                </van-picker>-->
<!--            </van-popup>-->
            <div class="title">7.公司成立年限(年):</div>
            <van-field maxlength="3" type="digit" v-model="yearsOfIncorporation" placeholder="请输入公司成立年限"/>
<!--            <div class="title">9.所在城市:</div>-->
<!--            <van-field-->
<!--                    readonly-->
<!--                    clickable-->
<!--                    name="picker"-->
<!--                    :value="city"-->
<!--                    placeholder="请选择城市"-->
<!--                    @click="showArea = true"-->
<!--            />-->
<!--            <van-popup v-model="showArea" position="bottom">-->
<!--                <van-area-->
<!--                        :area-list="areaList"-->
<!--                        :columns-num="2"-->
<!--                        @confirm="onConfirmCity"-->
<!--                        @cancel="showArea = false">-->
<!--                </van-area>-->
<!--            </van-popup>-->
            <div class="title">8.员工人数(个):</div>
            <van-field maxlength="8" type="digit" v-model="employeesNumber" placeholder="请输入员工人数"/>
            <div class="title">9.年营业额(亿):</div>
            <van-field maxlength="8" type="number" v-model="annualTurnover" placeholder="请输入年营业额"/>
            <div class="title">10.当前公司面对的主要问题(多选,最多选3个):</div>

            <div class="item_checkbox" v-for="(item,index) in dataList12" :key="index">
                <van-checkbox-group v-model="resultaa" :max="3">
<!--                <van-checkbox v-model="item.checked" icon-size="24px">{{item.content}}</van-checkbox>-->
                <van-checkbox :name="item.content">
                    {{item.content}}
                    <template #icon="props">
                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                    </template>
                </van-checkbox>
                </van-checkbox-group>
            </div>

            <div class="title">11.人才盘点实施情况（单选):</div>
            <van-radio-group v-model="subject13">
                <div class="item_checkbox" v-for="(item,index) in dataList13" :key="index">
                    <van-radio :name="item.content">
                        {{item.content}}
                        <template #icon="props">
                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                        </template>
                    </van-radio>
                </div>
            </van-radio-group>
            <div class="title">12.中基层干部培训实施情况（单选):</div>
            <van-radio-group v-model="subject14">
                <div class="item_checkbox" v-for="(item,index) in dataList14" :key="index">
                    <van-radio :name="item.content">
                        {{item.content}}
                        <template #icon="props">
                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                        </template>
                    </van-radio>
                </div>
            </van-radio-group>
            <div class="title">13.在线培训实施情况（单选):</div>
            <van-radio-group v-model="subject15">
                <div class="item_checkbox" v-for="(item,index) in dataList15" :key="index">
                    <van-radio :name="item.content">
                        {{item.content}}
                        <template #icon="props">
                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                        </template>
                    </van-radio>
                </div>
            </van-radio-group>
        </div>
        <van-button
                class="talent_button"
                type="primary"
                @click="handleSubmit">提交信息
        </van-button>
    </div>

</template>

<script>
    // import areaList from '@/assets/Area.js';
    // import industryList from '@/assets/hangye2.json';
    import {Area, Button, CellGroup, Field, Form, Picker, Popup, Toast, Checkbox, RadioGroup, Radio,CheckboxGroup} from "vant";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import api from "@/apis/talentReview/talentReview.js";
    import storage from "@/common/js/cacheData";

    export default {
        name: "UserInfoInput",
        data() {
            return {
                resultaa: [],
                id: null,
                showIndustryPicker: false,
                industryColumns: [],
                showArea: false,
                areaList: {},
                subject12: '',
                subject13: '',
                subject14: '',
                subject15: '',
                checked: false,
                activeIcon: require('../../../static/image/circle_selected.png'),
                inactiveIcon: require('../../../static/image/circle_un_selected.png'),
                name: null,
                phone: null,
                postName: null,
                workYears: null,
                totalWorkYears: null,
                companyName: null,
                industry: null,
                yearsOfIncorporation: null,
                city: null,
                employeesNumber: null,
                annualTurnover: null,
                columns: [
                    'SSC经理',
                    '培训专家',
                    '招聘专家',
                    '绩效薪酬专家',
                    '组织发展OD',
                    'HRM',
                    'HRD',
                    'CHRO/HRVP',
                    '副总经理',
                    '总经理'
                ],
                showPicker: false,
                dataList12: [
                    {
                        content: '业绩增长乏力，战略不清，前途迷茫',
                        checked: false
                    },
                    {
                        content: '产品迭代慢，竞争力逐渐下滑，新产品研发进度慢',
                        checked: false
                    }, {
                        content: '营销能力弱，新市场拓展不足，新营销渠道没打开',
                        checked: false
                    }, {
                        content: '中高层团队战斗力弱，无法满足未来预期业绩要求',
                        checked: false
                    }, {
                        content: '业务骨干缺乏，很多岗位配置偏低，腿部力量弱',
                        checked: false
                    }, {
                        content: '绩效激励机制设计不到位，考核流于形式',
                        checked: false
                    }, {
                        content: '牛人引进难，拿不到简历，即使约见了也无法有效吸引',
                        checked: false
                    }, {
                        content: '培训机制落后，无法培养出真正牛人',
                        checked: false
                    }, {
                        content: '人才盘点没做/做的很好，很多人明显达不到要求却没有被清理',
                        checked: false
                    }, {
                        content: '团队士气差，员工出工不出力',
                        checked: false
                    }
                ],
                dataList13: [
                    {
                        content: '没做过系统盘点，没工具/也不知道怎么做',
                        checked: false
                    },
                    {
                        content: '做过特别简单的评价盘点，主要在调薪和优化时',
                        checked: false
                    }, {
                        content: '对核心干部用传统的九宫格盘点工具盘点过，深度不够',
                        checked: false
                    }, {
                        content: '引进/开发了全面盘点工具，利用盘点结果对全体员工进行了优化、培养方案设计',
                        checked: false
                    }
                ],
                dataList14: [
                    {
                        content: '基本没有培训，靠自然成长',
                        checked: false
                    },
                    {
                        content: '做过零散的培训，不系统，没啥效果',
                        checked: false
                    }, {
                        content: '每年都有预算，安排多次线下培训，但效果不好',
                        checked: false
                    }, {
                        content: '培训每次做的很到位，干部能力提升非常明显',
                        checked: false
                    }
                ],
                dataList15: [
                    {
                        content: '从没做过线上培训',
                        checked: false
                    },
                    {
                        content: '购买过一些线上课程，但效果不好',
                        checked: false
                    }, {
                        content: '为核心干部都配置了线上课程，有一定效果，但不明显',
                        checked: false
                    }, {
                        content: '针对核心员工做过线上实战训练营，效果非常明显',
                        checked: false
                    }
                ],
                userInfoData: {}
            }
        },
        components: {
            [Toast.name]: Toast,
            [CellGroup.name]: CellGroup,
            [Button.name]: Button,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Area.name]: Area,
            [Form.name]: Form,
            [Checkbox.name]: Checkbox,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
            [Field.name]: Field,
            [CheckboxGroup .name]: CheckboxGroup
        },
        mounted() {
            document.title = '自我介绍';
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            //判断是否判断是否填写信息
            this.showIfBindPhone();
            this.shareContent();
        },
        methods: {
            showIfBindPhone() {
                api._checkOfficeReviewRecord().then(
                    response => {
                        if (response.code == 200) {
                            //0 未填写  1 已经填写 2 未扫码
                            let status = response.result.status;
                            if (status == 0) {
                                Toast('请填写个人信息');
                                this.$router.push({
                                    path: '/jingzhunInfoInput'
                                })
                            } else if (status == 1) {
                                if (response.result.isAddress==1){
                                    //isAddress=1代表之前没填写行业地区
                                    Toast('请完善个人信息');
                                    this.$router.push({
                                        path: '/jingzhunInfoInput',
                                        query: {
                                            isAddress: 1,
                                            username: response.result.username,
                                            id: response.result.id
                                        }
                                    })
                                }else {
                                    this.getDataList();
                                    // this.getData();
                                }


                            } else if (status == 2) {
                                Toast('请扫码')
                            }
                        }
                    }
                )
            },
            // getData(){
            //     this.areaList = areaList;
            //     this.industryColumns = industryList;
            //     // api._get_post_list().then(
            //     //     response => {
            //     //         if (response.code == 200) {
            //     //             response.result.forEach(item => {
            //     //                 this.columns.push(item.postName)
            //     //             })
            //     //         }
            //     //     }
            //     // );
            // },
            getDataList() {
                api._info_show().then(
                    response => {
                        if (response.code == 200) {
                            this.userInfoData = response.result;
                            this.id = this.userInfoData.id;
                            if (this.id) {
                                this.name = this.userInfoData.value1;
                                this.phone = this.userInfoData.value2;
                                this.postName = this.userInfoData.value3;
                                this.totalWorkYears = this.userInfoData.value4;
                                this.workYears = this.userInfoData.value5;
                                this.companyName = this.userInfoData.value6;
                                // this.industry = this.userInfoData.value7;
                                this.yearsOfIncorporation = this.userInfoData.value8;
                                // this.city = this.userInfoData.value9;
                                this.employeesNumber = this.userInfoData.value10;
                                this.annualTurnover = this.userInfoData.value11;//年营业额
                                this.subject13 = this.userInfoData.value13;
                                this.subject14 = this.userInfoData.value14;
                                this.subject15 = this.userInfoData.value15;
                                this.resultaa = this.userInfoData.value12.split('#');
                                // var subject12Array = this.userInfoData.value12.split('#');
                                // subject12Array.forEach(item => {
                                //
                                //     this.dataList12.forEach(it => {
                                //         if (item == it.content) {
                                //             it.checked = true
                                //         }
                                //     })
                                // });
                            }
                        }
                    }
                )
            },
            onConfirm(value) {
                this.postName = value;
                this.showPicker = false;
            },
            onConfirmIndustry(value) {
                this.industry = value.toString();
                this.showIndustryPicker = false;
            },
            onConfirmCity(values) {
                this.city = values
                    .filter((item) => !!item)
                    .map((item) => item.name)
                    .join('/');
                this.showArea = false;
            },
            handleSubmit() {
                this.subject12 = '';
                this.resultaa.forEach(item => {
                  this.subject12 += item + '#'
                });
                if (this.subject12 != '') {
                    this.subject12 = this.subject12.substring(0, this.subject12.length - 1);
                }
                if (!this.name) {
                    Toast('请输入姓名');
                    return
                }
                if (!this.phone) {
                    Toast('请输入手机号');
                    return
                }
                let reg = /(1[0-9]{10}$)/;
                if (!reg.test(this.phone)) {
                    Toast("请输入正确手机号");
                    return
                }
                if (!this.postName) {
                    Toast('请选择职位');
                    return
                }
                if (!this.totalWorkYears) {
                    Toast('请输入累计工作年限');
                    return
                }
                if (!this.workYears) {
                    Toast('请输入现公司工作年限');
                    return
                }
                if (!this.companyName) {
                    Toast('请输入公司名称');
                    return
                }
                // if (!this.industry) {
                //     Toast('请选择行业');
                //     return
                // }
                if (!this.yearsOfIncorporation) {
                    Toast('请输入公司成立年限');
                    return
                }
                // if (!this.city) {
                //     Toast('请选择城市');
                //     return
                // }
                if (!this.employeesNumber) {
                    Toast('请输入员工人数');
                    return
                }
                if (!this.yearsOfIncorporation) {
                    Toast('请输入年营业额');
                    return
                }
                if (!this.subject12) {
                    Toast('请选择公司面对的主要问题');
                    return
                }
                if (!this.subject13) {
                    Toast('请选择人才盘点实施状况');
                    return
                }
                if (!this.subject14) {
                    Toast('请选择中层干部培训实施状况');
                    return
                }
                if (!this.subject15) {
                    Toast('请选择在线培训实施状况');
                    return
                }
                let reqData = {
                    value1: this.name,
                    value2: this.phone,
                    value3: this.postName,
                    value4: this.totalWorkYears,
                    value5: this.workYears,
                    value6: this.companyName,
                    // value7: this.industry,
                    value8: this.yearsOfIncorporation,
                    // value9: this.city,
                    value10: this.employeesNumber,
                    value11: this.annualTurnover,
                    value12: this.subject12,
                    value13: this.subject13,
                    value14: this.subject14,
                    value15: this.subject15
                };
                if (this.id) {
                    reqData.id = this.id
                }
                api._info_save(reqData).then(
                    response => {
                        if (response.code == 200) {
                            Toast('提交成功');
                            this.getDataList();
                        }
                    }
                )
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .main_view {
        overflow-y: auto;
        background-color: #f4f4f4
    }
    .img-icon {
        height: 20px;
    }
    .main_item {
        margin: 0 10px 50px 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        background-color: white;
    }

    .title {
        color: #333333;
        padding: 10px 16px 0 16px;
        font-size: 14px;
        font-weight: bold;
    }

    .item_checkbox {
        margin: 15px 16px;
        font-size: 14px;
        color: #333333;
    }

    .talent_button {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #13d1be;
        border: 0px;
    }
</style>