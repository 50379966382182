<template>
    <div class="m-tabbar">
        <van-tabbar route active-color="#13d1be">
            <!--            <van-tabbar-item replace to="/Study" icon="home-o">学习</van-tabbar-item>-->
            <!--            <van-tabbar-item replace to="/TalentReviewList" icon="search">盘点</van-tabbar-item>-->
            <van-tabbar-item to="/HomePage" replace>
                <span>首页</span>
                <template #icon="props">
                    <img :src="props.active ? icon1.active : icon1.inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/TeamPage" replace>
                <span>团队</span>
                <template #icon="props">
                    <img :src="props.active ? icon2.active : icon2.inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/CustomerPage" replace>
                <span>客户</span>
                <template #icon="props">
                    <img :src="props.active ? icon2.active : icon2.inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/CommissionPage" replace>
                <span>佣金</span>
                <template #icon="props">
                    <img :src="props.active ? icon2.active : icon2.inactive" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script type="text/ecmascript-6">
    import {Tabbar, TabbarItem} from "vant";

    export default {
        data() {
            return {
                icon1: {
                    active: require('../../../../static/image/hr_study_selected.png'),
                    inactive: require('../../../../static/image/hr_study_unselected.png')
                },
                icon2: {
                    active: require('../../../../static/image/hr_review_selected.png'),
                    inactive: require('../../../../static/image/hr_review_unselected.png')
                }
            };
        },
        props: {

        },
        components: {
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem
        },
        methods: {

        },
        created() {
        },
        watch: {
        },
        filters: {}
    };
</script>

<style scoped>
    .m-tabbar {
        /* height: 4.6rem; */
        /*max-width: 37.5rem;*/
        margin: 0 auto;
        position: fixed;
        /*z-index: 9999;*/
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #e4e4e4;
        background: #fafafa;
        display: flex;
        display: -webkit-flex;
        box-sizing: border-box;
        display: -webkit-box;
        display: -ms-flexbox;
        text-align: center;
        margin: auto;
    }

    .mint-tab-item-icon {
        width: 1.9rem;
        height: 1.9rem;
        margin: 0 auto 0.5rem;
    }

    .mint-tab-item-icon .image {
        display: block;
        width: 100%;
        height: 100%;
    }

    .mint-tab-item {
        padding: 0.7rem 0 0.6rem 0;
        color: #333333;
    }
</style>
