<template>
  <div class="forcenter">
    <div style="padding:0 1.5rem;">
      <div class="title">
        古语云:性格决定命运。从现代人力资源管理角度:职
        业选择要和人格特质匹配，无论你是刚入HR职业的
        小白，还是已经征战多年的HR老鸟，选择正确的职
        业路径可以让你事半功倍!

      </div>
      <div class="tips">
        ●  先不要着急！
      </div>
      <div class="tips">
        ●  测评前⼀定要仔细阅读⼀下本篇内容,以达到最好<br/>
          &nbsp;&nbsp;&nbsp;的测评效果。
      </div>
      <div class="tips">
        ● 我们的每⼀道题都有四个答案,按照程度高低进<br/>
          &nbsp;&nbsp;&nbsp;行区分,在选择时请认真思考,哪个最符合你的真<br/>
          &nbsp;&nbsp;&nbsp;实情况。

      </div>
      <div class="tips">
        ● 人格没有好坏，认清自己，未来才能找，到更适<br/>
          &nbsp;&nbsp;&nbsp;合自己的⽅方向。请跟随感觉选出最符合你的选<br/>
          &nbsp;&nbsp;&nbsp;项。 注:本测评总共180道题，预计15- -20分钟，<br/>
          &nbsp;&nbsp;&nbsp;需要点您的耐⼼呦!
      </div>
    </div>
    <div class='go_start' style="position: absolute; bottom: 65px;text-align: center;left:0;right:0">
      <div class='botton-bg' @click='go_start'>
        开始测试
      </div>
    </div>
  </div>
</template>
<script>
import { startShart } from "@/common/js/tecentshare.js";
import { ShareLink , ShareLogo} from "@/apis/const.js";
import storage from "@/common/js/cacheData";
export default {
  data() {
    return {
      isFromList: 0
    };
  },
  methods: {
    go_start() {
      let that = this;
      that.$router.replace({
        path: "/personalityTestItem",
        query: {
          t: Date.now(),
          isFromList: this.isFromList
        }
      });
    },
    //分享
    shareContent() {
      const titleStr = "HRCOE";
      const contentStr = "让人力资源工作更简单!";
      const linkStr = ShareLink + "type=1";
      const imgStr = ShareLogo;
      window.shareData = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      window.shareIMdata = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      startShart();
    }
  },
  created() {
    let that = this;
    document.title = "测评";
    let openId = that.$route.query.openId;
    if (openId) {
      storage.saveData("openId", openId);
    }
    that.isFromList = that.$route.query.isFromList;
  },
  mounted() {
    this.shareContent();
  },
  watch: {}
};
</script>
<style scoped>
.forcenter {
  height: 100vh;
  width: 37.5rem;
  margin: 0 auto;
  background: #fff;
  padding-bottom: 3rem;
  background-image: url("https://xcx.hryun818.com/service/static/img2/evaluation/major/level_test_bg.png");
  background-size: 37.5rem 100%;
  background-repeat: no-repeat;
}

.title {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  padding: 30px 0 20px 0;
}
.tips {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  /* text-align: center; */
  padding: 0rem 0 10px 0;
}

.content {
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 20px;
}

.forgot-wrap {
  margin: 30rem 1rem 0 1rem;
}

.forgot-btn {
  margin: 0 auto;
  width: 90%;
  margin-top: 20px;
}
.btn {
  background: #6e92ea;
  color: #fff;
}
.disable {
  background: rgb(168, 190, 247);
  color: #fff;
}

.go_start {
  width: 140px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: 140px 50px;
  margin: auto;
  background-image: url(https://xcx.hryun818.com/service/static/img2/evaluation/major/test_level_btn.png);
}
.go_start image {
  width: 42rem;
  height: 11rem;
}

.botton-bg {
  font-size: 20px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  color: white;
}
</style>
