<template>
    <div>
        <div>客户</div>
        <foot/>
    </div>
</template>

<script>
    import Foot from "../components/public/d_foot.vue";
    export default {
        name: "CustomerPage",
        data(){
            return{

            }
        },
        components:{
            Foot
        }
    }
</script>

<style scoped>

</style>