import Storage from "store"

const USER = "USER"
const USERUID = "uid"


export default {
    //app 储存uid
    saveUid(uid){
        Storage.set(USERUID, uid);
    },
    getMobileUserId(){
        return  Storage.get(USERUID);
    },
    //存user数据 服务号
    save(userData) {
        Storage.set(USER, userData);
    },
    //获得用户数据
    getUserData() {
        let userData = Storage.get(USER);
        if (userData) {
            return userData;
        } else {
            return {
                wechatUserId:'',
                nickName:'',
                cellphone:'',
                sex2:'',
                headImgUrl:'',
                dengjiId:'',
                pcUserId:'',
                authType:'',
                companyId:'',
                bar:0,
                companyAuthUrl:'',
                remark:''
            };
        }
    },//是否点击过
    getRemark() {
        let userData = Storage.get(USER);
        if (userData && userData.remark) {
            return userData.remark;
        } else {
            return null;
        }
    },
    //获得授权的url
    getCompanyAuthUrl() {
        let userData = Storage.get(USER);
        if (userData && userData.companyAuthUrl) {
            return userData.companyAuthUrl;
        } else {
            return null;
        }
    },
    //获得bar币数量
    getBar() {
        let userData = Storage.get(USER);
        if (userData && userData.bar) {
            return userData.bar;
        } else {
            return null;
        }
    },
    //获得是否参与公司审核
    getCompanyId() {
        let userData = Storage.get(USER);
        if (userData && userData.companyId) {
            return userData.companyId;
        } else {
            return null;
        }
    },
    //获得公司信息审核状态
    getAuthType() {
        let userData = Storage.get(USER);
        if (userData && userData.authType) {
            return userData.authType;
        } else {
            return null;
        }
    },
    //获得userId
    getUserId() {
        let userData = Storage.get(USER);
        if (userData && userData.wechatUserId) {
            return userData.wechatUserId;
        } else {
            return null;
        }
    },
    //获得pcuserId
    getPCUserId() {
        let userData = Storage.get(USER);
        if (userData && userData.pcUserId) {
            return userData.pcUserId;
        } else {
            return null;
        }
    },
    //获得unioId
    getUnioId() {
        let userData = Storage.get(USER);
        if (userData && userData.unionId) {
            return userData.unionId;
        } else {
            return null;
        }
    },
    //获得userName
    getUserName() {
        let userData = Storage.get(USER);
        if (userData && userData.nickName) {
            return userData.nickName;
        } else {
            return null;
        }
    },
     //获得PCuserName
     getPCUserName() {
        let userData = Storage.get(USER);
        if (userData && userData.username) {
            return userData.username;
        } else {
            return null;
        }
    },
    //获得头像
    getHeadImgUrl() {
        let userData = Storage.get(USER);
        if (userData && userData.headImgUrl) {
            return userData.headImgUrl;
        } else {
            return null;
        }
    },
    //获得MobilePhone
    getMobilePhone() {
        let userData = Storage.get(USER);
        if (userData && userData.cellphone) {
            return userData.cellphone;
        } else {
            return null;
        }
    },
    //获得会员等级
    getDengjiId() {
        let userData = Storage.get(USER);
        if (userData && userData.dengjiId) {
            return userData.dengjiId;
        } else {
            return null;
        }
    },
    //清空缓存数据
    clear() {
        Storage.remove(USER);
    }
}
