<template>
    <div class="distributeCard">
        <div id="cardBac" class="two" ref="drawPic">
            <div class="two_inside">
                <img id="filterbg2" class="head_img"
                     @load="loadImage"
                     crossorigin="anonymous"
                     :src="posterUrl">
                <div class="fx_name">{{name}}</div>
                <div class="bottom_view">
                    <!--          crossorigin="anonymous" -->
<!--                    <img class="erweima" src="../../../../static/image/图像 170.png">-->
                    <img class="erweima" crossorigin="anonymous" :src="qrUrl">
                    <div style="display: flex;flex-direction: column;
          justify-content: space-between;margin-left: 1rem">
                        <div style="font-size: 1.4rem;color: #333333;font-weight: bold;margin-top: 0.5rem">HR COE</div>
                        <div style="font-size: 1.2rem;color: #999999;margin-bottom: 1rem">扫码完成测评</div>
                    </div>
                </div>
            </div>
            <div class="bottom_tag">HR COE</div>
        </div>

        <div class="three">
            <img id="postAAA" class="showEwCode" v-if="showPic" :src="xx" alt="">
        </div>
        <div class="btn-save">长按图片保存到本地</div>

    </div>


</template>
<script>
    //引入api
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import html2canvas from "html2canvas";
    import ColorThief from "colorthief";
    import api from "@/apis/talentReview/talentReview.js";

    export default {
        name: "QrCard",
        data() {
            return {
                dataInfo: {},
                xx: "",
                showPic: false,
                bacolor: null,
                name: null,
                posterUrl: null,
                qrUrl: null,
                shareUrl: null
            };
        },
        components: {},
        mounted() {
            let that = this;
            that.shareContent();
            setTimeout(function () {
                //html2canvas转换图片
                that.savePic();
            }, 2000);
        },
        created() {
            let that = this;
            document.title = "生成海报";
            that.name = that.$route.query.name;
            that.posterUrl = that.$route.query.posterUrl;
            that.qrUrl = that.$route.query.qrUrl;
            that.shareUrl = that.$route.query.qrUrl;
        },
        methods: {
            loadImage() {//图片加载完成后调用
                let domImg = document.querySelector('#filterbg2');
                let colorthief = new ColorThief();
                this.bacolor = colorthief.getPalette(domImg)[0];
//		    this.$emit('changeColor',this.bacolor)//往父级传值
                const cardBac = document.getElementById("cardBac");
                let arr = this.bacolor;
                cardBac.style.backgroundColor = `rgb(${arr[0]},${arr[1]},${arr[2]},.8)`;
            },

            //生成图片
            savePic() {
                let that = this;
                let targetDom = that.$refs.drawPic;
                html2canvas(targetDom, {
                    useCORS: true,
                    allowTaint: false,
                    logging: false
                }).then(function (canvas) {
                    var x = canvas.toDataURL("image/png");
                    that.xx = x;
                    that.showPic = true;
                });
            },
            //分享
            shareContent() {
                const titleStr = this.name;
                const contentStr = "让人力资源工作更简单!";
                const linkStr = this.shareUrl;
                const imgStr = this.posterUrl;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }


    };
</script>

<style scoped>
    .distributeCard {
        /*position: relative;*/
        width: 100%;
        height: 100%;
    }

    .distributeCard .two {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .distributeCard .two .two_inside {
        position: absolute;
        width: 90%;
        height: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        border-radius: 10px;
    }

    .distributeCard .two .two_inside .head_img {
        width: 100%;
        height: 62%;
        overflow: hidden;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .distributeCard .two .bottom_tag {
        font-size: 1.4rem;
        color: #f4f4f4;
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .fx_price {
        color: #FD4E23;
        font-size: 1.8rem;
        font-weight: bold;
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-right: 1rem;
        padding-left: 2rem;
    }

    .fx_original_price {
        color: #999999;
        font-size: 1.2rem;
        /*padding-top: 20px;*/
        /*padding-bottom: 20px;*/
        text-decoration: line-through;
    }

    .fx_name {
        color: #333333;
        font-size: 1.6rem;
        font-weight: bold;
        padding: 2rem;
        margin-bottom: 2rem;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .bottom_view {
        padding-left: 2rem;
        display: flex;
    }

    .erweima {
        width: 8rem;
        height: 8rem;
    }

    .toast_content {
        position: absolute;
        top: 50%;
        left: 40%;
        font-size: 1.6rem;
    }

    .distributeCard .three {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
    }

    .distributeCard .three .showEwCode {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .distributeCard .btn-save {
        position: fixed;
        bottom: 1%;
        font-size: 1.4rem;
        color: #dadada;
        width: 100%;
        text-align: center;
    }
</style>
