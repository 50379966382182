<template>
    <div>
        <van-sticky style="margin-bottom: 2rem;margin-top: 1rem">
            <van-tabs v-model="active" color="#13d1be" title-active-color="#13d1be" @click="selectType">
                <van-tab title="基本信息"></van-tab>
                <van-tab title="学历信息"></van-tab>
                <van-tab title="劳务合同"></van-tab>
                <van-tab title="紧急联系人"></van-tab>
                <van-tab title="家庭信息"></van-tab>
                <van-tab title="奖惩记录"></van-tab>
                <van-tab title="我司工作经历"></van-tab>
                <van-tab title="历史工作经历"></van-tab>
            </van-tabs>
        </van-sticky>
<!--        <keep-alive>-->
            <BaseInfo v-if="type==0"></BaseInfo>
            <EducationInfo v-if="type==1"></EducationInfo>
            <ServiceContract v-if="type==2"></ServiceContract>
            <EmergencyContact v-if="type==3"></EmergencyContact>
            <FamilyInfo v-if="type==4"></FamilyInfo>
            <RewardPunishmentRecords v-if="type==5"></RewardPunishmentRecords>
            <MyCompanyExperience v-if="type==6"></MyCompanyExperience>
            <HistoryExperience v-if="type==7"></HistoryExperience>
<!--        </keep-alive>-->

    </div>

</template>

<script>
    import {Tab, Tabs, Button, Sticky} from 'vant';
    import BaseInfo from "./BaseInfo";
    import EducationInfo from "./EducationInfo";
    import ServiceContract from "./ServiceContract";
    import EmergencyContact from "./EmergencyContact";
    import FamilyInfo from "./FamilyInfo";
    import RewardPunishmentRecords from "./RewardPunishmentRecords";
    import MyCompanyExperience from "./MyCompanyExperience";
    import HistoryExperience from "./HistoryExperience";
    import storage from "@/common/js/cacheData";
    export default {
        name: "PersonnelFilesPage",
        components: {
            HistoryExperience,
            MyCompanyExperience,
            RewardPunishmentRecords,
            FamilyInfo,
            EmergencyContact,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Button.name]: Button,
            [Sticky.name]: Sticky,
            ServiceContract, EducationInfo, BaseInfo
        },
        data() {
            return {
                active: 0,
                type: 0
            }
        },
        created() {
            document.title = '档案填写';
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            if (this.type == 0) {
                //基本信息
                this.active = 0
            } else if (this.type == 1) {
                //学历信息
                this.active = 1
            } else if (this.type == 2) {
                //劳务合同
                this.active = 2
            } else if (this.type == 3) {
                //紧急联系人
                this.active = 3
            } else if (this.type == 4) {
                //家庭信息
                this.active = 4
            } else if (this.type == 5) {
                //奖惩记录
                this.active = 5
            } else if (this.type == 6) {
                //我司工作经历
                this.active = 6
            } else if (this.type == 7) {
                //历史工作经历
                this.active = 7
            }
        },
        mounted() {
            this.$bus.off("toParent");
            this.$bus.on("toParent", param => {
                this.jumpNext(param);
            });
        },
        methods: {
            jumpNext(param) {
                this.active = param;
                this.type = param
            },
            selectType(title) {
                if (title == 0) {
                    this.type = 0
                } else if (title == 1) {
                    this.type = 1
                } else if (title == 2) {
                    this.type = 2
                } else if (title == 3) {
                    this.type = 3
                } else if (title == 4) {
                    this.type = 4
                } else if (title == 5) {
                    this.type = 5
                } else if (title == 6) {
                    this.type = 6
                } else if (title == 7) {
                    this.type = 7
                }
            }
        }
    }
</script>

<style scoped>
    /*/deep/.van-tabs__line {*/
    /*    position: absolute;*/
    /*    bottom: 15px;*/
    /*    left: 0;*/
    /*    z-index: 1;*/
    /*    min-width: 50px;*/
    /*    height: 44px;*/
    /*    padding: 0 10px;*/
    /*    background-color: #dddddd47;*/
    /*    border-radius: 30px;*/
    /*    box-sizing: content-box;*/
    /*}*/
    /deep/.van-tab{
        font-size: 1.4rem;
    }
    /deep/.van-tab--active{
        font-weight: bold;
        font-size: 1.6rem;
    }
</style>