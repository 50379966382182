import {
    get,
    post,
    postform
  } from '../utils/base';
  import Storage from "store"

  export default {
    //获取基础数据
    _selectBasicData: function () {
      const opts = _opts(true);
      return get('target/selectBasicData', {}, opts);
    },
    //获取目标数据
    _getUserTarget: function () {
      const opts = _opts(true);
      return get('target/getUserTarget', {
      }, opts);
    },
    //目标数据提交
    _addUserTarget: function (target,user) {
      const opts = _opts(true);
      return get('target/addUserTarget', {
        target: JSON.stringify(target),
        user: JSON.stringify(user)
      }, opts);
    },
    //上传图片
    _uploadImage: function (connectId,campId,file,content,sign) {
      const opts = _opts(true);
      let params={
        connectId: connectId,
        campId: campId
      };
      if (file){
        params['file']=file
      }
      if (content){
        params['content']=content
      }

      if (sign){
        params['sign']=sign
      }
      return postform('WeChatEvaluation/addHomeworkConnect', params, opts);
    },
    //上传简历信息
    _addUserResume: function (file,resumeType) {
      const opts = _opts(true);
      let params={
        resumeType: resumeType
      };
      if (file){
        params['file']=file
      }
      return postform('target/addUserResume', params, opts);
    },
    //目标数据提交
    _queryHomeworkConnect: function (connectId,sign) {
      const opts = _opts(true);
      return get('WeChatEvaluation/queryHomeworkConnect', {
        connectId: connectId,
        sign:sign
      }, opts);
    },
    //目标查看我的简历
    _queryMyResume: function (resumeType) {
      const opts = _opts(true);
      return get('target/queryMyResume', {resumeType:resumeType
      }, opts);
    },
    //获取题目
    _getXCXEvaluationResults: function () {
      const opts = _opts(true);
      return get('xcxEvaluationResults/findProblems', { titleCategoryId: 2 }, opts);
    },
     //提交测评
     _updateResults: function (number1,number2,number3,number4,number5,number6,number7,number8,number9) {
        const opts = _opts(true);
        return get('WeChatEvaluation/addHrPersonalityEvaluation', {
            number1: number1,
            number2:number2,
            number3:number3,
            number4:number4,
            number5:number5,
            number6:number6,
            number7:number7,
            number8:number8,
            number9:number9,
            currentType:2
         }, opts);
      },
    //分销提交测评
    _updateResultsDis: function (number1,number2,number3,number4,number5,number6,number7,number8,number9) {
      const opts = _opts(true);
      return get('WeChatEvaluation/addHrPersonalityEvaluation', {
        number1: number1,
        number2:number2,
        number3:number3,
        number4:number4,
        number5:number5,
        number6:number6,
        number7:number7,
        number8:number8,
        number9:number9,
        currentType:1
      }, opts);
    },
    //获取九型通用版的测评结果
    _getTestResult: function () {
        const opts = _opts(true);

        return post('WeChatEvaluation/findEvaluation', {
          currencyType:3
        }, opts);
    },
    //获取分销九型通用版的测评结果
    _getTestResultDis: function () {
      const opts = _opts(true);

      return post('WeChatEvaluation/findEvaluation', {
        currencyType:1
      }, opts);
    },
    //更新测评信息
    _getPersonCard: function (unionId,phone) {
        const opts = _opts(true);
        return post('employeeAssessment/queryByPhone', {
            unionId: unionId,
            employeePhone:phone
            }, opts);
    },
    //购买测评
    _buyTestMessage: function (unionId,employeeName,phone) {
        const opts = _opts(true);
        return post('employeeAssessment/editAndPay', {
            unionId: unionId,
            employeeName:employeeName,
            employeePhone:phone
            }, opts);
    },//获取岗位信息
    _getIndustryList: function () {
      const opts = _opts(true);
      let unionId = Storage.get("UNINID");
      return post('coe/selectCoeIndustryList', {
          unionId:unionId
      }, opts);
    },//获取情商测评题目
    _getEQItem: function () {
      const opts = _opts(true);
      // return post('first/selectQuestionList', {
      return post('hrCoe/review/dimensionList', {
        type:3
      }, opts);
    },//情商测评提交
    _getEQUpdateReport: function (obj) {
      const opts = _opts(true);
      return post('WeChatEvaluation/addEqEvaluation', obj, opts);
    },//获取情商测评报告
    _getEQReport: function () {
      const opts = _opts(true);
      return post('WeChatEvaluation/findEqResult', {
      }, opts);
    },//HR专业水平获取岗位
    _getGetPort: function () {
      const opts = _opts(true);
      return post('xcxHrMajorLevelResult/queryHrJob', {}, opts);
    },//HR专业水平题目
    _getHRItem: function () {
      const opts = _opts(true);
      return post('basicData/selectProblemList', { type:4
      }, opts);
    },//HR专业水平提交测评结果
    _getSubmitItem: function (obj) {
      const opts = _opts(true);
      return post('WeChatEvaluation/addMajorEvaluation', obj, opts);
    },//专业水平测评报告
    _getHRReport: function () {
      const opts = _opts(true)
      return post('WeChatEvaluation/findMajorResult', {
      }, opts);
    },//获取职业规划结果
    _getCareertReport: function () {
      const opts = _opts(true);
      return post('WeChatEvaluation/selectUserTargetInfo', {
      }, opts);
    },//获取职业规划结果的专业水平切换
    _getMajorEvaluation: function (postMatchingId) {
      const opts = _opts(true);
      return post('target/queryMajorEvaluation', {
        postMatchingId:postMatchingId
      }, opts);
    },//获取职业规划结果的九型人格切换
    _getHrEvaluation: function (postMatchingId) {
      const opts = _opts(true);
      return post('target/queryHrEvaluation', {
        postMatchingId:postMatchingId
      }, opts);
    },
    //企业微信授权
    _company_login: function (obj) {
      const opts = _opts(true);
      return post('wechat/outhApi', obj, opts);
    }
  };

  function _opts(isShowLoading) {
    var opt = {
      cache: {
        exp: 60
      }
    };
    if (isShowLoading) {
      return Object.assign(opt, {
        progress: true
      });
    }
  }
