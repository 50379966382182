export const AllDebug = true

//开发对应人的ip
// export const testUrl = 'http://192.168.0.113:8080' //飞鹏
// export const testUrl = 'http://192.168.0.116:8081' //金鹤
// export const testUrl = 'https://test.hrcoe.com/service' //测试



// export const ShareLink = 'http://test.hrcoe.com/service/api/weiChat/getWeChatFromWeiXin?'
//
// export const testUrl = 'http://192.168.0.117:8080/service'//平常数据


export const jespUrl = 'http://www.hrcoe.com:8080/hr' //hr透视数据

export const progressUrl = 'http://www.hrcoe.com:8080/hr' //hr透视数据

export const testUrl = 'https://xcx.hrcoe.com/service'//平常数据
// export const testUrl = 'http://d632x3.natappfree.cc/service'//平常数据
export const lineUrl = 'http://xcx.hrcoe.com';

export const Baselink = 'http://xcx.hrcoe.com';

export const ShareLink = 'http://xcx.hrcoe.com/service/api/hrCoe/weiChat/getWeChatFromWeiXin?';
export const ShareLogo = 'http://xcx.hrcoe.com/upload/logo/hr_coe.png';


export function getBaseUrl() {
  if (AllDebug) {
    return testUrl + '/api/'
  } else
  {
    return lineUrl + '/api/'
  }
}

export function getURL() {
  if (AllDebug) {
    return testUrl
  } else
  {
    return lineUrl
  }
}


export function getBaseLink() {
  if (AllDebug) {
    return Baselink + '/service.html#/freeCourse?firstIn=1'
  } else
  {
    return Baselink + '/service.html#/freeCourse?firstIn=1'
  }
}
