<template>
    <div class="main_view">
        <div class="main_item">
            <div class="title">1.公司名称:</div>
            <van-field maxlength="50" v-model="companyName" placeholder="请输入公司名称"/>
            <div class="title">2.所在城市:</div>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="city"
                    placeholder="请选择城市"
                    @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="columns"
                        value-key="regionName"
                        @confirm="onConfirm"
                        @cancel="showPicker = false"
                />
            </van-popup>
            <div class="title">3.2023年业绩规模:</div>
            <van-field  v-model="performanceScale"
                        rows="1"
                        autosize
                        type="textarea"
                        placeholder="请输入2023年业绩规模"/>
            <div class="title">4.人数规模:</div>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="personnelScale"
                    placeholder="请选择人数规模"
                    @click="showScalePicker = true"/>
            <van-popup v-model="showScalePicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="companyScaleList"
                        value-key="scale"
                        @confirm="onScaleConfirm"
                        @cancel="showScalePicker = false"
                />
            </van-popup>
            <div class="title">5.联系人姓名:</div>
            <van-field v-model="contactName" placeholder="请输入联系人姓名"/>
            <div class="title">6.职位:</div>
            <van-field v-model="postName" placeholder="请输入职位"/>
            <div class="title">7.手机号:</div>
            <van-field maxlength="11" type="tel" v-model="mobile" placeholder="请输入手机号"/>
        </div>
        <van-button
                class="talent_button"
                type="primary"
                @click="handleSubmit">提交信息
        </van-button>
    </div>

</template>

<script>
    import {Area, Button, CellGroup, Field, Form, Picker, Popup, Toast, Checkbox, RadioGroup, Radio,CheckboxGroup} from "vant";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import api from "@/apis/talentReview/talentReview.js";
    import storage from "@/common/js/cacheData";

    export default {
        name: "HumanResourcesInfoInput",
        data() {
            return {
                id: null,
                companyName: null,
                city: null,
                performanceScale: null,
                personnelScale: null,
                contactName: null,
                postName: null,
                mobile: null,
                showIndustryPicker: false,
                showArea: false,
                areaList: {},
                showPicker: false,
                showScalePicker: false,
                userInfoData: {},
                columns:[],
                companyScaleList:[],
                unionId: null
            }
        },
        components: {
            [Toast.name]: Toast,
            [CellGroup.name]: CellGroup,
            [Button.name]: Button,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Area.name]: Area,
            [Form.name]: Form,
            [Checkbox.name]: Checkbox,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
            [Field.name]: Field,
            [CheckboxGroup .name]: CheckboxGroup
        },
        mounted() {
            document.title = '信息填写';
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            this.getCityList();
            this.getCompanyScaleList();
            this.getDataList();
            this.shareContent();
        },
        methods: {
            getCityList(){
                api._selectRegionList({
                    regionId: 1
                }).then(
                    response => {
                        if (response.code == 200) {
                            if (response.result.length>0){
                                response.result.forEach(item => {
                                    item.children=item.child;
                                });
                                this.columns = response.result;

                            }


                        }
                    }
                )
            },
            getCompanyScaleList(){
                api._selectCompanyScaleList().then(
                    response => {
                        if (response.code == 200) {
                            this.companyScaleList = response.result;

                        }
                    }
                )
            },
            getDataList() {
                api._queryEnterpriseDiagnosis().then(
                    response => {
                        if (response.code == 200) {
                            this.userInfoData = response.result.enterpriseDiagnosis;
                            this.unionId = response.result.unionId;
                            this.id = this.userInfoData.id;
                            if (this.id) {
                                this.companyName = this.userInfoData.companyName;
                                this.city = this.userInfoData.city;
                                this.performanceScale = this.userInfoData.performanceScale;
                                this.personnelScale = this.userInfoData.personnelScale;
                                this.contactName = this.userInfoData.contactName;
                                this.postName = this.userInfoData.postName;
                                this.mobile = this.userInfoData.mobile;

                            }
                        }
                    }
                )
            },
            onConfirm(value) {
                if (value[1]){
                    this.city = value[1];
                    this.showPicker = false;
                }

            },
            onScaleConfirm(value) {
                this.personnelScale = value.scale;
                this.showScalePicker = false;
            },


            handleSubmit() {
                if (!this.companyName) {
                    Toast('请输入公司名称');
                    return
                }
                if (!this.city) {
                    Toast('请选择城市');
                    return
                }
                if (!this.performanceScale) {
                    Toast('请输入2023年业绩规模');
                    return
                }
                if (!this.personnelScale) {
                    Toast('请选择人数规模');
                    return
                }
                if (!this.contactName) {
                    Toast('请输入联系人姓名');
                    return
                }
                if (!this.postName) {
                    Toast('请输入职位');
                    return
                }

                if (!this.mobile) {
                    Toast('请输入手机号');
                    return
                }
                let reg = /(1[0-9]{10}$)/;
                if (!reg.test(this.mobile)) {
                    Toast("请输入正确手机号");
                    return
                }
                let reqData = {
                    companyName: this.companyName,
                    city: this.city,
                    performanceScale: this.performanceScale,
                    personnelScale: this.personnelScale,
                    contactName: this.contactName,
                    postName: this.postName,
                    mobile: this.mobile,
                    unionId: this.unionId
                };
                if (this.id) {
                    reqData.id = this.id
                }
                api._addEnterpriseDiagnosis(reqData).then(
                    response => {
                        if (response.code == 200) {
                            Toast('提交成功');
                            this.getDataList();
                        }
                    }
                )
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .main_view {

        background-color: #f4f4f4;
        height: 100vh;
    }
    .img-icon {
        height: 20px;
    }
    .main_item {
        margin: 0px 10px 50px 10px;
        padding-bottom: 50px;
        border-radius: 5px;
        background-color: white;
    }

    .title {
        color: #333333;
        padding: 10px 16px 0 16px;
        font-size: 14px;
        font-weight: bold;
    }

    .item_checkbox {
        margin: 15px 16px;
        font-size: 14px;
        color: #333333;
    }

    .talent_button {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #13d1be;
        border: 0px;
    }
</style>
