import  Storage from "store";


export default {
    saveData: function (key, val) {
        Storage.set(key,val)
    },
    getData: function (key) {
        return  Storage.get(key);
    },
    clearData: function (key) {
      return Storage.remove(key)
    }
}
