<template>
    <div>
        <div style="display: flex;background-color: white;justify-content: space-between;">
            <div class="title_left">盘点人数:{{reviewCount}}/{{reviewTotalCount}}</div>
            <van-dropdown-menu active-color="#13d1be" class="title_right">
                <van-dropdown-item v-model="mSelected" :options="option" @change="changeValue()"/>
            </van-dropdown-menu>
        </div>
        <van-list
                v-model="loading"
                :finished="finished"
                :immediate-check="false"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="item_container" v-for="(item,index) in dataList" :key="index">
                <div class="item">
                    <div class="item_left" v-if="item.username.length<2">
                        {{item.username.substring(item.username.length-1)}}
                    </div>
                    <div class="item_left" v-else>{{item.username.substring(item.username.length-2)}}</div>
                    <div style="display: flex;flex-direction: column;flex: 1">
                        <div style="display: flex;justify-content: space-between">
                            <div class="item_top_left">{{item.username}}</div>
                            <div class="item_top_right">{{item.evaluationName}}</div>
                        </div>
                        <div style="display: flex;justify-content: space-between">
                            <div class="item_bottom_left">发起时间:{{item.createTime}}</div>
                            <van-button class="item_bottom_right_1" round type="info" @click="toReview(item)">
                                {{item.score==0?"开始盘点":"已盘点"}}
                            </van-button>
                            <!--                        <div class="item_bottom_right_1" @click="toReview(item)">-->
                            <!--                            {{item.status==0?"��ʼ�̵�":"���̵�"}}-->
                            <!--                        </div>-->
                        </div>
                    </div>
                </div>
                <div class="gradeTabCover" v-if="item.score != 0">
                    <div v-for="(item, index) in item.list" :key="index">
                        <div class="gradeTab">
                            <div class="gradeTapView">
                                <div class="gradeTapTop">技能 <br />评价</div>
                            </div>
                            <div class="gradeTapView">
                                <div class="gradeTapTop">{{ item[0].title }}</div>
                            </div>
                            <div class="gradeTapView">
                                <div class="gradeTapTop">{{ item[1].title }}</div>
                            </div>
                            <div class="gradeTapView">
                                <div class="gradeTapTop">{{ item[2].title }}</div>
                            </div>
                            <div class="gradeTapView">
                                <div class="gradeTapTop">{{ item[3].title }}</div>
                            </div>
                        </div>
                        <div class="gradeTab">
                            <div class="gradeTabBottom">评分</div>
                            <div class="gradeTabBottom">
                                {{ item[0].score ? item[0].score + "分" : "" }}
                            </div>
                            <div class="gradeTabBottom">
                                {{ item[1].score ? item[1].score + "分" : "" }}
                            </div>
                            <div class="gradeTabBottom">
                                {{ item[2].score ? item[2].score + "分" : "" }}
                            </div>
                            <div class="gradeTabBottom">
                                {{ item[3].score ? item[3].score + "分" : "" }}
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </van-list>
        <no-result :title="'暂无盘点'" v-if="!dataList.length"></no-result>
    </div>
</template>

<script>
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import {startShart} from "@/common/js/tecentshare.js";
    import api from "@/apis/talentReview/talentReview.js";
    import NoResult from "../components/commonly/noResult.vue";
    import {Button, DropdownItem, DropdownMenu, List, Sticky, Tab, Tabs} from "vant";
    export default {
        name: "SkillsAssessmentList",
        data(){
            return{
                mSelected: 2,//选项卡使用
                option: [
                    {text: '全部', value: 2},
                    {text: '已盘点', value: 1},
                    {text: '未盘点', value: 0}
                ],
                loading: false,
                finished: false,
                currPage: 1,
                pageSize: 10,
                totalPage: 1,
                totalCount: 0,
                dataList: [],
                reviewTotalCount: 0,
                reviewCount: 0
            }
        },
        components: {
            [List.name]: List,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Button.name]: Button,
            [Sticky.name]: Sticky,
            NoResult
        },
        created() {
            this.officeId = localStorage.getItem("m_officeId");
            this.userId = localStorage.getItem("m_userId");
            this.pcUserId = localStorage.getItem("m_pcUserId");
            this.reviewId = localStorage.getItem('m_reviewId');
            this.getData();
        },
        mounted() {
            this.shareContent();
        },
        methods:{
            //重新组装数据
            changeData(list) {
                var scoreArray = [];
                var hange = parseInt(list.length / 4);
                var yu = list.length % 4;
                if (yu != 0) {
                    hange++;
                }
                for (var i = 0; i < hange; i++) {
                    let arrray = [];
                    for (var k = 0; k < 4; k++) {
                        let num = k + i * 4;
                        if (num < list.length) {
                            arrray.push(list[num]);
                        } else {
                            let emptyItem = { title: "" };
                            arrray.push(emptyItem);
                        }
                    }
                    scoreArray.push(arrray);
                }
                return scoreArray;
            },
            getData() {

                let reqData={
                    status: this.mSelected,
                    pcUserId: this.pcUserId,
                    officeId: this.officeId,
                    userId: this.userId,
                    page: this.currPage,
                    limit: this.pageSize
                };
                if (this.reviewId) {
                    reqData.reviewId = this.reviewId
                }
                api.skillEvaluationManagerialSkillsList(reqData).then(
                    response => {
                        this.loading = false;
                        if (response.code == 200) {
                            if (this.currPage == 1) {
                                this.dataList = [];
                            }
                            var dataArray = response.result.list.list;
                            for (var i = 0; i < dataArray.length; i++) {
                                var item = dataArray[i];
                                item.list = this.changeData(item.list);
                                this.dataList.push(item);
                            }
                            // this.dataList.push(...response.result.list.list);
                            this.pageSize = response.result.list.pageSize;
                            this.totalPage = response.result.list.totalPage;
                            this.currPage = response.result.list.currPage;
                            this.totalCount = response.result.list.totalCount;
                            this.reviewTotalCount = response.result.totalCount;
                            this.reviewCount = response.result.reviewCount;
                        }
                    }
                )
            },
            changeValue() {
                this.currPage = 1;
                this.getData()
            },
            toReview(item) {
                this.$router.push({
                    path: '/SkillsAssessmentTest',
                    query: {
                        skillId: item.skillId,
                        assessUserId: item.assessUserId,
                        userId: item.userId,
                        officeId: item.officeId,
                        mType:item.type,
                        category:item.category
                    }
                })
            },
            onLoad() {
                this.loading = true;
                if (this.currPage < this.totalPage) {
                    this.currPage += 1;
                    this.getData()
                } else {
                    this.finished = true;
                    this.loading = false;
                }
            },

            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .title_left {
        padding: 1rem;
        line-height: 3rem;
        font-size: 1.5rem;
        color: #333;
    }

    .title_right {
        padding-right: 1rem;
        font-size: 1rem;
        color: #333;
    }
    .item_container{
        margin: 1rem;
        padding: 1rem;
        background: #ffffff;
        border-radius: 1rem;
        box-shadow: 0px .4rem 1rem 0px rgba(0, 0, 0, 0.20);
    }

    .item {
        display: flex;
        align-items: center;
    }

    .item_left {
        width: 6rem;
        height: 6rem;
        color: white;
        display: flex;
        font-size: 2rem;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        background: linear-gradient(180deg, #6c97ff, #3f72ec);
        border-radius: 4px;
    }

    .item_top_left {
        padding: 0 0 0 1rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #333;
        line-height: 4rem;
    }

    .item_top_right {
        font-size: 1.2rem;
        color: #333;
        line-height: 4rem;
    }

    .item_bottom_left {
        padding: 1rem;
        font-size: 1.2rem;
        color: #333;
    }

    .item_bottom_right_1 {
        width: 10rem;
        height: 3rem;
        text-align: center;
        font-size: 1.1rem;
        background-color: #13d1be;
    }

    .item_bottom_right_2 {
        float: right;
        padding: 10px;
        font-size: 14px;
        color: #333;
        border-radius: 5px;
        border: 1px solid #13d1be;
    }
    .gradeTabCover {
        margin: 20px 0px 10px 0px;
    }
    .gradeTab {
        display: flex;
        align-items: center;
    }
    .gradeTapView {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        flex: 1;
        border: 1px solid #71d2c8;
        margin-left: -1px;
        margin-top: -1px;
        margin-bottom: -1px;
        text-align: center;
        font-size: 11px;
        background: #f0fcfa;
        text-align: center;
    }
    .gradeTapTop {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: center;
    }
    .gradeTabBottom {
        height: 30px;
        flex: 1;
        border: 1px solid #71d2c8;
        margin-left: -1px;
        text-align: center;
        font-size: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: #ffffff;
        line-height: 30px;
    }
</style>
