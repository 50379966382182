<template>
    <div class="classpadding">
        <div class="mapbox">
            <baidu-map class="bm-view" :zoom="zoom" :center="center" @ready="handler"
                       ak="k2jDwfDATMEfaAYsTawqXDuRfnsSbVfQ">
            </baidu-map>
            <div class="siginBox">
                <div class="PositioningBack">
                    <div @click="clickGcFn" class="sign-text" :class="isSignFlag?'':'isSignFlag'">签到</div>
                </div>
            </div>
            <div class="Positioningtips">
                <p class="isshow_Singn" v-if="isSignFlag">已进入签到范围</p>
                <p class="notshow_Singn" v-else style="color:#FD4E23">不在签到范围内</p>
            </div>
        </div>
        <van-dialog v-model="isshow_Singn_diglog" confirmButtonText="我知道了" confirmButtonColor="#5F6FAB"
                    @confirm="confirm">
            <div>
                <!--        <van-col class="positioncls_img" span="24" offset="10"><img src="../../assets/img/icon_checkinsucceeded.png" alt=""></van-col>-->
                <div span="24" class="position_text" offset="8">{{sigNinTime}}<p>签到成功</p></div>
            </div>
        </van-dialog>
    </div>
</template>
<script>
    import {Button, Dialog, Field, Popup, Toast} from "vant";
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    import api from "@/apis/talentReview/talentReview.js";

    export default {
        name: 'SignPage',
        data() {
            return {
                sinnumber: '432',
                center: {lng: 0, lat: 0},
                zoom: 3,//必须写上,自己因为忘记写一直无法自动定位
                datetime: null,
                nowTime: '',
                gc: {}, //地理解析
                pt: '', // 我的签到位置
                isSignFlag: true,
                longitude: '', //经纬度
                ranges: 0, //签到范围
                isshow_Singn_diglog: false,
                sigNinTime: ''
            }
        },
        components: {
            BaiduMap,
            [Toast.name]: Toast,
            [Dialog.name]: Dialog,
            [Dialog.Component.name]: Dialog.Component
        },
        created() {
            this.longitude = this.$route.query.longitude;
            this.ranges = this.$route.query.ranges;
            window.onresize = () => {
                return (() => {
                    // this.setRem(document)
                })();
            }
            this.$nextTick(() => {
                // this.setRem(document);
                // this.createQrcode()
            })
        },
        mounted() {
            this.datetime = setInterval(() => {
                // this.setNowTimes();
            }, 1000);
        },
        methods: {
            handler({BMap, map}) {
                let that = this;
                // var point = new BMap.Point(113.723652,34.752137);//创建定坐标
                var point = new BMap.Point(113.63141921, 34.75343885);//创建定坐标
                map.centerAndZoom(point, 15);//// 初始化地图,设置中心点坐标和地图级别
                var geolocation = new BMap.Geolocation();
                this.gc = new BMap.Geocoder();//创建地理编码器、
                var pointAttendance;
                // 开启SDK辅助定位
                geolocation.enableSDKLocation();
                Toast.loading({
                    message: '定位中...',
                    forbidClick: false,
                    duration: 0
                });
                geolocation.getCurrentPosition(function (r) {
                    Toast.clear();
                    if (r.accuracy == null) {
                        that.$dialog.alert({message: "请您打开浏览器定位权限"}).then(resd => {
                            // that.$router.push("/HistoryAppointment")
                        })
                    }
                    var BMAP_STATUS_SUCCESS = 0;
                    if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                        // var myicon = new BMap.Icon('http://webmap0.map.bdstatic.com/wolfman/static/common/images/us_cursor_9517a2b.png',
                        var myicon = new BMap.Icon(require("@/assets/mapman.svg"),new BMap.Size(32, 32),{imageSize: new BMap.Size(32, 32),imageOffset: new BMap.Size(0, 0)});
                        var mk = new BMap.Marker(r.point, {icon: myicon});
                        map.addOverlay(mk);
                        map.panTo(r.point);
                        // alert('您的位置：' + r.point.lng + ',' + r.point.lat);
                        point = new BMap.Point(r.point.lng, r.point.lat); // 获取自己的签到位置
                        that.pt = r.point;
                        map.panTo(that.pt);//移动地图中心点
                        var coordinate = that.longitude;// 设置考勤点经纬度  "116.321854,39.893237"
                        var arr = coordinate.split(",");// 切割经纬度
                        var lon = arr[0];
                        var lat = arr[1];
                        pointAttendance = new BMap.Point(lon, lat);
                        var circle = new BMap.Circle(pointAttendance, that.ranges, { // 考勤地点范围
                            fillColor: "blue", // 圆形颜色
                            strokeWeight: 1,
                            fillOpacity: 0.2,
                            strokeOpacity: 0.2
                        });
                        map.addOverlay(circle);
                        // 考勤的经纬度获取
                        var rr = new BMap.Marker(pointAttendance);
                        map.addOverlay(rr); // 标出考勤点的位置
                        // 计算签到与当前位置之间的差值
                        var distance = map.getDistance(point, pointAttendance).toFixed(2);
                        if (distance > parseInt(that.ranges)) {
                            that.isSignFlag = false
                        }
                    } else {
                        alert('failed' + this.getStatus());
                    }
                }, {enableHighAccuracy: true});
            },
            // 时间定时器
            setNowTimes() {
                //  根据插件获取时分秒
                this.nowTime = this.$moment().format("HH:mm:ss")
            },
            clickGcFn() {
                if (this.isSignFlag) {
                    // this.sigNinTime = this.nowTime
                    api.offline_addSign().then((response) => {
                        if (response.code == 200) {
                            // Toast({
                            //     message: "签到成功",
                            //     position: "middle",
                            //     duration: 1500
                            // });
                            this.isshow_Singn_diglog = true
                        }
                    });

                    // this.gc.getLocation(this.pt, function (rs) {
                    //     var addComp = rs.addressComponents;
                    //     alert(addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber);
                    //
                    //
                    // });
                } else {
                    this.$dialog.alert({message: "您当前位置不在签到范围"})
                }
            },
            confirm() {
                this.$router.go(-1);


                // let mydate = this.$moment().format('YYYY-MM-DD')
                // this.$http.post("/appoint/updaeSignTimeByOrderId", {
                //     orderId: this.$route.query.orderId,
                //     signTime: mydate + ' ' + this.sigNinTime
                // }).then(res => {
                //     // if(res.da){}
                //     console.log(res)
                //     if (res.data.code == 200) {
                //         // 签到完成跳转到等待叫号页面
                //         this.$router.push({
                //             path: '/Call',
                //             query: {
                //                 nowTime: this.sigNinTime,
                //                 place: this.$route.query.place
                //             }
                //         })
                //     } else {
                //         this.$dialog.alert({message: res.data.msg})
                //     }
                // })
            }
        },
        // 销毁页面之后清空定时器
        beforeDestroy() {
            if (this.datetime) {
                clearInterval(this.datetime)
            }
        }
    }
</script>
<style lang="less" scoped>

    .sinbox p {
        margin: 0.1rem 0rem;
    }

    .sinbox span {
        color: #5F6FAB;
    }

    .sinbox p:last-child {
        color: #E5B03C;
        font-weight: 400;
    }

    // map 地图样式
    .bm-view {
        width: 100%;
        height: 80vh;
    }

    .mapbox {
        width: 100%;
        height: 10rem;
    }

    .siginBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }


    .PositioningBack {
        width: 6.4rem;
        height: 6.4rem;
        line-height: 6.4rem;
        /*border: 1px solid #5683E8;*/
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .PositioningBack div {
        width: 6.4rem;
        height: 6.4rem;
        background: radial-gradient(#D9FFF1, #13D1BE);
        /*box-shadow: 0px 11px 21px 0px rgba(86, 133, 232, 0.39);*/
        border-radius: 50%;
    }

    .Positioningtips i {
        /*background: url('../../assets/img/checkinscope_yes.png');*/
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 0.24rem;
        height: 0.24rem;
        display: block;
        text-align: center;
    }

    .Positioningtips {
        margin-top: 1rem;
    }

    .Positioningtips p {
        font-size: 1.4rem;
        color: #999999;
        text-align: center;
    }


    .notshow_Singn i {
        /*background: url('../../assets/img/checkinscope_no.png');*/
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .isSignFlag {
        background: #999999 !important;
    }

    .positioncls_img {
        width: 1.32rem;
        height: 1.36rem;
        margin-top: 0.3rem;
        margin-bottom: 0.2rem;
    }

    .positioncls_img img {
        width: 100%;
        height: 100%;
    }

    .position_text {
        color: #333333;
        font-size: 1.6rem;
        font-weight: bold;
        padding: 2rem 0;
        text-align: center;
    }

    .sign-text {
        font-weight: bold;
        font-size: 1.4rem;
        color: white;
        text-align: center;
    }
</style>
