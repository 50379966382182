<template>
  <div class="page-container" v-if="dataInfo">
    <div class="position-container">
      <div class="position-name">{{dataInfo.cloudName}}</div>
      <div class="m-b-text mt10">
        <span>{{dataInfo.hangye}}</span><span class="pl20">{{dataInfo.scale}}</span><span class="pl20">{{dataInfo.property}}</span>
      </div>
    </div>

    <div class="m-c-text mt15">公司简介</div>
    <div class="p-line mt3"></div>
    <p class="p-company-info mt10">{{dataInfo.companyProfile}}</p>
    <div class="position-container mt40">
      <div class="position-name">{{dataInfo.postName}}</div>
      <div class="position-detail">
        {{dataInfo.typeName}}：{{dataInfo.salaryContent}}<span class="ml30">工作地点：{{dataInfo.workingAddress}}</span>
      </div>
    </div>
    <div class="m-a-text mt10">岗位职责</div>
    <div class="m-d-text mt10">{{dataInfo.jobResponsibilities}}</div>
    <div class="p-line mt10"></div>
    <div class="m-a-text mt10">岗位要求</div>
    <div class="m-d-text mt10">{{dataInfo.jobRequirements}}</div>
    <div class="p-line mt10"></div>
    <div style="display: flex;margin-top: 10px">
      <img class="erweima" v-if="dataInfo.leaderUrl" :src="dataInfo.leaderUrl"/>
      <div class="m-mianshiuan">
        <div v-if="dataInfo.interviewerName&&dataInfo.mobile">面试官: {{dataInfo.interviewerName}}</div>
        <div v-if="dataInfo.interviewerName&&dataInfo.mobile" :class="!dataInfo.leaderUrl?'mt10':''">手机号: {{dataInfo.mobile}}</div>
        <div class="m-tip" v-if="dataInfo.leaderUrl">扫码添加面试官~</div>
      </div>
    </div>


    <div style="height: 50px"></div>
    <div class="m-bottom">
      <div class="btn-left-right" @click="noIntrest">{{btnText}}</div>
      <div class="btn-center" @click="uploadResume">上传简历</div>
      <div class="btn-left-right" @click="morePosition">更多岗位</div>
    </div>
  </div>
</template>

<script>
  import storage from "@/common/js/cacheData";
  import api from "@/apis/recruit/recruit.js";
  import {startShart} from "@/common/js/tecentshare.js";
  import {ShareLink, ShareLogo} from "@/apis/const.js";
  import {Toast} from "vant";
  export default {
    name: "PositionDetail",
    data() {
      return {
        dataInfo: null,
        totalId: null,
        btnText: '不感兴趣'
      }
    },
    created() {
      document.title = "职位详情";
      let openId = this.$route.query.openId;
      if (openId) {
        storage.saveData("openId", openId);
      }
      this.totalId = this.$route.query.totalId;
      this.getData()
    },
    mounted() {
      this.shareContent();
    },
    methods: {
      getData() {
        api.positionInfo({
          totalId: this.totalId
        }).then(response => {
          if (response.result) {
            this.dataInfo = response.result;
            if (this.dataInfo.status==1){
              this.btnText='已投递'
            }else{
              this.btnText='不感兴趣'
            }

          }
        });
      },
      noIntrest() {
        if (this.dataInfo.status==1){
          Toast("已投递");
          return
        }
        //不感兴趣
        if (this.dataInfo&&this.dataInfo.status==2){
          api.addRecord({
            totalId: this.totalId,
            status: 0//不感兴趣,投递
          }).then(response => {
            this.$router.replace({
              path: "/RecruitList"
            })
          })
        }else{
          this.$router.replace({
            path: "/RecruitList"
          })
        }

      },
      uploadResume() {
        if (this.dataInfo.isPhone==0){
          //没绑定手机号，去绑定手机号
          Toast("请先绑定手机号");
          this.$router.push({
            path: "/TalentUserVerification",
            query: {
              totalId: this.totalId,
              type: 14
            }
          });
          return
        }
        this.$router.push({
          path: "/UploadResume",
          query: {
            totalId: this.totalId,
            isResume: this.dataInfo.isResume
          }
        });
      },
      morePosition() {
        this.$router.replace({
          path: "/RecruitList",
          query: {
            searchName: this.dataInfo.postName
          }
        });
      },
      //分享
      shareContent() {
        const titleStr = "HRCOE";
        const contentStr = "让人力资源工作更简单!";
        const linkStr = ShareLink + "type=1";
        const imgStr = ShareLogo;
        window.shareData = {
          fTitle: titleStr,
          fContent: contentStr,
          fImgUrl: imgStr,
          fLink: linkStr
        };
        window.shareIMdata = {
          fTitle: titleStr,
          fContent: contentStr,
          fImgUrl: imgStr,
          fLink: linkStr
        };
        startShart();
      }
    }
  }
</script>

<style scoped>
  .page-container {
    padding: 15px;
    position: relative;
  }
  .pl20 {
    padding-left: 20px;
  }
  .pl30 {
    padding-left: 30px;
  }

  .mb50 {
    margin-bottom: 50px;
  }

  .mt3 {
    margin-top: 3px;
  }

  .mt10 {
    margin-top: 10px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .mt15 {
    margin-top: 15px;
  }
  .mt40 {
    margin-top: 40px;
  }
  .m-a-text {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  }

  .m-b-text {
    font-size: 12px;
    color: #666666;
  }

  .m-c-text {
    font-size: 12px;
    font-weight: bold;
    color: #333333;
  }

  .m-d-text {
    font-size: 14px;
    color: #333333;
    line-height: 26px;
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
  }

  .p-line {
    height: 1px;
    background: #c9c9c9;
  }


  .p-company-info {
    font-size: 12px;
    color: #666666;
    line-height: 24px;
    font-weight: 400;
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
  }

  .position-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 80px;
    background: #ffffff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  }

  .position-name {
    font-size: 16px;
    font-weight: 700;
    color: #13d1be;
  }

  .position-detail {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }

  .m-bottom {
    position: fixed;
    justify-content: space-around;
    background-color: white;
    bottom: 0;
    left: 0;
    height: 50px;
    display: flex;
    width: 100%;
    align-items: center;
  }

  .btn-left-right {
    width: 84px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: white;
    font-size: 14px;
    background: rgba(33, 214, 234, 0.95);
    border-radius: 5px;
  }

  .btn-center {
    width: 139px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: white;
    font-size: 14px;
    background: #13d1be;
    border-radius: 5px;
  }
  .erweima{
    width: 100px;
    height: 100px;
    margin-right: 10px;
    border-radius: 1px;
    background-color: red;
  }
  .m-tip{
    font-size: 12px;
    font-weight: 400;
    color: #666666;
  }
  .m-mianshiuan{
    display: flex;
    padding: 5px 0;
    flex-direction: column;
    justify-content: space-between;
    color: #333333;
    font-size: 12px;
  }
</style>
