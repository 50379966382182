<template>
  <div class="band-page-view">
    <aliplayer
      :detailId="detailId+''"
      :totalId="totalId+''"
      :officeId="officeId+''"
      :userId="userId+''"
      v-if="(type==1&&totalId&&homework.isPreview)||(type==2&&totalId)"
    ></aliplayer>
    <div v-else-if="!homework.isPreview&&totalId" class="aliplayerbox"  v-lazy:background-image="defaultImgOptions(homework.coverUrl)">
      <div class="op-bg">
        <div class="op-title">该课程需要购买才能够观看!</div>
        <div class="op-btn" @click="_buyNow">立即购买</div>
      </div>
    </div>
    <div v-else  class="aliplayerbox"  v-lazy:background-image="defaultImgOptions(homework.coverUrl)">
      <div class="op-bg">
      </div>
    </div>
    <div class="catalog-bg" ref="catalog">
      <div v-for="(item,index) in  palyData.list" :key="'catalog'+index" :class="item.detailId == detailId?'selecct':'noselect'" class="bg-item" @click="seletOther(item,index)"> 
        <div style="width:13px;height:13px;align-self: center;margin: -0.5rem 0 0 118px;position: absolute;" >
          <img v-if="!item.isPreview" src="../../../../static/image/suo.png" style="width:100%;height: 100%;"/>
        </div>
        <div class="title-content">{{item.chapterName}}</div>
      </div>
    </div>
      <div class="task-Work-bg" v-if="type==2">
        <div class="title">作业内容:</div>
<!--        <div class="content" @click="looknet">查看作业提交流程</div>-->
      </div>
      <div class="work-list"  v-if="type==2">
        <p v-for="(content,index2) in homework.homeworkList" :key="index2">
        {{content.homeworkContent}}
        </p>
        <div class="time" style="margin-top:10px;">
          作业提交时间:{{homework.commitTime}}
        </div>
        <div class="time">
          作业点评时间:{{homework.commentTime}}
        </div>
      </div>
    <div v-if="type==2" style="padding: 5px;position: fixed;bottom: 0;left: 0;right: 0">
<!--      <button class="mint-button mint-button--danger mint-button--large" @click="openApp">下载APP查看作业模板详细信息</button>-->
      <van-button type="primary" block @click="looknet">查看作业提交流程</van-button>
    </div>
<!--    <div v-if="type==1" style="padding: 5px;position: fixed;bottom: 0;left: 0;right: 0">-->
<!--      <van-button type="primary" block @click="openApp">下载APP播放观看</van-button>-->
<!--&lt;!&ndash;      <button class="mint-button mint-button&#45;&#45;danger mint-button&#45;&#45;large" @click="openApp">下载APP缓存播放观看</button> &ndash;&gt;-->
<!--    </div>-->
<!--     <div class="turnToIndex" @click="turnIndex">-->
<!--      <img style="width:60px;height:60px;" src="../../../image/turnToIndex.png" alt="">-->
<!--    </div>-->
  </div>
</template>
<script>
import $ from "jquery";
import aliplayer from "../../components/commonly/VueAliplayer";
import goodsDetail from "@/apis/goodsDetail/goodsDetail";
import { Button } from 'vant';
import {ShareLink,ShareLogo} from "@/apis/const.js";
import {startShart} from "@/common/js/tecentshare.js";

export default {
  data() {
    return {
      modal: false,
      playId: null,
      totalId: null,
      detailId: 1,
      officeId: null,
      userId: null,
      loadOver: false,
      type: 1,
      palyData: {
        list: []
      },
      homework: {}
    };
  },
  components: { aliplayer ,[Button.name]: Button},
  methods: {
    defaultImgOptions(img) {
      return {
        src: img,
        error: require("../../../../static/image/banner默认图@3x.png")
      };
    },
    //分享
    shareContent() {
      const titleStr = "HRCOE";
      const contentStr = "让人力资源工作更简单!";
      const linkStr = ShareLink + "type=1";
      const imgStr = ShareLogo;
      window.shareData = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      window.shareIMdata = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      startShart();
    },
    _buyNow() {
      let that = this;
      that.$router.push({
        path: "/pay",
        query: {
          goodsInfoId: that.palyData.goodsInfoId,
          goodsTypeNo: that.palyData.goodsTypeNo
        }
      });
    },
    seletOther(val, index) {
      let that = this;
      if (that.detailId == val.detailId) {
        return;
      }
      that.detailId = val.detailId;
      that.homework = val;
      let catalog = that.$refs.catalog;
      catalog.scrollLeft = 160 * index - 140;
      that.totalId = null;
      that.$nextTick(() => {
        that.totalId = that.$route.query.totalId;
      });
    },
    loaddetailover(val) {
      let that = this;
    },
    ok() {},
    cancel() {},
    changeVideo(item) {
      this.playId = null;
      this.$nextTick(() => {
        this.spinShow = true;
        this.playId = item.detailId;
        this.selectCur = item.detailId;
      });
    },
    playDetail() {
      let that = this;
      let totalId = that.$route.query.totalId;
      let type = that.$route.query.type;
      let campTaskId = that.$route.query.campTaskId;
      let detailId = that.$route.query.detailId;
      goodsDetail._getPlayList(totalId, type, campTaskId, detailId,that.officeId,that.userId).then(
        response => {
          let result = response.result;
          that.palyData = result;
          that.detailId = result.detailId;
          that.totalId = result.totalId;
          that.playId = result.playId;
          for (let index = 0; index < result.list.length; index++) {
            const element = result.list[index];
            if (element.detailId == that.detailId) {
              that.homework = element;
              setTimeout(() => {
                let catalog = that.$refs.catalog;
                catalog.scrollLeft = 160 * index - 140;
              }, 2000);
              break;
            }
          }
          if (response.result.name) {
            document.title = response.result.name;
          }
        },
        error => {}
      );
    },
    openApp() {
      window.open(
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.jingyingtang.hryun818",
        "_blank"
      );
    },
    looknet() {
      window.open(
        "https://xcx.hryun818.com/mobile//submitworks_.html#/",
        "_blank"
      );
    },
    turnIndex() {
      //返回到首页
      window.open(ShareLink + "type=9", "_blank");
    }
  },
  created() {
    let that = this;
    document.title='视频播放';
    that.type = that.$route.query.type;
    that.officeId = that.$route.query.officeId;
    that.userId = that.$route.query.userId;
    that.playDetail();
  },
  mounted() {
    this.shareContent();
  },
  activated() {

  },
  filters: {
    defaulttime(val) {
      return val ? val : "--";
    }
  }
};
</script>
 <style scoped lang='less'>
.band-page-view {
  font-family: Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
    Arial, sans-serif;
  padding-bottom: 44px;
}
.course-learn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.aliplayerbox {
  height: 20rem;
  /*width: 37.5rem;*/
  background-size: auto 20rem;
  background-repeat: no-repeat;
  background-position: center center;
}

.op-bg {
  background: rgba(0, 0, 0, 0.4);
  height: 20rem;
  width: 37.5rem;
}

.op-title {
  color: #fff;
  font-size: 15px;
  text-align: center;
  padding: 6rem 10px 0 0;
}

.op-btn {
  width: 85px;
  height: 35px;
  line-height: 34px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin: 20px auto;
  background-color: #13d1be;
  border-radius: 5px;
  opacity: 1;
}
.catalog-bg {
  width: 100%;
  height: 7rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  margin: 1rem 0;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  // overflow-x: hidden;
  overflow-y: auto;
}
.catalog-bg::-webkit-scrollbar {
  display: none;
}

.bg-item {
  margin: 5px 5px;
  border-radius: 5px;
  height: 60px;
  text-align: left;
  font-size: 14px;
  width: 150px;
  padding: 1rem 22px 1rem 1rem;
  // overflow: hidden;
  // text-overflow: ellipsis;
  display: -webkit-box;
  text-overflow: -o-ellipsis-lastline;
  -webkit-box-orient: vertical;
  position: relative;
}
.title-content {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  word-break: break-all;
  -webkit-box-orient: vertical;
}

.selecct {
  border: 1px solid #13d1be;
  color: #13d1be;
}
.noselect {
  border: 1px solid #e4e4e4;
  color: #333;
}

.task-Work-bg {
  padding: 10px 15px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}
.title {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.content {
  font-size: 12px;
  font-weight: bold;
  color: #13d1be;
}
.work-list {
  padding: 0 15px;
  font-size: 13px;
  line-height: 22px;
}
p {
  color: #666;
  font-size: 14px;
  word-break: break-all;
}
.time {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  line-height: 25px;
}

.turnToIndex {
  position: fixed;
  bottom: 7%;
  right: 6%;
}
</style>
