<template>
  <div class="cover">
    <div v-for="(item, index) in dataList" :key="index">
      <div class="item">
        <div class="info">
          <div class="info_left" v-if="item.username.length < 2">
            {{ item.username.substring(item.username.length - 1) }}
          </div>
          <div class="info_left" v-else>
            {{ item.username.substring(item.username.length - 2) }}
          </div>
          <div class="info_right">
            <div class="name">{{ item.username }}-{{ item.postName }}</div>
            <div class="time">
              测评时间:{{ item.createTime ? item.createTime : "--" }}
            </div>
          </div>
        </div>
        <div>
          <div class="gradeTabCover" v-if="item.score != 0">
            <div v-for="(item, index) in item.list" :key="index">
              <div class="gradeTab">
                <div class="gradeTapView">
                  <div class="gradeTapTop">技能 <br />评价</div>
                </div>
                <div class="gradeTapView">
                  <div class="gradeTapTop">{{ item[0].title }}</div>
                </div>
                <div class="gradeTapView">
                  <div class="gradeTapTop">{{ item[1].title }}</div>
                </div>
                <div class="gradeTapView">
                  <div class="gradeTapTop">{{ item[2].title }}</div>
                </div>
                <div class="gradeTapView">
                  <div class="gradeTapTop">{{ item[3].title }}</div>
                </div>
              </div>
              <div class="gradeTab">
                <div class="gradeTabBottom">评分</div>
                <div class="gradeTabBottom">
                  {{ item[0].score ? item[0].score + "分" : "" }}
                </div>
                <div class="gradeTabBottom">
                  {{ item[1].score ? item[1].score + "分" : "" }}
                </div>
                <div class="gradeTabBottom">
                  {{ item[2].score ? item[2].score + "分" : "" }}
                </div>
                <div class="gradeTabBottom">
                  {{ item[3].score ? item[3].score + "分" : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom" v-if="item.score != 0">
          <div class="evaluateBtn" @click="goEvaluate(index)">
            评定等级<span v-if="item.level">: </span
            ><span
              class="grade"
              :class="{
                color1: item.level == '资深' || item.level == '高级',
                color2: item.level == '中级',
                color3: item.level == '初级',
              }"
              v-if="item.level"
              >{{ item.level + "-" + item.option }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="grayCover" v-if="isShowEvaluate == true"></div>
    <div class="evaluateCover" v-if="isShowEvaluate == true">
      <div class="btnCover">
        <div class="cancel" @click="cancelAction">取消</div>
        <div class="sure" @click="sureAction">确定</div>
      </div>
      <div class="line"></div>
      <div class="tip">评定级别：</div>
      <div v-for="(item, index) in levelArray" :key="index">
        <div class="gradeItem">
          <div class="scoreItemFirst">{{ item }}({{ index + 1 + "级" }})</div>
          <div v-for="(item1, index1) in gradeArray" :key="index1">
            <div
              class="scoreItem"
              :class="{
                selectItem: item == selectLevel && item1 == selectOption,
              }"
              @click="choseGrade(item, item1)"
            >
              {{ item1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomCover" v-if="isShowBottomBtn == true">
      <div class="leftBtn" @click="giveTest">发起测评</div>
      <div class="rightBtn" @click="editAnswer">修改答案</div>
      <div></div>
    </div>
    <no-result :title="'暂无数据'" v-if="!dataList.length"></no-result>
  </div>
</template>
<script>
import { ShareLink, ShareLogo } from "@/apis/const.js";
import { startShart } from "@/common/js/tecentshare.js";
import api from "@/apis/talentReview/talentReview.js";
import NoResult from "../components/commonly/noResult.vue";
import storage from "@/common/js/cacheData";
import {
  Toast,
  Field,
  Button,
  Popup,
  Form,
  DatetimePicker,
  CellGroup,
  List,
  Dialog
} from "vant";

export default {
  name: "SkillEvaluationExtension",
  components: {
    [List.name]: List,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Form.name]: Form,
    [Field.name]: Field,
    [DatetimePicker.name]: DatetimePicker,
    [CellGroup.name]: CellGroup,
    NoResult,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      dataList: [],
      levelArray: ["初级", "中级", "高级", "资深"],
      gradeArray: ["A", "B", "C"],
      isShowEvaluate: false,
      isShowBottomBtn: true,
      selectOption: "",
      selectLevel: "",
      selectIndex: 0,
      checkData: {},
      status: null,
      showAdd: false,
      name: "",
      postName: "",
      launchData: null
    };
  },
  created() {
    document.title = "专业水平评价";
    let openId = this.$route.query.openId;
    if (openId) {
      storage.saveData("openId", openId);
    }
    //判断是否填写信息
    this.showIfBindPhone();
  },
  mounted() {
    this.shareContent();
  },
  methods: {
    showIfBindPhone() {
      api
        ._checkOfficeReviewRecord({
          type: 3 //多准
        })
        .then((response) => {
          if (response.code == 200) {
            this.checkData = response.result;
            this.status = response.result.status;
            //0 未填写  1 已经填写 2 未扫码
            if (this.status == 0) {
              Toast("请填写个人信息");
              this.$router.push({
                path: "/jingzhunInfoInput",
                query: {
                  type: 3
                }
              });
            } else if (this.status == 1) {
              if (this.checkData.isAddress == 1) {
                //isAddress=1代表之前没填写行业地区
                Toast("请完善个人信息");
                this.$router.push({
                  path: "/jingzhunInfoInput",
                  query: {
                    type: 3,
                    isAddress: 1,
                    username: this.checkData.username,
                    id: this.checkData.id
                  }
                });
              } else {
                this.getEvaluationList();
              }
            } else if (this.status == 2) {
              Toast("请扫码");
            }
          }
        });
    },
    //重新组装数据
    changeData(list) {
      var scoreArray = [];
      var hange = parseInt(list.length / 4);
      var yu = list.length % 4;
      if (yu != 0) {
        hange++;
      }
      for (var i = 0; i < hange; i++) {
        let arrray = [];
        for (var k = 0; k < 4; k++) {
          let num = k + i * 4;
          if (num < list.length) {
            arrray.push(list[num]);
          } else {
            let emptyItem = { title: "" };
            arrray.push(emptyItem);
          }
        }
        scoreArray.push(arrray);
      }
      return scoreArray;
    },
    //列表
    getEvaluationList() {
      api
        .skillEvaluationExtensionList({
          skillId: this.checkData.reviewId,
          pcUserId: this.checkData.pcUserId,
          officeId: this.checkData.officeId,
          userId: this.checkData.userId
        })
        .then((response) => {
          if (response.code == 200) {
            this.dataList = [];
            var dataArray = response.result;
            for (var i = 0; i < dataArray.length; i++) {
              var item = dataArray[i];
              item.list = this.changeData(item.list);
              this.dataList.push(item);
            }
          }
        });
    },
    //清空数据
    changeEmptyData() {
      this.isShowBottomBtn = true;
      this.selectLevel = "";
      this.selectOption = "";
      this.selectIndex = 0;
      this.isShowEvaluate = false;
    },
    //选分
    choseGrade(level, option) {
      this.selectLevel = level;
      this.selectOption = option;
    },
    //取消
    cancelAction() {
      this.changeEmptyData();
    },
    //确定
    sureAction() {
      if (this.selectLevel == "" || this.selectOption == "") {
        Toast("请选择评分");
        return;
      } else {
        let param = {};
        let selectItem = this.dataList[this.selectIndex];
        param.skillId = selectItem.skillId;
        param.assessUserId = selectItem.assessUserId;
        param.userId = selectItem.userId;
        param.officeId = selectItem.officeId;
        param.level = this.selectLevel;
        param.option = this.selectOption;
        api.addSkillLevel(param).then((response) => {
          if (response.code == 200) {
            selectItem.level = this.selectLevel;
            selectItem.option = this.selectOption;
            this.changeEmptyData();
          }
        });
      }
    },
    //评价
    goEvaluate(index) {
      this.selectIndex = index;
      let selectItem = this.dataList[this.selectIndex];
      this.isShowEvaluate = true;
      this.isShowBottomBtn = false;
      if (selectItem.level) {
        this.selectLevel = selectItem.level;
        this.selectOption = selectItem.option;
      }
    },
    //发起测评
    giveTest() {
      if (this.checkData.reviewId) {
        api
          .startSkillEvaluation({
            userId: this.checkData.userId,
            pcUserId: this.checkData.pcUserId,
            officeId: this.checkData.officeId
          })
          .then((response) => {
            if (response.code == 200) {
              this.launchData = response.result;
              if (this.launchData.status == 0) {
                this.$toast("请先设置维度内容!");
              } else {
                this.$router.push({
                  path: "/QrCard",
                  query: {
                    name: this.launchData.name,
                    qrUrl: this.launchData.qrUrl,
                    posterUrl: this.launchData.posterUrl,
                    shareUrl: this.launchData.shareUrl
                  }
                });
              }
            }
            // this.dataInfo = response.result
          });
      } else {
        this.$toast("请先设置维度内容!");
      }
    },
    //修改答案
    editAnswer() {
      this.$router.push({
        path: "/DuoSetAnswer",
        query: {
          userId: this.checkData.userId,
          officeId: this.checkData.officeId
        }
      });
    },
    //分享
    shareContent() {
      const titleStr = "HRCOE";
      const contentStr = "让人力资源工作更简单!";
      const linkStr = ShareLink + "type=1";
      const imgStr = ShareLogo;
      window.shareData = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      window.shareIMdata = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      startShart();
    }
  }
};
</script>

<style scoped>
.cover {
  margin-top: 1.5rem;
  padding-bottom: 6rem;
}
.item {
  opacity: 1;
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0rem 0.4rem 1rem 0rem rgba(0, 0, 0, 0.2);
  margin: 1rem;
  padding: 1rem;
}
.info {
  display: flex;
  height: 5rem;
}
.info_left {
  width: 5rem;
  height: 100%;
  opacity: 1;
  background: linear-gradient(180deg, #6c97ff, #3f72ec);
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info_right {
  width: 100%;
  height: 100%;
  margin-left: 1rem;
  flex: 1;
  position: relative;
  overflow: hidden;
}
.name {
  opacity: 1;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  width: 100%;
}
.time {
  position: absolute;
  font-size: 1.2rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
}
.phone {
  opacity: 1;
  font-size: 1.2rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #666666;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  position: absolute;
  right: 0rem;
  top: 0rem;
}
.bottom {
  height: 4rem;
  position: relative;
}
.evaluateBtn {
  opacity: 1;
  font-size: 1.6rem;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: right;
  color: #13d1be;
  position: absolute;
  right: 0rem;
  top: 0rem;
  height: 100%;
  line-height: 4rem;
  width: 13rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.grade {
  opacity: 1;
  font-size: 1.6rem;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #13d1be;
}
.color1 {
  color: #13d1be;
}
.color2 {
  color: #e6b800;
}
.color3 {
  color: #fd4e23;
}
.grayCover {
  opacity: 0.2;
  background: #000000;
  width: 100%;
  height: 20rem;
  position: fixed;
  left: 0rem;
  top: 0rem;
  width: 100%;
  height: 100%;
}
.evaluateCover {
  opacity: 1;
  background: #ffffff;
  width: 100%;
  height: 38rem;
  position: fixed;
  left: 0rem;
  bottom: 0rem;
}
.btnCover {
  width: 100%;
  height: 5rem;
}
.cancel {
  opacity: 1;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #13d1be;
  float: left;
  height: 5rem;
  line-height: 5rem;
  width: 5rem;
  text-align: center;
}
.sure {
  opacity: 1;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  float: right;
  height: 5rem;
  line-height: 5rem;
  width: 5rem;
  text-align: center;
}
.line {
  opacity: 1;
  background: #f4f4f4;
  height: 0.1rem;
  width: 100%;
}
.tip {
  opacity: 1;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
  margin-left: 1rem;
  line-height: 5rem;
}
.gradeItem {
  margin-top: 2rem;
  height: 3rem;
}
.scoreItemFirst {
  float: left;
  margin-left: 1rem;
  height: 100%;
  opacity: 1;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 3rem;
  width: 6rem;
}
.scoreItem {
  float: left;
  margin-left: 1.5rem;
  height: 100%;
  line-height: 3rem;
  opacity: 1;
  background: #f4f4f4;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #333333;
  width: calc((100% - 6rem - 1rem - 6rem) / 3);
}
.selectItem {
  background: #13d1be;
  color: #ffffff;
}
.gradeTabCover {
  margin: 2rem 0rem 1rem 0rem;
}
.gradeTab {
  display: flex;
  align-items: center;
}
.gradeTapView {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  flex: 1;
  border: 0.1rem solid #71d2c8;
  margin-left: -0.1rem;
  margin-top: -0.1rem;
  margin-bottom: -0.1rem;
  text-align: center;
  font-size: 1.1rem;
  background: #f0fcfa;
  text-align: center;
}
.gradeTapTop {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
}
.gradeTabBottom {
  height: 3rem;
  flex: 1;
  border: 0.1rem solid #71d2c8;
  margin-left: -0.1rem;
  text-align: center;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #ffffff;
  line-height: 3rem;
}
.add_btn {
  position: fixed;
  bottom: 0rem;
  width: 100%;
  left: 0rem;
  background-color: #13d1be;
  border: 0rem;
}

.m_field {
  width: 30rem;
  margin: 2rem auto;
  border: 0.1rem solid #f4f4f4;
}
.bottomCover {
  position: fixed;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
  height: 6rem;
  display: flex;
  background-color: white;
}
.leftBtn {
  background: #13d1be;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 4rem;
  height: 4rem;
  text-align: center;
  flex: 2;
  margin: 1rem;
}
.rightBtn {
  opacity: 1;
  background: rgba(19, 209, 190, 0.2);
  border: 0.1rem solid #13d1be;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #13d1be;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  flex: 1;
  margin: 1rem 1rem 1rem 0rem;
}
</style>