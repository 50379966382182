<template>
    <div class="main_view">
        <div class="item">
            <div class="title">诊断得分：{{this.resultData.totalScore.score}}分</div>
<!--            <div class="content">{{this.resultData.totalScore.content}}</div>-->
            <div style="margin: 10px;display: flex;background-color: white">
                <div class="tag">0</div>
                <Progress style="margin-bottom: 20px"
                          :percent="diagnosePercent"
                          :stroke-width="20"
                          status="active"
                          text-inside />
                <div class="tag">5</div>
            </div>
        </div>
        <div class="item">
            <div class="title">能力评估</div>
            <div style="display: flex;justify-content: center">
                <render :renderdata='renderdata' v-if="showRender"></render>
            </div>

        </div>
        <div class="item">
            <div class="title">企业HR能力评估</div>
            <div class="item_pinggu" v-for="(item,index) in resultData.eachScore" :key="index">
                <div class="item_a">{{item.name}}</div>
                <div class="item_b">{{item.score}}分</div>
                <Progress class="item_c"
                          :percent="(item.score*100/5).toFixed(2)"
                          :stroke-width="20"
                          status="active"
                          text-inside />
            </div>
        </div>
        <div style="display:flex;justify-content: center">
            <van-button class="talent_button" @click="reTry">重新诊断</van-button>
        </div>
        
    </div>
</template>

<script>
    import {Button} from "vant";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import api from "@/apis/talentReview/talentReview.js";
    import render from "../components/render";
    import storage from "@/common/js/cacheData";
    export default {
        name: "DiagnosisResult",
        data() {
            return {
                diagnosePercent: 0,
                resultData: {
                    totalScore:{
                        score: 0,
                        content: ''
                    }
                },
                renderdata: {
                    data: [
                    ]
                },
                //控制雷达图显示隐藏
                showRender: false
            }
        },
        components:{
          [Button.name]: Button,
          render
        },
        mounted() {
            document.title='诊断结果';
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            this.getData();
            this.shareContent();
        },
        methods: {
            getData() {
                api._hr_diagnosis_result().then(
                    response => {
                        if (response.code == 200) {
                            this.resultData = response.result;
                            this.showRender = true;
                            this.renderdata.data = response.result.radarScore;
                            this.diagnosePercent=this.resultData.totalScore.score*100/5
                            this.diagnosePercent=this.diagnosePercent.toFixed(2)
                        }
                    }
                )
            },
            reTry(){
                //重新诊断
                this.$router.push({
                    path: '/DiagnosticNotes'
                })
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .main_view {
        background-color: #f4f4f4;
        overflow-y: auto;
        height: 100vh;
    }

    .item {
        background-color: white;
        margin: 10px 16px;
        border-radius: 5px;
    }
    .tag{
        font-size: 16px;
        margin: 0 16px;
        color: #333333;
    }
    .title {
        height: 40px;
        line-height: 48px;
        text-align: center;
        color: #FD4E23;
        font-size: 16px;
        font-weight: bold;
    }

    .content {
        color: #333333;
        font-size: 14px;
        margin: 10px;
        text-indent: 3rem;
        line-height: 30px;
    }
    .item_pinggu{
        display: flex;
        margin: 10px 16px;
        padding-bottom: 10px;
    }
    .item_a{
       width: 20%;
        font-size: 14px;
    }
    .item_b{
        color: #FD4E23;
        width: 15%;
        font-size: 14px;
    }
    .item_c{
        width: 65%;
    }
    .talent_button {
        background-color: #13d1be;
        border-radius: 3px;
        color: white;
        margin: 3rem 0;
        width: 200px;
        height: 40px;
    }
</style>