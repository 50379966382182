<template>
  <diV class="setAnswer">
    <mystep curstep="1" />
    <div class="mContent">
      <div class="mContentTille">标准答案</div>
      <div class="mContentAnswer">
        <div class="item">
          <div class="itemTiltle">A-0分</div>
          <div class="itemInput">
            <input v-model="optionA"
                   :onkeyup="optionA = optionA.replace(/\s+/g,'')"
                   placeholder="请输入标准答案" />
          </div>
        </div>
        <div class="item">
          <div class="itemTiltle">B-1分</div>
          <div class="itemInput">
            <input v-model="optionB"
                   :onkeyup="optionB = optionB.replace(/\s+/g,'')"
                   placeholder="请输入标准答案" />
          </div>
        </div>
        <div class="item">
          <div class="itemTiltle">C-3分</div>
          <div class="itemInput">
            <input v-model="optionC"
                   :onkeyup="optionC = optionC.replace(/\s+/g,'')"
                   placeholder="请输入标准答案" />
          </div>
        </div>
        <div class="item">
          <div class="itemTiltle">D-5分</div>
          <div class="itemInput">
            <input v-model="optionD"
                   :onkeyup="optionD = optionD.replace(/\s+/g,'')"
                   placeholder="请输入标准答案" />
          </div>
        </div>
      </div>
    </div>
    <div class="mOperate">
      <van-button class="saveBtn" @click="handleNextStep"
        >保存并进入下一步</van-button
      >
    </div>
  </diV>
</template>
<script>
import mystep from "./mystep.vue";
import api from "@/apis/talentReview/talentReview.js";
import { Toast } from "vant";
import {startShart} from "@/common/js/tecentshare.js";
import {ShareLink, ShareLogo} from "@/apis/const.js";
export default {
  name: "DuoSetAnswer",
  components: {
    [Toast.name]: Toast,
    mystep
  },
  data() {
    return {
      id: "",
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
      userId: "",
      officeId: ""
    };
  },
  created() {
    document.title = "";
    this.userId = this.$route.query.userId;
    this.officeId = this.$route.query.officeId;
    this.getPreViewData();
    this.shareContent();
  },
  methods: {
    //预览数据
    getPreViewData() {
      api.duoPreview({
        userId:this.userId,
        officeId:this.officeId
      }).then((response) => {
        if (response.code == 200) {
          this.id = response.result.skillEvaluation.id;
          this.optionA = response.result.skillEvaluation.optionA;
          this.optionB = response.result.skillEvaluation.optionB;
          this.optionC = response.result.skillEvaluation.optionC;
          this.optionD = response.result.skillEvaluation.optionD;
        }
      });
    },
    handleNextStep() {
      if (
        this.optionA == undefined || this.optionA == "" ||
        this.optionB == undefined || this.optionB == "" ||
        this.optionC == undefined || this.optionC == "" ||
        this.optionD == undefined || this.optionD == ""
      ) {
        Toast("答案设置不完整");
        return;
      }
      let param = {};
      param.userId = this.userId;
      param.officeId = this.officeId;
      param.optionA = this.optionA;
      param.optionB = this.optionB;
      param.optionC = this.optionC;
      param.optionD = this.optionD;
      if (this.id != undefined && this.id != "") {
        param.id = this.id;
      }
      api.addSkillLevelEvaluation(param).then((response) => {
        if (response.code == 200) {
          this.$router.push({
            path: "/DuoSetDimension",
            query: {
              userId: this.userId,
              officeId: this.officeId
            }
          });
        }
      });
    },
    //分享
    shareContent() {
      const titleStr = "HRCOE";
      const contentStr = "让人力资源工作更简单!";
      const linkStr = ShareLink + "type=1";
      const imgStr = ShareLogo;
      window.shareData = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      window.shareIMdata = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      startShart();
    }
  }
};
</script>
<style lang='less' scoped>
.setAnswer {
  .mContent {
    overflow: hidden;
    width: 90%;
    background: #ffffff;
    border-radius: 1rem;
    box-shadow: 0rem 1rem 2rem 0rem rgba(0, 0, 0, 0.1);
    margin: 1rem auto;
    padding-bottom: 5rem;
    .mContentTille {
      text-align: center;
      padding: 2rem 0;
      font-size: 1.8rem;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333;
    }
    .mContentAnswer {
      .item {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        margin: 2rem;
        .itemInput {
          margin-left: 0.5rem;
          flex: 1;
        }
        .itemTiltle {
          opacity: 1;
          font-size: 1.4rem;
          font-family: PingFang SC, PingFang SC-Bold;
          font-weight: 700;
          color: #333333;
		  width: 5rem;
        }
      }
    }
  }
  .mOperate {
    text-align: center;
    margin: 5rem auto;
  }
  .saveBtn {
    width: 20rem;
    height: 4rem;
    opacity: 1;
    background: #13d1be;
    border-radius: 0.5rem;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #ffffff;
  }
}
input {
  padding-left: 1rem;
  width: 100%;
  height: 3rem;
  opacity: 1;
  background: #ffffff;
  border: 0.1rem solid #dadada;
  border-radius: 0.5rem;
  outline: none;
  font-size: 1.2rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #333;
}
input::-webkit-input-placeholder {
  font-size: 1.2rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #999;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 1.2rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #999;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 1.2rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #999;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 1.2rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #999;
}
</style>