<template>
    <div class="center-view">
        <div class="topImage">
            <div class="title">今日学习:{{study.studyTime}}分钟 打败了{{study.percentage}}的同学</div>
        </div>
        <div class="select-item">
            <div class="item">
                <div :class="select=='0'?'select':'selectno'" @click="selectClick(0)">工作坊</div>
                <div :class="select=='0'?'selectLine':'selectnoLine'" class="line-select"></div>
            </div>
            <div class="item">
                <div :class="select=='1'?'select':'selectno'" @click="selectClick(1)">课程</div>
                <div :class="select=='1'?'selectLine':'selectnoLine'" class="line-select"></div>
            </div>
        </div>
        <div class="tarin-list" v-if="select == '0'">
            <train-list v-for="(item,index) in study.myClass" :trainList=item @gotoDitial=gotoDitial
                        :key="'train'+index"></train-list>
            <no-result :title="'您还没有加入工作坊'" v-if="!study.myClass.length"></no-result>
        </div>
        <div class="tarin-list" v-if="select == '1'">
            <coure-list v-for="(item,index) in study.myCourse" :coureList=item @gotoDitial=gotoDitial
                        :key="'coure'+index"></coure-list>
            <no-result :title="'您还没有加入课程'" v-if="!study.myCourse.length"></no-result>
        </div>
        <foot/>
    </div>
</template>

<script>
    import goodsDetail from "@/apis/goodsDetail/goodsDetail";
    import TrainList from "../components/commonly/trainingList.vue";
    import CoureList from "../components/commonly/coureList.vue";
    import NoResult from "../components/commonly/noResult.vue";
    import Foot from "../components/public/x_foot.vue";
    import {ShareLink,ShareLogo} from "@/apis/const.js";
    import {startShart} from "@/common/js/tecentshare.js";
    export default {
        name: "Study",
        data() {
            return {
                select: "0",
                noresult: "你还没有学习哦",
                isMebership: true,
                defImg: require("../../../static/image/chatBoy.png"),
                study: {
                    myClass: [],
                    myCourse: [],
                    studyTime: "--",
                    percentage: "--"
                }
            }
        },
        components: {
            TrainList, CoureList, NoResult, Foot
        },
        created() {
            this.officeId = localStorage.getItem('m_officeId');
            this.userId = localStorage.getItem('m_userId');
            this.getDataList();
        },
        mounted() {
            this.shareContent();
        },
        activated() {
            document.title = localStorage.getItem('m_cloudName');
            if (this.officeId != localStorage.getItem('m_officeId')) {
                this.officeId = localStorage.getItem('m_officeId');
                this.userId = localStorage.getItem('m_userId');
                this.getDataList();
            }

        },
        methods: {
            selectClick(val) {
                let that = this;
                that.select = val;
            },
            getDataList() {
                let that = this;
                goodsDetail._getMyStudyList(this.officeId, this.userId).then(
                    response => {
                        let result = response.result;
                        that.study = result;
                    },
                    error => {
                    }
                );
            },
            gotoDitial(item, type) {
                let that = this;
                if (type == 0) {
                    // if (item.campCategory == 2) {
                    //     that.$router.push({
                    //         path: "/teacher",
                    //         query: {
                    //             goodsInfoId: item.totalId,
                    //             t: Date.now()
                    //         }
                    //     });
                    // } else {
                    //训练营任务
                    that.$router.push({
                        path: "/taskDetail",
                        query: {
                            totalId: item.totalId,
                            officeId: this.officeId,
                            userId: this.userId
                        }
                    });
                    // }
                } else {
                    //课程播放详情
                    that.$router.push({
                        path: "/videoPaly",
                        query: {
                            type: 1,
                            totalId: item.totalId,
                            officeId: this.officeId,
                            userId: this.userId,
                            t: Date.now()
                        }
                    });
                }
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .tarin-list {
        margin-top: 1rem;
        position: relative;
    }

    .center-view {
        background-color: #fff;
        padding-bottom: 4rem;
    }

    .topImage {
        width: 100%;
        background-image: url("../../../static/image/study_top_bg.png");
        background-size: 100% 10rem;
        height: 10rem;
    }

    .title {
        padding: 6.5rem 0 0 2rem;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
    }

    .select-item {
        display: flex;
        display: -webkit-flex;
        padding: 0 6rem;
        height: 44px;
    }

    .item {
        font-size: 15px;
        font-weight: bold;
        color: #333;
        text-align: center;
        line-height: 30px;
        height: 30px;
        width: 50%;
        margin-top: 1rem;
    }

    .select {
        color: #13d1be;
    }

    .selectno {
        color: #333;
    }

    .selectLine {
        background-color: #13d1be;
        height: 2px;
        width: 40px;
        margin: 0 auto;
    }

    .selectnoLine {
        background-color: #fff;
        height: 2px;
        width: 40px;
        margin: 0 auto;
    }
</style>