<template>
    <div style="display: flex;flex-direction: column">
        <div class="main_view">
            <div class="title">信息填写</div>
            <input class="m_input" v-model="username"
                   :disabled="isAddress==1"
                   :onkeyup="username = username.replace(/\s+/g,'')"
                   placeholder="请填写姓名"/>
            <div class="m_input m_placeholder" :class="city=='请选择城市'?'m_placeholder':'m_placeholder_2'" @click="showArea = true">{{city}}</div>
            <van-popup v-model="showArea" position="bottom">
                <van-area
                        :area-list="areaList"
                        :columns-num="2"
                        @confirm="onConfirmCity"
                        @cancel="showArea = false">
                </van-area>
            </van-popup>
            <div class="m_input m_placeholder" :class="industry=='请选择行业'?'m_placeholder':'m_placeholder_2'" @click="showIndustryPicker = true">{{industry}}</div>
            <van-popup v-model="showIndustryPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="industryColumns"
                        :columns-num="2"
                        @confirm="onConfirmIndustry"
                        @cancel="showIndustryPicker = false">
                </van-picker>
            </van-popup>
<!--            <input class="m_input" v-model="phone" placeholder="请填写手机号"/>-->
            <div class="info" v-if="isAddress==1">你还完善写个人信息，请先完善个人信息！</div>
            <div class="info" v-else>你还未填写个人信息，请先填写个人信息！</div>
        </div>
        <div class="btn_" @click="jump">完成</div>
    </div>
</template>

<script>
    import {Toast,Picker, Popup,Area} from "vant";
    import api from "@/apis/talentReview/talentReview.js";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import areaList from '@/assets/Area.js';
    import industryList from '@/assets/hangye.json';
    export default {
        name: "jingzhunInfoInput",
        data() {
            return {
                showIndustryPicker: false,
                showArea: false,
                username: '',
                phone: '',
                type: null,
                mId: null,
                isAddress: null,
                city: '请选择城市',
                industry: '请选择行业',
                areaList: {},
                industryColumns: []
            }
        },
        components:{
            [Toast .name]: Toast,
            [Picker .name]: Picker,
            [Popup .name]: Popup,
            [Area .name]: Area
        },
        mounted() {
            document.title = "填写个人信息";
            this.type = this.$route.query.type;
            this.isAddress = this.$route.query.isAddress;
            if (this.isAddress==1){
                this.username = this.$route.query.username;
                this.mId = this.$route.query.id;
            }
            this.shareContent();
            this.getData()
        },
        methods: {
            onConfirmIndustry(value) {
                this.industry = value.toString();
                this.showIndustryPicker = false;
            },
            onConfirmCity(values) {
                console.log(values)
                this.city = values
                    .filter((item) => !!item)
                    .map((item) => item.name)
                    .join('/');
                this.showArea = false;
            },
            getData(){
                this.areaList = areaList;
                this.industryColumns = industryList;
            },
            jump(){
                if (this.username==''){
                    Toast('请填写姓名');
                    return
                }
                if (this.username.length>10){
                    Toast('输入姓名内容过长');
                    return
                }
                if (this.city=='请选择城市'){
                    Toast('请选择城市');
                    return
                }
                if (this.industry=='请选择行业'){
                    Toast('请选择行业');
                    return
                }
                // if (this.phone==''){
                //     Toast('请填写手机号');
                //     return
                // }
                // let reg = /(1[0-9]{10}$)/;
                // if (!reg.test(this.phone)) {
                //     Toast({
                //         message: "请输入正确手机号",
                //         position: "middle",
                //         duration: 1000
                //     });
                //     return
                // }
                let reqData={
                    username: this.username,
                    address: this.city,
                    industry: this.industry
                };
                if (this.type){
                    reqData.type=this.type
                }
                if (this.mId){
                    reqData.id=this.mId
                }
                api._addOfficeReviewRecord(reqData).then(
                    response => {
                        if (response.code == 200) {
                            Toast({
                                message: response.msg,
                                position: "middle",
                                duration: 1500
                            });
                            this.$router.go(-1)
                            // if (this.type==2){
                            //     this.$router.push({
                            //         path: '/IntroducePage',
                            //         query: {
                            //             dimension: response.result.dimension,
                            //             reviewId: response.result.reviewId,
                            //             type: response.result.type,
                            //             userId: response.result.userId,
                            //             id: response.result.id,
                            //             username: response.result.username,
                            //             reviewUserId: response.result.reviewUserId,
                            //             pcUserId: response.result.userId,
                            //             JumpType: this.JumpType
                            //         }
                            //     })
                            // }else if (this.type==1){
                            //     this.$router.go(-1)
                            // }

                        }
                    }
                )

            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .main_view {
        display: flex;
        flex-direction: column;
        margin: 5rem 2rem;
        opacity: 1;
        text-align: center;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    }

    .title {
        padding-top: 10px;
        padding-bottom: 20px;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        color: #333333;
    }

    .m_input {
        background: #ffffff;
        border: 2px solid #f4f4f4;
        border-radius: 5px;
        padding-left: 1rem;
        font-size: 16px;
        margin: 2rem 2rem 0 2rem;
        height: 4rem;
        line-height: 4rem;
    }
    .m_placeholder{
        text-align: left;
        color: #999999;
        font-size: 14px;
    }
    .m_placeholder_2{
        text-align: left;
        color: #333333;
        font-size: 14px;
    }

    .info {
        font-size: 14px;
        font-weight: 400;
        margin-top: 4rem;
        margin-bottom: 4rem;
        text-align: center;
        color: #999999;
    }

    .btn_ {
        width: 25rem;
        color: white;
        height: 4rem;
        margin: 0 auto;
        line-height: 4rem;
        font-size: 16px;
        background-color: #13d1be;
        text-align: center;
        border-radius: 5px;
    }
</style>