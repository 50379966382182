<template>
    <div>
        <div class="top-view">
            <div>
                <div class="top-item-text-1">{{ title }}</div>
                <div class="top-item-text-2">{{ address }}</div>
            </div>
            <div class="top-item mt10">
                <div class="top-item-text-3">主讲老师：{{speaker}}</div>
                <div class="top-item-text-3">班主任：{{headmaster}}</div>
            </div>
            <div class="mt10 top-item-text-3">起止时间：{{startTime+'-'+endTime}}</div>

        </div>
        <div class="top">
            <div class="top-content">签到记录</div>
            <div class="top-line"></div>
        </div>
        <div class="list">
            <div v-for="(item, index) in dataList" :key="index">
                <div>
                    <div class="listItem-top">
                        <div class="listItem-content">签到时间</div>
                        <div class="listItem-content">{{ item.createTime }}</div>
                    </div>
                    <div class="listItem-line"></div>
                </div>
            </div>
        </div>
        <no-result :title="'暂无数据'" v-if="!dataList.length"></no-result>
        <div class="bottom">
            <Button
                    class="bottom_left"
                    :class="{
          noSignData: !dataList.length,
        }"
                    @click="inputExperience()"
            >
                填写心得
            </Button>
            <Button class="noSign" @click="addSign()">{{isSign==1?'已签到':'签到'}}</Button>
        </div>

    </div>
</template>
<script>
    import {Toast, Picker, Popup} from "vant";
    import api from "@/apis/talentReview/talentReview.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import NoResult from "../../components/commonly/noResult.vue";
    import storage from "@/common/js/cacheData";

    export default {
        name: "SignList",
        data() {
            return {
                title: "",
                address: "",
                speaker: "",
                headmaster: "",//班主任
                startTime: "",
                endTime: "",
                ranges: "",//签到范围
                longitude: "",//经纬度
                isSign: 1,
                id: "",
                experience: "",
                score: 0,
                dataList: [],
                latestSignTime: 0,//时间戳
                timeInterval: 1200000
            }
        },
        components: {
            [Toast.name]: Toast,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            NoResult
        },
        created() {
            document.title = "签到";
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            this.getSignList()
        },
        mounted() {
            this.shareContent()
        },
        methods: {
            //签到列表
            getSignList() {
                api.offline_signList().then((response) => {
                    if (response.code == 200) {
                        this.id = response.result.id;
                        if (response.result.score) {
                            this.score = response.result.score;
                        }
                        if (response.result.experience) {
                            this.experience = response.result.experience;
                        }
                        this.title = response.result.title;
                        this.address = response.result.address;
                        this.isSign = response.result.isSign;
                        this.speaker = response.result.speaker;
                        this.headmaster = response.result.headmaster;
                        this.startTime = response.result.startTime;
                        this.endTime = response.result.endTime;
                        this.longitude = response.result.longitude;
                        this.ranges = response.result.ranges;
                        this.dataList = response.result.list;
                        if (this.dataList.length > 0) {
                            this.latestSignTime = Date.parse(this.dataList[0].createTime);


                        }
                    }
                });
            },
            //签到
            addSign() {
                let currentTimeStr = new Date().getTime();
                let jiange = currentTimeStr - this.latestSignTime;
                if (jiange < this.timeInterval) {
                    Toast("20分钟之内不可以重复签到")
                } else {
                    if (this.longitude) {
                        this.$router.push({
                            path: '/SignPage',
                            query: {
                                ranges: this.ranges,
                                longitude: this.longitude
                            }
                        })
                    } else {
                        api.offline_addSign().then((response) => {
                            if (response.code == 200) {
                                Toast({
                                    message: "签到成功",
                                    position: "middle",
                                    duration: 1500
                                });
                                this.getSignList();
                            }
                        });
                    }
                }


            },
            //填写心得
            inputExperience() {
                if (this.dataList.length) {
                    this.$router.push({
                        path: "/SignExperienceInput",
                        query: {
                            id: this.id,
                            score: this.score,
                            experience: this.experience
                        }
                    });
                }
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
            }
        }
    };
</script>
<style scoped>
    .mt10 {
        margin-top: 10px;
    }

    .top-view {
        background: #ffffff;
        border: 1px solid #f4f4f4;
        border-radius: 5px;
        margin: 10px;
        padding: 10px;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10);
    }

    .top-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .top-item-text-1 {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
    }

    .top-item-text-2 {
        font-size: 14px;
        color: #333333;
    }

    .top-item-text-3 {
        font-size: 12px;
        color: #666666;
    }

    .top {
        margin: 2rem 2rem 0 2rem;
    }

    .top-title {
        font-size: 1.6rem;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .top-content {
        font-size: 1.4rem;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        margin-top: 3rem;
    }

    .top-line {
        height: 0.1rem;
        background: #dadada;
        margin-top: 1rem;
    }

    .list {
        margin: 0rem 2rem;
        padding-bottom: 8rem;
    }

    .listItem-top {
        display: flex;
        justify-content: space-between;
    }

    .listItem-content {
        height: 4rem;
        font-size: 1.4rem;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 4rem;
    }

    .listItem-line {
        height: 0.1rem;
        background: #dadada;
    }

    .bottom {
        display: flex;
        position: fixed;
        left: 0rem;
        right: 0rem;
        bottom: 0rem;
        background-color: white;
        padding-bottom: 2rem;
    }

    .bottom_left {
        height: 4rem;
        background: #13d1be;
        border-radius: 0.5rem;
        border: 0.01rem solid #13d1be;
        font-size: 1.4rem;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        margin: 0 2rem;
        flex: 2;
    }

    .noSignData {
        background: #dadada;
        color: #999999;
        border: 0;
    }

    .noSign {
        height: 4rem;
        background: rgba(19, 209, 190, 0.2);
        border: 1px solid #13d1be;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        font-weight: 400;
        text-align: center;
        color: #13d1be;
        margin-right: 2rem;
        flex: 1;
    }

    .hasSign {
        background: #dadada;
        color: #999999;
        border: 0;
    }
</style>
