<template>
  <div class="forcenter">
    <div class="m_card">
      <div class="title">欢迎参加“专业水平”技能评价系统</div>
      <div class="content">
        您正在测试的是“专业水平”<span v-if="evaluationName">{{evaluationName}}-</span>技能测评。
      </div>
      <div class="content">
        “专业水平”技能评价系统适用于岗位的专业水平评价，由企业自行定义评价内容和评价维度，以岗位关键技能和关键动作为主要评价内容，旨在通过多维度子项技能评价员工的实际专业等级，进而指导聘用、调薪、培训等工作开展。评价一般为员工自己（参考）、上级主管、间接主管、人力资源负责人。
      </div>
      <div class="content">
        请您根据员工的实际工作表现进行评价，尽可能做到客观、公正。
      </div>
      <div class="content">我已经准备好了！</div>
    </div>
    <van-button class="talent_button" @click="startInventory"
      >开始测评</van-button
    >
  </div>
</template>
<script>
import { Button } from "vant";
import { startShart } from "@/common/js/tecentshare.js";
import { ShareLink, ShareLogo } from "@/apis/const.js";
import storage from "@/common/js/cacheData";
export default {
  name: "SkillEvaluationNotice",
  data() {
    return {
      skillId:"",
      assessUserId:"",
      userId:"",
      officeId:"",
      skillEvaluationId:"",
      isExtension: null,
      evaluationName: null,
      category: null
    };
  },
  components: {
    [Button.name]: Button
  },
  created() {
    document.title = "专业水平";
    this.skillId = this.$route.query.skillId;
    this.assessUserId = this.$route.query.assessUserId;
    this.userId = this.$route.query.userId;
    this.officeId = this.$route.query.officeId;
    this.skillEvaluationId = this.$route.query.skillEvaluationId;
    this.isExtension = this.$route.query.isExtension;
    this.evaluationName = this.$route.query.evaluationName;
    this.category = this.$route.query.category;
    let openId = this.$route.query.openId;
    if (openId) {
      storage.saveData("openId", openId);
    }
  },
  mounted() {
    this.shareContent();
  },
  methods: {
    startInventory() {
      this.$router.push({
        path: "/SkillEvaluationTest",
        query: {
          skillId: this.skillId,
          assessUserId: this.assessUserId,
          userId: this.userId,
          officeId: this.officeId,
          skillEvaluationId:this.skillEvaluationId,
          isExtension: this.isExtension,
          category: this.category
        }
      });
    },
    //分享
    shareContent() {
      const titleStr = "HRCOE";
      const contentStr = "让人力资源工作更简单!";
      const linkStr = ShareLink + "type=1";
      const imgStr = ShareLogo;
      window.shareData = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      window.shareIMdata = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      startShart();
    }
  }
};
</script>
<style scoped>
.forcenter {
  /*min-height: 100vh;*/
  margin: 0rem auto;
  background: #fff;
  text-align: center;
}
.m_card {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 20%;
}
.title {
  font-size: 1.6rem;
  line-height: 0.2rem;
  margin-bottom: 2rem;
  color: #333;
  text-align: center;
  padding: 3rem 0 0 0;
}
.content {
  text-indent: 3rem;
  font-size: 1.4rem;
  color: #333;
  text-align: left;
  line-height: 3rem;
}
.talent_button {
  background-color: #13d1be;
  border-radius: 0.3rem;
  color: white;
  margin-top: 15%;
  width: 20rem;
  height: 4rem;
}
</style>
