<template>
    <div class="forcenter">
        <div class="m_card">
            <div class="m_title">
                <!--                <div class='title'>盘点维度:<span>[{{dataItem[index].remark}}]</span></div>-->
                <div class='title'>{{dataItem[index].title}}</div>
            </div>

            <div class="item_card">
                <div class="left-item"
                     :class="(allanswer.length > last)&&(allanswer[last].score == evaluationScoreSetDto.optionA)?'answers-bg-active':'answers-bg'"
                     @click="next(evaluationScoreSetDto.optionA)">
                    <div>A、</div>
                    <div>{{dataItem[index].optionA}}</div>
                </div>
                <div class="left-item"
                     :class="(allanswer.length > last)&&(allanswer[last].score == evaluationScoreSetDto.optionB)?'answers-bg-active':'answers-bg'"
                     @click="next(evaluationScoreSetDto.optionB)">
                    <div>B、</div>
                    <div>{{dataItem[index].optionB}}</div>
                </div>
                <div class="left-item"
                     :class="(allanswer.length > last)&&(allanswer[last].score == evaluationScoreSetDto.optionC)?'answers-bg-active':'answers-bg'"
                     @click="next(evaluationScoreSetDto.optionC)">
                    <div>C、</div>
                    <div>{{dataItem[index].optionC}}</div>

                </div>
                <div class="left-item"
                     :class="(allanswer.length > last)&&(allanswer[last].score == evaluationScoreSetDto.optionD)?'answers-bg-active':'answers-bg'"
                     @click="next(evaluationScoreSetDto.optionD)">
                    <div>D、</div>
                    <div>{{dataItem[index].optionD}}</div>
                </div>
            </div>
            <div class="m_bottom">
                <div v-if="isShow" class='m_bottom_left' @click='go_start'>{{previousName}}</div>
                <div style="width: 5rem" v-else></div>

<!--                <div class='m_bottom_right'>{{index+1}}/{{dataItem.length}}</div>-->
                <div class='m_bottom_center'>{{index+1}}/{{dataItem.length}}</div>
                <div v-if="index==dataItem.length-1" class='m_bottom_right' @click='submit'>提交</div>
                <div v-else class='m_bottom_right' @click='go_next'>{{nextName}}</div>
            </div>

        </div>

    </div>
</template>

<script>
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import api from "@/apis/talentReview/talentReview.js";
    import {Toast} from 'vant'

    export default {
        name: "ReviewItem",
        data() {
            return {
                fromCaizhun: null,
                isConfirm: true,
                dimension: '',
                type: null,
                reviewId: null,
                id: 0,
                reviewUserId: 0,
                userId: 0,
                pcUserId: 0,
                isShow: false,
                index: 0,
                last: 0,
                previousName: "上一题",
                nextName: "下一题",
                dataItem: [
                    {
                        id: 1
                    }
                ],
                allanswer: [
                    {
                        totalId: 0,
                        parentId: 0,
                        score: null
                    }
                ],
                evaluationScoreSetDto: {
                    optionA: '',
                    optionB: '',
                    optionC: '',
                    optionD: '',
                    name: '',
                    type: ''
                }
            };
        },
        comments: {
            [Toast.name]: Toast
        },
        activated() {

        },
        created() {
        },
        mounted() {
            this.dimension = this.$route.query.dimension;
            this.reviewId = this.$route.query.reviewId;
            this.userId = this.$route.query.userId;//从组织进入传过来的userId
            this.pcUserId = this.$route.query.pcUserId;//评审人列表传过来的userId
            this.type = this.$route.query.type;
            this.id = this.$route.query.id;
            this.reviewUserId = this.$route.query.reviewUserId;
            this.fromCaizhun = this.$route.query.fromCaizhun;
            if (this.userId == this.pcUserId) {
                document.title = "您现在的状态";
            } else {
                document.title = "对" + this.$route.query.username + "的盘点";
            }
            this.getDataList();
            this.shareContent();
        },
        methods: {
            getDataList() {
                api.getDimensionList({
                    dimension: this.dimension,
                    reviewId: this.reviewId,
                    userId: this.pcUserId,
                    reviewUserId: this.reviewUserId,
                    type: this.type
                }).then(
                    response => {
                        if (response.code == 200) {
                            this.dataItem = response.result.list;
                            this.evaluationScoreSetDto = response.result.evaluationScoreSetDto;
                        }
                    }
                )
            },
            go_start() {
                let that = this;
                that.index--;
                that.last--;
                if (that.index > 0) {
                    that.isShow = true;
                } else {
                    that.isShow = false;
                }
            },
            go_next() {
                let that = this;
                if (!that.allanswer[that.index]||that.allanswer[that.index].score == undefined){
                    Toast('请先做题');
                    return
                }
                that.index++;
                that.last++;
                if (that.index > 0) {
                    that.isShow = true;
                } else {
                    that.isShow = false;
                }
            },
            next(a) {
                if (!this.isConfirm){
                    return
                }
                this.isConfirm=false;
                let that = this;
                var ansobj = {
                    parentId: 0,
                    score: 0
                };
                ansobj.parentId = that.dataItem[that.index].parentId;
                ansobj.totalId = that.dataItem[that.index].id;
                ansobj.score = a;
                that.allanswer[that.index] = ansobj;
                that.$forceUpdate();
                that.previousName = "上一题";
                setTimeout(() =>{
                    this.isConfirm=true;
                    if (that.index == that.dataItem.length - 1) {
                        return;
                    }
                    that.index++;
                    that.last++;
                    if (that.index > 0) {
                        that.isShow = true;
                    } else {
                        that.isShow = false;
                    }

                },300)
            },
            submit() {
                let that = this;
                var alldata = that.allanswer;
                if (alldata.length < this.dataItem.length) {
                    Toast({
                        message: "盘点未完善",
                        position: "middle",
                        duration: 1000
                    });
                    return
                }
                let subobj = {};
                subobj.id = this.id;
                subobj.userId = this.userId;
                subobj.reviewId = this.reviewId;
                subobj.type = this.type;
                subobj.list = JSON.stringify(alldata);
                api._getSubmitItem(subobj).then(
                    response => {
                        if (response.code == 200) {
                            Toast({
                                message: "盘点完成",
                                position: "middle",
                                duration: 1000
                            });
                            setTimeout(() => {
                                if (this.fromCaizhun==1){
                                    localStorage.setItem('caizhun_userId',this.pcUserId)
                                }
                                this.$router.go(-2);//返回上一层
                                that.index = 0;
                                that.last = 0;
                                that.allanswer = [{
                                    totalId: 0,
                                    parentId: 0,
                                    score: 0
                                }];
                            }, 1000);
                        }

                    }
                );
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }


    }
</script>

<style scoped>
    .forcenter {
        margin: 0 auto;
        min-height: 100vh;
        /*background-position: center center;*/
        background: url("../../../static/image/review_bac.png");
        background-size: cover;
        text-align: center;
        overflow: hidden;
    }

    .m_card {
        /*position: fixed;*/
        /*top: 2rem;*/
        /*left: 2rem;*/
        /*right: 2rem;*/
        /*bottom: 2rem;*/
        margin-top: 5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        background: white;
        border-radius: 10px;
        overflow: hidden;
    }

    .m_title {
        background: #13D1BE;
        border-radius: 10px 10px 0px 0px;

    }

    .item_card {
        margin-top: 4rem;
        /*position: absolute;*/
        /*top: 50%;*/
        /*transform: translate(0, -50%);*/
        /*-webkit-transform: translate(0, -50%);*/
        /*-moz-transform: translate(0, -50%);*/
        /*left: 0;*/
        /*right: 0;*/
    }

    .answers-bg {
        display: flex;
        width: 90%;
        background: #ffffff;
        border: 1px solid #f4f4f4;
        border-radius: 10px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
        padding: 5px 10px;
        margin: 13px auto;
        color: #666666;
        font-size: 1.2rem;
        line-height: 2.4rem;
        text-align: left;
    }

    .answers-bg-active {
        display: flex;
        width: 90%;
        background: #f4f4f4;
        border-radius: 10px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
        padding: 5px 10px;
        margin: 13px auto;
        color: #13D1BE;
        font-size: 1.2rem;
        line-height: 2.4rem;
        text-align: left;
        border: 1px solid #f4f4f4;
    }


    .title {
        font-size: 1.6rem;
        color: #fff;
        text-align: center;
        font-weight: 600;
        padding: 10px 0 10px 0;
    }

    .content-title {
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        text-align: center;
        height: 20px;
        padding: 0 10px 5px 0;
    }

    .m_bottom {
        margin-top: 3rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .m_bottom_left {
        font-size: 1.4rem;
        text-align: center;
        height: 3rem;
        margin-left: 2rem;
        line-height: 3rem;
        color: #13D1BE;
    }

    .m_bottom_right {
        height: 3rem;
        line-height: 3rem;
        color: #13D1BE;
        font-size: 1.4rem;
        margin-right: 2rem;
    }

    .m_bottom_center {
        bottom: 10px;
        height: 30px;
        line-height: 30px;
        color: #999999;
        font-size: 1.6rem;
    }

</style>
