<template>
    <div class="main_view">
        <div class="main_item">
            <div class="item">
                <div class="m_left">姓名：</div>
                <div class="m_right_1">{{dataInfo.name}}</div>
            </div>
            <div class="item">
                <div class="m_left">手机号：</div>
                <div class="m_right_1">{{dataInfo.mobile}}</div>
            </div>
            <div class="item">
                <div class="m_left">部门：</div>
                <div class="m_right_1">
                    <div>{{dataInfo.deptName}}</div>
                    <img class="m_right_arrow" src="../../../static/image/arrow_right_img.png"/>
                </div>
            </div>
            <div class="item">
                <div class="m_left">岗位：</div>
                <div class="m_right_1">
                    <div>{{dataInfo.postName}}</div>
                    <img class="m_right_arrow" src="../../../static/image/arrow_right_img.png"/>
                </div>
            </div>
            <div class="item">
                <div class="m_left">简历：</div>
                <div class="m_right_2">{{dataInfo.resumeName}}</div>
            </div>
            <div class="item_2">
                <div class="m_left">人格测评：</div>
                <div class="m_right_3">{{dataInfo.professionalEvaluation}}</div>
            </div>
            <div class="item_2">
                <div class="m_left">面试记录：</div>
                <div class="m_right_2">
                    <div v-for="(item,index) in dataInfo.list" :key="'a'+index">
                        <div style="margin-bottom: 10px" @click="handleSee(item.fileUrl)">{{item.fileName}}</div>
                    </div>

                </div>
            </div>
            <div class="item">
                <div class="m_left">面试评价：</div>
                <div class="m_right_4" @click="showPicker = true" v-if="dataInfo.status==0">未通过</div>
                <div class="m_right_4" @click="showPicker = true" v-if="dataInfo.status==1">通过</div>
                <div class="m_right_4" @click="showPicker = true" v-if="dataInfo.status==3">储备</div>
            </div>
            <van-popup v-model="showPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="columns"
                        value-key="name"
                        @confirm="onConfirm"
                        @cancel="showPicker = false"
                />
            </van-popup>
            <div class="item" v-if="dataInfo.status==1||dataInfo.status==3">
                <div class="m_left">加入人才库：</div>
                <div class="m_right_5">
                    <van-checkbox v-model="dataInfo.isJoinTalentPool" checked-color="#13d1be" shape="square"/>
                </div>

            </div>
        </div>

        <van-button
                class="talent_button"
                type="primary"
                @click="handleSubmit">提交评审
        </van-button>
    </div>
</template>

<script>
    import {Button, Picker, Popup, Toast,Checkbox, CheckboxGroup} from "vant";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import api from "@/apis/talentReview/talentReview.js";
    import storage from "@/common/js/cacheData";

    export default {
        name: "ComprehensiveEvaluationA",
        data() {
            return {
                showPicker: false,
                columns: [
                    {
                        name: '未通过',
                        id: 0
                    },
                    {
                        name: '通过',
                        id: 1
                    },
                    {
                        name: '储备',
                        id: 3
                    }
                ],
                dataInfo: {
                    list: []
                }
            }
        },
        components: {
            [Toast.name]: Toast,
            [Button.name]: Button,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup
        },
        created() {
            document.title = '信息填写';
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            this.getData();
            this.shareContent();
        },
        methods: {
            getData: function () {
                api.generalReviewOfJob().then(
                    response => {
                        if (response.code == 200) {
                            this.dataInfo = response.result;

                        }
                    }
                )
            },
            handleSee(url) {
                window.open(url);
            },
            handleSubmit() {
                api.updateHuntingEvaluationRecord({
                    id: this.dataInfo.id,
                    status: this.dataInfo.status,
                    isJoinTalentPool: this.dataInfo.isJoinTalentPool
                }).then(
                    response => {
                        if (response.code == 200) {
                            // this.dataInfo = response.result;
                            Toast({
                                message: response.msg,
                                position: "middle",
                                duration: 1000
                            });
                        }
                    }
                )
            },
            onConfirm(value) {
                this.dataInfo.status = value.id;
                this.showPicker = false;
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .main_view {
        overflow-y: auto;
        background-color: #f4f4f4;
        min-height: 100vh;
    }

    .main_item {
        margin: 0 10px 50px 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        background-color: white;
    }

    .item {
        display: flex;
        align-items: center;
        padding: 10px 15px;
    }

    .item_2 {
        display: flex;
        padding: 10px 15px;
    }

    .m_left {
        flex: 1;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        text-align: right;
        margin-right: 10px;
    }

    .m_right_1 {
        display: flex;
        flex: 3;
        height: 36px;
        background: #f4f4f4;
        border: 1px solid #dadada;
        border-radius: 5px;
        font-size: 14px;
        color: #333333;
        padding: 0px 10px;
        justify-content: space-between;
        align-items: center;
    }

    .m_right_arrow {
        height: 15px;
    }

    .m_right_2 {
        flex: 3;
        font-size: 14px;
        color: #13d1be;
        padding: 0px 10px
    }

    .m_right_3 {
        flex: 3;
        font-size: 14px;
        color: #333333;
        padding: 0px 10px
    }

    .m_right_4 {
        flex: 3;
        border: 1px solid #dadada;
        border-radius: 5px;
        font-size: 14px;
        color: #333333;
        height: 36px;
        line-height: 36px;
        padding: 0px 10px;
    }
    .m_right_5 {
        flex: 3;
    }

    .talent_button {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #13d1be;
        border: 0px;
    }
</style>
