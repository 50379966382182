<template>
    <div>
        <div class="topTitle">招聘岗位({{ totalCount }})</div>
        <div class="searchCover">
            <van-field
                    class="searchCover_left"
                    v-model="searchName"
                    placeholder="请输入职位名称"
            />
            <div class="searchCover_btn" @click="goSearch()">搜索职位</div>
        </div>
        <div class="choseCityCover">
            <van-field
                    class="choseCityField"
                    v-model="cityName"
                    is-link
                    readonly
                    placeholder="请选择所在地区"
                    @click="showCityPopup = true"
            />
        </div>
        <van-list
                :immediate-check="false"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                v-if="dataList.length"
        >
            <div v-for="item in dataList" :key="item.id">
                <div class="listItem" @click="goPostDetail(item)">
                    <div class="listItem_top">
                        <div class="listItem_post">{{ item.postName }}</div>
                        <div v-if="item.salaryContent" class="listItem_salary">
                            {{ item.typeName }}：{{ item.salaryContent }}
                        </div>
                    </div>
                    <div class="listItem_company">{{ item.cloudName }}</div>
                    <div class="listItem_bottom">
                        <div class="listItem_address">
                            工作地点：{{ item.workingAddress }}
                        </div>
                        <div class="listItem_btn" v-if="item.isEvaluation==1" @click.stop="goChoseTest(item)">
                            去测评
                        </div>
                    </div>
                </div>
            </div>
        </van-list>
        <no-result :title="'暂无数据'" v-if="!dataList.length"></no-result>
        <van-popup v-model="showCityPopup" round position="bottom">
            <van-cascader
                    v-model="choseCityId"
                    title="请选择所在地区"
                    :options="cityData"
                    @close="cancelChoseCity"
                    @finish="choseCity"
                    active-color="#13D1BE"
                    :field-names="{ text: 'label', children: 'child' }"
            />
        </van-popup>
        <van-popup v-model="showTestPopup">
            <div class="testCover">
                <div class="testCover_topTitle">去测评</div>
                <div v-if="currentItem.evaluationResult != 2">
                    <div class="testCover_line"></div>
                    <div class="testCover_item">
                        <div class="testCover_title">人格与岗位匹配</div>
                        <div
                                class="testCover_btn"
                                :class="{ testCover_btn_gray: currentItem.evaluationResult == 1 }"
                                @click="goTest(1)"
                        >
                            去测评
                        </div>
                    </div>
                </div>

                <div v-if="currentItem.eqResult != 2">
                    <div class="testCover_line"></div>
                    <div class="testCover_item">
                        <div class="testCover_title">情商测评</div>
                        <div
                                class="testCover_btn"
                                :class="{ testCover_btn_gray: currentItem.eqResult == 1 }"
                                @click="goTest(2)"
                        >
                            去测评
                        </div>
                    </div>
                    <div class="testCover_cancelBtn" @click="showTestPopup = false">
                        取消
                    </div>
                </div>

            </div>
        </van-popup>
    </div>
</template>
<script>
    import {List, Field, Cascader, Popup} from "vant";
    import NoResult from "../components/commonly/noResult.vue";
    import api from "@/apis/recruit/recruit.js";
    import storage from "@/common/js/cacheData";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";

    export default {
        name: "RecruitList",
        data() {
            return {
                searchName: "",
                cityName: "",
                choseCityId: "",
                showCityPopup: false,
                showTestPopup: false,
                currentItem: {},
                cityData: [],
                dataList: [],
                currPage: 1,
                pageSize: 10,
                totalCount: 1,
                loading: false,
                finished: false
            };
        },
        components: {
            [List.name]: List,
            [Field.name]: Field,
            [Cascader.name]: Cascader,
            [Popup.name]: Popup,
            NoResult
        },
        created() {
            let that = this;
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            if (that.$route.query.searchName) {
                that.searchName = that.$route.query.searchName;
            }
            that.getCity();
            that.getList();
        },
        mounted() {
            this.shareContent();
        },
        methods: {
            //城市数据
            getCity() {
                let that = this;
                api.selectCityList().then((response) => {
                    if (response.code == 200) {
                        that.cityData = response.result;
                    }
                });
            },
            //职位搜索
            goSearch() {
                let that = this;
                that.currPage = 1;
                that.getList();
            },
            //选择城市
            choseCity({selectedOptions}) {
                let that = this;
                let item = selectedOptions[selectedOptions.length - 1];
                that.cityName = item.label;
                that.showCityPopup = false;
                that.currPage = 1;
                that.getList();
            },
            //取消选择城市
            cancelChoseCity() {
                let that = this;
                that.showCityPopup = false;
                that.choseCityId = "";
                that.cityName = "";
                that.currPage = 1;
                that.getList();
            },
            //职位详情
            goPostDetail(item) {
                let that = this;
                that.$router.push({
                    path: "/PositionDetail",
                    query: {
                        totalId: item.totalId
                    }
                });
            },
            onLoad() {
                let that = this;
                if (that.dataList.length < that.totalCount) {
                    that.currPage += 1;
                    that.getList();
                } else {
                    that.finished = true;
                }
            },
            //列表数据
            getList() {
                let that = this;
                that.loading = true;
                let param = {};
                param.page = that.currPage;
                param.limit = that.pageSize;
                if (that.searchName && that.searchName != "") {
                    param.searchName = that.searchName;
                }
                if (that.cityName && that.cityName != "") {
                    param.cityName = that.cityName;
                }
                api.positionList(param).then((response) => {
                    that.loading = false;
                    if (response.code == 200) {
                        if (that.currPage == 1) {
                            that.dataList = response.result.list;
                        } else {
                            that.dataList = that.dataList.concat(response.result.list);
                        }
                        that.totalCount = response.result.totalCount;
                        that.pageSize = response.result.pageSize;
                        that.currPage = response.result.currPage;
                    }
                });
            },
            //选择测评类型
            goChoseTest(item) {
                let that = this;
                that.showTestPopup = true;
                that.currentItem = item;
            },
            //去测评
            goTest(type) {
                let that = this;
                if (type == 1 && this.currentItem.evaluationResult != 1) {
                    that.$router.push({
                        path: "/PersonalityTestKnow",
                        query: {
                            isFromList: 1
                        }
                    });
                }
                if (type == 2 && this.currentItem.eqResult != 1) {
                    that.$router.push({
                        path: "/EmotionalTestKnow",
                        query: {
                            isFromList: 1
                        }
                    });
                }
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    };
</script>
<style scoped>
    .topTitle {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        color: #333333;
        margin: 15px;
    }

    .searchCover {
        display: flex;
        justify-content: space-between;
        margin: 15px;
    }

    .searchCover_left {
        flex: 1;
        background: #ffffff;
        height: 45px;
        border: 1px solid #dadada;
        border-radius: 5px 0px 0px 5px;
    }

    .searchCover_btn {
        width: 85px;
        height: 45px;
        background: #13d1be;
        border-radius: 0px 5px 5px 0px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 45px;
    }

    .choseCityCover {
        height: 45px;
        background: #ffffff;
        margin: 0 15px;
    }

    .choseCityField {
        border: 1px solid #dadada;
        border-radius: 5px;
    }

    .listItem {
        background: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 15px;
    }

    .listItem_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .listItem_post {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        color: #13d1be;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .listItem_salary {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: right;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .listItem_company {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        margin: 15px 0;
    }

    .listItem_bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .listItem_address {
        flex: 1;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
    }

    .listItem_btn {
        width: 85px;
        height: 25px;
        background: #13d1be;
        border-radius: 5px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 25px;
        margin-left: 5px;
    }

    .testCover {
        width: 300px;
        height: 300px;
        background: #ffffff;
        border-radius: 5px;
        position: relative;
    }

    .testCover_topTitle {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: center;
        color: #333333;
        padding: 15px;
    }

    .testCover_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
    }

    .testCover_title {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
    }

    .testCover_btn {
        width: 85px;
        height: 25px;
        background: #13d1be;
        border-radius: 5px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 25px;
    }

    .testCover_btn_gray {
        background: #999999;
    }

    .testCover_line {
        height: 1px;
        background: #dadada;
        margin: 0 15px;
    }

    .testCover_cancelBtn {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #999999;
        line-height: 50px;
        border-top: 1px solid #f4f4f4;
    }
</style>

