<template>
  <div class="stepbox">
    <div class="stepcontainer">
      <div class="stepitem pointer">
        <div class="stepnum act">1</div>
        <div class="steptitle">创建标准答案</div>
      </div>
      <div class="linebox">
        <!-- <div class="line"></div> -->
      </div>
      <div class="stepitem pointer">
        <div class="stepnum" v-if="curstep==1">2</div>
        <div class="stepnum act" v-else>2</div>
        <div class="steptitle">维度设置</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  props: {
    curstep: {
      type: [String, Number],
      default: 1
    }
  },
  created() {
  },
  methods: {
  }
};
</script>
<style lang="less" scoped>
.stepbox {
  width: 100%;
  padding: 3rem 20px;
  position: relative;
  .stepcontainer {
    width: 50%;
    margin: 0 auto;
    display: flex;
    align-content: center;
    justify-content: space-between;
    .stepitem {
      position: relative;
      // width: 2rem;
      text-align: center;
      .stepnum {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        border: 1px solid #ccc;
        border-radius: 50%;
        margin: 0 auto;
        background: #fff;
        font-size: 1.4rem;
        position: relative;
        &.act {
          background: #13D1BE;
          color: #fff;
          border:none;
        }
      }
      .steptitle {
        position: absolute;
        left: 50%;
        transform: translate(-50%,0);
        width: 10rem;
        margin-top: 1rem;
        font-size: 1.4rem;
        // line-height: 0.6rem;

        color: #333;
        &.act {
          color: #00c292;
        }
      }
    }
    .linebox {
      flex: 1;
      height: .2rem;
      background: #dadada;
      align-self: center;
    }
  }
  
  
}
</style>