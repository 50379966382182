<template>
  <div class="cover">
    <div v-for="(item, index) in dataList" :key="index">
      <div class="item">
        <div class="info">
          <div class="info_left" v-if="item.username.length < 2">
            {{ item.username.substring(item.username.length - 1) }}
          </div>
          <div class="info_left" v-else>
            {{ item.username.substring(item.username.length - 2) }}
          </div>
          <div class="info_right">
            <div class="name">{{ item.username }}-{{ item.postName }}</div>
            <div class="info_bottom">
              <div class="timeCover">
                <div class="time">
                  测评时间:{{ item.createTime ? item.createTime : "--" }}
                </div>
              </div>
              <div
                class="testBtn"
                :class="{ completeTest: item.list.length != 0 }"
                @click="goTest(item)"
              >
                {{ item.score != 0 ? "重新测评" : "去测评" }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="gradeTabCover" v-if="item.score != 0">
            <div v-for="(item, index) in item.list" :key="index">
              <div class="gradeTab">
                <div class="gradeTapView">
                  <div class="gradeTapTop">技能 <br />评价</div>
                </div>
                <div class="gradeTapView">
                  <div class="gradeTapTop">{{ item[0].title }}</div>
                </div>
                <div class="gradeTapView">
                  <div class="gradeTapTop">{{ item[1].title }}</div>
                </div>
                <div class="gradeTapView">
                  <div class="gradeTapTop">{{ item[2].title }}</div>
                </div>
                <div class="gradeTapView">
                  <div class="gradeTapTop">{{ item[3].title }}</div>
                </div>
              </div>
              <div class="gradeTab">
                <div class="gradeTabBottom">评分</div>
                <div class="gradeTabBottom">
                  {{ item[0].score ? item[0].score + "分" : "" }}
                </div>
                <div class="gradeTabBottom">
                  {{ item[1].score ? item[1].score + "分" : "" }}
                </div>
                <div class="gradeTabBottom">
                  {{ item[2].score ? item[2].score + "分" : "" }}
                </div>
                <div class="gradeTabBottom">
                  {{ item[3].score ? item[3].score + "分" : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom" v-if="item.score != 0 && item.level">
          <div class="evaluateBtn">
            评定等级<span>: </span
            ><span
              class="grade"
              :class="{
                color1: item.level == '资深' || item.level == '高级',
                color2: item.level == '中级',
                color3: item.level == '初级',
              }"
              v-if="item.level"
              >{{ item.level + "-" + item.option }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <no-result :title="'暂无数据'" v-if="!dataList.length"></no-result>
  </div>
</template>
<script>
import { ShareLink, ShareLogo } from "@/apis/const.js";
import { startShart } from "@/common/js/tecentshare.js";
import api from "@/apis/talentReview/talentReview.js";
import NoResult from "../../components/commonly/noResult.vue";
import storage from "@/common/js/cacheData";
import {
  Toast,
  Field,
  Button,
  Popup,
  Form,
  DatetimePicker,
  CellGroup,
  List,
  Dialog
} from "vant";

export default {
  name: "DuoResult",
  components: {
    [List.name]: List,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Form.name]: Form,
    [Field.name]: Field,
    [DatetimePicker.name]: DatetimePicker,
    [CellGroup.name]: CellGroup,
    NoResult,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      dataList: []
    };
  },
  created() {
    document.title = "专业水平评价";
    let openId = this.$route.query.openId;
    if (openId) {
      storage.saveData("openId", openId);
    }
    this.getDataList();
  },
  mounted() {
    this.shareContent();
  },
  methods: {
    getDataList() {
      api.mySkillEvaluationScore({
        type: 1 //区分个人和发起人
      }).then((response) => {
        if (response.code == 200) {
          this.dataList = [];
          var dataArray = response.result.list;
          for (var i = 0; i < dataArray.length; i++) {
            var item = dataArray[i];
            item.list = this.changeData(item.list);
            this.dataList.push(item);
          }
        }
      });
    },
    //重新组装数据
    changeData(list) {
      var scoreArray = [];
      var hange = parseInt(list.length / 4);
      var yu = list.length % 4;
      if (yu != 0) {
        hange++;
      }
      for (var i = 0; i < hange; i++) {
        let arrray = [];
        for (var k = 0; k < 4; k++) {
          let num = k + i * 4;
          if (num < list.length) {
            arrray.push(list[num]);
          } else {
            let emptyItem = { title: "" };
            arrray.push(emptyItem);
          }
        }
        scoreArray.push(arrray);
      }
      return scoreArray;
    },
    //去测评
    goTest(item) {
      this.$router.push({
        path: "/SkillEvaluationNotice",
        query: {
          skillId: item.skillId,
          assessUserId: item.assessUserId,
          userId: item.userId,
          officeId: item.officeId
        }
      });
    },
    //分享
    shareContent() {
      const titleStr = "HRCOE";
      const contentStr = "让人力资源工作更简单!";
      const linkStr = ShareLink + "type=1";
      const imgStr = ShareLogo;
      window.shareData = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      window.shareIMdata = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      startShart();
    }
  }
};
</script>

<style scoped>
.cover {
  margin-top: 1.5rem;
}
.item {
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0rem 0.4rem 1rem 0rem rgba(0, 0, 0, 0.2);
  margin: 1rem;
  padding: 1rem;
}
.info {
  display: flex;
  height: 5rem;
}
.info_left {
  width: 5rem;
  height: 100%;
  background: linear-gradient(180deg, #6c97ff, #3f72ec);
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info_right {
  width: 100%;
  height: 100%;
  margin-left: 1rem;
  flex: 1;
  position: relative;
  overflow: hidden;
}
.name {
  opacity: 1;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  height: 1.4rem;
  line-height: 1.4rem;
}
.info_bottom {
  position: absolute;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
  height: 2.4rem;
  display: flex;
  justify-content: space-between;
}
.timeCover {
  position: relative;
  overflow: hidden;
  margin-right: 0.5rem;
  height: 2.4rem;
  width: 100%;
}
.time {
  position: absolute;
  font-size: 1.2rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
}
.testBtn {
  background: #13d1be;
  border-radius: 1.2rem;
  font-size: 1.2rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #ffffff;
  min-width: 8rem;
  height: 2.4rem;
  line-height: 2.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.completeTest {
}
.bottom {
  height: 4rem;
  position: relative;
}
.evaluateBtn {
  font-size: 1.6rem;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: right;
  color: #13d1be;
  position: absolute;
  right: 0rem;
  top: 0rem;
  height: 100%;
  line-height: 4rem;
  width: 13rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.grade {
  font-size: 1.6rem;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #13d1be;
}
.color1 {
  color: #13d1be;
}
.color2 {
  color: #e6b800;
}
.color3 {
  color: #fd4e23;
}
.gradeItem {
  margin-top: 2rem;
  height: 3rem;
}
.selectItem {
  background: #13d1be;
  color: #ffffff;
}
.gradeTabCover {
  margin: 2rem 0rem 1rem 0rem;
}
.gradeTab {
  display: flex;
  align-items: center;
}
.gradeTapView {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  flex: 1;
  border: 0.1rem solid #71d2c8;
  margin-left: -0.1rem;
  margin-top: -0.1rem;
  margin-bottom: -0.1rem;
  text-align: center;
  font-size: 1.1rem;
  background: #f0fcfa;
  text-align: center;
}
.gradeTapTop {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
}
.gradeTabBottom {
  height: 3rem;
  flex: 1;
  border: 0.1rem solid #71d2c8;
  margin-left: -0.1rem;
  text-align: center;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #ffffff;
  line-height: 3rem;
}
.add_btn {
  position: fixed;
  bottom: 0rem;
  width: 100%;
  left: 0rem;
  background-color: #13d1be;
  border: 0rem;
}

.m_field {
  width: 30rem;
  margin: 2rem auto;
  border: 0.1rem solid #f4f4f4;
}
</style>