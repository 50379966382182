<template>
    <div></div>
</template>

<script>
    import api from "@/apis/talentReview/talentReview.js";
    import storage from "@/common/js/cacheData";

    export default {
        name: "StaffJudge",
        data() {
            return {
                type: ''
            }
        },
        created() {
            let openId = this.$route.query.openId;
            //type   //多准10 菁准11 彩准12 管理技能评估18 干部述职23
            this.type = this.$route.query.type;

            if (openId) {
                storage.saveData("openId", openId);
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                api.review_checkOfficeUserId({
                    type: this.type
                }).then(
                    response => {
                        if (response.code == 200) {
                            localStorage.setItem('m_officeId', response.result.officeId);
                            localStorage.setItem('m_userId', response.result.userId);
                            localStorage.setItem('m_pcUserId', response.result.evaluationUserId);
                            localStorage.setItem('m_cloudName', response.result.cloudName);
                            localStorage.setItem('m_reviewId', response.result.reviewId);
                            if (this.type==23){
                                this.$router.push({
                                    path: '/CadreReportList'
                                })

                            }else if(this.type==24){
                                this.$router.push({
                                    path: '/ComprehensiveEvaluationA'
                                })

                            }else{
                                this.$router.push({
                                    path: '/TalentReviewList',
                                    query: {
                                        threeType: this.type
                                    }
                                })
                            }

                        }
                    }
                );
            }
        }
    }
</script>

<style scoped>

</style>
