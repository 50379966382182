<template>
    <div class="main_view">
        <div class="main_item">
            <div class="title">企业简称:</div>
            <van-field maxlength="20" v-model="createReq.cloudName" placeholder="请输入企业简称"/>
            <div class="title">企业全称:</div>
            <van-field v-model="createReq.cloudFullName" placeholder="请输入企业全称"/>
            <div class="title">管理员姓名:</div>
            <van-field maxlength="6" v-model="createReq.name" placeholder="请输入管理员姓名"/>
            <div class="title">行业:</div>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="createReq.industry"
                    placeholder="请选择行业"
                    @click="showIndustryPicker = true"/>
            <van-popup v-model="showIndustryPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="industryList"
                        :columns-num="2"
                        value-key="name"
                        @confirm="onConfirmIndustry"
                        @cancel="showIndustryPicker = false"
                />
            </van-popup>
            <div class="title">城市:</div>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="createReq.city"
                    placeholder="请选择城市"
                    @click="showCityPicker = true"
            />
            <van-popup v-model="showCityPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="cityList"
                        :columns-num="2"
                        value-key="name"
                        @confirm="onConfirmCity"
                        @cancel="showCityPicker = false"
                />
            </van-popup>

            <div class="title">规模:</div>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="createReq.scale"
                    placeholder="请选择规模"
                    @click="showScalePicker = true"/>
            <van-popup v-model="showScalePicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="scaleList"
                        value-key="name"
                        @confirm="onConfirmScale"
                        @cancel="showScalePicker = false"
                />
            </van-popup>

            <div class="title">性质:</div>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="createReq.property"
                    placeholder="请选择性质"
                    @click="showPropertyPicker = true"/>
            <van-popup v-model="showPropertyPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="propertyList"
                        value-key="name"
                        @confirm="onConfirmProperty"
                        @cancel="showPropertyPicker = false"
                />
            </van-popup>

            <div v-if="isPhone=='0'" class="title">手机号:</div>
            <van-field
                    v-if="isPhone=='0'"
                    v-model="createReq.mobile"
                    type="tel"
                    placeholder="请输入手机号"
            />


            <div v-if="isPhone=='0'" class="title">验证码:</div>
            <van-field
                    v-if="isPhone=='0'"
                    v-model="createReq.mobileCode"
                    center
                    clearable
                    placeholder="请输入短信验证码"
            >
                <template #button>
                    <van-button
                            size="small"
                            type="primary"
                            :class="isActive?'btnActive':'btnNotActive'"
                            @click="sendSmsCode">{{message}}
                    </van-button>
                </template>
            </van-field>


        </div>
        <van-button
                class="talent_button"
                type="primary"
                @click="handleSubmit">提交信息
        </van-button>
    </div>

</template>

<script>
    import {
        Area,
        Button,
        CellGroup,
        Field,
        Form,
        Picker,
        Popup,
        Toast,
        Checkbox,
        RadioGroup,
        Radio,
        CheckboxGroup
    } from "vant";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import api from "@/apis/talentReview/talentReview.js";
    import storage from "@/common/js/cacheData";

    export default {
        name: "CreateOrg",
        data() {
            return {
                id: null,
                //行业picker
                showIndustryPicker: false,
                //性质picker
                showPropertyPicker: false,
                //城市
                showCityPicker: false,
                //规模
                showScalePicker: false,
                userInfoData: {},
                columns: [],
                unionId: null,
                createReq: {
                    cloudName: "",
                    cloudFullName: "",
                    name: "",
                    city: '',
                    cityId: '',
                    provinceId: '',
                    industry: '',
                    industryOneId: '',
                    industryTwoId: '',
                    scaleId: "",
                    scale: "",
                    propertyId: "",
                    property: "",
                    mobileCode: '',
                    mobile: ''
                },
                cityList: [],
                scaleList: [],
                industryList: [],
                propertyList: [],
                isPhone: null,
                message: '获取验证码',
                isActive: true,
                openId: null,
                canClick: true //添加canClick
            }
        },
        components: {
            [Toast.name]: Toast,
            [CellGroup.name]: CellGroup,
            [Button.name]: Button,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Area.name]: Area,
            [Form.name]: Form,
            [Checkbox.name]: Checkbox,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
            [Field.name]: Field,
            [CheckboxGroup.name]: CheckboxGroup
        },
        mounted() {
            document.title = '创建组织';
            this.openId = this.$route.query.openId;
            this.isPhone = this.$route.query.isPhone;

            if (this.openId) {
                storage.saveData("openId", this.openId);
            }
            this.getDataList();
            this.shareContent();
        },
        methods: {

            sendSmsCode() {
                let reg = /(1[0-9]{10}$)/;
                if (!reg.test(this.createReq.mobile)) {
                    Toast({
                        message: "请输入正确手机号",
                        position: "middle",
                        duration: 1000
                    });
                    return
                }
                if (!this.canClick) return; //改动的是这两行代码
                this.canClick = false;
                api.getIdentifyCode(this.createReq.mobile, 1).then(
                    response => {
                        if (response.code == 200) {
                            this.isActive = false;
                            var num = 60;
                            var that = this;
                            var timer = setInterval(function () {
                                num--;
                                that.message = num + "s重新获取";
                                if (num === 0) {
                                    that.isActive = true;
                                    that.message = "获取验证码";
                                    that.canClick = true; //这里重新开启
                                    clearInterval(timer);
                                }
                            }, 1000);
                        }
                    },
                    res => {
                        this.canClick = true;
                    }
                );
            },
            getDataList() {
                api.basicData_officeBasicData().then(
                    response => {
                        if (response.code == 200) {
                            this.scaleList = response.result.scaleList;
                            this.propertyList = response.result.propertyList;
                            this.cityList = response.result.cityList;

                            this.cityList.forEach(item => {
                                if (item.children.length === 0) {
                                    delete item.children
                                } else {
                                    item.children.forEach(it => {
                                        if (it.children.length === 0) {
                                            delete it.children
                                        }
                                    })
                                }
                            });
                            this.industryList = response.result.industryList;
                            this.industryList.forEach(item => {
                                if (item.children.length === 0) {
                                    delete item.children
                                } else {
                                    item.children.forEach(it => {
                                        if (it.children.length === 0) {
                                            delete it.children
                                        }
                                    })
                                }
                            });


                        }
                    }
                )
            },
            onConfirmCity(value,index) {
                if (index){
                    this.createReq.city = value[0]+'/'+value[1];
                    this.createReq.provinceId=this.cityList[index[0]].id;
                    this.createReq.cityId=this.cityList[index[0]].children[index[1]].id;
                    this.showCityPicker = false;
                }
            },
            onConfirmIndustry(value,index) {

                if (index){
                    this.createReq.industry = value[0]+'/'+value[1];
                    this.createReq.industryOneId=this.industryList[index[0]].id;
                    this.createReq.industryTwoId=this.industryList[index[0]].children[index[1]].id;
                    this.showIndustryPicker = false;
                }
            },
            onConfirmScale(value) {
                this.createReq.scaleId = value.id;
                this.createReq.scale = value.name;
                this.showScalePicker = false;
            },
            onConfirmProperty(value) {
                this.createReq.propertyId = value.id;
                this.createReq.property = value.name;
                this.showPropertyPicker = false;
            },

            handleSubmit() {
                if (!this.createReq.cloudName) {
                    Toast('请输入企业简称');
                    return
                }
                if (!this.createReq.cloudFullName) {
                    Toast('请输入企业全称');
                    return
                }
                if (!this.createReq.name) {
                    Toast('请输入管理员姓名');
                    return
                }
                if (!this.createReq.industryTwoId) {
                    Toast('请选择行业');
                    return
                }
                if (!this.createReq.cityId) {
                    Toast('请选择城市');
                    return
                }
                if (!this.createReq.scaleId) {
                    Toast('请选择规模');
                    return
                }
                if (!this.createReq.propertyId) {
                    Toast('请选择性质');
                    return
                }
                if (this.isPhone=='0') {
                    if (!this.createReq.mobile){
                        Toast('请输入手机号');
                        return;
                    }
                    if (!this.createReq.mobileCode|| this.createReq.mobileCode == ''){
                        Toast('请输入验证码');
                        return;
                    }
                }
                let reqData = {
                    cloudName: this.createReq.cloudName,
                    cloudFullName: this.createReq.cloudFullName,
                    name: this.createReq.name,
                    cityId: this.createReq.cityId,//城市id
                    provinceId: this.createReq.provinceId,//省份id
                    industryOne: this.createReq.industryOneId,//一级行业id
                    industryTwo: this.createReq.industryTwoId,//二级行业id
                    // industryThree: this.createReq.industry[2],//三级行业id
                    scaleId: this.createReq.scaleId,//二级行业id
                    propertyId: this.createReq.propertyId,//二级行业id
                    mobile: this.createReq.mobile,//二级行业id
                    mobileCode: this.createReq.mobileCode//二级行业id
                };
                api.performance_addOffice(reqData).then(
                    response => {
                        if (response.code == 200) {
                            Toast('提交成功');
                            if (this.openId){
                                this.$router.push({
                                    path: '/SelectOrganization'
                                })
                            }else {
                                this.$router.go(-1);
                            }

                        }
                    }
                )
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .main_view {
        background-color: #f4f4f4;
        height: 100vh;
        overflow-y: auto;
    }

    .img-icon {
        height: 20px;
    }

    .main_item {
        margin: 0px 10px 50px 10px;
        border-radius: 5px;
        padding-bottom: 30px;
        background-color: white;
    }

    .title {
        color: #333333;
        padding: 10px 16px 0 16px;
        font-size: 14px;
        font-weight: bold;
    }

    .item_checkbox {
        margin: 15px 16px;
        font-size: 14px;
        color: #333333;
    }

    .talent_button {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #13d1be;
        border: 0px;
    }

    .btnActive {
        background-color: #13d1be;
        border: 0px;
    }

    .btnNotActive {
        background-color: #b2b2b2;
        border: 0px;
    }
</style>
