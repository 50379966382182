<template>
  <div class="cover">
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="(item, index) in dataList" :key="index">
        <div class="item">
          <div class="info">
            <div class="info_left" v-if="item.username.length < 2">
              {{ item.username.substring(item.username.length - 1) }}
            </div>
            <div class="info_left" v-else>
              {{ item.username.substring(item.username.length - 2) }}
            </div>
            <div class="info_center">
              <div class="name">{{ item.username }}</div>
              <div class="title">{{ item.evaluationName }}</div>
              <div class="time">
                测评时间:{{ item.createTime ? item.createTime : "--" }}
              </div>
            </div>
            <div class="info_right">
              <div class="phone">{{ item.mobile }}</div>
              <div
                class="testBtn"
                :class="{ completeTest: item.list.length != 0 }"
                @click="goTest(item)"
              >
                {{ item.score != 0 ? "重新测评" : "去测评" }}
              </div>
            </div>
          </div>
          <div>
            <div class="gradeTabCover" v-if="item.score != 0">
              <div v-for="(item, index) in item.list" :key="index">
                <div class="gradeTab">
                  <div class="gradeTapView">
                    <div class="gradeTapTop">技能 <br />评价</div>
                  </div>
                  <div class="gradeTapView">
                    <div class="gradeTapTop">{{ item[0].title }}</div>
                  </div>
                  <div class="gradeTapView">
                    <div class="gradeTapTop">{{ item[1].title }}</div>
                  </div>
                  <div class="gradeTapView">
                    <div class="gradeTapTop">{{ item[2].title }}</div>
                  </div>
                  <div class="gradeTapView">
                    <div class="gradeTapTop">{{ item[3].title }}</div>
                  </div>
                </div>
                <div class="gradeTab">
                  <div class="gradeTabBottom">评分</div>
                  <div class="gradeTabBottom">
                    {{ item[0].score ? item[0].score + "分" : "" }}
                  </div>
                  <div class="gradeTabBottom">
                    {{ item[1].score ? item[1].score + "分" : "" }}
                  </div>
                  <div class="gradeTabBottom">
                    {{ item[2].score ? item[2].score + "分" : "" }}
                  </div>
                  <div class="gradeTabBottom">
                    {{ item[3].score ? item[3].score + "分" : "" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom" v-if="item.score != 0">
            <div class="evaluateBtn" @click="goEvaluate(index)">
              评定等级<span v-if="item.level">: </span
              ><span
                class="grade"
                :class="{
                  color1: item.level == '资深' || item.level == '高级',
                  color2: item.level == '中级',
                  color3: item.level == '初级',
                }"
                v-if="item.level"
                >{{ item.level + "-" + item.option }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <div class="grayCover" v-if="isShowEvaluate == true"></div>
    <div class="evaluateCover" v-if="isShowEvaluate == true">
      <div class="btnCover">
        <div class="cancel" @click="cancelAction">取消</div>
        <div class="sure" @click="sureAction">确定</div>
      </div>
      <div class="line"></div>
      <div class="tip">评定级别：</div>
      <div v-for="(item, index) in levelArray" :key="index">
        <div class="gradeItem">
          <div class="scoreItemFirst">{{ item }}({{ index + 2 + "级" }})</div>
          <div v-for="(item1, index1) in gradeArray" :key="index1">
            <div
              class="scoreItem"
              :class="{
                selectItem: item == selectLevel && item1 == selectOption,
              }"
              @click="choseGrade(item, item1)"
            >
              {{ item1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <no-result :title="'暂无数据'" v-if="!dataList.length"></no-result>
  </div>
</template>
<script>
import { ShareLink, ShareLogo } from "@/apis/const.js";
import { startShart } from "@/common/js/tecentshare.js";
import api from "@/apis/talentReview/talentReview.js";
import NoResult from "../components/commonly/noResult.vue";
import {
  Toast,
  Field,
  Button,
  Popup,
  Form,
  DatetimePicker,
  CellGroup,
  List
} from "vant";

export default {
  name: "SkillEvaluationList",
  components: {
    [List.name]: List,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Form.name]: Form,
    [Field.name]: Field,
    [DatetimePicker.name]: DatetimePicker,
    [CellGroup.name]: CellGroup,
    NoResult
  },
  data() {
    return {
      dataList: [],
      levelArray: ["中级", "高级", "资深","权威"],
      gradeArray: ["A", "B", "C","不达标"],
      isShowEvaluate: false,
      selectOption: "",
      selectLevel: "",
      selectIndex: 0,
      currPage: 1,
      pageSize: 10,
      totalCount: 1,
      loading: false,
      finished: false,
      userId: 0,
      officeId: 0,
      pcUserId: 0,
      reviewId: ''
    };
  },
  created() {
    // document.title = "多准评价";
    this.officeId = localStorage.getItem("m_officeId");
    this.userId = localStorage.getItem("m_userId");
    this.pcUserId = localStorage.getItem("m_pcUserId");
    this.reviewId = localStorage.getItem('m_reviewId');
    this.getEvaluationList();
  },
  mounted() {
    this.shareContent();
  },
  methods: {
    //重新组装数据
    changeData(list) {
      var scoreArray = [];
      var hange = parseInt(list.length / 4);
      var yu = list.length % 4;
      if (yu != 0) {
        hange++;
      }
      for (var i = 0; i < hange; i++) {
        let arrray = [];
        for (var k = 0; k < 4; k++) {
          let num = k + i * 4;
          if (num < list.length) {
            arrray.push(list[num]);
          } else {
            let emptyItem = { title: "" };
            arrray.push(emptyItem);
          }
        }
        scoreArray.push(arrray);
      }
      return scoreArray;
    },
    //列表
    getEvaluationList() {
      let reqData={
        pcUserId: this.pcUserId,
        officeId: this.officeId,
        userId: this.userId,
        page: this.currPage,
        limit: this.pageSize
      };
      if (this.reviewId) {
        reqData.reviewId = this.reviewId
      }
      api
        .skillEvaluationList(reqData)
        .then((response) => {
          this.loading = false;
          if (response.code == 200) {
            if (this.currPage == 1) {
              this.dataList = [];
            }
            var dataArray = response.result.list.list;
            for (var i = 0; i < dataArray.length; i++) {
              var item = dataArray[i];
              item.list = this.changeData(item.list);
              this.dataList.push(item);
            }
            this.totalCount = response.result.list.totalCount;
          }
        });
    },
    onLoad() {
      if (this.dataList.length < this.totalCount) {
        this.currPage += 1;
        this.getEvaluationList();
      } else {
        this.finished = true;
      }
    },
    //清空数据
    changeEmptyData() {
      this.selectLevel = "";
      this.selectOption = "";
      this.selectIndex = 0;
      this.isShowEvaluate = false;
    },
    //选分
    choseGrade(level, option) {
      this.selectLevel = level;
      this.selectOption = option;
    },
    //取消
    cancelAction() {
      this.changeEmptyData();
    },
    //确定
    sureAction() {
      if (this.selectLevel == "" || this.selectOption == "") {
        Toast("请选择评分");
        return;
      } else {
        let param = {};
        let selectItem = this.dataList[this.selectIndex];
        param.skillId = selectItem.skillId;
        param.assessUserId = selectItem.assessUserId;
        param.userId = selectItem.userId;
        param.officeId = selectItem.officeId;
        param.skillEvaluationId = selectItem.skillEvaluationId;
        param.level = this.selectLevel;
        param.option = this.selectOption;
        api.addSkillLevel(param).then((response) => {
          if (response.code == 200) {
            selectItem.level = this.selectLevel;
            selectItem.option = this.selectOption;
            this.changeEmptyData();
          }
        });
      }
    },
    //评价
    goEvaluate(index) {
      this.selectIndex = index;
      let selectItem = this.dataList[this.selectIndex];
      this.isShowEvaluate = true;
        if (selectItem.level) {
          this.selectLevel = selectItem.level;
          this.selectOption = selectItem.option;
        }
    },
    //去测评
    goTest(item) {
      this.$router.push({
        path: "/SkillEvaluationNotice",
        query: {
          skillId: item.skillId,
          assessUserId: item.assessUserId,
          userId: item.userId,
          officeId: item.officeId,
          skillEvaluationId:item.skillEvaluationId,
          evaluationName:item.evaluationName,
          category:item.category
        }
      });
    },
    //分享
    shareContent() {
      const titleStr = "HRCOE";
      const contentStr = "让人力资源工作更简单!";
      const linkStr = ShareLink + "type=1";
      const imgStr = ShareLogo;
      window.shareData = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      window.shareIMdata = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      startShart();
    }
  }
};
</script>

<style scoped>
.cover {
  margin-top: 15px;
}
.item {
  opacity: 1;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  margin: 10px;
  padding: 10px;
}
.info {
  display: flex;
  height: 6rem;
}
.info_left {
  width: 6rem;
  height: 100%;
  opacity: 1;
  background: linear-gradient(180deg, #6c97ff, #3f72ec);
  border-radius: 4px;
  font-size: 2rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  line-height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info_center {
  height: 100%;
  margin: 0px 5px 0px 10px;
  flex: 1;
  position: relative;
}
.name {
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  width: 100%;
}
.title {
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Bold;
  text-align: left;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  top: 35%;
  width: 100%;
}
.time {
  opacity: 1;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.info_right {
  width: 80px;
  height: 100%;
  position: relative;
}
.phone {
  opacity: 1;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #666666;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  position: absolute;
  right: 0px;
  top: 0px;
}
.testBtn {
  opacity: 1;
  background: #13d1be;
  border-radius: 12px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #ffffff;
  width: 80px;
  height: 24px;
  line-height: 24px;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.completeTest {
}
.bottom {
  height: 40px;
  position: relative;
}
.evaluateBtn {
  opacity: 1;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: right;
  color: #13d1be;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  line-height: 40px;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.grade {
  opacity: 1;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #13d1be;
}
.color1 {
  color: #13d1be;
}
.color2 {
  color: #e6b800;
}
.color3 {
  color: #fd4e23;
}
.grayCover {
  opacity: 0.2;
  background: #000000;
  width: 100%;
  height: 200px;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
.evaluateCover {
  opacity: 1;
  background: #ffffff;
  width: 100%;
  height: 380px;
  position: fixed;
  left: 0px;
  bottom: 0px;
}
.btnCover {
  width: 100%;
  height: 50px;
}
.cancel {
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #13d1be;
  float: left;
  height: 50px;
  line-height: 50px;
  width: 50px;
  text-align: center;
}
.sure {
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  float: right;
  height: 50px;
  line-height: 50px;
  width: 50px;
  text-align: center;
}
.line {
  opacity: 1;
  background: #f4f4f4;
  height: 1px;
  width: 100%;
}
.tip {
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #333333;
  margin-left: 10px;
  line-height: 50px;
}
.gradeItem {
  margin-top: 20px;
  height: 30px;
}
.scoreItemFirst {
  float: left;
  margin-left: 10px;
  height: 100%;
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 30px;
  width: 60px;
}
.scoreItem {
  float: left;
  margin-left: 15px;
  height: 100%;
  line-height: 30px;
  opacity: 1;
  background: #f4f4f4;
  border-radius: 5px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #333333;
  width: calc((100% - 60px - 20px - 60px) / 4);
}
.selectItem {
  background: #13d1be;
  color: #ffffff;
}
.gradeTabCover {
  margin: 20px 0px 10px 0px;
}
.gradeTab {
  display: flex;
  align-items: center;
}
.gradeTapView {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  flex: 1;
  border: 1px solid #71d2c8;
  margin-left: -1px;
  margin-top: -1px;
  margin-bottom: -1px;
  text-align: center;
  font-size: 11px;
  background: #f0fcfa;
  text-align: center;
}
.gradeTapTop {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
}
.gradeTabBottom {
  height: 30px;
  flex: 1;
  border: 1px solid #71d2c8;
  margin-left: -1px;
  text-align: center;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #ffffff;
  line-height: 30px;
}
</style>
