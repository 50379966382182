<template>
    <div class="main_view">
        <div class="main_item">
            <div class="item">
                <div class="m_left">姓名：</div>
                <input class="m_right_1" v-model="dataInfo.username" placeholder="请输入姓名" />
            </div>
            <div class="item">
                <div class="m_left">手机号：</div>
                <input class="m_right_1" v-model="dataInfo.mobile" placeholder="请输入手机号" />
            </div>

            <div class="item_2">
                <div class="m_left">简历：</div>
                <div class="m_right_2">
                    <div style="margin-bottom: 10px;" v-if="dataInfo.preUrl" @click="toPreView(dataInfo.preUrl)">{{dataInfo.resumeName}}</div>
                    <div class="upload_btn" @click="uploadResume">上传简历</div>
                </div>

            </div>

        </div>
        <input class="hide-input" id="file" hidden type="file" @change="chooseFile"/>
        <van-button
                class="talent_button"
                type="primary"
                @click="touDi">投递简历
        </van-button>
    </div>
</template>

<script>
    import {Button, Picker, Popup, Toast,Checkbox, CheckboxGroup} from "vant";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import api from "@/apis/recruit/recruit.js";

    export default {
        name: "UploadResume",
        data() {
            return {
                mFile: null,
                totalId: null,
                dataInfo: {
                    preUrl: null,
                    username: null,
                    mobile: null,
                    resumeName: null
                },
                isResume: 0,
                btnContent: '上传简历'

            }
        },
        components: {
            [Toast.name]: Toast,
            [Button.name]: Button,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup
        },
        created() {
            document.title = "我的简历";
            this.totalId = this.$route.query.totalId;
            this.isResume = this.$route.query.isResume;
            if (this.isResume==1){
                this.queryMyResume()
            }
        },
        mounted() {
            this.shareContent();
        },
        methods: {
            queryMyResume(){
                let that=this;
                api._queryMyResume().then(response => {
                    if (response.result) {
                        that.dataInfo=response.result;
                    }
                })
            },
            uploadResume() {

                document.querySelector("#file").value = null;
                document.querySelector("#file").click();
            },
            chooseFile(event) {
                this.mFile = event.target.files[0];
                this.uploadFile();
            },
            uploadFile() {
                let that = this;
                if (!that.mFile) {
                    return that.$toast('请先上传简历');
                }
                api.addUserResume(that.mFile, that.totalId).then(response => {
                    that.$toast('上传成功');
                    that.dataInfo.id = response.result.id;
                    that.dataInfo.preUrl = response.result.preUrl;
                    that.dataInfo.resumeName = response.result.resumeName;
                });
            },
            touDi(){
                let that = this;
                if (!that.dataInfo.username){
                    return that.$toast('请先输入姓名');
                }
                if (!that.dataInfo.mobile){
                    return that.$toast('请先输入手机号');
                }
                let reg = /(1[0-9]{10}$)/;
                if (!reg.test(that.dataInfo.mobile)) {
                    Toast({
                        message: "请输入正确手机号",
                        position: "middle",
                        duration: 1000
                    });
                    return
                }
                if (!that.dataInfo.id){
                    return that.$toast('请先上传简历');
                }
                api.addRecord({
                    username: that.dataInfo.username,
                    mobile: that.dataInfo.mobile,
                    totalId: that.totalId,
                    status: 1//不感兴趣,投递
                }).then(response => {
                    that.$toast('投递成功');
                });
            },
            toPreView(url){
                this.$router.push({
                    path: '/PreviewResume',
                    query:{
                        url: url
                    }
                })
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .main_view {
        overflow-y: auto;
        background-color: #f4f4f4;
        min-height: 100vh;
    }

    .main_item {
        margin: 0 10px 50px 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        background-color: white;
    }

    .item {
        display: flex;
        align-items: center;
        padding: 10px 15px;
    }

    .item_2 {
        display: flex;
        padding: 10px 15px;
    }
    .m_left {
        width: 100px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        text-align: right;
        margin-right: 10px;
    }

    .m_right_1 {
        display: flex;
        flex: 3;
        height: 36px;
        background: #ffffff;
        border: 1px solid #dadada;
        border-radius: 5px;
        font-size: 14px;
        color: #333333;
        padding: 0px 10px;
        justify-content: space-between;
        align-items: center;
    }



    .m_right_2 {
        flex: 3;
        font-size: 14px;
        color: #13d1be;
    }
    .upload_btn{
        width: 110px;
        height: 30px;
        background: #13d1be;
        border-radius: 15px;
        color: white;
        line-height: 30px;
        text-align: center;

    }
    .talent_button {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #13d1be;
        border: 0px;
    }
</style>
