import {
    get,
    post,
    postJson,
    postform
} from "../utils/base";

// import userCache from '@/common/js/userCache'
function _opts(isShowLoading) {
    var opt = {
        cache: {
            exp: 60
        },
        silent: false
    };
    if (isShowLoading) {
        return Object.assign(opt, {
            progress: true
        })
    }
}

export default {
    //多准预览
    duoDimensionSet: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/skillEvaluation/addSkillLevelProblem', params, opts);
    },
    //多准维度条目删除
    deleteSkillProblem: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/skillEvaluation/deleteSkillProblem', params, opts);
    },
    //多准发起
    startSkillEvaluation: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/skillEvaluation/startSkillEvaluation', params, opts);
    },
    // 获取验证码
    getIdentifyCode(mobile, type) {
        const opts = _opts(false);
        const params = {
            mobile: mobile,
            type: type
        };
        return post('first/getIdentifyCode', params, opts)
    },
    // 绑定手机号
    bindMobile(mobile, code) {
        const opts = _opts(false);
        const params = {
            mobile: mobile,
            code: code
        };
        return post('weiChat/bindUser', params, opts)
    },
    // 获取个人信息
    getUserInfo(param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/weiChat/queryHrCoeUserInfo', params, opts)
    },
    // 获取组织列表
    getOfficeList(param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('hrCoe/review/officeList', params, opts)
    },
// 获取Review列表
    getReviewList(param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('hrCoe/review/userList', params, opts)
    },
    // 获取Review题目列表
    getDimensionList(param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('hrCoe/review/dimensionList', params, opts)
    },

    //评审提交
    _getSubmitItem: function (param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('hrCoe/review/addReviewV2', params, opts);
    },
    //需求提交
    _addCustomer: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('home/addCustomer', params, opts);
    },
    //判断是否填写信息
    _checkOfficeReviewRecord: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/review/checkOfficeReviewRecord', params, opts);
    },
    //填写信息提交
    _addOfficeReviewRecord: function (param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('hrCoe/review/addOfficeReviewRecord', params, opts);
    },
    //获取列表信息
    _getOfficeReviewList: function (param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('hrCoe/review/officeReviewList', params, opts);
    },
    //添加彩准成员
    _addOfficeReviewPersonRecord: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/review/addOfficeReviewPersonRecord', params, opts);
    },
    //删除彩准成员
    _deleteOfficeReviewPersonRecord: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/review/deleteOfficeReviewPersonRecord', params, opts);
    },
    //HR 诊断题目
    _hr_diagnosis_subject: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('basicData/selectProblemList', params, opts);
    },
    //HR 诊断提交
    _hr_diagnosis_submit: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/review/addMajorEvaluation', params, opts);
    },
    //HR 诊断结果
    _hr_diagnosis_result: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/review/findDiagnoseResult', params, opts);
    },
    //信息填写
    _info_save: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('office/saveRequirements', params, opts);
    },
    //信息复写
    _info_show: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('office/getRequirements', params, opts);
    },
    //人力资源诊断复写
    _queryEnterpriseDiagnosis: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/offline/queryEnterpriseDiagnosis', params, opts);
    },
    //人力资源诊断添加
    _addEnterpriseDiagnosis: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/offline/addEnterpriseDiagnosis', params, opts);
    },
    //获取职位列表
    _get_post_list: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('basicData/selectMatchingPost', params, opts);
    },
    //获取城市列表
    _selectRegionList: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('consumption/selectRegionList', params, opts);
    },
    //获取基本数据
    basicData_officeBasicData: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('basicData/officeBasicData', params, opts);
    },
    //创建组织
    performance_addOffice: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/performance/addOffice', params, opts);
    },
    //获取人数规模
    _selectCompanyScaleList: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('consumption/selectCompanyScaleList', params, opts);
    },
    //添加评级
    _addReviewLevel: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/review/addReviewLevel', params, opts);
    },
    //获取技能评价列表
    skillEvaluationList(param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('hrCoe/skillEvaluation/list', params, opts)
    },
    //获取技能评价推广列表
    skillEvaluationExtensionList(param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('hrCoe/review/officeSkillList', params, opts)
    },
     //获取技能评价题目列表
     skillEvaluationTestList(param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('hrCoe/skillEvaluation/problemList', params, opts)
    },
    //技能评价题目提交
    addSkillScore: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/skillEvaluation/addSkillScore', params, opts);
    },
    //获取价值观评估题目列表
    managerAssessmentProblemList(param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('hrCoe/managerAssessment/problemList', params, opts)
    },
     //价值观评估题目提交
    managerAssessmentAddAssessmentScore: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/managerAssessment/addAssessmentScore', params, opts);
    },

    //技能评价评级
    addSkillLevel: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/skillEvaluation/addSkillLevel', params, opts);
    },
    //多准预览
    duoPreview: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/skillEvaluation/previewSkillEvaluation', params, opts);
    },
    //创建标注答案
    addSkillLevelEvaluation: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/skillEvaluation/addSkillLevelEvaluation', params, opts);
    },
    //多准评价结果
    mySkillEvaluationScore: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/skillEvaluation/mySkillEvaluationScore', params, opts);
    },
    //签到信息填写
    offline_addStudent: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/offline/addStudent', params, opts);
    },
    //签到
    offline_addSign: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/offline/addSign', params, opts);
    },
     //签到列表
     offline_signList: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/offline/signList', params, opts);
    },
    //签到填写心得
    offline_addExperience: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/offline/addExperience', params, opts);
    },
    //判断员工
    review_checkOfficeUserId: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/review/checkOfficeUserId', params, opts);
    },
    //管理技能评估列表
    skillEvaluationManagerialSkillsList: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/skillEvaluation/managerialSkillsList', params, opts);
    } ,
    //价值观评估列表
    managerAssessmentValueAssessmentList: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/managerAssessment/valueAssessmentList', params, opts);
    },
    //干部述职列表
    cadresScore_cadresList: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/cadresScore/cadresList', params, opts);
    },
    //干部述职题目列表
    cadresScore_problemList: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/cadresScore/problemList', params, opts);
    },
    //干部述职题目提交
    cadresScore_addCadresScore: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/cadresScore/addCadresScore', params, opts);
    },
    //干部述职评审结果
    cadresScore_cadresResult: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/cadresScore/cadresResult', params, opts);
    },
    //HR诊断验证信息
    diagnosisAddDiagnosisRecord: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/diagnosis/addDiagnosisRecord', params, opts);
    },
    //HR诊断题目
    diagnosisDiagnosisProblemList: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/diagnosis/diagnosisProblemList', params, opts);
    },
    //HR诊断提交
    diagnosisAddDiagnosisScore: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/diagnosis/addDiagnosisScore', params, opts);
    },
    //HR诊断结果
    diagnosisDiagnosisResult: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/diagnosis/diagnosisResult', params, opts);
    } ,
    //综合评价非应聘者信息获取
    generalReviewOfJob: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/weiChat/generalReviewOfJob', params, opts);
    } ,
    //综合评价评审提交
    updateHuntingEvaluationRecord: function (param) {
        const opts = _opts(false);
        let params = param ? param : {};
        return post('hrCoe/weiChat/updateHuntingEvaluationRecord', params, opts);
    }
}
