var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"forcenter"},[(_vm.deptName)?_c('div',{staticClass:"m_name"},[_vm._v(_vm._s(_vm.deptName+'-'+_vm.username))]):_c('div',{staticClass:"m_name"},[_vm._v(_vm._s(_vm.username))]),_c('div',{staticClass:"m_card"},[_c('div',{staticClass:"m_title"},[_c('div',{staticClass:"remark"},[_vm._v("["+_vm._s(_vm.dataItem[_vm.index].remark)+"]")]),_c('div',{staticClass:"title"},[_vm._v("Q"+_vm._s(_vm.index + 1+'.'+_vm.dataItem[_vm.index].title))])]),_c('div',{staticClass:"item_card"},[_c('div',{staticClass:"left-item",class:_vm.allanswer.length > _vm.last && _vm.allanswer[_vm.last].score == 1
          ? 'answers-bg-active'
          : 'answers-bg',on:{"click":function($event){return _vm.next(1)}}},[_c('div',[_vm._v("A、")]),_c('div',[_vm._v(_vm._s(_vm.dataItem[_vm.index].optionA))])]),_c('div',{staticClass:"left-item",class:_vm.allanswer.length > _vm.last && _vm.allanswer[_vm.last].score == 2
          ? 'answers-bg-active'
          : 'answers-bg',on:{"click":function($event){return _vm.next(2)}}},[_c('div',[_vm._v("B、")]),_c('div',[_vm._v(_vm._s(_vm.dataItem[_vm.index].optionB))])]),_c('div',{staticClass:"left-item",class:_vm.allanswer.length > _vm.last && _vm.allanswer[_vm.last].score == 3
          ? 'answers-bg-active'
          : 'answers-bg',on:{"click":function($event){return _vm.next(3)}}},[_c('div',[_vm._v("C、")]),_c('div',[_vm._v(_vm._s(_vm.dataItem[_vm.index].optionC))])]),_c('div',{staticClass:"left-item",class:_vm.allanswer.length > _vm.last && _vm.allanswer[_vm.last].score == 4
          ? 'answers-bg-active'
          : 'answers-bg',on:{"click":function($event){return _vm.next(4)}}},[_c('div',[_vm._v("D、")]),_c('div',[_vm._v(_vm._s(_vm.dataItem[_vm.index].optionD))])]),_c('div',{staticClass:"left-item",class:_vm.allanswer.length > _vm.last && _vm.allanswer[_vm.last].score == 5
          ? 'answers-bg-active'
          : 'answers-bg',on:{"click":function($event){return _vm.next(5)}}},[_c('div',[_vm._v("E、")]),_c('div',[_vm._v(_vm._s(_vm.dataItem[_vm.index].optionE))])])]),_c('div',{staticClass:"m_bottom"},[(_vm.isShow)?_c('div',{staticClass:"m_bottom_left",on:{"click":_vm.go_start}},[_vm._v(" "+_vm._s(_vm.previousName)+" ")]):_c('div',{staticStyle:{"width":"5rem"}}),_c('div',{staticClass:"m_bottom_center"},[_vm._v(_vm._s(_vm.index + 1)+"/"+_vm._s(_vm.dataItem.length))]),(_vm.index == _vm.dataItem.length - 1)?_c('div',{staticClass:"m_bottom_right",on:{"click":_vm.submit}},[_vm._v(" 提交 ")]):_c('div',{staticClass:"m_bottom_right",on:{"click":_vm.go_next}},[_vm._v(_vm._s(_vm.nextName))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }