import { render, staticRenderFns } from "./ServiceContract.vue?vue&type=template&id=ecb346ac&scoped=true&"
import script from "./ServiceContract.vue?vue&type=script&lang=js&"
export * from "./ServiceContract.vue?vue&type=script&lang=js&"
import style0 from "./ServiceContract.vue?vue&type=style&index=0&id=ecb346ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecb346ac",
  null
  
)

export default component.exports