<template>
    <div class="zhx_container" v-if="resultData">
        <div class='question_box'>
            <div class="title-view">
                <div class="title_1">综合得分：</div>
                <div class="title_2">{{resultData.score}}分</div>
            </div>
            <div class="title_3">诊断时间：{{resultData.createTime}}</div>
            <div class="table">
                <div class="tr bg-w">
                    <div class="th_left">HR诊断</div>
                    <div class="th_right">得分</div>
                </div>
                <div v-for="(item,index) in resultData.list" :key="index+'aa'">
                    <div class="tr" :class="index%2==1?'bg-g':''">
                        <div class="td_left">{{item.name}}</div>
                        <div class="td_right">{{item.score}}</div>
                    </div>
                </div>


                <!--                <div class="tr bg-g">-->
                <!--                    <div class="td_left">情绪控制</div>-->
                <!--                    <div class="td_right">{{resultData.emotionalControl}}</div>-->
                <!--                </div>-->

                <!--                <div class="tr">-->
                <!--                    <div class="td_left">行为控制</div>-->
                <!--                    <div class="td_right">{{resultData.behaviorControl}}</div>-->
                <!--                </div>-->

                <!--                <div class="tr bg-g">-->
                <!--                    <div class="td_left">认知他人</div>-->
                <!--                    <div class="td_right">{{resultData.knowingOthers}}</div>-->
                <!--                </div>-->

                <!--                <div class="tr">-->
                <!--                    <div class="td_left">建立与他人关系</div>-->
                <!--                    <div class="td_right">{{resultData.buildRelationships}}</div>-->
                <!--                </div>-->

                <!--                <div class="tr bg-g">-->
                <!--                    <div class="td_left">面对冲突</div>-->
                <!--                    <div class="td_right">{{resultData.faceConflict}}</div>-->
                <!--                </div>-->
            </div>
        </div>
        <div class="bottombtn" @click="clickBtn()">重新诊断</div>
    </div>
</template>

<script>
    //引入api

    import api from "@/apis/talentReview/talentReview.js";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import storage from "@/common/js/cacheData";

    export default {
        name: 'HrDiagnosisResult',
        data() {
            return {
                //测试结果
                resultData: null,
                skillId: null
            };
        },


        methods: {
            clickBtn() {
                let that = this;
                that.$router.replace({
                    path: '/HrDiagnosisTest',
                    query: {
                        skillId: that.skillId
                    }
                })
                // that.$router.go(-1); //返回上一层
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            },
            //初始化获取测评结果
            _getTestResult() {
                let that = this;
                api.diagnosisDiagnosisResult().then(
                    response => {
                        that.resultData = response.result;
                        // that.resultData.list.push({name: "维度003", score: "2.00"})
                        // that.resultData.list.push({name: "维度004", score: "5.00"})
                        // that.resultData.list.push({name: "维度005", score: "7.00"})
                        // that.resultData.list.push({name: "维度006", score: "1.00"})
                        // that.resultData.list.push({name: "维度007", score: "6.00"})
                        // that.resultData.list.push({name: "维度008", score: "4.00"})
                        // that.resultData.list.push({name: "维度009", score: "6.00"})
                        // that.resultData.list.push({name: "维度010", score: "2.00"})
                        // that.resultData.list.push({name: "维度010", score: "2.00"})
                        // that.resultData.list.push({name: "维度010", score: "2.00"})
                        // that.resultData.list.push({name: "维度010", score: "2.00"})
                        // that.resultData.list.push({name: "维度010", score: "2.00"})
                        // that.resultData.list.push({name: "维度010", score: "2.00"})
                    },
                    res => {
                    }
                );
            }
        },
        created() {
            let that = this;
            let openId = that.$route.query.openId;
            that.skillId = that.$route.query.skillId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            document.title = "诊断结果";
            that._getTestResult();
        },
        mounted() {
            let that = this;
            that.shareContent();
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .zhx_container {
        /*width: 100vw;*/
        /*height: 100vh;*/
        padding: 6rem 2rem;
    }

    .question_box {

        /*position: relative;*/
        color: #333333;
        /*height: 450px;*/
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
        padding-bottom: 1rem;
        /*top: 50%;*/
        /*transform: translate(0, -50%);*/
    }

    .title-view {
        display: flex;
        padding-top: 2rem;
        align-items: center
    }

    .title_1 {
        padding-left: 1.5rem;
        font-weight: bold;
        font-size: 16px;
    }

    .title_2 {
        color: #13D1BE;
        font-weight: bold;
        font-size: 16px;
    }

    .title_3 {
        padding-left: 1.5rem;
        padding-top: 1.5rem;
        color: #666666;
        font-size: 14px;
    }

    .table {
        border-top: 1px solid #A0E1DA;
        border-left: 1px solid #A0E1DA;
        margin: 2.5rem 1.5rem 2.5rem 1.5rem;
    }

    .tr {
        display: flex;
        width: 100%;
        justify-content: center;
        height: 40px;
        line-height: 40px;
        align-items: center;
        border-bottom: 1px solid #A0E1DA;
    }

    .td_left {
        width: 70%;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        border-right: 1px solid #A0E1DA;
    }

    .td_right {
        width: 30%;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        border-right: 1px solid #A0E1DA;
    }

    .bg-w {
        background: snow;
    }

    .bg-g {
        background: #EDFAF9;
    }

    .th_left {
        width: 70%;
        justify-content: center;
        border-bottom: 1px solid #A0E1DA;
        border-right: 1px solid #A0E1DA;
        background: #C3EFEA;
        color: #333;
        font-weight: bold;
        display: flex;
        height: 40px;
        font-size: 14px;
        align-items: center;
    }

    .th_right {
        width: 30%;
        justify-content: center;
        border-bottom: 1px solid #A0E1DA;
        border-right: 1px solid #A0E1DA;
        background: #C3EFEA;
        color: #333;
        font-weight: bold;
        display: flex;
        height: 40px;
        font-size: 14px;
        align-items: center;
    }

    .bottombtn {
        position: fixed;
        top: 2vh;
        right: 0px;
        background-color: #c32d2c;
        height: 4rem;
        line-height: 4rem;
        font-size: 14px;
        color: #fff;
        border: 1px solid #e1999a;
        padding: 0;
        width: 120px;
        text-align: center;
        border-top-left-radius: 3.5vh;
        border-bottom-left-radius: 3.5vh;
        border-right: none;
    }
</style>
