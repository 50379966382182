<template>
  <div class="getwidth">
    <canvas
      id="rander"
      width='284'
      height="220"
    ></canvas>
  </div>
</template>
<script>
import { debug } from 'util';
export default {
  data() {
    return {};
  },
  props: {
    renderdata: Object
  },
  created() {},
  mounted() {
    // console.log(this);
    this.drawrender();
  },
  methods: {
    drawrender() {
      let that = this;
      var c = document.getElementById("rander");
      var context = c.getContext("2d");
      let canvasdata = that.renderdata.data;
        let aa=[];
        canvasdata.forEach(item =>{
           aa.push(item.name)
        });
      c.style.width = 284;
      c.style.height = 220;
      c.width = 284;
      c.height = 220;

      context.scale(1, 1);
      let centerPoint = [142, 110],
        angleNum = 10,
        angleAvg = 360 / angleNum,
        angleOffset = 90,
        layerNum = 5,
        layerWidth = 16,
        layerPoints = [],
        bgLineColor = "#fff",
        // wordArr = [
          //   "1号-完美型",
          //   "2号-助人型",
          //   "3号-成就型",
          //   "4号-自我型",
          //   "5号-理智型",
          //   "6号-忠诚型",
          //   "7号-活跃型",
          //   "8号-领袖型",
          //   "9号-和平型",
          //   "9号-和平型"
          // ];
          wordArr=aa;
      let wordOffset = [
        [-30, 8],
        [8, 0],
        [10, -6],
        [0, 10],
        [-10, 20],
        [30, 20],
        [60, 10],
        [60, 0],
        [60, 0],
        [60, 0]
      ];
      context.save();
      context.lineWidth = 1;
      context.strokeStyle = "#ebd3cd";
      context.fontSize = 8;
      context.fillStyle = "#333";
      context.moveTo(centerPoint[0], centerPoint[1]);
      context.fontWeight = "bold";
      context.beginPath();
      for (let n = 0; n < layerNum; n++) {
        layerPoints[n] = [];
        for (let k = 0; k < angleNum; k++) {
          context.moveTo(centerPoint[0], centerPoint[1]);
          let offsetX =
            layerWidth * (n + 1) * that.getXParam(angleAvg * k - angleOffset);
          let offsetY =
            layerWidth * (n + 1) * that.getYParam(angleAvg * k - angleOffset);
          let distX = centerPoint[0] + offsetX;
          let distY = centerPoint[1] + offsetY;
          if (n == layerNum - 1) {
            context.lineTo(distX, distY);
            if (wordArr[k]) {
              let wordOffsetX = offsetX >= 0 ? 1 : -1;
              wordOffsetX = distX + wordOffsetX * wordOffset[k][0];
              let wordOffsetY = offsetY >= 0 ? 1 : -1;
              wordOffsetY = distY + wordOffsetY * wordOffset[k][1];
              context.fillText(wordArr[k], wordOffsetX, wordOffsetY);
            }
          }
          layerPoints[n][k] = [distX, distY];
        }
      }
      context.stroke();
      context.restore();

      context.save();
      context.lineWidth = 1;
      context.strokeStyle = "#ebd3cd";
      context.beginPath();

      for (let m = 0; m < layerPoints.length; m++) {
        for (let n = 0; n < layerPoints[m].length; n++) {
          context.moveTo(layerPoints[m][n][0], layerPoints[m][n][1]);
          if (n < layerPoints[m].length - 1) {
            context.lineTo(layerPoints[m][n + 1][0], layerPoints[m][n + 1][1]);
          } else {
            context.moveTo(layerPoints[m][n][0], layerPoints[m][n][1]);
            context.lineTo(layerPoints[m][0][0], layerPoints[m][0][1]);
          }
        }
      }
      context.stroke();
      context.restore();

      context.save();
      context.strokeStyle = "#13d1be";
      context.fillStyle = "rgba(181,231,225,0.46)";
      context.beginPath();
      let isFirstPoint = true;
      let tmpPoints = [];
      let alllength = 10 * layerWidth;
      context.moveTo(centerPoint[0], centerPoint[1]);
      let p_x = [],
        p_y = [];
      for (let aa = 0; aa < canvasdata.length; aa++) {
        let line_length = (canvasdata[aa].showValue) * layerWidth;
        p_x[aa] = line_length * that.getXParam(angleAvg * aa - angleOffset);
        p_y[aa] = line_length * that.getYParam(angleAvg * aa - angleOffset);
        if (isFirstPoint) {
          context.moveTo(p_x[aa] + centerPoint[0], p_y[aa] + centerPoint[1]);
          isFirstPoint = false;
        } else {
          context.lineTo(p_x[aa] + centerPoint[0], p_y[aa] + centerPoint[1]);
        }
      }
      context.lineTo(p_x[0] + centerPoint[0], p_y[0] + centerPoint[1]);
      context.fill();
      context.stroke();
      context.restore();
      for (let m = 0; m < canvasdata.length; m++) {
        context.beginPath();
          context.fillStyle = "#13d1be";
        // if (canvasdata[m].type == "1") {
        //   context.fillStyle = "#e60012";
        // } else if (canvasdata[m].type == "2") {
        //   context.fillStyle = "#009944";
        // } else {
        //   context.fillStyle = "#b28850";
        // }
        context.arc(
          p_x[m] + centerPoint[0],
          p_y[m] + centerPoint[1],
          4,
          0,
          2 * Math.PI,
          false
        );

        context.closePath();
        context.fill();
        // context.scale(0.5, 0.5);
      }
    },
    getXParam(angle) {
      let param = 1;
      if (angle >= -90 && angle < 0) {
        param = 1;
        angle = -angle;
      } else if (angle >= 0 && angle < 90) {
        param = 1;
          // eslint-disable-next-line no-self-assign
        angle = angle;
      } else if (angle >= 90 && angle < 180) {
        param = -1;
        angle = 180 - angle;
      } else if (angle >= 180 && angle <= 270) {
        param = -1;
        angle = angle - 180;
      }

      let angleCos = Math.cos((Math.PI / 180) * angle);
      if (angleCos < 0) {
        angleCos = angleCos * -1;
      }
      return angleCos * param;
    },

    getYParam(angle) {
      let param = 1;
      if (angle >= -90 && angle < 0) {
        param = -1;
        angle = -angle;
      } else if (angle >= 0 && angle < 90) {
        param = 1;
          // eslint-disable-next-line no-self-assign
        angle = angle;
      } else if (angle >= 90 && angle < 180) {
        param = 1;
        angle = 180 - angle;
      } else if (angle >= 180 && angle <= 270) {
        param = -1;
        angle = angle - 180;
      }

      let angleSin = Math.sin((Math.PI / 180) * angle);
      if (angleSin < 0) {
        angleSin = angleSin * -1;
      }
      return angleSin * param;
    }
  }
};
</script>
 <style scoped>
</style>