import {
  get,
  post
} from '../utils/base'
import userCache from '@/common/js/userCache'

function _opts (isShowLoading) {
  var opt = {
    cache: {
      exp: 60
    },
    silent:false
  }
  if (isShowLoading) {
    return Object.assign(opt, {
      progress: true
    })
  }
  return Object.assign(opt)
}

// 获取学习类型
export function studylistType() {
  const opts = _opts(true)
  const params = {}
  return post('studyList/listType', params, {}, opts)
}

// 获取学习列表
export function studylist(cId, sort, page) {
  const opts = _opts(true)
  const params = {
    cId: cId,
    sort: sort,
    page: page,
    limit: 12
  }
  return post('studyList/list', params, {}, opts)
}

// 课程详情 网易
export function courseDetails(courseId) {
  const opts = _opts(true)
  const params = {
    id: courseId
  }
  return post('studyList/selectOneAliyun', params, {}, opts)
}
// 课程详情 阿里
export function campDetailList(campId) {
  const opts = _opts(true)
  const params = {
    campId: campId
  }
  return post('studyList/selectCampDetailWatchList', params, {}, opts)
}
// 课程目录 网易
export function overview(courseId) {
  const opts = _opts(true)
  const params = {
    id: courseId
  }
  return post('studyList/selectContents', params, {}, opts)
}

// 学员评价
export function evaluate(courseId, page) {
  const opts = _opts(true)
  const params = {
    audiototalId: courseId,
    limit: 6,
    page: page
  }
  return post('studyList/selectComment', params, {}, opts)
}

// 添加评论
export function comment(dengji, content, audiototalId) {
  const opts = _opts(true)
  const params = {
    audiototalId: audiototalId,
    content: content,
    dengji: dengji
  }
  return post('studyList/insertComment', params, {}, opts)
}
// 视频播放 网易
export function playVideo(audioId) {
  const opts = _opts(true)
  const params = {
    audioId: audioId
  }
  return post('studyList/playVideo', params, {}, opts)
}
//购买免费课程，免费课程学习
export function buyFreeVideo(id) {
  const opts = _opts(true)
  const params = {
    id: id
  }
  return post('studyList/buyFreeVideo', params, {}, opts)
}


// 阿里播放 
// 课程目录 阿里
export function overview_ali(courseId, courseType) {
  const opts = _opts(true)
  const params = {
    id: courseId,
    courseType: courseType
  }
  return post('studyList/selectContentsAliyunVideo', params, {}, opts)
}
// 课程详情 阿里
export function courseDetails_ali(courseId, courseType) {
  const opts = _opts(true)
  const params = {
    id: courseId,
    courseType: courseType

  }
  return post('studyList/selectOneAliyun', params, {}, opts)
}
// 视频播放 阿里
export function playVideo_ali(detailId, totalId, type,officeId,userId) {
  const opts = _opts(true)
  const params = {
    detailId: detailId,
    totalId: totalId,
    officeId: officeId,
    userId: userId,
    type: type
  }
  return post('hrCoe/study/getVideoUrl', params, {}, opts)
}
// 视频播放 阿里
export function playVideo_campali(audioId) {
  const opts = _opts(true)
  const params = {
    audioId: audioId
  }
  return post('studyList/playAliyunVideoV2', params, {}, opts)
}
// 发送时长 阿里
export function studyList_editTime(params) {
  const opts = _opts(true)
  return post('study/editTime', params, {}, opts)
}
// 10s发送时长 阿里
export function studyList_editTimeV2(params) {
  const opts = _opts(true)
  return post('hrCoe/study/editTime', params, {}, opts)
}