<template>
  <div class="forcenter">
<!--    <div class='title'>— {{index+1}}/30 —</div>-->
<!--    <div class='content-title'>{{index+1+'.'+dataItem[index].title}}</div>-->
    <div class="question_box">
      <div class="title-view">
        <div class="question_detail">{{index+1+'.'+dataItem[index].title}}</div>
      </div>
      <div class="left-item"
           :class="(allanswer.length > last)&&(allanswer[last].score == 1)?'answers-bg-active':'answers-bg'"
           @click="next(1)">A、{{dataItem[index].optionA}}
      </div>
      <div class="left-item"
           :class="(allanswer.length > last)&&(allanswer[last].score == 3)?'answers-bg-active':'answers-bg'"
           @click="next(3)">B、{{dataItem[index].optionB}}
      </div>
      <div class="left-item"
           :class="(allanswer.length > last)&&(allanswer[last].score == 4)?'answers-bg-active':'answers-bg'"
           @click="next(4)">C、{{dataItem[index].optionC}}
      </div>
      <div class="left-item"
           :class="(allanswer.length > last)&&(allanswer[last].score == 5)?'answers-bg-active':'answers-bg'"
           @click="next(5)">D、{{dataItem[index].optionD}}
      </div>
      <div class="bottom-view">
        <div class='gopre' v-if="isShow" @click='gopre'>上一题</div>
        <div class='gopre' v-else></div>
        <div class='submit' v-if="submit_show" @click='submit'>提交</div>
        <div class='submit_empty' v-else></div>
        <div class="num-view">{{index +1}}/{{dataItem.length}}</div>
      </div>
    </div>
  </div>
</template>
<script>
  //引入分享
  import {startShart} from "@/common/js/tecentshare.js";
  import {ShareLink, ShareLogo} from "@/apis/const.js";
  import Emotional from "@/apis/test/test.js";

  export default {
    data() {
      return {
        isConfirm: true,
        isShow: false,
        submit_show: false,
        index: 0,
        last: 0,
        name: "上一题",
        dataItem: [
          {
            id: 1
          }
        ],
        allanswer: [
          {
            type: "",
            score: -1
          }
        ],
        isFromList: 0
      }
    },
    methods: {
      // go_start() {
      //   let that = this;
      //   // if (that.index == 29) {
      //   //   that.submit();
      //   //   return;
      //   // }
      //   that.index--;
      //   that.last--;
      //   if (that.index > 0) {
      //     that.isShow = true;
      //   } else {
      //     that.isShow = false;
      //   }
      // },
      gopre(){
        let that = this;
        that.index--;
        that.last--;
        if (that.index > 0) {
          that.isShow = true;
        } else {
          that.isShow = false;
        }
      },
      next(a) {
        if (!this.isConfirm){
          return
        }
        this.isConfirm=false;
        let that = this;
        var ansobj = {
          type: "",
          score: -1
        };
        ansobj.type = that.dataItem[that.index].type;
        ansobj.score = a;
        ansobj.totalId = that.dataItem[that.index].id;
        ansobj.parentId = that.dataItem[that.index].parentId;
        that.allanswer[that.index] = ansobj;
        that.$forceUpdate();
        setTimeout(() =>{
          this.isConfirm=true;
          if (that.index == 29) {
            that.submit_show = true;
            return;
          } else {
            that.submit_show = false;
          }
          that.index++;
          that.last++;
          if (that.index > 0) {
            that.isShow = true;
          } else {
            that.isShow = false;
          }

        },300)

      },
      submit() {
        let that = this;
        Emotional._getEQUpdateReport({
          list: JSON.stringify(that.allanswer)
        }).then(
          response => {
            that.$toast('测评完成');
            setTimeout(() => {
              if (this.isFromList==1){
                that.$router.go(-1);
              }else{
                that.$router.replace({
                  path: "/RecruitList"
                })
              }
            }, 2000);
          },
          res => {
          }
        );
      },
      _getItem() {
        let that = this;
        Emotional._getEQItem().then(
          response => {
            that.dataItem = response.result;
          },
          res => {
          }
        );
      },
      //分享
      shareContent() {
        const titleStr = "HRCOE";
        const contentStr = "让人力资源工作更简单!";
        const linkStr = ShareLink + "type=1";
        const imgStr = ShareLogo;
        window.shareData = {
          fTitle: titleStr,
          fContent: contentStr,
          fImgUrl: imgStr,
          fLink: linkStr
        };
        window.shareIMdata = {
          fTitle: titleStr,
          fContent: contentStr,
          fImgUrl: imgStr,
          fLink: linkStr
        };
        startShart();
      }
    },
    created() {
      document.title = "情商测评";
      this.isFromList = this.$route.query.isFromList;
      this._getItem();
    },
    mounted() {
      this.shareContent();
    }
  };
</script>
<style scoped>
  .forcenter {
    width: 100vw;
    background: #fff;
  }

  .question_box {
    position: relative;
    color: #666666;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
    top: 50%;
    transform: translate(0, 20%);
    padding: 0 0 2rem 0;
    margin: 0 15px;
  }
  .title-view {
    background: #13D1BE;
    height: 80px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .question_detail{
    padding: 30px 5px 10px 10px;
    font-size: 1.6rem;
    color: white;

  }
  .bottom-view {
    display: flex;
    width: 100%;
    bottom: 2.5rem;
    align-items: center;
    justify-content: space-between;
  }
  .num-view{
    width: 7.5rem;
    height: 4rem;
    line-height: 4rem;
    text-align: center;
    color: #999999;
    font-size: 1.4rem;
  }
  .submit{
    width: 8rem;
    height: 3rem;
    border-radius: 5px;
    line-height: 3rem;
    background: #13D1BE;
    text-align: center;
    color: white;
    font-size: 1.4rem;

  }
  .gopre{
    width: 7rem;
    height: 4rem;
    line-height: 4rem;
    margin-left: 1.5rem;
    text-align: center;
    color: #13D1BE;
    font-size: 1.4rem;
  }

  .go_start image {
    width: 42rem;
    height: 11rem;
  }

  .answers-bg{
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
    font-size: 1.4rem;
    padding: 1rem;
    color: #333333;
    margin: 1.5rem 2rem;
    color: #333333;
  }
  .answers-bg-active{
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
    color: white;
    font-size: 1.4rem;
    background-color: #13D1BE;
    padding: 1rem;
    color: white;
    margin: 1.5rem 2rem;
  }
  .hide {
    display: none;
  }

  .pre {
    /* background-color:rgb(21, 39, 125); */
    width: 100vw;
    height: 100vh;
  }

  .title {
    font-size: 24px;
    color: #fff;
    text-align: center;
    font-weight: 600;
    padding: 30px 0 20px 0;
  }

  .content-title {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    font-weight: 600;
    height: 50px;
    padding: 0 10px;
  }

  .botton-bg {
    font-size: 20px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-weight: 600;
    color: white;
  }



  .left-top {
    margin-bottom: 2rem;
  }
</style>
