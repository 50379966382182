<template>
    <div>
        <mystep curstep='2'/>
        <div class="dimension-bac">
            <div class="d-flex margin-bottom-20">
                <div style="flex: 1"></div>
                <div class="d-title">维度设置</div>
                <div class="d-delete" @click="handleDelete">{{deleteText}}</div>
            </div>
            <div class="d-box" v-for="(item, index) in datalist" :key="'people'+index">
                <div class="d-line"></div>
                <div class="d-flex padding-top-10">
                    <div class="d-weidu">维度<span>{{index+1}}</span></div>
                    <div class="d-circle-dimension"></div>
                    <div class="d-input">
                        <input style="outline:none;border:none;background: none"
                               type="text" v-model="item.title"
                               :onkeyup="item.title = item.title.replace(/\s+/g,'')"
                               @focus="handleFocus"
                               @blur="handleBlurDimension(item)">
                        <img v-if="showDeleteTag&&item.id!=0" class="d-img-delete"
                             src="../../../../static/image/icon_delete.png"
                             @click="deleteDimensionItem(item)"/>
                    </div>

                </div>
                <div v-for="(item1,index1) in item.children" :key="'people'+index1">
                    <div class="d-flex margin-top-20">
                        <div class="d-question">问题<span>{{index1+1}}</span></div>
                        <div class="d-circle-question"></div>
                        <div class="d-input">
                            <input style="outline:none;border:none;background: none"
                                   type="text" v-model="item1.title"
                                   :onkeyup="item1.title = item1.title.replace(/\s+/g,'')"
                                   @blur="handleBlurQuestion(item1,item.id)"
                                   @focus="handleFocus">
                            <img v-if="showDeleteTag&&item1.id!=0" class="d-img-delete"
                                 src="../../../../static/image/icon_delete.png" @click="deleteDimensionItem(item1)"/>
                        </div>

                    </div>
                </div>

                <div class="d-btn-add-question margin-top-10" @click="addQuestion(item)">添加问题</div>
            </div>
            <div class="d-flex">
                <div class="d-circle-dimension margin-left-70"></div>
                <div class="d-btn-add-weidu margin-top-10" @click="addDimension">添加维度</div>
            </div>

        </diV>
        <div class="d-btn-preview" @click="handlePreview">预览</div>
    </div>


</template>

<script>
    import mystep from "./mystep";
    import api from "@/apis/talentReview/talentReview.js";
    import {Dialog, Toast} from "vant";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";

    export default {
        name: "DuoSetDimension",
        data() {
            return {
                deleteText: '删除',
                showDeleteTag: false,
                weiduItem: null,
                weiduStatus: false,
                questionStatus: false,
                weiduIschanged: false,
                questionIschanged: false,
                officeId: null,
                userId: null,
                questionTag: false,
                dimensionItem: null,
                datalist: [],
                mSkillEvaluation: {
                    id: 0
                },
                addweidu: false
            }
        },
        components: {mystep},
        created() {
            document.title = "专业水平设置维度";
            this.userId = this.$route.query.userId;
            this.officeId = this.$route.query.officeId;
            this.getData();
            this.shareContent();
        },
        methods: {
            handleFocus() {
                this.weiduIschanged = true;
                this.questionIschanged = true;
            },
            getData() {
                api.duoPreview({
                    userId: this.userId,
                    officeId: this.officeId
                    // userId: 695,
                    // officeId: 125
                }).then(
                    response => {
                        if (response != null) {
                            if (response.result.skillEvaluation){
                                this.mSkillEvaluation = response.result.skillEvaluation;
                            }
                            if (response.result.list) {
                                this.datalist = response.result.list
                            }
                            if (this.weiduIschanged && this.weiduStatus) {
                                this.tianjiaewidu();
                            }
                            if (this.questionIschanged && this.questionStatus) {
                                let obj = {
                                    id: 0,
                                    title: ""
                                };
                                this.datalist.forEach(item => {
                                    if (item.id == this.weiduItem.id) {
                                        item.children.push(obj);
                                    }
                                });

                            }
                            this.weiduIschanged = false;
                            this.weiduStatus = false;
                            this.questionIschanged = false;
                            this.questionStatus = false;

                        }
                    }
                )
            },
            handleBlurDimension(item) {
                if (item.title == '') {
                    this.$toast('请填写内容');
                    return
                }
                let reqData = {
                    skillId: this.mSkillEvaluation.id,
                    title: item.title
                };
                if (item.id != 0) {
                    reqData.id = item.id
                }
                api.duoDimensionSet(reqData).then(
                    response => {
                        this.getData();
                    }
                )
            },
            handleBlurQuestion(item, parentId) {
                if (item.title == '') {
                    this.$toast('请填写内容');
                    return
                }
                this.questionTag = !this.questionTag;
                let reqData = {
                    skillId: this.mSkillEvaluation.id,
                    title: item.title,
                    parentId: parentId,
                    optionA: this.mSkillEvaluation.optionA,
                    optionB: this.mSkillEvaluation.optionB,
                    optionC: this.mSkillEvaluation.optionC,
                    optionD: this.mSkillEvaluation.optionD
                };
                if (item.id != 0) {
                    reqData.id = item.id
                }
                api.duoDimensionSet(reqData).then(
                    response => {
                        this.getData();
                    }
                )
            },
            handleDelete() {
                //删除操作
                this.showDeleteTag = !this.showDeleteTag;
                if (this.showDeleteTag) {
                    this.deleteText = '取消'
                } else {
                    this.deleteText = '删除'
                }
            },
            addQuestion(item) {

                this.weiduItem = item;
                if (!this.questionIschanged) {
                    let obj = {
                        id: 0,
                        title: ""
                    };
                    item.children.push(obj);
                } else {
                    this.questionStatus = true;
                }

            },
            // 添加维度
            addDimension() {
                if (!this.weiduIschanged) {
                    this.tianjiaewidu()
                } else {
                    this.weiduStatus = true;
                }
            },
            tianjiaewidu() {
                let obj = {
                    title: "",
                    id: 0
                };
                this.datalist.push(obj);
            },
            handlePreview() {
                this.$router.push({
                    path: '/DuoPreview',
                    query: {
                        userId: this.userId,
                        officeId: this.officeId
                    }
                })
            },
            /**
             * 删除维度条目
             * @param item
             */
            deleteDimensionItem(item) {
                Dialog.confirm({
                    message: '是否确定当前操作'
                })
                    .then(() => {
                        // on confirm
                        // Toast('确定')
                        api.deleteSkillProblem({
                            id: item.id
                        }).then(
                            response => {
                                // this.showDeleteTag = false;
                                this.getData();
                            }
                        )
                    })
                    .catch(() => {
                        // on cancel
                        // Toast('取消')
                    });

            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .dimension-bac {
        margin: 2rem;
        background: #ffffff;
        border-radius: 0.5rem;
        padding: 1.5rem 1.5rem 1.5rem 0;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
    }

    .d-box {
        position: relative;
    }

    .d-line {
        position: absolute;
        top: 2rem;
        bottom: -3rem;
        left: 7.5rem;
        width: 1px;
        background: #dadada;
        z-index: 0;
    }

    .d-flex {
        display: flex;
        align-items: center
    }

    .margin-bottom-20 {
        margin-bottom: 2rem;
    }

    .margin-top-30 {
        margin-top: 3rem;
    }

    .margin-top-20 {
        margin-top: 2rem;
    }

    .margin-top-10 {
        margin-top: 1rem;
    }

    .margin-left-70 {
        margin-left: 7rem;
    }

    .padding-top-10 {
        padding-top: 1rem;
    }

    .d-title {
        flex: 1;
        margin: 0 auto;
        text-align: center;
        font-size: 1.8rem;
        color: #333333;
        font-weight: 400;
    }

    .d-delete {
        flex: 1;
        font-size: 1.4rem;
        color: #999999;
        text-align: right;
    }

    .d-weidu {
        font-size: 1.6rem;
        font-weight: bold;
        color: #333333;
        width: 6rem;
        margin-right: 1rem;
        text-align: right;
    }

    .d-question {
        width: 6rem;
        margin-right: 1.15rem;
        font-size: 1.4rem;
        color: #333333;
        text-align: right;
    }

    .d-input {
        position: relative;
        height: 3rem;
        line-height: 3rem;
        flex: 1;
        background: #ffffff;
        padding-left: 1rem;
        border: 1px solid #dadada;
        border-radius: 0.5rem;
        font-size: 1.4rem;
    }

    .d-img-delete {
        position: absolute;
        top: -1rem;
        right: -0.8rem;
        width: 1.8rem;
        height: 1.8rem;
    }

    .d-btn-add-question {
        margin-left: auto;
        width: 9rem;
        height: 3rem;
        line-height: 3rem;
        background: rgba(19, 209, 190, 0.20);
        border: 1px solid #13d1be;
        border-radius: 0.5rem;
        text-align: center;
        color: #13D1BE;
        font-size: 1.4rem;
    }

    .d-btn-add-weidu {
        margin-left: auto;
        width: 23rem;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        background: rgba(19, 209, 190, 0.20);
        border: 1px solid #13d1be;
        border-radius: 0.5rem;
        color: #13D1BE;
        font-size: 1.4rem;
    }

    .d-circle-dimension {
        width: 1rem;
        height: 1rem;
        margin-right: 1rem;
        background: #ffffff;
        border: 1px solid #13d1be;
        border-radius: 50%;
        z-index: 1;
    }

    .d-circle-question {
        width: 0.8rem;
        height: 0.8rem;
        margin-right: 1rem;
        background: #ffffff;
        border: 1px solid #13d1be;
        border-radius: 50%;
        z-index: 1;
    }

    .d-btn-preview {
        margin: 6rem 10rem 3rem 10rem;
        height: 4rem;
        line-height: 4rem;
        background: #13d1be;
        border-radius: 5px;
        text-align: center;
        color: white;
        font-size: 1.4rem;
    }
</style>