<template>
    <div class="talent_all">
        <div class="talent_header">
            测评信息验证
        </div>
        <van-field
                v-model="name"
                label="姓名"
                placeholder="请输入姓名"
                input-align="right"
        />
        <van-field
                v-model="phone"
                label="手机号"
                type="tel"
                placeholder="请输入手机号"
                input-align="right"
        />
        <van-field
                v-if="isPhone=='0'"
                v-model="sms"
                center
                clearable
                label="短信验证码"
                placeholder="请输入短信验证码"
        >
            <template #button>
                <van-button
                        size="small"
                        type="primary"
                        :class="isActive?'btnActive':'btnNotActive'"
                        @click="sendSmsCode">{{message}}
                </van-button>
            </template>
        </van-field>
        <van-button
                class="talent_button"
                type="primary"
                @click="bindPhone">确定
        </van-button>
    </div>
</template>

<script>
    import {Button} from 'vant'
    import {Field} from 'vant'
    import {Toast} from 'vant'
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import {startShart} from "@/common/js/tecentshare.js";
    import storage from "@/common/js/cacheData";
    import api from "@/apis/talentReview/talentReview.js";

    export default {
        name: "TestInfoVerification",
        components: {
            [Button.name]: Button,
            [Field.name]: Field,
            [Toast.name]: Toast
        },
        data() {
            return {
                type: 1,
                skillId: null,
                name: '',
                phone: '',
                sms: '',
                isPhone: null,
                showLoginView: false,
                message: '获取验证码',
                isActive: true,
                canClick: true //添加canClick
            }
        },
        created() {
            let openId = this.$route.query.openId;
            this.isPhone = this.$route.query.isPhone;
            this.skillId = this.$route.query.skillId;
            // this.skillId = 79;
            if (openId) {
                storage.saveData("openId", openId);
            }
        },
        mounted() {
            this.shareContent();
        },
        methods: {
            sendSmsCode() {
                let reg = /(1[0-9]{10}$)/;
                if (!reg.test(this.phone)) {
                    Toast({
                        message: "请输入正确手机号",
                        position: "middle",
                        duration: 1000
                    });
                    return
                }
                if (!this.canClick) return; //改动的是这两行代码
                this.canClick = false;
                api.getIdentifyCode(this.phone, 1).then(
                    response => {
                        if (response.code == 200) {
                            this.isActive = false;
                            var num = 60;
                            var that = this;
                            var timer = setInterval(function () {
                                num--;
                                that.message = num + "s重新获取";
                                if (num === 0) {
                                    that.isActive = true;
                                    that.message = "获取验证码";
                                    that.canClick = true; //这里重新开启
                                    clearInterval(timer);
                                }
                            }, 1000);
                        }
                    },
                    res => {
                        this.canClick = true;
                    }
                );
            },
            bindPhone() {
                if (!this.name || this.name == '') {
                    Toast({
                        message: "请输入姓名",
                        position: "middle",
                        duration: 1000
                    });
                    return;
                }
                let reg = /(1[0-9]{10}$)/;
                if (!reg.test(this.phone)) {
                    Toast({
                        message: "请输入正确手机号",
                        position: "middle",
                        duration: 1000
                    });
                    return
                }
                if (this.isPhone=='0'&&(!this.sms || this.sms == '')) {
                    Toast({
                        message: "请输入验证码",
                        position: "middle",
                        duration: 1000
                    });
                    return;
                }
                api.diagnosisAddDiagnosisRecord({
                    username: this.name,
                    mobile: this.phone,
                    code: this.sms
                }).then(
                    response => {
                        if (response.code == 200) {
                            Toast({
                                message: "人员信息验证成功",
                                position: "middle",
                                duration: 1000
                            });

                            this.$router.replace({
                                path: '/HrDiagnosisTest',
                                query: {
                                    skillId: this.skillId
                                }
                            })
                        } else {
                            Toast(response.msg)
                        }
                    }
                );

            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .talent_all {
        padding: 10px;
    }

    .talent_header {
        padding: 10px;
        font-size: 18px;
        color: #333;
        font-weight: bold;
        text-align: center;
        line-height: 30px;
        margin-bottom: 30px;
    }

    .talent_button {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #13d1be;
        border: 0px;
    }

    .btnActive {
        background-color: #13d1be;
        border: 0px;
    }

    .btnNotActive {
        background-color: #b2b2b2;
        border: 0px;
    }
</style>
