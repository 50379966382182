<template>
    <div class="talent_review">
        <van-sticky>
            <van-tabs v-model="active" color="#13d1be" title-active-color="#13d1be" @click="selectType">
                <van-tab title="十二项素质"></van-tab>
                <van-tab title="人岗匹配"></van-tab>
                <van-tab title="专业水平"></van-tab>
                <van-tab title="技能评估"></van-tab>
                <van-tab title="价值观评估"></van-tab>
            </van-tabs>
        </van-sticky>
        <div v-if="type == 1 || type == 2">
            <div style="display: flex;background-color: white;justify-content: space-between;">
                <div class="title_left">盘点人数:{{reviewCount}}/{{reviewTotalCount}}</div>
                <van-dropdown-menu active-color="#13d1be" class="title_right">
                    <van-dropdown-item v-model="mSelected" :options="option" @change="changeValue()"/>
                </van-dropdown-menu>
            </div>
            <van-list
                    v-model="loading"
                    :finished="finished"
                    :immediate-check="false"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
                <div class="item" v-for="(item,index) in dataList" :key="index">
                    <div class="item_left" v-if="item.username&&item.username.length<2">
                        {{item.username.substring(item.username.length-1)}}
                    </div>
                    <div class="item_left" v-else-if="item.username">{{item.username.substring(item.username.length-2)}}</div>
                    <div style="display: flex;flex-direction: column;flex: 1">
                        <div style="display: flex;justify-content: space-between">
                            <div class="item_top_left">{{item.username}}</div>
                            <div class="item_top_right">{{item.departName}} - {{item.postName}}</div>
                        </div>
                        <div style="display: flex;justify-content: space-between">
                            <div class="item_bottom_left">发起时间:{{item.cTime}}</div>
                            <van-button class="item_bottom_right_1" round type="info" @click="toReview(item)">
                                {{item.status==0?"开始盘点":"已盘点"}}
                            </van-button>
                            <!--                        <div class="item_bottom_right_1" @click="toReview(item)">-->
                            <!--                            {{item.status==0?"开始盘点":"已盘点"}}-->
                            <!--                        </div>-->
                        </div>
                    </div>

                </div>
            </van-list>
            <!--        <no-result :title="'您还没有加入课程'" v-if="!study.myCourse.length"></no-result>-->
            <no-result :title="'暂无盘点'" v-if="!dataList.length"></no-result>
        </div>
        <skill-evaluation-list v-if="type == 3"></skill-evaluation-list>
        <skills-assessment-list v-if="type == 4"></skills-assessment-list>
        <value-assessment-list v-if="type == 5"></value-assessment-list>
        <div style="height: 50px"></div>
        <foot/>
    </div>

</template>

<script>

    import {DropdownMenu, DropdownItem, List, Tab, Tabs, Button, Sticky} from 'vant';
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import {startShart} from "@/common/js/tecentshare.js";
    import api from "@/apis/talentReview/talentReview.js";
    import Foot from "../components/public/x_foot.vue";
    import NoResult from "../components/commonly/noResult.vue";
    import skillEvaluationList from "@/views/page/TalentReview/SkillEvaluationList.vue";
    import SkillsAssessmentList from "@/views/page/TalentReview/SkillsAssessmentList.vue";
    import ValueAssessmentList from "@/views/page/TalentReview/ValueAssessmentList.vue";
    export default {
        name: "TalentReviewList",
        components: {
            [List.name]: List,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Button.name]: Button,
            [Sticky.name]: Sticky,
            Foot,
            NoResult,
            skillEvaluationList,
            SkillsAssessmentList,
            ValueAssessmentList
        },
        data() {
            return {
                type: 2,//1彩准 2菁准 3技能评价
                active: 0,
                mSelected: 2,//选项卡使用
                option: [
                    {text: '全部', value: 2},
                    {text: '已盘点', value: 1},
                    {text: '未盘点', value: 0}
                ],
                value: '',
                dataList: [],
                list: [],
                loading: false,
                finished: false,
                userId: 0,
                reviewId: '',
                officeId: 0,
                currPage: 1,
                pageSize: 10,
                totalPage: 1,
                totalCount: 0,
                reviewTotalCount: 0,
                reviewCount: 0,
                threeType: ''

            }
        },
        created() {
            document.title = localStorage.getItem('m_cloudName');
            this.officeId = localStorage.getItem('m_officeId');
            this.userId = localStorage.getItem('m_userId');
            this.reviewId = localStorage.getItem('m_reviewId');
            this.type = localStorage.getItem('m_type') ? localStorage.getItem('m_type') : 2;

            this.threeType = this.$route.query.threeType;
            if (this.threeType) {
                if (this.threeType == 10) {
                    //多准
                    this.type = 3
                } else if (this.threeType == 11) {
                    //菁准
                    this.type = 2
                } else if (this.threeType == 12) {
                    //彩准
                    this.type = 1
                } else if (this.threeType == 18) {
                    //管理技能评估
                    this.type = 4
                }else if (this.threeType == 19) {
                    //管理技能评估
                    this.type = 5
                }
            }
            if (this.type == 2) {
                //菁准
                this.active = 0
            } else if (this.type == 1) {
                //彩准
                this.active = 1
            } else if (this.type == 3) {
                //技能评价
                this.active = 2
            } else if (this.type == 4) {
                //技能评价
                this.active = 3
            } else if (this.type == 5) {
                //价值观评价
                this.active = 4
            }
            if (this.type != 3&&this.type != 4&&this.type != 5) {
                this.getReviewList()//默认菁准
            }
        },
        mounted() {
            this.shareContent();
        },
        activated() {

        },
        methods: {
            selectType(title) {
                this.currPage = 1;
                this.mSelected = 2;
                if (title == 0) {
                    this.type = 2
                } else if (title == 1) {
                    this.type = 1
                } else if (title == 2) {
                    this.type = 3
                } else if (title == 3) {
                    this.type = 4
                }else if (title == 4) {
                    this.type = 5
                }
                if (this.type == 1 || this.type == 2) {
                    localStorage.setItem("m_type", this.type);
                    this.getReviewList()
                }
            },
            changeValue() {
                this.currPage = 1;
                this.getReviewList()
            },
            getReviewList() {
                let reqData = {
                    officeId: this.officeId,
                    userId: this.userId,
                    status: this.mSelected,
                    page: this.currPage,
                    limit: this.pageSize,
                    type: this.type
                };
                if (this.reviewId) {
                    reqData.reviewId = this.reviewId
                }
                api.getReviewList(reqData).then(
                    response => {
                        this.loading = false;
                        if (response.code == 200) {
                            if (this.currPage == 1) {
                                this.dataList = [];
                            }
                            this.dataList.push(...response.result.list.list);
                            this.pageSize = response.result.list.pageSize;
                            this.totalPage = response.result.list.totalPage;
                            this.currPage = response.result.list.currPage;
                            this.totalCount = response.result.list.totalCount;
                            this.reviewTotalCount = response.result.totalCount;
                            this.reviewCount = response.result.reviewCount;
                        }
                    }
                )
            },
            toReview(val) {
                this.$router.push({
                    path: '/IntroducePage',
                    query: {
                        dimension: val.dimension,
                        reviewId: val.reviewId,
                        type: val.type,
                        userId: this.userId,
                        id: val.id,
                        username: val.username,
                        reviewUserId: val.reviewUserId,
                        pcUserId: val.userId
                    }
                })
            },
            onLoad() {
                // 异步更新数据
                this.loading = true;
                if (this.currPage < this.totalPage) {
                    this.currPage += 1;
                    this.getReviewList()
                } else {
                    this.finished = true;
                    this.loading = false;
                }
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .talent_review {
        display: flex;
        flex-direction: column;
        background: white;
    }

    .title_left {
        padding: 1rem;
        line-height: 3rem;
        font-size: 1.5rem;
        color: #333;
    }

    .title_right {
        padding-right: 1rem;
        font-size: 1rem;
        color: #333;
    }

    .item {
        display: flex;
        align-items: center;
        opacity: 1;
        margin: 1rem;
        padding: 1rem;
        background: #ffffff;
        border-radius: 1rem;
        box-shadow: 0px .4rem 1rem 0px rgba(0, 0, 0, 0.20);
    }

    .item_left {
        width: 6rem;
        height: 6rem;
        color: white;
        display: flex;
        font-size: 2rem;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        background: linear-gradient(180deg, #6c97ff, #3f72ec);
        border-radius: 4px;
    }

    .item_top_left {
        padding: 0 0 0 1rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #333;
        line-height: 4rem;
    }

    .item_top_right {
        font-size: 1.2rem;
        color: #333;
        line-height: 4rem;
    }

    .item_bottom_left {
        padding: 1rem;
        font-size: 1.2rem;
        color: #333;
    }

    .item_bottom_right_1 {
        width: 8rem;
        height: 3rem;
        text-align: center;
        font-size: 1.1rem;
        background-color: #13d1be;
    }

    .item_bottom_right_2 {
        float: right;
        padding: 10px;
        font-size: 14px;
        color: #333;
        border-radius: 5px;
        border: 1px solid #13d1be;
    }
</style>
