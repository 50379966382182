<template>
    <div class="cover">
        <div class="parentWrap">
            <div class="parent">
                <div
                        class="child"
                        :class="currentSelect == 1 ? 'itemSelect' : ''"
                        @click="choseItem(1)"
                >
                    {{ item1.year ? item1.year : "年份" }}
                </div>
                <div
                        class="child"
                        :class="currentSelect == 2 ? 'itemSelect' : ''"
                        @click="choseItem(2)"
                >
                    {{ item2.year ? item2.year : "年份" }}
                </div>
                <div
                        class="child"
                        :class="currentSelect == 3 ? 'itemSelect' : ''"
                        @click="choseItem(3)"
                >
                    {{ item3.year ? item3.year : "年份" }}
                </div>
                <div
                        class="child"
                        :class="currentSelect == 4 ? 'itemSelect' : ''"
                        @click="choseItem(4)"
                >
                    {{ item4.year ? item4.year : "年份" }}
                </div>
            </div>
        </div>
        <van-form @submit="doSubmit" validate-trigger="onSubmit">
            <van-cell-group>
                <!-- 输入任意文本 -->
                <van-field
                        v-model="currentItem.year"
                        required
                        readonly
                        label="年份"
                        placeholder="请选择年份"
                        input-align="right"
                        :rules="[{ required: true, message: '' }]"
                        @click="showYearPicker = true"
                >
                </van-field>
                <van-popup v-model="showYearPicker" position="bottom">
                    <van-datetime-picker
                            v-model="currentDate"
                            type="year"
                            title="选择年份"
                            :min-date="minDate"
                            :max-date="maxDate"
                            @cancel="showYearPicker = false"
                            @confirm="onConfirmYear"
                    />
                </van-popup>
                <van-field
                        maxlength="9"
                        v-model="currentItem.income"
                        required
                        type="text"
                        label="收入(w)"
                        placeholder="请输入金额(w)"
                        input-align="right"
                        :rules="[{ required: true, message: '' }]"
                        @blur="blur"
                        @input="inputChange(1)"
                >
                </van-field>
                <van-field
                        maxlength="9"
                        v-model="currentItem.profit"
                        required
                        type="text"
                        label="净利润(w)"
                        placeholder="请输入金额(w)"
                        input-align="right"
                        :rules="[{ required: true, message: '' }]"
                        @blur="blur"
                        @input="inputChange(2)"
                >
                </van-field>
                <van-field
                        maxlength="9"
                        v-model="currentItem.averagePeoples"
                        required
                        type="digit"
                        label="平均人数"
                        placeholder="请输入人数"
                        input-align="right"
                        :rules="[{ required: true, message: '' }]"
                        @blur="blur"
                >
                </van-field>
                <van-field
                        v-model="currentItem.averageIncome"
                        required
                        readonly
                        label="人均收入(w)"
                        placeholder="非输入"
                        input-align="right"
                        @blur="blur"
                >
                </van-field>
                <van-field
                        v-model="currentItem.averageProfit"
                        required
                        readonly
                        label="人均利润(w)"
                        placeholder="非输入"
                        input-align="right"
                        @blur="blur"
                >
                </van-field>
                <van-field
                        maxlength="9"
                        v-model="currentItem.grossprofit"
                        required
                        type="text"
                        label="毛利润(w)"
                        placeholder="请输入金额(w)"
                        input-align="right"
                        :rules="[{ required: true, message: '' }]"
                        @blur="blur"
                        @input="inputChange(3)"
                >
                </van-field>
                <van-field
                        label-width="8em"
                        maxlength="9"
                        v-model="currentItem.emolumentCost"
                        required
                        type="text"
                        label="薪酬成本总额(w)"
                        placeholder="请输入金额(w)"
                        input-align="right"
                        :rules="[{ required: true, message: '' }]"
                        @blur="blur"
                        @input="inputChange(4)"
                >
                </van-field>
                <van-field
                        v-model="currentItem.emolumentForSale"
                        required
                        readonly
                        label="薪酬占比销售"
                        placeholder="非输入"
                        input-align="right"
                        @blur="blur"
                >
                </van-field>
                <van-field
                        v-model="currentItem.emolumentForGrossprofit"
                        required
                        readonly
                        label="薪酬占比毛利"
                        placeholder="非输入"
                        input-align="right"
                        @blur="blur"
                >
                </van-field>
                <van-field
                        label-width="8em"
                        v-model="currentItem.humanCostAverage"
                        required
                        readonly
                        label="人均人力成本(w)"
                        placeholder="非输入"
                        input-align="right"
                        @blur="blur"
                >
                </van-field>
                <div class="talent_button">
                    <van-button
                            block
                            type="info"
                            native-type="submit"
                            :disabled="isForbid"
                            color="#13D1BE"
                    >保存
                    </van-button
                    >
                </div>
            </van-cell-group>
        </van-form>
    </div>
</template>

<script>
    import globalFunction from "@/apis/utils/globalFunction.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import {startShart} from "@/common/js/tecentshare.js";
    import api1 from "@/apis/talentReview/HumanEffect.js";
    import api2 from "@/apis/talentReview/talentReview.js";
    import storage from "@/common/js/cacheData";
    import {
        Toast,
        Field,
        Button,
        Popup,
        Form,
        DatetimePicker,
        CellGroup
    } from "vant";

    export default {
        name: "HumanEffect",
        components: {
            [Toast.name]: Toast,
            [Button.name]: Button,
            [Popup.name]: Popup,
            [Form.name]: Form,
            [Field.name]: Field,
            [DatetimePicker.name]: DatetimePicker,
            [CellGroup.name]: CellGroup
        },
        data() {
            return {
                currentYear: "",
                currentSelect: 1,
                currentItem: {},
                item1: {},
                item2: {},
                item3: {},
                item4: {},
                showYearPicker: false,
                isForbid: false,
                minDate: new Date(),
                maxDate: new Date(),
                currentDate: new Date()
            };
        },
        created() {
            document.title = "人效数据";
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            this.minDate = this.getPassYearFormatDate();
            this.showIfBindPhone();
        },
        mounted() {
            this.shareContent();
        },
        methods: {
            inputChange(type) {
                var obj = event.target;
                var t = obj.value.charAt(0);
                obj.value = obj.value.replace(".", "$#$")//把第一个字符'.'替换成'$#$'
                    .replace(/\./g, "")//把其余的字符'.'替换为空
                    .replace("$#$", ".")//把字符'$#$'替换回原来的'.'
                    .replace(/[^\d.]/g, "")//只能输入数字和'.'
                    .replace(/^\./g, "")//不能以'.'开头
                    .replace(/([0-9]+\.[0-9]{2})[0-9]*/, "$1")//只保留2位小数
            
                if(type == 2){
                    //只有利润可以为负
                    if (t == '-') {
                    obj.value = '-' + obj.value;
                }
                }
                if (type == 1) {
                    this.currentItem.income = obj.value;
                } else if (type == 2) {
                    this.currentItem.profit = obj.value;
                } else if (type == 3) {
                    this.currentItem.grossprofit = obj.value;
                } else if (type == 4) {
                    this.currentItem.emolumentCost = obj.value;
                }
            },
            showIfBindPhone() {
                api2._checkOfficeReviewRecord().then((response) => {
                    if (response.code == 200) {
                        //0 未填写  1 已经填写 2 未扫码
                        let status = response.result.status;
                        if (status == 0) {
                            Toast("请填写个人信息");
                            this.$router.push({
                                path: "/jingzhunInfoInput"
                            });
                        } else if (status == 1) {
                            if (response.result.isAddress==1){
                                //isAddress=1代表之前没填写行业地区
                                Toast('请完善个人信息');
                                this.$router.push({
                                    path: '/jingzhunInfoInput',
                                    query: {
                                        isAddress: 1,
                                        username: response.result.username,
                                        id: response.result.id
                                    }
                                })
                            }else {
                                this.getList();
                            }

                        } else if (status == 2) {
                            Toast("请扫码");
                        }
                    }
                });
            }
            ,
            //点击item
            choseItem(value) {
                this.currentSelect = value;
                if (value == 1) {
                    this.currentItem = this.item1;
                }
                if (value == 2) {
                    this.currentItem = this.item2;
                }
                if (value == 3) {
                    this.currentItem = this.item3;
                }
                if (value == 4) {
                    this.currentItem = this.item4;
                }
            }
            ,
            //获取列表
            getList() {
                this.item1 = {};
                this.item2 = {};
                this.item3 = {};
                this.item4 = {};
                this.currentSelect = 1;
                this.currentItem = this.item1;
                api1.getHumanEffectList().then((response) => {
                    if (response.code == 200) {
                        let list = response.result;
                        if (list.length >= 1) {
                            this.item1 = list[0];
                        }
                        if (list.length >= 2) {
                            this.item2 = list[1];
                        }
                        if (list.length >= 3) {
                            this.item3 = list[2];
                        }
                        if (list.length >= 4) {
                            this.item4 = list[3];
                        }
                        this.currentItem = this.item1;
                        this.getSelectYear();
                    }
                });
            }
            ,
            getSelectYear() {
                if (this.currentYear != "" && this.currentYear != undefined) {
                    if (this.currentYear == this.item1.year) {
                        this.currentSelect = 1;
                        this.currentItem = this.item1;
                    }
                    if (this.currentYear == this.item2.year) {
                        this.currentSelect = 2;
                        this.currentItem = this.item2;
                    }
                    if (this.currentYear == this.item3.year) {
                        this.currentSelect = 3;
                        this.currentItem = this.item3;
                    }
                    if (this.currentYear == this.item4.year) {
                        this.currentSelect = 4;
                        this.currentItem = this.item4;
                    }
                }
            }
            ,
            blur() {
                if (this.currentItem.profit == "-") {
                    this.currentItem.profit = "";
                }
                if (this.currentItem.grossprofit == "-") {
                    this.currentItem.grossprofit = "";
                }
                if(this.currentItem.income == 0){
                   this.currentItem.income = "0";
                }
                if(this.currentItem.profit == 0){
                   this.currentItem.profit = "0";
                }
                if(this.currentItem.averagePeoples == 0){
                   this.currentItem.averagePeoples = "";
                }
                if(this.currentItem.grossprofit == 0){
                   this.currentItem.grossprofit = "0";
                }
                if(this.currentItem.emolumentCost == 0){
                   this.currentItem.emolumentCost = "";
                }
                //人均收入
                if (this.currentItem.averagePeoples == "0") {
                    this.currentItem.averageIncome = "0";
                } else if (
                    this.currentItem.income != "" &&
                    this.currentItem.income != undefined &&
                    this.currentItem.averagePeoples != "" &&
                    this.currentItem.averagePeoples != undefined
                ) {
                    this.currentItem.averageIncome =
                        this.currentItem.income / this.currentItem.averagePeoples;
                    this.currentItem.averageIncome = parseFloat(
                        this.currentItem.averageIncome
                    ).toFixed(2);
                } else {
                    this.currentItem.averageIncome = "";
                }
                //人均利润
                if (this.currentItem.averagePeoples == "0") {
                    this.currentItem.averageProfit = "0";
                } else if (
                    this.currentItem.profit != "" &&
                    this.currentItem.profit != undefined &&
                    this.currentItem.averagePeoples != "" &&
                    this.currentItem.averagePeoples != undefined
                ) {
                    this.currentItem.averageProfit =
                        this.currentItem.profit / this.currentItem.averagePeoples;
                    this.currentItem.averageProfit = parseFloat(
                        this.currentItem.averageProfit
                    ).toFixed(2);
                } else {
                    this.currentItem.averageProfit = "";
                }
                //薪酬占比销售
                if (this.currentItem.income == "0") {
                    this.currentItem.emolumentForSale = "0.00%";
                } else if (
                    this.currentItem.emolumentCost != "" &&
                    this.currentItem.emolumentCost != undefined &&
                    this.currentItem.income != "" &&
                    this.currentItem.income != undefined
                ) {
                    this.currentItem.emolumentForSale =
                        this.currentItem.emolumentCost / this.currentItem.income;
                    this.currentItem.emolumentForSale =
                        this.currentItem.emolumentForSale * 100;
                    this.currentItem.emolumentForSale = parseFloat(
                        this.currentItem.emolumentForSale
                    ).toFixed(2);
                    this.currentItem.emolumentForSale =
                        this.currentItem.emolumentForSale + "%";
                } else {
                    this.currentItem.emolumentForSale = "";
                }
                //薪酬占比毛利
                if (this.currentItem.grossprofit == "0") {
                    this.currentItem.emolumentForGrossprofit = "0.00%";
                } else if (
                    this.currentItem.emolumentCost != "" &&
                    this.currentItem.emolumentCost != undefined &&
                    this.currentItem.grossprofit != "" &&
                    this.currentItem.grossprofit != undefined
                ) {
                    this.currentItem.emolumentForGrossprofit =
                        this.currentItem.emolumentCost / this.currentItem.grossprofit;
                    this.currentItem.emolumentForGrossprofit =
                        this.currentItem.emolumentForGrossprofit * 100;
                    this.currentItem.emolumentForGrossprofit = parseFloat(
                        this.currentItem.emolumentForGrossprofit
                    ).toFixed(2);
                    this.currentItem.emolumentForGrossprofit =
                        this.currentItem.emolumentForGrossprofit + "%";
                } else {
                    this.currentItem.emolumentForGrossprofit = "";
                }
                //人均人力成本
                if (this.currentItem.averagePeoples == "0") {
                    this.currentItem.humanCostAverage = "0";
                } else if (
                    this.currentItem.emolumentCost != "" &&
                    this.currentItem.emolumentCost != undefined &&
                    this.currentItem.averagePeoples != "" &&
                    this.currentItem.averagePeoples != undefined
                ) {
                    this.currentItem.humanCostAverage =
                        this.currentItem.emolumentCost / this.currentItem.averagePeoples;
                    this.currentItem.humanCostAverage = parseFloat(
                        this.currentItem.humanCostAverage
                    ).toFixed(2);
                } else {
                    this.currentItem.humanCostAverage = "";
                }
                this.$forceUpdate();
            }
            ,
            //10年前的日期
            getPassYearFormatDate() {
                let nowDate = new Date();
                let date = new Date(nowDate);
                date.setDate(date.getDate() - 365 * 10);
                return new Date(date.getFullYear(), date.getMonth(), date.getDate());
            }
            ,
            onConfirmYear(value) {
                let year = this.currentDate.getFullYear();
                if (
                    this.item1.year == year ||
                    this.item2.year == year ||
                    this.item3.year == year ||
                    this.item4.year == year
                ) {
                    Toast("年份重复请重新选择");
                } else {
                    this.showYearPicker = false;
                    this.currentItem.year = year;
                }
            }
            ,
            //保存
            doSubmit() {
                 if(this.currentItem.grossprofit - this.currentItem.income >= 0){
                    Toast("毛利润须小于收入，请重新输入"); 
                    return;  
                 }
                 if(this.currentItem.profit - this.currentItem.grossprofit >= 0){
                    Toast("净利润须小于毛利润，请重新输入");
                    return;  
                 }
                 if(this.currentItem.emolumentCost - this.currentItem.grossprofit >= 0){
                    Toast("薪酬成本总额须小于毛利润，请重新输入"); 
                    return;  
                 }
                let reqData = this.currentItem;
                this.isForbid = true;
                api1.saveHumanEffectData(reqData).then((response) => {
                    this.isForbid = false;
                    if (response.code == 200) {
                        this.currentYear = this.currentItem.year;
                        Toast("保存成功");
                        this.getList();
                    }
                });
            }
            ,
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
    ;
</script>

<style scoped>
    .talent_button {
        width: 100% -20px;
        border: 1px solid #28f1dd;
        border-radius: 5px;
        overflow: hidden;
        margin: 10px;
        background-color: white;
    }

    .cover {
        padding: 20px;
        background-color: #f4f4f4;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        bottom: 0px;
    }

    .parentWrap {
        overflow: hidden;
        margin-bottom: 15px;
    }

    .parent {
        margin-right: -10px;
        overflow: hidden;
        background-color: #f4f4f4;
    }

    .child {
        float: left;
        height: 35px;
        line-height: 35px;
        text-align: center;
        width: 25%;
        padding-right: 10px;
        box-sizing: border-box;
        background-clip: content-box;
        font-size: 16px;
        background-color: white;
    }

    .itemSelect {
        color: #28f1dd;
        font-weight: bold;
    }
</style>