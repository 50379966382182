<template>
    <div>
        <van-form @submit="doSubmit" validate-trigger="onSubmit">
            <van-cell-group>
                <!-- 输入任意文本 -->
                <van-field
                        v-model="name"
                        required
                        label="姓名"
                        placeholder="请输入姓名"
                        :rules="[{ required: true, message: '请输入姓名' }]"
                >
                </van-field>
                <!-- 输入手机号，调起手机号键盘 -->
                <van-field
                        v-model="tel"
                        required
                        type="tel"
                        label="手机号"
                        placeholder="请输入手机号"
                        :rules="[{ required: true, message: '请输入手机号' },
                    { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}]"
                >
                </van-field>
                <van-field
                        v-model="postName"
                        label="职位"
                        required
                        placeholder="请输入当前职位"
                        :rules="[{ required: true, message: '请输入当前职位' }]"
                >
                </van-field>
                <van-field
                        readonly
                        clickable
                        required
                        name="picker"
                        :value="companyScale"
                        label="公司规模"

                        :rules="[{ required: true, message: '请选择公司规模' }]"
                        placeholder="点击选择公司规模"
                        @click="showScalePicker = true">
                </van-field>
                <van-popup v-model="showScalePicker" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="companyScaleColumns"
                            @confirm="onConfirmScale"
                            @cancel="showScalePicker = false">
                    </van-picker>
                </van-popup>
                <van-field
                        readonly
                        clickable
                        name="area"
                        required
                        :value="selectedArea"
                        label="地区选择"
                        placeholder="点击选择省市区"
                        :rules="[{ required: true, message: '请选择省市区' }]"
                        @click="showArea = true">
                </van-field>
                <van-popup v-model="showArea" position="bottom">
                    <van-area
                            :area-list="areaList"
                            :columns-num="2"
                            @confirm="onConfirmCity"
                            @cancel="showArea = false">
                    </van-area>
                </van-popup>
                <van-field
                        readonly
                        clickable
                        name="picker"
                        required
                        :value="industry"
                        label="所属行业"
                        :rules="[{ required: true, message: '请选择所属行业' }]"
                        placeholder="点击选择所属行业"
                        @click="showIndustryPicker = true">
                </van-field>
                <van-popup v-model="showIndustryPicker" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="industryColumns"
                            :columns-num="2"
                            @confirm="onConfirmIndustry"
                            @cancel="showIndustryPicker = false">
                    </van-picker>
                </van-popup>
                <van-field
                        style="margin-bottom: 44px"
                        v-model="demand"
                        label="我的需求"
                        rows="1"
                        autosize
                        type="textarea"
                        placeholder="请输入您的需求"
                >
                </van-field>
                <div class="talent_button">
                    <van-button block type="info" native-type="submit" :disabled="isForbid">提交</van-button>
                </div>
            </van-cell-group>
        </van-form>
    </div>
</template>

<script>
    import areaList from '@/assets/Area.js';
    import industryList from '@/assets/hangye.json';
    import {ShareLink,ShareLogo} from "@/apis/const.js";
    import {startShart} from "@/common/js/tecentshare.js";
    // import areaList from '@/assets/areaCode.json'


    import {Toast, Field, CellGroup, Button, Picker, Popup, Area, Form} from 'vant'
    import api from "@/apis/talentReview/talentReview.js";

    export default {
        name: "DemandStatistics",
        components: {
            [Toast.name]: Toast,
            [CellGroup.name]: CellGroup,
            [Button.name]: Button,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Area.name]: Area,
            [Form.name]: Form,
            [Field.name]: Field
        },
        data() {
            return {
                tel: '',
                name: '',
                postName: '',
                industry: '',
                companyScale: '',
                selectedArea: '',
                demand: '',
                industryColumns: [],
                companyScaleColumns: ['100人以下', '100-300人', '300-500人', '500-1000人', '1000-3000人', '3000-5000人', '5000-10000人', '10000以上'],
                showScalePicker: false,
                showIndustryPicker: false,
                showArea: false,
                areaList: {},
                isForbid: false
            }
        },
        created() {
            document.title = 'HR COE系统试用信息采集';
            this.getDataList()
        },
        mounted() {
            this.shareContent();
        },
        methods: {
            getDataList() {
                this.areaList = areaList;
                // this.mapTree(industryList);
                this.industryColumns = industryList;
            },
            mapTree(data) {
                data.map(item => {    //遍历树
                    item.text = item.name;
                    if (item.children) {
                        this.mapTree(item.children)
                    }
                });
            },
            onConfirmScale(value) {
                this.companyScale = value;
                this.showScalePicker = false;
            },
            onConfirmIndustry(value) {
                this.industry = value.toString();
                this.showIndustryPicker = false;
            },
            onConfirmCity(values) {
                this.selectedArea = values
                    .filter((item) => !!item)
                    .map((item) => item.name)
                    .join('/');
                this.showArea = false;
            },
            doSubmit() {
                let reqData = {
                    name: this.name,
                    cellphone: this.tel,
                    postName: this.postName,
                    scaleCompany: this.companyScale,
                    cityName: this.selectedArea,
                    industry: this.industry,
                    demand: this.demand
                };
                this.isForbid = true;
                api._addCustomer(reqData).then(
                    response => {
                        this.isForbid = false;
                        if (response.code == 200) {
                            Toast('信息提交成功')
                        }
                    }
                )
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }

    }
</script>

<style scoped>

    .talent_button {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        z-index: 3;
        background-color: #13d1be;
        border: 0px;
    }
</style>