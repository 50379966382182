import {
    get,
    post,
    postJson,
    postform
} from "../utils/base";

// import userCache from '@/common/js/userCache'
function _opts(isShowLoading) {
    var opt = {
        cache: {
            exp: 60
        },
        silent: false
    };
    if (isShowLoading) {
        return Object.assign(opt, {
            progress: true
        })
    }
}

export default {
    //人效列表
    getHumanEffectList(param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('hrCoe/review/selectHrTalentsAnalyzeList', params, opts)
    },
    //保存人效数据
    saveHumanEffectData(param) {
        const opts = _opts(true);
        let params = param ? param : {};
        return post('/hrCoe/review/addHrTalentsAnalyze', params, opts)
    }
}