<template>
    <div class="zhx_container" v-if="resultData">

        <div v-if="resultData.deptName" class="m_name">{{resultData.deptName+'-'+resultData.name}}</div>
        <div v-else class="m_name">{{resultData.name}}</div>
        <div class='question_box'>
            <div class="title-view">
                <div class="title_1">综合得分：</div>
                <div class="title_2">{{resultData.totalscore}}分</div>
            </div>
            <div class="title_3">测评时间：{{resultData.createTime}}</div>
            <div class="table">
                <div class="tr bg-w">
                    <div class="th_left">干部述职</div>
                    <div class="th_right">得分</div>
                </div>
                <div v-for="(item,index) in resultData.list" :key="index+'aa'">
                    <div class="tr" :class="index%2==1?'bg-g':''">
                        <div class="td_left">{{item.title}}</div>
                        <div class="td_right">{{item.score}}</div>
                    </div>
                </div>
            </div>
        </div>
                <div class="bottombtn" @click="clickBtn()">重新评审</div>
    </div>
</template>

<script>
    //引入api

    import api from "@/apis/talentReview/talentReview.js";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import storage from "@/common/js/cacheData";

    export default {
        name: 'CadreReportResult',
        data() {
            return {
                //测试结果
                resultData: {
                    list: []
                },
                assessUserId: null,
                intervalId: null,
                userId: null,
                officeId: null
            };
        },


        methods: {
            clickBtn() {
                let that = this;
                that.$router.replace({
                    path: '/CadreReportItem',
                    query: {
                        assessUserId: that.assessUserId,
                        userId: that.userId,
                        officeId: that.officeId,
                        intervalId:that.intervalId,
                        deptName: that.resultData.deptName,
                        username: that.resultData.name
                    }
                })
                // that.$router.go(-1); //返回上一层
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            },
            //初始化获取测评结果
            _getTestResult() {
                let that = this;
                api.cadresScore_cadresResult(
                    {
                        assessUserId: this.assessUserId,
                        intervalId: this.intervalId,
                        userId: this.userId
                    }
                ).then(
                    response => {
                        that.resultData = response.result;
                    },
                    res => {
                    }
                );
            }
        },
        created() {
            let that = this;
            let openId = that.$route.query.openId;
            that.userId = that.$route.query.userId;
            that.assessUserId = that.$route.query.assessUserId;
            that.intervalId = that.$route.query.intervalId;
            that.officeId = that.$route.query.officeId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            document.title = "述职结果";
            that._getTestResult();

        },
        mounted() {
            let that = this;
            that.shareContent();
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .zhx_container {
        /*width: 100vw;*/
        /*height: 100vh;*/
        padding: 2rem;
    }

    .m_name {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        text-align: left;
        margin-bottom: 1.5rem;
        margin-top: 1rem;
    }

    .question_box {

        /*position: relative;*/
        color: #333333;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        /*top: 50%;*/
        /*transform: translate(0, -50%);*/
    }

    .title-view {
        display: flex;
        padding-top: 2rem;
        align-items: center
    }

    .title_1 {
        padding-left: 1.5rem;
        font-weight: bold;
        font-size: 16px;
    }

    .title_2 {
        color: #13D1BE;
        font-weight: bold;
        font-size: 16px;
    }

    .title_3 {
        padding-left: 1.5rem;
        padding-top: 1.5rem;
        color: #666666;
        font-size: 14px;
    }

    .table {
        border-top: 1px solid #9DE0D9;
        border-left: 1px solid #9DE0D9;
        margin: 2.5rem 1.5rem 2.5rem 1.5rem;
    }

    .tr {
        display: flex;
        width: 100%;
        justify-content: center;
        height: 40px;
        line-height: 40px;
        align-items: center;
        border-bottom: 1px solid #9DE0D9;
    }

    .td_left {
        width: 70%;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        border-right: 1px solid #9DE0D9;
    }

    .td_right {
        width: 30%;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        border-right: 1px solid #9DE0D9;
    }

    .bg-w {
        background: snow;
    }

    .bg-g {
        background: #EDFAF9;
    }

    .th_left {
        width: 70%;
        justify-content: center;
        border-bottom: 1px solid #9DE0D9;
        border-right: 1px solid #9DE0D9;
        background: #C3EFEA;
        color: #333;
        font-weight: bold;
        display: flex;
        height: 40px;
        font-size: 14px;
        align-items: center;
    }

    .th_right {
        width: 30%;
        justify-content: center;
        border-bottom: 1px solid #9DE0D9;
        border-right: 1px solid #9DE0D9;
        background: #C3EFEA;
        color: #333;
        font-weight: bold;
        display: flex;
        height: 40px;
        font-size: 14px;
        align-items: center;
    }

    .bottombtn {
        position: fixed;
        top: 2vh;
        right: 0px;
        background-color: #c32d2c;
        height: 4rem;
        line-height: 4rem;
        font-size: 14px;
        color: #fff;
        border: 1px solid #e1999a;
        padding: 0;
        width: 120px;
        text-align: center;
        border-top-left-radius: 3.5vh;
        border-bottom-left-radius: 3.5vh;
        border-right: none;
    }
</style>
