<template>
    <div>
        <div class="box_container mrb50">
            <div class="item_container">
                <div class="left_title">姓名:</div>
                <input class="m_input" v-model="staff.name"
                       :onkeyup="staff.name = staff.name.replace(/\s+/g,'')"
                       placeholder="请输入姓名"/>
            </div>
            <div class="item_container">
                <div class="left_title">工号:</div>
                <input class="m_input" v-model="staff.jobNumber"
                       :onkeyup="staff.jobNumber = staff.jobNumber.replace(/\s+/g,'')"
                       placeholder="请输入工号"/>
            </div>
            <div class="item_container">
                <div class="left_title">照片:</div>

                <div class="img_bac" v-if="staff.headPortrait" @click="handleUpload">
                    <img class="delete_img" src="../../../static/image/delete_img.png" @click.stop="handleDeleteImg"/>
                    <img class="img_content" :src="staff.headPortrait"/>
                </div>
                <div class="img_bac" v-else @click="handleUpload">
                    <div class="img_tip_1">+</div>
                    <div class="img_tip_2">添加图片</div>
                </div>
                <div class="img_tip">(建议上传一寸照片）</div>
            </div>
            <div class="item_container">
                <div class="left_title">性别:</div>
                <van-radio-group v-model="staff.sex" direction="horizontal">
                    <van-radio name="1">
                        <span class="radio_text">男</span>
                        <template #icon="props">
                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                        </template>
                    </van-radio>
                    <van-radio name="0">
                        <span class="radio_text">女</span>
                        <template #icon="props">
                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                        </template>
                    </van-radio>
                </van-radio-group>
            </div>
            <div class="item_container">
                <div class="left_title">政治面貌:</div>
                <van-radio-group v-model="staff.politicalOutlook" direction="horizontal">
                    <van-radio name="党员">
                        <span class="radio_text">党员</span>
                        <template #icon="props">
                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                        </template>
                    </van-radio>
                    <van-radio name="民主人士">
                        <span class="radio_text">民主人士</span>
                        <template #icon="props">
                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                        </template>
                    </van-radio>
                    <van-radio name="无党派">
                        <span class="radio_text">无党派</span>
                        <template #icon="props">
                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                        </template>
                    </van-radio>
                </van-radio-group>
            </div>
            <div class="item_container">
                <div class="left_title">入司时间:</div>
                <div class="m_input m_placeholder"
                     :class="staff.enterCompanyTime=='请选择入司时间'?'m_placeholder':'m_placeholder_2'"
                     @click="showRusiTime = true">{{staff.enterCompanyTime}}
                    <img class="arrow" src="../../../static/image/arrow_right_img.png"/>
                </div>
                <van-popup v-model="showRusiTime" position="bottom">
                    <van-datetime-picker
                            type="date"
                            title="选择入司时间"
                            :min-date="minDate"
                            :formatter="formatter"
                            @confirm="onConfirmRusiTime"
                            @cancel="showRusiTime = false"
                    />
                </van-popup>
            </div>
            <div class="item_container">
                <div class="left_title">籍贯:</div>
                <input class="m_input" v-model="staff.nativePlace"
                       :onkeyup="staff.nativePlace = staff.nativePlace.replace(/\s+/g,'')"
                       placeholder="请输入籍贯"/>
            </div>
            <div class="item_container">
                <div class="left_title">民族:</div>
                <div class="m_input m_placeholder" :class="staff.nation=='请选择民族'?'m_placeholder':'m_placeholder_2'"
                     @click="showNation = true">{{staff.nation}}
                    <img class="arrow" src="../../../static/image/arrow_right_img.png"/>
                </div>

                <van-popup v-model="showNation" position="bottom">
                    <van-picker
                            title="选择民族"
                            show-toolbar
                            :columns="nationList"
                            @confirm="onConfirmNation"
                            @cancel="showNation = false"/>
                </van-popup>
            </div>
            <div class="item_container">
                <div class="left_title">身份证号:</div>
                <input class="m_input" v-model="staff.idCard"
                       :onkeyup="staff.idCard = staff.idCard.replace(/[\W]/g,'')"
                       placeholder="请输入身份证号"/>
            </div>
            <div class="item_container">
                <div class="left_title">手机号:</div>
                <input class="m_input phone_bac" v-model="staff.mobile"
                       disabled="disabled"
                       placeholder="手机号不可编辑"/>
            </div>
            <div class="item_container">
                <div class="left_title">工资卡:</div>
                <input class="m_input" v-model="staff.bankOfDeposit"
                       :onkeyup="staff.bankOfDeposit = staff.bankOfDeposit.replace(/\s+/g,'')"
                       placeholder="请输入开户行"/>
            </div>
            <div class="item_container">
                <div class="left_title">卡号:</div>
                <input class="m_input" v-model="staff.bankCardNumber" :maxlength="20"
                       @keyup="staff.bankCardNumber = staff.bankCardNumber.replace(/[^\d]/g,'')"
                       placeholder="请输入卡号"/>
            </div>
            <div class="item_container">
                <div class="left_title">家庭住址:</div>
                <input class="m_input" v-model="staff.address"
                       :onkeyup="staff.address = staff.address.replace(/\s+/g,'')"
                       placeholder="请输入家庭住址"/>
            </div>
        </div>
        <input id="file" hidden type="file" @change="chooseImg" accept="image/png, image/jpeg"/>
        <div class="btnCover">
            <div class="submit_btn" @click="handleSubmit">保存并进入下一步</div>
        </div>

    </div>

</template>

<script>

    import {Area, Popup, DatetimePicker, RadioGroup, Radio, Picker, Toast} from "vant";
    import nationList from '@/assets/minzu.js';
    import api from "@/apis/PersonnelFiles/personnelFiles.js";

    export default {
        name: "BaseInfo",
        data() {
            return {
                staff: {
                    name: '',
                    jobNumber: '',//工号
                    headPortrait: '',//照片
                    sex: '',//性别
                    politicalOutlook: '',//政治面貌
                    enterCompanyTime: '请选择入司时间',//入司时间
                    nativePlace: '',//籍贯
                    nation: '请选择民族',//民族
                    idCard: '',//身份证号
                    mobile: '',//手机号
                    bankOfDeposit: '',//开户行
                    bankCardNumber: '',//银行卡号
                    address: ''//家庭住址
                },
                imgFile: '',
                nationList: [],
                showRusiTime: false,
                showNation: false,
                minDate: new Date(2000, 0, 1),
                maxDate: new Date(2025, 10, 1),
                activeIcon: require('../../../static/image/circle_selected.png'),
                inactiveIcon: require('../../../static/image/circle_un_selected.png')
            }
        },
        components: {
            [Popup.name]: Popup,
            [DatetimePicker.name]: DatetimePicker,
            [Area.name]: Area,
            [Picker.name]: Picker,
            [Radio.name]: Radio,
            [RadioGroup.name]: RadioGroup
        },
        created() {
            nationList.forEach(it => {
                it.text = it.name
            });
            this.nationList = nationList;
            this.getData();
        },
        methods: {
            getData() {
                api.hrCoe_performance_staffInfo({
                    staffType: 1
                }).then(
                    response => {
                        if (response.code == 200) {
                            let staffObj = response.result.staff;
                            if (staffObj.name) {
                                this.staff.name = staffObj.name
                            }
                            if (staffObj.jobNumber) {
                                this.staff.jobNumber = staffObj.jobNumber
                            }
                            if (staffObj.headPortrait) {
                                this.staff.headPortrait = staffObj.headPortrait
                            }
                            if (staffObj.sex) {
                                this.staff.sex = staffObj.sex
                            }
                            if (staffObj.politicalOutlook) {
                                this.staff.politicalOutlook = staffObj.politicalOutlook
                            }
                            if (staffObj.enterCompanyTime) {
                                this.staff.enterCompanyTime = staffObj.enterCompanyTime
                            }
                            if (staffObj.nativePlace) {
                                this.staff.nativePlace = staffObj.nativePlace
                            }
                            if (staffObj.nation) {
                                this.staff.nation = staffObj.nation
                            }
                            if (staffObj.idCard) {
                                this.staff.idCard = staffObj.idCard
                            }
                            if (staffObj.mobile) {
                                this.staff.mobile = staffObj.mobile
                            }
                            if (staffObj.bankOfDeposit) {
                                this.staff.bankOfDeposit = staffObj.bankOfDeposit
                            }
                            if (staffObj.bankCardNumber) {
                                this.staff.bankCardNumber = staffObj.bankCardNumber
                            }
                            if (staffObj.address) {
                                this.staff.address = staffObj.address
                            }
                            if (staffObj.id) {
                                this.staff.id = staffObj.id
                            }

                        }
                    }
                )
            },
            onConfirmNation(values) {
                this.staff.nation = values.text;
                this.showNation = false;
            },
            onConfirmRusiTime(value) {
                this.staff.enterCompanyTime = this.filterTime(value);
                this.showRusiTime = false;
            },
            handleSubmit() {
                let reqData = {};
                if (!this.staff.name) {
                    return Toast('请输入姓名')
                } else {
                    reqData.name = this.staff.name
                }
                if (this.staff.jobNumber) {
                    reqData.jobNumber = this.staff.jobNumber
                }
                if (this.staff.headPortrait) {
                    reqData.headPortrait = this.staff.headPortrait
                }
                if (this.staff.sex) {
                    reqData.sex = this.staff.sex
                }
                if (this.staff.politicalOutlook) {
                    reqData.politicalOutlook = this.staff.politicalOutlook
                }
                if (this.staff.enterCompanyTime != '请选择入司时间') {
                    reqData.enterCompanyTime = this.staff.enterCompanyTime
                }
                if (this.staff.nativePlace) {
                    reqData.nativePlace = this.staff.nativePlace
                }
                if (this.staff.nation != '请选择民族') {
                    reqData.nation = this.staff.nation
                }
                if (this.staff.idCard) {
                    var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                    if(reg.test(this.staff.idCard) === false) {
                        return Toast('身份证输入不合法')
                    }
                    reqData.idCard = this.staff.idCard
                }
                if (this.staff.mobile) {
                    reqData.mobile = this.staff.mobile
                }
                if (this.staff.bankOfDeposit) {
                    reqData.bankOfDeposit = this.staff.bankOfDeposit
                }
                if (this.staff.bankCardNumber) {
                    reqData.bankCardNumber = this.staff.bankCardNumber
                }
                if (this.staff.address) {
                    reqData.address = this.staff.address
                }
                if (this.staff.userId) {
                    reqData.userId = this.staff.userId
                }
                if (this.staff.id) {
                    reqData.id = this.staff.id
                }
                api.hrCoe_performance_addStaff({
                    staffType: 1,
                    dto: JSON.stringify({staff: reqData})
                }).then(
                    response => {
                        if (response.code == 200) {
                            Toast(response.msg);
                            setTimeout(() => {
                                // //进入下一步
                                this.$bus.emit("toParent", 1);
                            }, 1000);
                        }
                    }
                )
            },
            handleUpload() {
                document.querySelector("#file").value = null;
                document.querySelector("#file").click();
            },
            chooseImg(event) {
                this.imgFile = event.target.files[0];
                this.uploadImg();
            },
            uploadImg() {
                api.basicData_insertPicture({file: this.imgFile}).then(
                    response => {
                        if (response.code == 200) {
                            this.staff.headPortrait = response.result.fileUrl
                        }
                    }
                )
            },
            handleDeleteImg() {
                this.staff.headPortrait = ''
            },
            filterTime(time) {
                var date = new Date(time);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                var d = date.getDate();
                d = d < 10 ? "0" + d : d;
                return y + "-" + m + "-" + d;
            },
            formatter(type, val) {
                if (type === 'year') {
                    return `${val}年`;
                } else if (type === 'month') {
                    return `${val}月`;
                } else if (type === 'day') {
                    return `${val}日`;
                }
                return val;
            }
        }
    }
</script>

<style scoped>
    .box_container {
        background: #ffffff;
        border-radius: 1rem;
        margin: 3rem 2rem 2rem 2rem;
        box-shadow: 0px 0px 2rem 0px rgba(0, 0, 0, 0.10);
    }

    .item_container {
        display: flex;
        align-items: center;
        padding: 1rem;
    }

    .left_title {
        font-size: 1.4rem;
        color: #333333;
        width: 20%;
        text-align: right;
        margin-right: 1rem;
    }

    .m_input {
        background: #ffffff;
        border: 1px solid #dadada;
        border-radius: 5px;
        padding-left: 0.5rem;
        font-size: 1.4rem;
        width: 75%;
        height: 3.6rem;
        line-height: 3.6rem;
    }

    .m_placeholder {
        position: relative;
        text-align: left;
        color: #999999;
        font-size: 1.4rem;
    }

    .m_placeholder_2 {
        position: relative;
        text-align: left;
        color: #333333;
        font-size: 1.4rem;
    }

    .arrow {
        position: absolute;
        right: 3%;
        top: 30%;
        width: 0.6rem;
    }

    .btnCover {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 6rem;
        background: #fff;
    }

    .submit_btn {
        width: 20rem;
        height: 4rem;
        line-height: 4rem;
        border-radius: 5px;
        font-size: 1.6rem;
        background-color: #13d1be;
        text-align: center;
        margin: 1rem auto;
        color: white;
    }

    .img-icon {
        height: 1.8rem;
    }

    .img_bac {
        position: relative;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        width: 10rem;
        height: 14rem;
        background: #f4f4f4;
        /*border: 1px dashed #999999;*/
    }

    .img_content {
        width: 10rem;
        height: 14rem;
        /*border: 1px dashed #999999;*/
        object-fit: cover;
    }

    .img_tip {
        color: #999999;
        font-size: 1.2rem;
        padding-left: 1rem;
    }

    .img_tip_1 {
        color: #999999;
        font-size: 5rem;
        margin-top: -2rem;
        text-align: center
    }

    .img_tip_2 {
        position: absolute;
        color: #13d1be;
        font-size: 1.4rem;
        text-align: center;
        margin-top: 3rem;
    }

    .delete_img {
        position: absolute;
        top: -1rem;
        right: -0.9rem;
        width: 1.8rem;
    }

    .radio_text {
        font-size: 1.2rem;
        padding-top: 0.5rem;
    }

    .mrb50 {
        margin-bottom: 7rem;
    }

    .phone_bac {
        background: #f4f4f4;
    }
</style>