import Vue from "vue";
import VueRouter from "vue-router";
import TalentUserVerification from '../views/page/TalentUserVerification'
import TestInfoVerification from '../views/page/TestInfoVerification'
import PromptPage from '../views/page/PromptPage'
import HrDiagnosisTest from '../views/page/TalentReview/HrDiagnosisTest'
import HrDiagnosisResult from '../views/page/TalentReview/HrDiagnosisResult'
import TalentReviewList from '../views/page/TalentReview/TalentReviewList'
import ReviewItem from '../views/page/TalentReview/ReviewItem'
import SelectOrganization from '../views/page/SelectOrganization'
import DemandStatistics from '../views/page/TalentReview/DemandStatistics'
import IntroducePage from '../views/page/TalentReview/IntroducePage'
import Study from '../views/page/study/Study'
import videoPaly from '../views/page/study/detail/videoPaly'
import taskDetail from '../views/page/study/detail/taskDetail'
import jingzhunInfoInput from '../views/page/TalentReview/jingzhunInfoInput'
import jingzhunReview from '../views/page/TalentReview/jingzhunReview'
import caizhunReview from '../views/page/TalentReview/caizhunReview'
import HomePage from '../views/page/Distribution/HomePage'
import TeamPage from '../views/page/Distribution/TeamPage'
import CustomerPage from '../views/page/Distribution/CustomerPage'
import CommissionPage from '../views/page/Distribution/CommissionPage'
import HumanEffect from '../views/page/TalentReview/HumanEffect'
import DiagnosticNotes from '../views/page/TalentReview/DiagnosticNotes'
import DiagnosisItem from '../views/page/TalentReview/DiagnosisItem'
import DiagnosisResult from '../views/page/TalentReview/DiagnosisResult'
import UserInfoInput from '../views/page/TalentReview/UserInfoInput'
import HumanResourcesInfoInput from '../views/page/TalentReview/HumanResourcesInfoInput'
import CreateOrg from '../views/page/CreateOrg'
import SkillEvaluationNotice from'../views/page/TalentReview/SkillEvaluationNotice.vue'
import SkillEvaluationTest from'../views/page/TalentReview/SkillEvaluationTest.vue'
import SkillsAssessmentTest from'../views/page/TalentReview/SkillsAssessmentTest.vue'
import ValueAssessmentTest from'../views/page/TalentReview/ValueAssessmentTest.vue'
import SkillEvaluationExtension from'../views/page/TalentReview/SkillEvaluationExtension.vue'
import DuoSetAnswer from'../views/page/TalentReview/DuoZhunProgress/DuoSetAnswer.vue'
import DuoSetDimension from'../views/page/TalentReview/DuoZhunProgress/DuoSetDimension.vue'
import DuoPreview from'../views/page/TalentReview/DuoZhunProgress/DuoPreview.vue'
import QrCard from'../views/page/TalentReview/DuoZhunProgress/QrCard.vue'
import DuoInfoInput from'../views/page/TalentReview/DuoZhunProgress/DuoInfoInput.vue'
import DuoResult from'../views/page/TalentReview/DuoZhunProgress/DuoResult.vue'
import SignInfoInput from'../views/page/TalentReview/Sign/SignInfoInput.vue'
import SignList from'../views/page/TalentReview/Sign/SignList.vue'
import SignExperienceInput from'../views/page/TalentReview/Sign/SignExperienceInput.vue'
import StaffJudge from'../views/page/TalentReview/StaffJudge.vue'
import PersonnelFilesPage from'../views/page/PersonnelFiles/PersonnelFilesPage.vue'
import RecruitList from '../views/page/recruit/RecruitList.vue'
import PositionDetail from '../views/page/recruit/PositionDetail.vue'
import UploadResume from '../views/page/recruit/UploadResume.vue'
import EmotionalTestKnow from '../views/page/emotionaltest/testKnow.vue'
import EmotionalTestItem from '../views/page/emotionaltest/testItem.vue'
import PersonalityTestKnow from '../views/page/personalitytest/testKnow.vue'
import PersonalityTestItem from '../views/page/personalitytest/testItem.vue'
import SignPage from '../views/page/TalentReview/Sign/SignPage.vue'
import CadreReportList from '../views/page/TalentReview/cadreReport/CadreReportList.vue'
import CadreReportItem from '../views/page/TalentReview/cadreReport/CadreReportItem.vue'
import CadreReportResult from '../views/page/TalentReview/cadreReport/CadreReportResult.vue'
import ComprehensiveEvaluationA from '../views/page/TalentReview/ComprehensiveEvaluationA.vue'
import PreviewResume from '../views/page/recruit/PreviewResume.vue'
import Login from '../views/page/companyWechat/Login.vue'
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
Vue.use(VueRouter);

const routes = [
  {
    path: '/',//选择组织页面
    redirect: '/SelectOrganization'
  },{
    path: "/SelectOrganization",//选择组织页面
    component: SelectOrganization,
    meta: {
      keepAlive: false // 需要缓存
    }
  },{
    path: "/TalentUserVerification",//人才盘点人员验证
    component: TalentUserVerification
  },{
    path: "/TestInfoVerification",//测评信息验证
    component: TestInfoVerification
  },{
    path: "/PromptPage",//非员工提示页
    component: PromptPage
  },{
    path: "/HrDiagnosisTest",//HR诊断做题
    component: HrDiagnosisTest
  },{
    path: "/HrDiagnosisResult",//HR诊断结果
    component: HrDiagnosisResult
  },
  {
    path: "/Study",//学习主页
    component: Study,
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  {
    path: "/TalentReviewList",//人才盘点列表
    component: TalentReviewList
  },{
    path: "/ReviewItem",//人才盘点过程
    component: ReviewItem
  },{
    path: "/DemandStatistics",//需求统计
    component: DemandStatistics
  },{
    path: "/IntroducePage",//引导页面
    component: IntroducePage
  },
  {
    path: "/videoPaly",//视频播放
    component: videoPaly
  },
  {
    path: "/taskDetail",//任务详情
    component: taskDetail
  },
  {
    path: "/jingzhunInfoInput",//菁准个人信息填写
    component: jingzhunInfoInput
  },
  {
    path: "/jingzhunReview",//菁准评价列表
    component: jingzhunReview
  },
  {
    path: "/caizhunReview",//彩准评价列表
    component: caizhunReview
  },
  {
    path: "/HomePage",//分销首页
    component: HomePage
  },
  {
    path: "/TeamPage",//分销团队
    component: TeamPage
  },
  {
    path: "/CustomerPage",//分销客户
    component: CustomerPage
  },
  {
    path: "/CommissionPage",//分销佣金
    component: CommissionPage
  },
  {
    path: "/HumanEffect",//人效分析
    component: HumanEffect
  },
  {
    path: "/DiagnosticNotes",//诊断须知
    component: DiagnosticNotes
  },
  {
    path: "/DiagnosisItem",//HR 诊断
    component: DiagnosisItem
  },{
    path: "/DiagnosisResult",//HR 诊断结果
    component: DiagnosisResult
  },{
    path: "/UserInfoInput",//信息填写
    component: UserInfoInput
  },{
    path: "/HumanResourcesInfoInput",//人力资源诊断信息填写
    component: HumanResourcesInfoInput
  },{
    path: "/CreateOrg",//创建组织
    component: CreateOrg
  },{
    path: "/SkillEvaluationNotice",//技能评价测评须知
    component: SkillEvaluationNotice
  },{
    path: "/SkillEvaluationTest",//技能评价测评
    component: SkillEvaluationTest
  },{
    path: "/ValueAssessmentTest",//价值观评价测评
    component: ValueAssessmentTest
  },{
    path: "/SkillsAssessmentTest",//管理技能评价测评
    component: SkillsAssessmentTest
  }
  ,{
    path: "/SkillEvaluationExtension",//多准推广
    component: SkillEvaluationExtension
  },
  {
    path: "/DuoSetAnswer",//多准设置标准答案
    component: DuoSetAnswer
  },
  {
    path: "/DuoSetDimension",//多准设置维度
    component: DuoSetDimension
  },
  {
    path: "/DuoPreview",//多准预览
    component: DuoPreview
  },
  {
    path: "/QrCard",//扫码卡片
    component: QrCard
  },{
    path: "/DuoInfoInput",//扫码者多准信息填写
    component: DuoInfoInput
  },
  {
    path: "/DuoResult",//多准评价结果
    component: DuoResult
  },
  {
    path: "/SignInfoInput",//签到信息填写
    component: SignInfoInput
  },
  {
    path: "/SignList",//签到列表
    component: SignList
  },
  {
    path: "/SignExperienceInput",//填写签到心得
    component: SignExperienceInput
  }, {
    path: "/StaffJudge",//判断是否是员工
    component: StaffJudge
  },
  {
    path: "/PersonnelFilesPage",//人事档案
    component: PersonnelFilesPage
  },{
    path: "/RecruitList",//招聘列表
    component: RecruitList
  }, {
    path: "/PositionDetail",//职位详情
    component: PositionDetail
  }, {
    path: "/UploadResume",//上传简历
    component: UploadResume
  },{
    path: "/emotionalTestKnow",//情商测评须知
    component: EmotionalTestKnow
  }, {
    path: "/emotionalTestItem",//情商开始测评
    component: EmotionalTestItem
  },{
    path: "/personalityTestKnow",//人格测评须知
    component: PersonalityTestKnow
  }, {
    path: "/personalityTestItem",//人格开始测评
    component: PersonalityTestItem
  } ,{
    path: "/SignPage",//签到页面
    component: SignPage
  },{
    path: "/Login",//授权登录页面
    component: Login
  },{
    path: "/CadreReportList",//干部述职列表
    component: CadreReportList
  },{
    path: "/CadreReportItem",//干部述职评审
    component: CadreReportItem
  },{
    path: "/CadreReportResult",//干部述职列表结果
    component: CadreReportResult
  },{
    path: "/ComprehensiveEvaluationA",//综合评价非应聘者信息填写
    component: ComprehensiveEvaluationA
  },{
    path: "/PreviewResume",//简历预览
    component: PreviewResume
  }
];

const router = new VueRouter({
  routes
});

export default router;
