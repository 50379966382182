<template>
    <div>
        <div class="top_view">
            <div class="m_title">
                <div class="m_title_1">您的组织</div>
                <div class="m_title_2">以下为 +86 {{cellphone}} 已加入的组织，可直接进入。</div>
            </div>

            <div v-for="(item,index) in dataList" :key="index">
                <div class="item" @click="toReviewList(item.officeId,item.userId,item.pcUserId,item.cloudName)">
                    <div class="item_left">{{item.cloudName.substring(0,1)}}</div>
                    <div>
                        <div class="item_right_top">{{item.cloudName}}</div>
                        <div class="item_right_bottom">{{item.username}}</div>
                    </div>
                    <div class="item_right">进入</div>
                </div>

            </div>
        </div>
        <div>
            <van-button
                    class="talent_button"
                    type="primary"
                    @click="createOrg">创建组织
            </van-button>
        </div>
    </div>

</template>

<script>
    import storage from "@/common/js/cacheData";
    import {ShareLink,ShareLogo} from "@/apis/const.js";
    import {startShart} from "@/common/js/tecentshare.js";
    import api from "@/apis/talentReview/talentReview.js";

    export default {
        name: "SelectOrganization",
        data() {
            return {
                cellphone: '',
                dataList: []

            }
        },
        created() {

        },
        mounted() {
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            this._getOfficeList();
            this._getUserInfo();
            this.shareContent();
        },
        activated() {
            document.title = "选择组织";
        },
        methods: {
            createOrg(){
                this.$router.push({
                    path: '/CreateOrg'
                })
            },
            _getUserInfo() {
                api.getUserInfo().then(
                    response => {
                        if (response.code == 200) {
                            this.cellphone = response.result.cellphone;

                        }
                    }
                )
            },
            toReviewList(officeId, userId,pcUserId,cloudName) {
                localStorage.setItem('m_officeId', officeId);
                localStorage.setItem('m_userId', userId);
                localStorage.setItem('m_pcUserId', pcUserId);
                localStorage.setItem('m_cloudName', cloudName);
                localStorage.setItem("m_type", 2);
                this.$router.push({
                    path: '/Study'
                })
            },
            _getOfficeList() {
                api.getOfficeList().then(
                    response => {
                        if (response.code == 200) {
                            this.dataList = response.result;
                        }
                    }
                )
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .top_view{
        margin-bottom: 60px;
    }
    .m_title{
        background: url("../../static/image/webp.png") no-repeat;
        background-size: cover;
    }
    .m_title_1 {
        font-size: 30px;
        color: #333;
        padding: 20px;
        font-weight: bold;
        text-align: left;
    }

    .m_title_2 {
        font-size: 14px;
        color: #333;
        padding-left: 20px;
        padding-bottom: 10px;
        text-align: left;
        margin-bottom: 10px;
    }

    .item {
        display: flex;
        align-items: center;
        margin: 10px;
        padding: 10px;
        border: 2px solid #f4f4f4;
        border-radius: 10px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    }

    .item_left {
        width: 60px;
        height: 60px;
        color: white;
        display: flex;
        font-size: 20px;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        background: linear-gradient(180deg,#6c97ff, #3f72ec);
        border-radius: 4px;
    }

    .item_right_top {
        height: 30px;
        line-height: 30px;
        padding-left: 10px;
        font-size: 16px;
        color: #333;
        font-weight: bold;
        text-align: left;
    }

    .item_right_bottom {
        padding-left: 10px;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #333;
        text-align: left;
    }
    .item_right{
        background-color: #13d1be;
        padding: 5px 10px 5px 10px;
        color: white;
        margin-left: auto;
        width: 60px;
        text-align: center;
        font-size: 14px;
        border-radius: 24px;
    }
    .talent_button {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #13d1be;
        border: 0px;
    }
</style>
