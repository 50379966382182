<template>
    <div>

        <van-list
                v-model="loading"
                :finished="finished"
                :immediate-check="false"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="item" v-for="(item,index) in dataList" :key="index">
                <div class="item_left" v-if="item.username.length<=2">
                    {{item.username.substring(0,item.username.length-1)}}
                </div>
                <div class="item_left" v-else>{{item.username.substring(0,item.username.length-2)}}</div>
                <div style="display: flex;flex-direction: column;flex: 1">
                    <div style="display: flex;justify-content: space-between;align-items: center">
                        <div class="item_top_left">{{item.username}}</div>
                        <div class="item_time">{{item.startTime+'-'+item.endTime}}</div>
                        <van-button class="item_bottom_right_1" round type="info" @click="toReview(item)">
                            {{item.score==0?"去评审":"已评审"}}
                        </van-button>
                    </div>
                </div>

            </div>
        </van-list>
        <no-result :title="'暂无盘点'" v-if="!dataList.length"></no-result>
    </div>
</template>

<script>
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import {startShart} from "@/common/js/tecentshare.js";
    import api from "@/apis/talentReview/talentReview.js";
    import NoResult from "../../components/commonly/noResult.vue";
    import {Button, DropdownItem, DropdownMenu, List, Sticky, Tab, Tabs} from "vant";
    import storage from "@/common/js/cacheData";
    export default {
        name: "CadreReportList",
        data() {
            return {
                loading: false,
                finished: false,
                currPage: 1,
                pageSize: 10,
                totalPage: 1,
                totalCount: 0,
                dataList: [],
                reviewTotalCount: 0,
                reviewCount: 0
            }
        },
        components: {
            [List.name]: List,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Button.name]: Button,
            [Sticky.name]: Sticky,
            NoResult
        },
        created() {
            this.officeId = localStorage.getItem("m_officeId");
            this.userId = localStorage.getItem("m_userId");
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            this.getData();
        },
        mounted() {
            this.shareContent();
        },
        methods: {
            getData() {

                let reqData = {
                    officeId: this.officeId,
                    userId: this.userId,
                    page: this.currPage,
                    limit: this.pageSize
                };
                api.cadresScore_cadresList(reqData).then(
                    response => {
                        this.loading = false;
                        if (response.code == 200) {
                            if (this.currPage == 1) {
                                this.dataList = [];
                            }
                            this.dataList.push(...response.result.list.list);
                            this.pageSize = response.result.list.pageSize;
                            this.totalPage = response.result.list.totalPage;
                            this.currPage = response.result.list.currPage;
                            this.reviewTotalCount = response.result.totalCount;
                            this.reviewCount = response.result.reviewCount;
                        }
                    }
                )
            },
            toReview(item) {
                if (item.score === 0) {
                    //去评审
                    this.$router.push({
                        path: '/CadreReportItem',
                        query: {
                            assessUserId: item.assessUserId,
                            userId: item.userId,
                            officeId: item.officeId,
                            intervalId: item.intervalId,
                            deptName: item.deptName,
                            username: item.username
                        }
                    })
                } else {
                    //查看结果
                    this.$router.push({
                        path: '/CadreReportResult',
                        query: {
                            assessUserId: item.assessUserId,
                            userId: item.userId,
                            officeId: item.officeId,
                            intervalId: item.intervalId
                        }
                    })
                }

            },
            onLoad() {
                this.loading = true;
                if (this.currPage < this.totalPage) {
                    this.currPage += 1;
                    this.getData()
                } else {
                    this.finished = true;
                    this.loading = false;
                }
            },

            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .title_left {
        padding: 1rem;
        line-height: 3rem;
        font-size: 1.5rem;
        color: #333;
    }

    .title_right {
        padding-right: 1rem;
        font-size: 1rem;
        color: #333;
    }

    .item {
        display: flex;
        align-items: center;
        opacity: 1;
        margin: 1rem;
        padding: 1rem;
        background: #ffffff;
        border-radius: 1rem;
        box-shadow: 0px .4rem 1rem 0px rgba(0, 0, 0, 0.20);
    }

    .item_left {
        width: 6rem;
        height: 6rem;
        color: white;
        display: flex;
        font-size: 2rem;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        background: linear-gradient(180deg, #6c97ff, #3f72ec);
        border-radius: 4px;
    }

    .item_top_left {
        padding: 0 0 0 1rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #333;
        line-height: 4rem;
    }

    .item_time {
        font-size: 1.2rem;
        color: #333;
        line-height: 4rem;
    }

    .item_top_right {
        font-size: 1.2rem;
        color: #333;
        line-height: 4rem;
    }

    .item_bottom_left {
        padding: 1rem;
        font-size: 1.2rem;
        color: #333;
    }

    .item_bottom_right_1 {
        width: 8rem;
        height: 3rem;
        text-align: center;
        font-size: 1.2rem;
        background-color: #13d1be;
    }

    .item_bottom_right_2 {
        float: right;
        padding: 10px;
        font-size: 14px;
        color: #333;
        border-radius: 5px;
        border: 1px solid #13d1be;
    }
</style>
