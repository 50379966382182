import { render, staticRenderFns } from "./StaffJudge.vue?vue&type=template&id=3326a369&scoped=true&"
import script from "./StaffJudge.vue?vue&type=script&lang=js&"
export * from "./StaffJudge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3326a369",
  null
  
)

export default component.exports