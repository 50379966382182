<template>

    <div class="m-container">
        <div id="el" class="m-resume-content" >
            <iframe style="width:100%; height:100%" v-if="url" :src="url" frameborder="0"></iframe>
            <div v-else class="m-empty">暂无简历</div>
        </div>
    </div>

</template>

<script>
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import $ from 'jquery'

    export default {
        name: "PreviewResume",
        data() {
            return {
                url: null
            }
        },
        created() {
            document.title = "简历预览";
            this.url = this.$route.query.url;
            this.reloadView()

        },
        mounted() {
            this.shareContent();
        },
        methods: {
            reloadView(){
                //el为你的外层容器
                let that = this;
                var html = '<iframe width="100%" height="100%" src="' + that.url + '" frameborder="0"></iframe>';
                $('#el').empty();
                $('#el').append(html);
            },

            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .m-container {
        position: relative;
    }


    .m-resume-content {
        height: 100vh;
        width: 100%;
    }

    .m-empty{
        height: 100%;
        color: #999999;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
