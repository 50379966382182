import {
  get,
  post, postform
} from '../utils/base';
import userCache from '@/common/js/userCache';

export default {
  // 职位详情
  positionInfo(param) {
    const opts = _opts(true);
    let params = param ? param : {};
    return post('hrCoe/weiChat/positionInfo', params, opts)
  },
  // 不感兴趣
  addRecord(param) {
    const opts = _opts(true);
    let params = param ? param : {};
    return post('hrCoe/weiChat/addRecord', params, opts)
  },
  // 查询简历，方便预览
  _queryMyResume(param) {
    const opts = _opts(true);
    let params = param ? param : {};
    return post('hrCoe/weiChat/queryMyResume', params, opts)
  },
  // 上传简历
  addUserResume(file,totalId) {
    const opts = _opts(true);
    let params={
      totalId: totalId
    };
    if (file){
      params['file']=file
    }
    return postform('hrCoe/weiChat/addUserResume', params, opts);
  },
  //省市数据
  selectCityList(param) {
    const opts = _opts(true);
    let params = param ? param : {};
    return post('basicData/selectCityList', params, opts)
  },
  //招聘中心列表
  positionList(param) {
    const opts = _opts(true);
    let params = param ? param : {};
    return post('hrCoe/weiChat/positionList', params, opts)
  }
};
function _opts(isShowLoading) {
  var opt = {
    cache: {
      exp: 60
    }
  };
  if (isShowLoading) {
    return Object.assign(opt, {
      progress: true
    });
  }
}
