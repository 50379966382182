<template>
  <div>
    <div class="forcenter"></div>
    <div class="cover">
      <div v-for="(item, index) in dataList" :key="index">
        <div class="m_card">
          <div class="m_title">
            <div class="remark">{{ dataList[index].remark }}</div>
            <div class="title">
              Q{{ index + 1 + "." + dataList[index].title }}
            </div>
          </div>
          <div class="item_card">
            <div class="left-item answers-bg">
              <div>A、</div>
              <div>{{ dataList[index].optionA }}</div>
            </div>
            <div class="left-item answers-bg">
              <div>B、</div>
              <div>{{ dataList[index].optionB }}</div>
            </div>
            <div class="left-item answers-bg">
              <div>C、</div>
              <div>{{ dataList[index].optionC }}</div>
            </div>
            <div class="left-item answers-bg">
              <div>D、</div>
              <div>{{ dataList[index].optionD }}</div>
            </div>
          </div>
          <div class="m_bottom">
            <div class="m_bottom_center">
              {{ index + 1 }}/{{ dataList.length }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomBtn" @click="goTest">去测评</div>
  </div>
</template>

<script>
import { startShart } from "@/common/js/tecentshare.js";
import { ShareLink, ShareLogo } from "@/apis/const.js";
import api from "@/apis/talentReview/talentReview.js";
import { Toast } from "vant";
export default {
  name: "DuoPreview",
  data() {
    return {
      userId: "",
      officeId:"",
      dataList: []
    };
  },
  comments: {
    [Toast.name]: Toast
  },
  activated() {},
  created() {
    document.title = "专业水平预览";
    this.userId = this.$route.query.userId;
    this.officeId = this.$route.query.officeId;
    this.getPreViewData();
  },
  mounted() {
    this.shareContent();
  },
  methods: {
    //预览数据
    getPreViewData() {
      api.duoPreview({
        userId:this.userId,
        officeId:this.officeId
      }).then((response) => {
        if (response.code == 200) {
          this.dataList = [];
          var list = response.result.list;
          for(var i = 0; i < list.length;i++){
            var item = list[i];
            var children = item.children;
             for(var k = 0; k < children.length;k++){
                 var problem = children[k];
                 problem.remark = item.title;
                 this.dataList.push(problem);
             }
          }
        }
      });
    },
    //去测评
    goTest(){
        this.$router.push({
            path: "/SkillEvaluationExtension"
          })
    },
    //分享
    shareContent() {
      const titleStr = "HRCOE";
      const contentStr = "让人力资源工作更简单!";
      const linkStr = ShareLink + "type=1";
      const imgStr = ShareLogo;
      window.shareData = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      window.shareIMdata = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      startShart();
    }
  }
};
</script>

<style scoped>
.cover {
  position: relative;
  margin-bottom: 8rem;
}
.forcenter {
  position: fixed;
  left: 0rem;
  right: 0rem;
  top: 0rem;
  bottom: 0rem;
  background: url("../../../../static/image/review_bac.png");
  background-size: cover;
}
.m_card {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  margin: 2rem 2rem 0rem 2rem;
}
.m_title {
  background: #13d1be;
  border-radius: 1rem 1rem 0rem 0rem;
}
.remark {
  opacity: 1;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  padding: 1.5rem 1rem 1rem 1rem;
}
.title {
  opacity: 1;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  padding: 0rem 1rem 1.5rem 1rem;
}
.item_card {
  margin-top: 4rem;
}
.answers-bg {
  display: flex;
  width: 90%;
  background: #ffffff;
  border: 0.1rem solid #f4f4f4;
  border-radius: 1rem;
  box-shadow: 0rem 0.4rem 1rem 0rem rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  margin: 1.3rem auto;
  color: #666666;
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-align: left;
}
.content-title {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #fff;
  text-align: center;
  height: 2rem;
  padding: 0rem 1rem 0.5rem 0rem;
}
.m_bottom {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.m_bottom_center {
  height: 3rem;
  line-height: 3rem;
  color: #999999;
  font-size: 1.6rem;
  text-align: center;
}
.bottomBtn {
  position: fixed;
  margin: auto;
  left: 0rem;
  right: 0rem;
  bottom: 2rem;
  width: 20rem;
  height: 4rem;
  background: #13d1be;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 4rem;
}
</style>