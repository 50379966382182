<template>
  <div class='zhx_container'>
    <div class="content_box">
      <div class='title'>测评须知</div>
      <div class='content'>测评前一定要仔细阅读一下本篇内容，以 达到最好的测评效果。</div>
      <div class='content'>情商是由多个维度共同组合而成!改变自己 首先要准确认识自己，因此展示真实的你非常 重要。我们的每一道题都有四个答案，按照程 度高低进行区分,在选择时请认真思考，哪个最 符合你的真实情况。</div>
      <div class='content'>本测评总共30道题，预计5分钟。</div>
      <div class='content'>我已经看完须知，并且准备好了。</div>
      <div class='go_start' @click='go_start'>马上开始测评</div>
    </div>
  </div>
</template>
<script>
  import {startShart} from "@/common/js/tecentshare.js";
  import {ShareLink, ShareLogo} from "@/apis/const.js";
import storage from "@/common/js/cacheData";

export default {
  data() {
    return {
      isFromList: 0
    };
  },
  methods: {
    go_start() {
      let that = this;
      that.$router.replace({
        path: "/emotionalTestItem",
        query: {
          isFromList: this.isFromList,
          t: Date.now()
        }
      })
    },
    //分享
    shareContent() {
      const titleStr = "HRCOE";
      const contentStr = "让人力资源工作更简单!";
      const linkStr = ShareLink + "type=1";
      const imgStr = ShareLogo;
      window.shareData = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      window.shareIMdata = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      startShart();
    }
  },
  created() {
    document.title = "测评";
    let that = this;
    let openId = that.$route.query.openId;
    if (openId) {
      storage.saveData("openId", openId);
    }
    that.isFromList = that.$route.query.isFromList;
  },
  watch: {},
  mounted() {
    let that = this;
    that.shareContent();
  }
};
</script>
<style scoped>
  .zhx_container {
    width: 100vw;
    height: 100vh;
  }
  .content_box{
    position: relative;
    color: #333333;
    height: 450px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.16);
    top: 50%;
    transform: translate(0, -50%);
    margin: 0 1.5rem;
  }
  .title{
    font-size:16px;
    line-height:5rem;
    text-align:center;
    font-weight:bold;
    padding:2.5rem 0 0 0;
  }
  .content{
    text-indent:2em;
    margin:0 auto;
    font-size:14px;
    line-height:3rem;
    padding: 0px 1.5rem;
  }
  .go_start{
    position: absolute;
    width: 16rem;
    height: 4rem;
    line-height: 4rem;
    bottom: 5%;
    left: 50%;
    font-size: 14px;
    transform: translate(-50%, -50%);
    color: white;
    background-color: #13D1BE;
    text-align: center;
    border-radius: 5px;
  }
</style>
