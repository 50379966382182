<template>
  <div class="forcenter">
    <div class="m_card">
      <div class="m_title">
        <div class="remark">[{{ dataItem[index].remark }}]</div>
        <div class="title">Q{{index + 1+'.'+dataItem[index].title }}</div>
      </div>
      <div class="item_card">
        <div
          class="left-item"
          :class="
            allanswer.length > last && allanswer[last].score == evaluationScoreSetDto.optionA
              ? 'answers-bg-active'
              : 'answers-bg'
          "
          @click="next(evaluationScoreSetDto.optionA)"
        >
          <div>A、</div>
          <div>{{ dataItem[index].optionA }}</div>
        </div>
        <div
          class="left-item"
          :class="
            allanswer.length > last && allanswer[last].score == evaluationScoreSetDto.optionB
              ? 'answers-bg-active'
              : 'answers-bg'
          "
          @click="next(evaluationScoreSetDto.optionB)"
        >
          <div>B、</div>
          <div>{{ dataItem[index].optionB }}</div>
        </div>
        <div
          class="left-item"
          :class="
            allanswer.length > last && allanswer[last].score == evaluationScoreSetDto.optionC
              ? 'answers-bg-active'
              : 'answers-bg'
          "
          @click="next(evaluationScoreSetDto.optionC)"
        >
          <div>C、</div>
          <div>{{ dataItem[index].optionC }}</div>
        </div>
        <div
          class="left-item"
          :class="
            allanswer.length > last && allanswer[last].score == evaluationScoreSetDto.optionD
              ? 'answers-bg-active'
              : 'answers-bg'
          "
          @click="next(evaluationScoreSetDto.optionD)"
        >
          <div>D、</div>
          <div>{{ dataItem[index].optionD }}</div>
        </div>
      </div>
      <div class="m_bottom">
        <div v-if="isShow" class="m_bottom_left" @click="go_start">
          {{ previousName }}
        </div>
        <div style="width: 5rem" v-else></div>
        <div class="m_bottom_center">{{ index + 1 }}/{{ dataItem.length }}</div>
        <div
          v-if="index == dataItem.length - 1"
          class="m_bottom_right"
          @click="submit"
        >
          提交
        </div>
        <div v-else class="m_bottom_right" @click="go_next">{{ nextName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { startShart } from "@/common/js/tecentshare.js";
import { ShareLink, ShareLogo } from "@/apis/const.js";
import api from "@/apis/talentReview/talentReview.js";
import { Toast } from "vant";

export default {
  name: "ReviewItem",
  data() {
    return {
      fromCaizhun: null,
      isConfirm: true,
      id: 0,
      isShow: false,
      index: 0,
      last: 0,
      previousName: "上一题",
      nextName: "下一题",
      dataItem: [
        {
          id: 1
        }
      ],
      allanswer: [
        {
          totalId: 0,
          parentId: 0,
          score: null
        }
      ],
      skillId:"",
      assessUserId:"",
      userId:"",
      officeId:"",
      skillEvaluationId:"",
      isExtension:null,
      category:null,
      evaluationScoreSetDto: {
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        name: '',
        type: ''
      }
    };
  },
  comments: {
    [Toast.name]: Toast
  },
  activated() {},
  created() {
    document.title = "专业水平测评";
    this.skillId = this.$route.query.skillId;
    this.assessUserId = this.$route.query.assessUserId;
    this.userId = this.$route.query.userId;
    this.officeId = this.$route.query.officeId;
    this.skillEvaluationId = this.$route.query.skillEvaluationId;
    this.isExtension = this.$route.query.isExtension;
    this.category = this.$route.query.category;
    this.getDataList();
  },
  mounted() {
    this.shareContent();
  },
  methods: {
    getDataList() {
      api.skillEvaluationTestList({
          skillId: this.skillId,
          skillEvaluationId:this.skillEvaluationId
        })
        .then((response) => {
          if (response.code == 200) {
            this.dataItem = response.result.list;
            this.evaluationScoreSetDto = response.result.evaluationScoreSetDto;
          }
        });
    },
    go_start() {
      let that = this;
      that.index--;
      that.last--;
      if (that.index > 0) {
        that.isShow = true
      } else {
        that.isShow = false
      }
    },
    go_next() {
      let that = this;
      if (
        !that.allanswer[that.index] ||
        that.allanswer[that.index].score == undefined
      ) {
        Toast("请先做题");
        return;
      }
      that.index++;
      that.last++;
      if (that.index > 0) {
        that.isShow = true
      } else {
        that.isShow = false
      }
    },
    next(a) {
      if (!this.isConfirm) {
        return;
      }
      this.isConfirm = false;
      let that = this;
      var ansobj = {};
      ansobj.totalId = that.dataItem[that.index].totalId;
      ansobj.score = a;
      that.allanswer[that.index] = ansobj;
      that.$forceUpdate();
      that.previousName = "上一题";
      setTimeout(() => {
        this.isConfirm = true;
        if (that.index == that.dataItem.length - 1) {
          return;
        }
        that.index++;
        that.last++;
        if (that.index > 0) {
          that.isShow = true;
        } else {
          that.isShow = false;
        }
      }, 300);
    },
    submit() {
      let that = this;
      var alldata = that.allanswer;
      if (alldata.length < this.dataItem.length) {
        Toast({
          message: "测评未完善",
          position: "middle",
          duration: 1000
        });
        return;
      }
      let subobj = {};
      subobj.skillId = this.skillId;
      subobj.assessUserId = this.assessUserId;
      subobj.userId = this.userId;
      subobj.officeId = this.officeId;
      subobj.category = this.category;
      subobj.skillEvaluationId = this.skillEvaluationId;
      subobj.list = JSON.stringify(alldata);
      api.addSkillScore(subobj).then((response) => {
        if (response.code == 200) {
          Toast({
            message: "测评完成",
            position: "middle",
            duration: 1000
          });
          if (that.isExtension&&that.isExtension==1){
            this.$router.push({
              path: "/DuoResult"
            })
          }else{
            localStorage.setItem("m_type", 3);
            setTimeout(() => {
              that.$router.go(-2); //返回上一层
              that.index = 0;
              that.last = 0;
              that.allanswer = [

              ];
            }, 1000)
          }

        }
      });
    },
    //分享
    shareContent() {
      const titleStr = "HRCOE";
      const contentStr = "让人力资源工作更简单!";
      const linkStr = ShareLink + "type=1";
      const imgStr = ShareLogo;
      window.shareData = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      window.shareIMdata = {
        fTitle: titleStr,
        fContent: contentStr,
        fImgUrl: imgStr,
        fLink: linkStr
      };
      startShart();
    }
  }
};
</script>

<style scoped>
.forcenter {
  margin: 0rem auto;
  min-height: 100vh;
  background: url("../../../static/image/review_bac.png");
  background-size: cover;
  text-align: center;
  overflow: hidden;
}
.m_card {
  margin-top: 5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  background: white;
  border-radius: 1rem;
  overflow: hidden;
}
.m_title {
  background: #13d1be;
  border-radius: 1rem 1rem 0rem 0rem;
}
.remark {
  opacity: 1;
  font-size: 1.6rem;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  padding: 1.5rem 1rem 1rem 1rem;
}
.title {
  opacity: 1;
  font-size: 1.6rem;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  padding: 0rem 1rem 1.5rem 1rem;
}
.item_card {
  margin-top: 4rem;
}
.answers-bg {
  display: flex;
  width: 90%;
  background: #ffffff;
  border: 0.1rem solid #f4f4f4;
  border-radius: 1rem;
  box-shadow: 0rem 0.4rem 1rem 0rem rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  margin: 1.3rem auto;
  color: #666666;
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-align: left;
}
.answers-bg-active {
  display: flex;
  width: 90%;
  background: #f4f4f4;
  border-radius: 1rem;
  box-shadow: 0rem 0.4rem 1rem 0rem rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  margin: 1.3rem auto;
  color: #13d1be;
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-align: left;
  border: 0.1rem solid #f4f4f4;
}
.content-title {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #fff;
  text-align: center;
  height: 2rem;
  padding: 0rem 1rem 0.5rem 0rem;
}
.m_bottom {
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.m_bottom_left {
  font-size: 1.6rem;
  text-align: center;
  height: 3rem;
  margin-left: 2rem;
  line-height: 3rem;
  color: #13d1be;
}
.m_bottom_right {
  height: 3rem;
  line-height: 3rem;
  color: #13d1be;
  font-size: 1.4rem;
  margin-right: 2rem;
}
.m_bottom_center {
  bottom: 1rem;
  height: 3rem;
  line-height: 3rem;
  color: #999999;
  font-size: 1.6rem;
}
</style>
