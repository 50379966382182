<template>
    <div style="margin-bottom: 50px">
        <div class="item_fu" v-for="(item,index) in dataList" :key="index">
            <div class="item">
                <div class="item_left" v-if="item.username.length<2">
                    {{item.username.substring(item.username.length-1)}}
                </div>
                <div class="item_left" v-else>{{item.username.substring(item.username.length-2)}}</div>
                <div style="display: flex;flex-direction: column;flex: 1">
                    <div style="display: flex;justify-content: space-between">
                        <div class="item_top_left">{{item.username}}</div>
                        <div class="item_top_right">
                            <!--                            <span v-if="item.status==1"-->
                            <!--                                  :class="item.levelId?'item_top_right_pingdingdengji_2':'item_top_right_pingdingdengji'"-->
                            <!--                                  @click="openBottom(item)">{{item.levelId?'已评级':'评定等级'}}</span>-->
                            <span class="item_top_right_del" @click="deleteItem(item)">删除</span>
                        </div>
                    </div>
                    <div class="item_top_left_fuben" v-if="item.postName">{{item.postName}}</div>
                    <div style="display: flex;justify-content: space-between">
                        <div class="item_bottom_left">时间:{{item.createTime}}</div>
                        <van-button
                                class="item_bottom_right_1"
                                :class="item.status==0?'item_bottom_right_1':'item_bottom_right_2'"
                                round type="info" @click="toReview(item)"
                        >
                            {{item.status==0?"未评价":"已评价"}}
                        </van-button>
                    </div>
                </div>
            </div>
            <div class="item_detail" v-if="item.status==1">
                <div class="item_d_table">
                    <div class="item_d_table_th">
                        <div class="item_d_table_th_item lh4rem fir">人岗匹配</div>
                        <div class="item_d_table_th_item lh4rem">业绩评估</div>
                        <div class="item_d_table_th_item">敬业度/<br>态度 评估</div>
                        <div class="item_d_table_th_item">专业水平<br>评价</div>
                        <div class="item_d_table_th_item lh4rem">专业悟性</div>
                    </div>
                    <div class="item_d_table_tb">
                        <div class="item_d_table_tb_item fir">评分</div>
                        <div class="item_d_table_tb_item">{{item.performanceScore}}分</div>
                        <div class="item_d_table_tb_item">{{item.dedicationScore}}分</div>
                        <div class="item_d_table_tb_item">{{item.professionalScore}}分</div>
                        <div class="item_d_table_tb_item">{{item.savvyScore}}分</div>
                    </div>

                    <div class="item_d_table_th_2">
                        <div class="item_d_table_th_item_3 lh4rem_2 fir">KPI</div>
                        <div class="item_d_table_th_item_2 lh4rem_2" v-if="item.kpi">{{item.kpi | jieQu}}</div>
                        <div class="item_d_table_th_item_2 lh4rem_2" v-else>--</div>
                        <div class="item_d_table_th_item_2 lh4rem_2" v-if="item.kpi2">{{item.kpi2 | jieQu}}</div>
                        <div class="item_d_table_th_item_2 lh4rem_2" v-else>--</div>
                    </div>
                    <div class="item_d_table_tb">
                        <div class="item_d_table_th_item_3 fir">目标</div>
                        <div class="item_d_table_th_item_2" v-if="item.target">{{item.target | jieQu}}</div>
                        <div class="item_d_table_th_item_2" v-else>--</div>
                        <div class="item_d_table_th_item_2" v-if="item.target2">{{item.target2 | jieQu}}</div>
                        <div class="item_d_table_th_item_2" v-else>--</div>
                    </div>
                </div>
                <div class="pddj" @click="openBottom(item)" v-if="item.levelId">评定等级：
                    <span style="color: #13d1be" v-if="item.reviewLevel=='A'||item.reviewLevel=='B'">{{item.reviewLevel | zuanHuan}}</span>
                    <span style="color: #E6B800" v-if="item.reviewLevel=='C'">{{item.reviewLevel | zuanHuan}}</span>
                    <span style="color: #FD4E23" v-if="item.reviewLevel=='D'">{{item.reviewLevel | zuanHuan}}</span>
                </div>
                <div class="pddj" @click="openBottom(item)" v-else>评定等级</div>
            </div>
        </div>
        <no-result :title="'暂无评价'" v-if="!dataList.length"></no-result>
        <van-button
                v-if="checkData.status==1"
                class="add_btn"
                type="primary"
                @click="addNumber">添加成员
        </van-button>
        <van-dialog
                v-model="showAdd"
                title="添加成员"
                show-cancel-button
                show-confirm-button
                confirmButtonColor="#13d1be"
                :before-close="onBeforeClose"
        >
            <van-field class="m_field" v-model="name" placeholder="请输入成员名称"/>
            <van-field style="margin-bottom: 3rem" class="m_field" v-model="postName" placeholder="请输入岗位"/>
        </van-dialog>
        <van-popup v-model="showBottom" position="bottom">
            <div class="pop_title">
                <div class="btn_cancel" @click="handleCancel">取消</div>
                <div class="btn_confirm" @click="handleConfirm">确定</div>
            </div>
            <div class="pop_content">下一年KPI目标:</div>
            <div style="display: flex;align-items: center;margin-bottom: 10px">
                <div class="pop_left_title">KPI1:</div>
                <Input class="input_view" v-model="kpi" placeholder="请输入KPI1"/>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 10px">
                <div class="pop_left_title">目标1:</div>
                <Input class="input_view" v-model="target1" placeholder="请输入目标1"/>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 10px">
                <div class="pop_left_title">KPI2:</div>
                <Input class="input_view" v-model="kpi2" placeholder="请输入KPI2"/>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 10px">
                <div class="pop_left_title">目标2:</div>
                <Input class="input_view" v-model="target2" placeholder="请输入目标2"/>
            </div>

            <div class="pop_content">综合评价:</div>
            <Input class="input_view" v-model="evaluate" type="textarea" :rows="4" placeholder="请输入综合评价"/>
            <div class="pop_content">评定级别:</div>
            <div style="display: flex;flex-wrap: wrap;margin-bottom: 50px">
                <div v-for="(item,index) in ratingData" :key="index">
                    <div class="item_grade"
                         :class="(reviewLevel==ratingData[index].value)?'item_grade_selected':'item_grade'"
                         @click="selectLevel(item.value)">{{item.value+' '+item.content}}
                    </div>
                </div>
            </div>

        </van-popup>
    </div>
</template>
<script>
    import {Button, Dialog, Field, Toast, Popup} from "vant";
    import api from "@/apis/talentReview/talentReview.js";
    import NoResult from "../components/commonly/noResult.vue";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import storage from "@/common/js/cacheData";

    export default {
        name: "caizhunReview",
        data() {
            return {
                kpi: null,
                kpi2: null,
                target1: null,
                target2: null,
                caizhun_userId: null,
                reviewLevel: null,
                userId: null,
                levelId: null,
                futureGoal: null,
                evaluate: null,
                showBottom: false,
                name: '',
                postName: '',
                showAdd: false,
                showDelete: false,
                dataList: [],
                checkData: {},
                ratingData: [
                    {
                        content: '完全胜任',
                        value: 'A'
                    }, {
                        content: '基本胜任',
                        value: 'B'
                    }, {
                        content: '可培养',
                        value: 'C'
                    }, {
                        content: '优化',
                        value: 'D'
                    }
                ]
            }
        },
        components: {
            [Button.name]: Button,
            [Toast.name]: Toast,
            [Dialog.name]: Dialog,
            [Dialog.Component.name]: Dialog.Component,
            [Field.name]: Field,
            [Popup.name]: Popup,
            NoResult
        },
        mounted() {
            document.title = "人岗匹配评价";
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            //判断是否判断是否填写信息
            this.showIfBindPhone();
            this.shareContent();
        },
        methods: {
            showIfBindPhone() {
                api._checkOfficeReviewRecord({
                    type: 1//彩准
                }).then(
                    response => {
                        if (response.code == 200) {
                            this.checkData = response.result;
                            //0 未填写  1 已经填写 2 未扫码
                            if (this.checkData.status == 0) {
                                Toast('请填写个人信息');
                                this.$router.push({
                                    path: '/jingzhunInfoInput',
                                    query: {
                                        type: 1
                                    }
                                })
                            } else if (this.checkData.status == 1) {
                                if (this.checkData.isAddress==1){
                                    //isAddress=1代表之前没填写行业地区
                                    Toast('请完善个人信息');
                                    this.$router.push({
                                        path: '/jingzhunInfoInput',
                                        query: {
                                            type: 1,
                                            isAddress: 1,
                                            username: this.checkData.username,
                                            id: this.checkData.id
                                        }
                                    })
                                }else {
                                    this.getDataList(this.checkData)
                                }

                            } else if (this.checkData.status == 2) {
                                Toast('请扫码')
                            }
                        }
                    }
                )
            },
            getDataList(val) {
                api._getOfficeReviewList({
                    officeId: val.officeId,
                    reviewId: val.reviewId,
                    pcUserId: val.pcUserId,
                    userId: val.userId,
                    type: val.type
                }).then(
                    response => {
                        if (response.code == 200) {
                            this.dataList = response.result;
                            this.caizhun_userId = localStorage.getItem('caizhun_userId');
                            if (this.caizhun_userId) {
                                this.dataList.forEach(it => {
                                    if (this.caizhun_userId == it.userId) {
                                        this.openBottom(it);
                                        localStorage.removeItem('caizhun_userId')
                                    }
                                })
                            }
                        }
                    }
                )
            },
            toReview(item) {
                this.$router.push({
                    path: '/IntroducePage',
                    query: {
                        dimension: item.dimension,
                        reviewId: item.reviewId,
                        type: item.type,
                        userId: item.reviewUserId,//评审人id
                        id: item.id,
                        username: item.username,
                        reviewUserId: item.reviewUserId,//评审人id
                        pcUserId: item.userId,
                        fromCaizhun: 1
                    }
                })
            },
            deleteItem(item) {
                Dialog.confirm({
                    message: '确定删除吗'
                })
                    .then(() => {
                        // on confirm
                        // Toast('确定')
                        api._deleteOfficeReviewPersonRecord({
                            id: item.deleteId
                        }).then(
                            response => {
                                if (response.code == 200) {
                                    Toast({
                                        message: response.msg,
                                        position: "middle",
                                        duration: 1500
                                    });
                                    this.getDataList(this.checkData)
                                }
                            }
                        )
                    })
                    .catch(() => {
                        // on cancel
                        // Toast('取消')
                    });
            },
            addNumber() {
                this.name = '';
                this.postName = '';
                this.showAdd = true
            },
            // handleConfirm() {
            //
            // },async
            async onBeforeClose(action, done) {
                if (action == 'confirm') {
                    if (this.name == '') {
                        Toast('请输入姓名');
                        return done(false)
                    }
                    if (this.name.length > 10) {
                        Toast('输入姓名内容过长');
                        return done(false)
                    }
                    if (this.postName == '') {
                        Toast('请输入岗位名');
                        return done(false)
                    }
                    api._addOfficeReviewPersonRecord({
                        status: 1,//彩准1 菁准2
                        officeId: this.checkData.officeId,
                        username: this.name,
                        postName: this.postName,
                        reviewId: this.checkData.reviewId,
                        evaluationUserId: this.checkData.pcUserId
                    }).then(
                        response => {
                            if (response.code == 200) {
                                Toast({
                                    message: response.msg,
                                    position: "middle",
                                    duration: 1500
                                });
                                this.getDataList(this.checkData);
                                return done()
                            }
                        }
                    )
                } else {

                    return done()
                }
            },
            selectLevel(val) {
                this.reviewLevel = val;

            },
            handleConfirm() {
                if (!this.kpi) {
                    Toast('请填写KPI1');
                    return
                }
                if (!this.target1) {
                    Toast('请填写目标1');
                    return
                }
                if (!this.kpi2) {
                    Toast('请填写KPI2');
                    return
                }

                if (!this.target2) {
                    Toast('请填写目标2');
                    return
                }
                if (!this.evaluate) {
                    Toast('请填写评价');
                    return
                }
                if (!this.reviewLevel) {
                    Toast('请选择评级');
                    return
                }
                let reqData = {
                    userId: this.userId,
                    target: this.target1,
                    target2: this.target2,
                    kpi: this.kpi,
                    kpi2: this.kpi2,
                    evaluate: this.evaluate,
                    reviewLevel: this.reviewLevel
                };
                if (this.levelId) {
                    reqData.levelId = this.levelId
                }
                api._addReviewLevel(reqData).then(
                    response => {
                        if (response.code == 200) {
                            Toast({
                                message: response.msg,
                                position: "middle",
                                duration: 1500
                            });
                            this.showBottom = false;
                            this.getDataList(this.checkData)
                        }
                    }
                )
            },
            handleCancel() {
                this.showBottom = false
            },
            openBottom(item) {
                this.showBottom = true;
                this.userId = item.userId;
                this.kpi = null;
                this.kpi2 = null;
                this.target1 = null;
                this.target2 = null;
                this.evaluate = null;
                this.reviewLevel = null;
                if (item.levelId) {
                    this.levelId = item.levelId;
                    this.kpi = item.kpi;
                    this.kpi2 = item.kpi2;
                    this.target1 = item.target;
                    this.target2 = item.target2;
                    this.evaluate = item.evaluate;
                    this.reviewLevel = item.reviewLevel;
                }
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        },
        filters: {
            zuanHuan(val) {
                if (val == 'A') {
                    return 'A 完全胜任'
                } else if (val == 'B') {
                    return 'B 基本胜任'
                } else if (val == 'C') {
                    return 'C 可培养'
                } else if (val == 'D') {
                    return 'D 优化'
                }
            },
            jieQu(val) {
                if (val.length > 10) {
                    return val.substring(0, 10) + "...";
                } else {
                    return val
                }
            }
        }
    }
</script>

<style scoped>
    .item_fu {
        opacity: 1;
        margin: 1rem;
        padding: 1rem;
        background: #ffffff;
        border-radius: 1rem;
        box-shadow: 0px .4rem 1rem 0px rgba(0, 0, 0, 0.20);
    }

    .item {
        display: flex;
        align-items: center;
    }

    .item_left {
        width: 7rem;
        height: 7rem;
        color: white;
        display: flex;
        font-size: 2rem;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        background: linear-gradient(180deg, #6c97ff, #3f72ec);
        border-radius: 4px;
    }

    .item_top_left {
        padding: 0 0 0 1rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #333;
        line-height: 4rem;
    }

    .item_top_left_fuben {
        padding: 0 0 0 1rem;
        font-size: 1.2rem;
        color: #333;
        font-weight: normal;
    }

    .item_top_right {
        font-size: 1.2rem;
        line-height: 4rem;
    }

    .item_top_right_del {
        color: #999;

    }

    .item_top_right_pingdingdengji {
        margin-right: 1rem;
        color: #13D1BE;
    }

    .item_top_right_pingdingdengji_2 {
        margin-right: 1rem;
        color: #999999;
    }

    .item_bottom_left {
        padding: 1rem;
        font-size: 1.2rem;
        color: #333;
    }

    .item_bottom_right_1 {
        width: 8rem;
        height: 3rem;
        text-align: center;
        font-size: 1.1rem;
        background-color: #13D1BE;
    }

    .item_bottom_right_2 {
        width: 8rem;
        height: 3rem;
        text-align: center;
        font-size: 1.1rem;
        background-color: #dadada;
    }

    .add_btn {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: #13d1be;
        border: 0px;
    }

    .m_field {
        width: 300px;
        margin: 20px auto;
        border: 1px solid #f4f4f4;
    }

    .item_detail {
        margin: .5rem 0;
    }

    .item_d_table {
        /* border: 1px solid #71D2C8; */
        border-radius: .6rem;
    }

    .item_d_table_th {
        display: flex;
        height: 4rem;
        align-items: center;
        background: #F0FCFA;

    }

    .item_d_table_th_2 {
        display: flex;
        height: 3rem;
        align-items: center;

    }

    .item_d_table_th_item {
        height: 100%;
        flex: 1;
        border: 1px solid #71D2C8;
        margin-left: -1px;
        text-align: center;
        font-size: 1.2rem;
        /* margin-top: -1px; */
    }

    .item_d_table_th_item_2 {
        height: 100%;
        flex: 2.01;
        border: 1px solid #71D2C8;
        margin-left: -1px;
        margin-top: -2px;
        text-align: center;
        font-size: 1.2rem;
        /* margin-top: -1px; */
    }

    .item_d_table_th_item_3 {
        height: 100%;
        flex: 1;
        border: 1px solid #71D2C8;
        margin-left: -1px;
        margin-top: -2px;
        text-align: center;
        font-size: 1.2rem;
    }

    .item_d_table_tb {
        display: flex;
        height: 3rem;
        line-height: 3rem;
    }

    .item_d_table_tb_item {
        flex: 1;
        border: 1px solid #71D2C8;
        margin-left: -1px;
        margin-top: -1px;
        text-align: center;
        font-size: 1.2rem;
    }


    .fir {
        margin-left: 0;
    }

    .lh4rem {
        line-height: 4rem;
    }

    .lh4rem_2 {
        line-height: 3rem;
    }

    .pop_title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f4f4f4;
    }

    .btn_cancel {
        line-height: 50px;
        margin-left: 16px;
        height: 50px;
        color: #333333;
        font-size: 14px;

    }

    .btn_confirm {
        line-height: 50px;
        height: 50px;
        margin-right: 16px;
        color: #13d1be;
        font-size: 14px;
    }

    .pop_content {
        margin-left: 16px;
        line-height: 40px;
        font-size: 12px;
        color: #333333;
        font-weight: bold;
    }

    .input_view {
        padding-left: 16px;
        padding-right: 16px;
    }

    .item_grade {
        line-height: 20px;
        text-align: center;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        margin: 0 0 1rem 1rem;
        font-size: 1.4rem;
        padding: 0.5rem 1rem 0.5rem 1rem;
        cursor: pointer;
    }

    .item_grade_selected {
        text-align: center;
        background-color: #13d1be;
        color: white;
        font-size: 1.4rem;
        border-radius: 5px;
        margin: 0 0 1rem 1rem;
        padding: 0.5rem 1rem 0.5rem 1rem;
        cursor: pointer;
    }

    .pop_left_title {
        margin-left: 16px;
        color: #333333;
        font-size: 12px;
        width: 5rem;
        font-weight: bold;
    }

    .pddj {
        margin-top: 2rem;
        text-align: right;
        color: #13d1be;
        font-size: 1.6rem;
    }
</style>