import Vue from "vue";
import App from "./App.vue";
import VueResource from 'vue-resource'
import router from "./router";
import ViewUI from 'view-design';
import VueLazyLoad from 'vue-lazyload'
Vue.config.productionTip = false;
Vue.use(ViewUI);
Vue.use(VueLazyLoad);
Vue.use(VueResource);
import { Button,Dialog } from 'vant';

Vue.use(Button);
Vue.use(Dialog);
import VueBus from 'vue-bus';
Vue.use(VueBus);
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
