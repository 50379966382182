<template>
    <div class="no-result">
        <div class="no-result-wrapper">
            <div class="no-result-icon"></div>
            <p class="no-result-text">{{title}}</p>
        </div>
    </div>
</template>
<script type="text/ecmascript-6">
    export default {
        props: {
            title: {
                type: String,
                default: ""
            }
        }
    };
</script>
<style scoped>
    .no-result {
        height: 400px;
    }

    .no-result-wrapper {
        position: absolute;
        width: 100%;
        top: 50%;
        max-width: 37.5rem;
        transform: translateY(-50%);
        text-align: center;
    }

    .no-result-icon {
        width: 8.6rem;
        height: 9rem;
        margin: 0 auto;
        background-image: url('../../../../static/image/no-result.png');
        background-size: 8.6rem 9rem;
    }

    .no-result-text {
        margin-top: 3rem;
        font-size: 14px;
        color: #999;
    }
</style>
