<template>
    <div id="center-view">
        <div class="topImage">
            <div class="title">{{taskData.info.campName}}</div>
            <div class="progress">
                <div style="width: 58px;">已学习</div>
                <i-progress :percent="taskData.info.studyPercentage|fillterPercent()" :stroke-width="8"
                            :stroke-color="['#FFAB97', '#FF8A6E','#FF6944']" status="active"/>
            </div>
            <div class="progress-bg">
                <div style="width:56%">打败了<span style="color:#FFAB97">{{taskData.info.percentage}}</span>的同学</div>
                <div>任务:{{taskData.info.unlockTotal}}/{{taskData.info.taskTotal}}&emsp;作业:{{taskData.info.commitHomework}}/{{taskData.info.countHomework}}</div>
            </div>
        </div>
        <div class="select-item">
            <task-list v-for="(item,index) in taskData.list" :task=item @gotoDitial=gotoDitial
                       :key="'task'+index"></task-list>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
    import {ShareLink,ShareLogo} from "@/apis/const.js";
    import {startShart} from "@/common/js/tecentshare.js";
    import TaskList from "../../components/commonly/taskList.vue";
    import goodsDetail from "@/apis/goodsDetail/goodsDetail";

    export default {
        data() {
            return {
                officeId: null,
                userId: null,
                taskData: {
                    list: [],
                    info: {}
                }
            };
        },
        filters: {
            fillterPercent(val) {
                return parseInt(val);
            }
        },
        props: {},
        components: {
            TaskList
        },
        methods: {
            selectClick(val) {
                let that = this;
                that.select = val;
            },
            gotoDitial(task) {
                let that = this;
                that.$router.push({
                    path: "/videoPaly",
                    query: {
                        type: 2,
                        totalId: task.campId,
                        campTaskId: task.campTaskId,
                        officeId: that.officeId,
                        userId: that.userId,
                        t: Date.now()
                    }
                });
            },
            getDataList() {
                let that = this;
                let totalId = that.$route.query.totalId;
                goodsDetail._getMyTaskList(totalId, that.officeId, that.userId).then(
                    response => {
                        let result = response.result;
                        that.taskData = result;
                    },
                    error => {
                    }
                );
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        },
        mounted() {
            document.title = '任务详情';
            this.officeId = this.$route.query.officeId;
            this.userId = this.$route.query.userId;
            this.shareContent();
            this.getDataList();
        },
        created() {
        },
        destoryed() {
        }
    };
</script>

<style scoped>
    @import "../../../../common/stylus/CusMinit.css";

    .tarin-list {
        margin-top: 1rem;
    }

    .center-view {
        background-color: #f1f1f1;
    }

    .topImage {
        width: 100%;
        background-image: url("../../../../static/image/task_detail_bg.png");
        background-size: 100% 10rem;
        height: 10rem;
        padding: 1rem 1.5rem;
        color: #ffffff;
        font-size: 12px;
    }

    .title {
        font-size: 16px;
        color: #fff;
        font-weight: bold;
    }

    .progress {
        display: flex;
        display: -webkit-flex;
        width: 57%;
        margin: 10px 0;
    }

    .progress-bg {
        display: flex;
        display: -webkit-flex;
    }
</style>
