<template>
    <div style="display: flex;flex-direction: column">
        <div class="main_view">
            <div class="title">信息填写</div>
            <input class="m_input" v-model="username"
                   :onkeyup="username = username.replace(/\s+/g,'')"
                   placeholder="请填写姓名"/>
            <input style="margin-bottom: 8rem" class="m_input"
                   v-model="postName"
                   :onkeyup="postName = postName.replace(/\s+/g,'')"
                   placeholder="请填写岗位"/>
        </div>
        <div class="btn_" @click="jump">完成</div>
    </div>
</template>

<script>
    import {Toast, Picker, Popup, Area} from "vant";
    import api from "@/apis/talentReview/talentReview.js";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import storage from "@/common/js/cacheData";

    export default {
        name: "DuoInfoInput",
        data() {
            return {
                username: '',
                type: null,
                mId: null,
                postName: '',//岗位
                officeId: null,
                pcUserId: null,
                skillId: null,
                isExtension: null
            }
        },
        components: {
            [Toast.name]: Toast,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Area.name]: Area
        },
        mounted() {
            document.title = "填写个人信息";
            this.officeId = this.$route.query.officeId;
            this.pcUserId = this.$route.query.pcUserId;
            this.skillId = this.$route.query.skillId;
            this.isExtension = this.$route.query.isExtension;
            let openId = this.$route.query.openId;
            if (openId) {
                storage.saveData("openId", openId);
            }
            this.shareContent();
        },
        methods: {
            jump() {
                if (this.username == '') {
                    Toast('请输入姓名');
                    return
                }
                if (this.username.length > 10) {
                    Toast('输入姓名内容过长');
                    return
                }
                if (!this.postName) {
                    Toast('请输入岗位名');
                    return
                }
                api._addOfficeReviewPersonRecord({
                    status: 3,//彩准1 菁准2 多准3
                    officeId: this.officeId,
                    username: this.username,
                    postName: this.postName,
                    evaluationUserId: this.pcUserId
                }).then(
                    response => {
                        if (response.code == 200) {
                            Toast({
                                message: response.msg,
                                position: "middle",
                                duration: 1500
                            });
                            this.$router.push({
                                path: "/SkillEvaluationNotice",
                                query: {
                                    skillId: this.skillId,
                                    assessUserId: response.result.userId,
                                    userId: response.result.userId,
                                    officeId: this.officeId,
                                    isExtension: this.isExtension
                                }
                            });
                        }
                    }
                )

            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .main_view {
        display: flex;
        flex-direction: column;
        margin: 5rem 2rem;
        opacity: 1;
        text-align: center;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    }

    .title {
        padding-top: 10px;
        padding-bottom: 20px;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        color: #333333;
    }

    .m_input {
        background: #ffffff;
        border: 2px solid #f4f4f4;
        border-radius: 5px;
        padding-left: 1rem;
        font-size: 16px;
        margin: 2rem 2rem 0 2rem;
        height: 4rem;
        line-height: 4rem;
    }

    .m_placeholder {
        text-align: left;
        color: #999999;
        font-size: 14px;
    }

    .m_placeholder_2 {
        text-align: left;
        color: #333333;
        font-size: 14px;
    }

    .info {
        font-size: 14px;
        font-weight: 400;
        margin-top: 4rem;
        margin-bottom: 4rem;
        text-align: center;
        color: #999999;
    }

    .btn_ {
        width: 25rem;
        color: white;
        height: 4rem;
        margin: 0 auto;
        line-height: 4rem;
        font-size: 16px;
        background-color: #13d1be;
        text-align: center;
        border-radius: 5px;
    }
</style>