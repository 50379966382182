

import {getBaseUrl} from '@/apis/const'
import $ from 'jquery'


function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return 'iOS';

  }
  else if (userAgent.match(/Android/i)) {

    return 'Android';
  }
  else {
    return 'unknown';
  }
}

//锟角凤拷锟斤拷微锟斤拷锟斤拷锟斤拷
function is_in_wechat() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (userAgent.match(/micromessenger/i)) {
    return true;
  } else {
    return false;
  }
}

var defaultShareData = {
  tTitle: "",
  tLink: "",
  tImgUrl: "",
  tContent: "",
  fImgUrl: '',
  fLink: '',
  fContent: '',
  fTitle: ''
}


export function startShart() {
  window.shareData = window.shareData || defaultShareData;
  window.shareIMdata = window.shareIMdata || window.shareData;

  var is_wechat = false,
    os = '';
  if (getMobileOperatingSystem() == 'Android') {
    if (is_in_wechat()) {
      is_wechat = true;
    }
    os = 'Android';
  } else {
    if (is_in_wechat()) {
      is_wechat = true;
    }
    os = 'ios';
  }
  // console.log("startShart:os",os,"is_wechat",is_wechat)
  if (is_wechat) {
    window.user_type = 1;

    // let aaa = {
    //     mt: new Date(),
    //     url: document.location.href.split('#')[0]
    //   };
    //   console.log(aaa.url);

    createWeixinShare();
  } else {
    window.user_type = 2;
  }
  window.isWeChat = is_wechat;
}

function createWeixinShare() {   
  let url = getBaseUrl() + "hrCoe/weiChat/getJsApiTicket";
  $.ajax({
    url: url,
    data: {
      mt: new Date(),
      url: document.location.href.split('#')[0]
    },
    type: "GET",
    dataType: "json",
    success: function (response) {
      // alert('result.appId==' + result.data.appId);
      // alert('result.timestamp==' + result.data.timestamp);
      // alert('result.nonceStr==' + result.data.nonceStr);
      // alert('result.signature==' + result.data.signature);
      addWXConfig(response.result);
    },
    error: function (error) {
      alert('error--jsonp',error);
    }
  });


  function addWXConfig(wx_config) {
    wx.config({
      debug: false,
      appId: wx_config.appId,
      timestamp: wx_config.timestamp,
      nonceStr: wx_config.noncestr,
      signature: wx_config.signature,
      jsApiList: [
        'checkJsApi',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'hideMenuItems',
        'showMenuItems',
        'hideAllNonBaseMenuItem',
        'showAllNonBaseMenuItem',
        'translateVoice',
        'startRecord',
        'stopRecord',
        'onRecordEnd',
        'playVoice',
        'pauseVoice',
        'stopVoice',
        'uploadVoice',
        'downloadVoice',
        'chooseImage',
        'previewImage',
        'uploadImage',
        'downloadImage',
        'getNetworkType',
        'openLocation',
        'getLocation',
        'hideOptionMenu',
        'showOptionMenu',
        'closeWindow',
        'scanQRCode',
        'chooseWXPay',
        'openProductSpecificView',
        'addCard',
        'chooseCard',
        'openCard'
      ]
    });
    wx.ready(function () {
      var sd = {
        title: window.shareData.fTitle,
        link: window.shareData.fLink,
        imgUrl: window.shareData.fImgUrl,
        desc: window.shareData.fContent,
        success: function () {

        },
        cancel: function () {
        }
      };

      // 锟斤拷锟斤拷圈
      wx.onMenuShareTimeline(sd);

      // 锟斤拷锟斤拷
      wx.onMenuShareAppMessage({
        title: window.shareIMdata.fTitle || window.shareData.fTitle,
        link: window.shareIMdata.fLink || window.shareData.fLink,
        imgUrl: window.shareIMdata.fImgUrl || window.shareData.fImgUrl,
        desc: window.shareIMdata.fContent || window.shareData.fContent,
        success: function () {

        },
        cancel: function () {
        }
      });

      // 锟斤拷Q
      wx.onMenuShareQQ(sd);

      // 微锟斤拷
      wx.onMenuShareWeibo(sd);

      // 锟秸硷拷
      wx.onMenuShareQZone(sd);
    });
  }
}




export function updateWeixinEvents(obj) {
  if (!obj) {
    return;
  }
  var sd = {
    title: obj.title || window.shareData.fTitle,
    link: obj.link || window.shareData.fLink,
    imgUrl: obj.img || window.shareData.fImgUrl,
    desc: obj.desc || window.shareData.fContent,
    success: function () {

    },
    cancel: function () {
    }
  };

  wx.onMenuShareTimeline(sd);

  // 锟斤拷锟斤拷
  wx.onMenuShareAppMessage({
    title: window.shareIMdata.fTitle || obj.title || window.shareData.fTitle,
    link: window.shareIMdata.fLink || obj.link || window.shareData.fLink,
    imgUrl: window.shareIMdata.fImgUrl || obj.img || window.shareData.fImgUrl,
    desc: window.shareIMdata.fContent || obj.desc || window.shareData.fContent,
    success: function () {

    },
    cancel: function () {
    }
  });

  // 锟斤拷Q
  wx.onMenuShareQQ(sd);

  // 微锟斤拷
  wx.onMenuShareWeibo(sd);

  // 锟秸硷拷
  wx.onMenuShareQZone(sd);
}
