<template>
    <div class="forcenter">
        <div class="m_card">
            <div class="m_title">
                <div class='title'>[{{dataItem[index].remark}}]</div>
                <div class='content-title'>{{dataItem[index].problemName}}</div>
            </div>

            <div class="item_card">
                <div class="left-item"
                     :class="(allanswer.length > last)&&(allanswer[last].score == 0)?'answers-bg-active':'answers-bg'"
                     @click="next(0)">
                    从未开展
                </div>
                <div class="left-item"
                     :class="(allanswer.length > last)&&(allanswer[last].score == 1)?'answers-bg-active':'answers-bg'"
                     @click="next(1)">
                    做过一部分，基本没用
                </div>
                <div class="left-item"
                     :class="(allanswer.length > last)&&(allanswer[last].score == 3)?'answers-bg-active':'answers-bg'"
                     @click="next(3)">
                    系统做过，部分使用/使用中
                </div>
                <div class="left-item"
                     :class="(allanswer.length > last)&&(allanswer[last].score == 5)?'answers-bg-active':'answers-bg'"
                     @click="next(5)">
                   目前全力推进，效果很好
                </div>
            </div>
            <div class="m_bottom">
                <div v-if="isShow" class='m_bottom_left' @click='go_start'>{{previousName}}</div>
                <div class='m_bottom_center'>{{index+1}}/{{dataItem.length}}</div>
                <div v-if="index==dataItem.length-1" class='m_bottom_right' @click='submit'>提交</div>
                <div v-else class='m_bottom_right' @click='go_next'>{{nextName}}</div>
            </div>

        </div>

    </div>
</template>

<script>
    import {Toast} from "vant";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink, ShareLogo} from "@/apis/const.js";
    import api from "@/apis/talentReview/talentReview.js";

    export default {
        name: "DiagnosisItem",
        data() {
            return {
                isConfirm: true,
                dimension: '',
                type: 2,//诊断
                reviewId: null,
                id: 0,
                reviewUserId: 0,
                userId: 0,
                pcUserId: 0,
                isShow: false,
                index: 0,
                last: 0,
                previousName: "上一题",
                nextName: "下一题",
                dataItem: [
                    {
                        id: 1
                    }
                ],
                allanswer: [
                    {
                        type: 0,
                        score: -1
                    }
                ]
            }
        },
        comments: {
            [Toast.name]: Toast
        },
        mounted() {
            document.title='HR诊断';
            this.getDataList();
            this.shareContent();
        },
        methods: {
            getDataList() {
                api._hr_diagnosis_subject({
                    type: 5
                }).then(
                    response => {
                        if (response.code == 200) {
                            this.dataItem = response.result;
                        }
                    }
                )
            },
            go_start() {
                let that = this;
                that.index--;
                that.last--;
                if (that.index > 0) {
                    that.isShow = true;
                } else {
                    that.isShow = false;
                }
            },
            go_next() {
                let that = this;
                if (!that.allanswer[that.index]||that.allanswer[that.index].score==-1){
                    Toast('请先做题');
                    return
                }
                that.index++;
                that.last++;
                if (that.index > 0) {
                    that.isShow = true;
                } else {
                    that.isShow = false;
                }
            },
            next(a) {
                if (!this.isConfirm) {
                    return
                }
                this.isConfirm = false;
                let that = this;
                var ansobj = {
                    type: 0,
                    score: 0
                };
                ansobj.type = that.dataItem[that.index].type;
                ansobj.score = a;
                that.allanswer[that.index] = ansobj;
                that.$forceUpdate();
                that.previousName = "上一题";
                setTimeout(() => {
                    this.isConfirm = true;
                    if (that.index == that.dataItem.length - 1) {
                        return;
                    }
                    that.index++;
                    that.last++;
                    if (that.index > 0) {
                        that.isShow = true;
                    } else {
                        that.isShow = false;
                    }

                }, 300)
            },
            submit() {
                let that = this;
                var alldata = that.allanswer;
                if (alldata.length < this.dataItem.length) {
                    Toast({
                        message: "诊断未完善",
                        position: "middle",
                        duration: 1000
                    });
                    return
                }
                let subobj = {};
                for (let i = 0; i < alldata.length; i++) {
                    subobj["number" + alldata[i].type] = 0;
                }
                for (let i = 0; i < alldata.length; i++) {
                    let sorc = alldata[i].score;
                    subobj["number" + alldata[i].type] += sorc;
                }
                subobj.type = this.type;
                api._hr_diagnosis_submit(subobj).then(
                    response => {
                        if (response.code == 200) {
                            Toast({
                                message: "诊断完成",
                                position: "middle",
                                duration: 1000
                            });
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/DiagnosisResult'
                                });
                                that.index = 0;
                                that.last = 0;
                                that.allanswer = [{
                                    type: 0,
                                    score: -1
                                }];
                            }, 1000);
                        }

                    }
                );
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .forcenter {
        margin: 0 auto;
        min-height: 100vh;
        /*background-position: center center;*/
        background: url("../../../static/image/review_bac.png");
        background-size: cover;
        text-align: center;
    }

    .m_card {
        position: fixed;
        top: 90px;
        left: 20px;
        right: 20px;
        bottom: 90px;
        background: white;
        border-radius: 10px;
    }

    .m_title {
        background: #13D1BE;
        border-radius: 10px 10px 0px 0px;
    }

    .item_card {
        position: absolute;
        top: 52%;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        left: 0;
        right: 0;
    }

    .answers-bg {
        width: 80%;
        background: #ffffff;
        border: 1px solid #f4f4f4;
        border-radius: 10px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
        padding: 5px 10px 5px 10px;
        margin: 23px auto;
        color: #666666;
        font-size: 1.2rem;
        line-height: 2.4rem;
        text-align: center;
    }

    .answers-bg-active {
        width: 80%;
        background: #f4f4f4;
        border-radius: 10px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
        padding: 5px 10px 5px 10px;
        margin: 23px auto;
        color: #13D1BE;
        font-size: 1.2rem;
        line-height: 2.4rem;
        text-align: center;
        border: 1px solid #f4f4f4;
    }


    .title {
        font-size: 1.6rem;
        font-weight: bold;
        color: #fff;
        text-align: center;
        padding: 10px 0 10px 0;
    }

    .content-title {
        font-size: 1.6rem;
        color: #fff;
        font-weight: bold;
        text-align: center;
        padding: 0 0 10px 0;
    }

    .m_bottom {
        /*display: flex;*/
        /*flex-direction: row;*/
        /*justify-content: space-between;*/
    }

    .m_bottom_left {
        font-size: 1.4rem;
        text-align: center;
        height: 3rem;
        line-height: 3rem;
        color: #13D1BE;
        position: absolute;
        bottom: 1rem;
        left: 2rem;
    }

    .m_bottom_right {
        bottom: 1rem;
        position: absolute;
        height: 3rem;
        line-height: 3rem;
        right: 2rem;
        color: #13D1BE;
        font-size: 1.4rem;
    }

    .m_bottom_center {
        bottom: 1rem;
        position: absolute;
        height: 3rem;
        line-height: 3rem;
        right: 12rem;
        left: 12rem;
        color: #999999;
        font-size: 1.6rem;
    }
</style>
