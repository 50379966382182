<template>
    <div class="forcenter">
        <div class="m_card" v-if="queryObj.type==1">
            <div class="title">
                欢迎参加“人岗匹配”测评系统！
            </div>
            <div class="content">
                您正在测试的是“人岗匹配”测评。
            </div>
            <div class="content">
                本系统适用于公司各类岗位的综合评估，从业绩实现、专业水平、工作态度、专业悟性等多个维度进行评价，评价周期一般以上一年度综合表现为依据，人岗匹配评估主要采取上级对下属评价方式。
            </div>
            <div class="content">
                请您根据被评审者的真实表现进行评价，尽可能做到客观、公正。
            </div>
            <div class="content" style="margin-bottom: 40px">我已经准备好了！！！</div>

        </div>
        <div class="m_card" v-else>
            <div class="title">
                欢迎参加十二项素质测评系统！
            </div>
            <div class="content">
                您正在测试的是“十二项素质”测评。
            </div>
            <div class="content">
                本系统适用于公司中高层管理者评价，也可作为基层管理者评价依据，测评通过自评、上级、下属三个方面进行。
            </div>
            <div class="content">
                请您根据被评审者的真实表现进行评价，尽可能做到客观、公正。
            </div>
            <div class="content">我已经准备好了！！！</div>
        </div>
        <van-button class="talent_button" @click="startInventory">开始盘点</van-button>
    </div>
</template>

<script>
    import {Button} from "vant";
    import {startShart} from "@/common/js/tecentshare.js";
    import {ShareLink,ShareLogo} from "@/apis/const.js";
    export default {
        name: "IntroducePage",
        data() {
            return {
                queryObj: {}
            }
        },
        components: {
            [Button.name]: Button
        },
        mounted() {
            document.title = "盘点须知";
            this.queryObj = this.$route.query;
            // if (this.queryObj.type==1){
            //     //彩准
            // }else{
            //     //2菁准
            // }
            this.shareContent();
        },
        methods: {
            startInventory(){
                this.$router.push({
                    path: '/ReviewItem',
                    query: this.queryObj
                })
            },
            //分享
            shareContent() {
                const titleStr = "HRCOE";
                const contentStr = "让人力资源工作更简单!";
                const linkStr = ShareLink + "type=1";
                const imgStr = ShareLogo;
                window.shareData = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                window.shareIMdata = {
                    fTitle: titleStr,
                    fContent: contentStr,
                    fImgUrl: imgStr,
                    fLink: linkStr
                };
                startShart();
            }
        }
    }
</script>

<style scoped>
    .forcenter {
        margin: 0 auto;
        background: #fff;
        text-align: center;
    }
    .m_card {
        margin-left: 2rem;
        margin-right: 2rem;
        margin-top: 20%;
    }
    .title {
        font-size: 16px;
        line-height: 2rem;
        margin-bottom: 20px;
        color: #333;
        text-align: center;
        padding: 3rem 0 0 0;
    }

    .content {
        text-indent: 3rem;
        font-size: 14px;
        color: #333;
        text-align: left;
        line-height: 3rem;
    }

    .talent_button {
        background-color: #13d1be;
        border-radius: 3px;
        color: white;
        margin-top: 15%;
        width: 200px;
        height: 40px;
    }
</style>
