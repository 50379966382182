import Vue from 'vue'
import {Toast} from 'vant';
Vue.use(Toast);
import Promise from 'promise';
import Cache from './cache';
import CacheData from '@/common/js/cacheData'
import {getBaseUrl} from '../const.js'

let getApiUrl = (apiUrl, url) => {
    var baseUrl = getBaseUrl();
    apiUrl = apiUrl || baseUrl;
    return apiUrl + url;
};

/**
 * method
 */
export let METHOD = {
    POST: "POST",
    GET: "GET",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
    JSONP: "JSONP"
};

export let ContentType = {
    NORMAL: "application/x-www-form-urlencoded",
    RESTFUL: 'application/json;charset=utf-8',
    FORMTYPE: 'multipart/form-data'

};


export let blocker = () => {

    Vue.http.interceptors.push({

        request: function (request) {
            return request;
        },

        response: function (response) {

            return response;

        }

    });

}


window.ajaxQueue = {

    checkInQueue: (key) => {

        window.ajaxQueueData = window.ajaxQueueData || {};
        let queue = window.ajaxQueueData;


        if (!queue[key]) {  //not in
            queue[key] = 1;
            return null;
        } else {

            return new Promise(function (resolve, reject) {

            });

        }
    },
    remove(key) {
        window.ajaxQueueData = window.ajaxQueueData || {};
        delete window.ajaxQueueData[key];
    }

};

let toast = function (msg) {
    Toast(msg)
};

/**
 *
 * @param opts:{
 *
 *  cache:{       //cache不传则不开启
 *    exp:1,      //0 不开启
 *    read:-1,    //-1不开启
 *  }
 * }
 * @param parseFunc
 * @returns {*}
 */
export let createApi = (opts, parseFunc) => {

    let cachepattern = opts.cache;

    let cacheKey = opts.cacheKey;

    //强制更新
    if (opts.update) {
        Cache.remove(cacheKey);
    }

    var headerConfig = {};

    if (opts.headers) {
        for (let key in opts.headers) {
            headerConfig[key] = opts.headers[key];
        }
    }

    if (opts.isStream) {
        headerConfig['Content-Type'] = undefined;//'application/octet-stream';
    } else {
        headerConfig['Content-Type'] = ContentType.NORMAL;
    }

    let options = {
        method: opts.method || "GET",
        url: opts.url,
        params: opts.params || {},
        body: opts.data || {},
        headers: headerConfig,
        cache: false,
        timeout: 100000,
        emulateJSON: true
        //withCredentials:true
    }
    let loadingBar = opts.progress;
    if (loadingBar) {
        showLoading()
    }

    var promise = new Promise(function (resolve, reject) {
        /**请求加载*/
        Vue.http(options).then(function (response) {
            let resp = response.body;
            // console.log(resp,"-------")
            hideToast();
            if (resp.code == 200 || options.method == 'JSONP') {
                cachepattern;
                window.ajaxQueue.remove(cacheKey);
                resolve(resp);
            } else {
                window.ajaxQueue.remove(cacheKey);
                if (resp.errCode == "clg1006") {
                    autoShowToast(resp.msg);
                } else {
                    autoShowToast(resp.msg);
                    reject(response);
                }

            }

        }, function (response) {
            hideToast();
            window.ajaxQueue.remove(cacheKey);
            let status = response.status;
            if (status == 401) {
                // toast("接口权限错误");
                autoShowToast("接口权限错误");

            } else if (status == 500) {
                // toast("请求报错,请稍后再试.");
                autoShowToast("请求报错,请稍后再试.");

                reject(status);
            } else if (status == 404) {
                //找不到请求
                // toast("请求报错,请稍后再试");
                autoShowToast("请求报错,请稍后再试");

                reject(status);
                let errerUrl = "#/404";
                window.location.href = errerUrl;
            } else {
                // toast("请确认网络连接是否正常");
                autoShowToast("请确认网络连接是否正常");

                reject(status);
            }
        })

    });
    return promise;
};
let getCacheKey = (url, data) => {
    data["_"] = undefined;
    return url + JSON.stringify(data);
}

let getClearData = function (data) {
    let openId = CacheData.getData('openId')

    let _data = {
        // wechatUserId: '9'
    };
    if (openId) {
        _data['openId'] = openId;
    }
    for (let prop in data) {
        let item = data[prop];
        if (item != undefined) {
            _data[prop] = item;
        }
    }
    return _data;
}

let getClearJsonPData = function (data) {
    let _data = {};
    for (let prop in data) {
        let item = data[prop];
        if (item != undefined) {
            _data[prop] = item;
        }
    }
    return _data;
}


export let getParamsOne = (url, param, opts = {}, parseFunc = undefined) => {
    let requestUrl = getApiUrl(opts.apiUrl, url) + '/' + `${param}`;
    let data = {};
    let cacheKey = getCacheKey(url, data);
    data["_"] = Math.random();
    opts.url = requestUrl;
    opts.cacheKey = cacheKey;
    opts.method = METHOD.GET;
    opts.params = getClearData(data);
    return createApi(opts, parseFunc);
}

export let get = (url, data = {}, opts = {}, parseFunc = undefined) => {
    let requestUrl = getApiUrl(opts.apiUrl, url);
    let cacheKey = getCacheKey(url, data);
    data["_"] = Math.random();
    opts.url = requestUrl;
    opts.cacheKey = cacheKey;
    opts.method = METHOD.GET;
    opts.params = getClearData(data);
    return createApi(opts, parseFunc);
}

export let post = (url, data = {}, opts = {}, parseFunc = undefined) => {
    let requestUrl = getApiUrl(opts.apiUrl, url);
    let cacheKey = getCacheKey(url, data);
    data["_"] = Math.random();
    opts.url = requestUrl;
    opts.cacheKey = cacheKey;
    opts.method = METHOD.POST;
    opts.data = getClearData(data);
    return createApi(opts, parseFunc);
}
export let postJson = (url, data = {}, opts = {}, parseFunc = undefined) => {
    let requestUrl = getApiUrl(opts.apiUrl, url);
    let cacheKey = getCacheKey(url, data);
    data["_"] = Math.random();
    opts.url = requestUrl;
    opts.cacheKey = cacheKey;
    opts.method = METHOD.POST;
    opts.headers = opts.headers || {};
    opts.headers['Content-Type'] = ContentType.RESTFUL;
    opts.data = getClearData(data);
    return createApi(opts, parseFunc);
}
export let postform = (url, data = {}, opts = {}, parseFunc = undefined) => {
    let requestUrl = getApiUrl(opts.apiUrl, url);
    let cacheKey = getCacheKey(url, data);
    opts.url = requestUrl;
    opts.cacheKey = cacheKey;
    opts.method = METHOD.POST;
    opts.headers = opts.headers || {};
    opts.params = getClearData(data);
    opts.headers['Content-Type'] = ContentType.FORMTYPE;
    opts.data = getClearJFormPData(data);

    return createApi(opts, parseFunc);
}
export let jsonp = (url, data = {}, opts = {}, parseFunc = undefined) => {
    let requestUrl = getApiUrl(opts.apiUrl, url);
    let cacheKey = getCacheKey(url, data);
    opts.url = requestUrl;
    opts.cacheKey = cacheKey;
    opts.method = METHOD.JSONP;
    opts.params = getClearJsonPData(data);
    return createApi(opts, parseFunc);
}
let getClearJFormPData = function (data) {

    let formdata = new FormData();
    for (let prop in data) {
        let item = data[prop];
        if (item != undefined) {
            formdata.append(prop, item);
        }
    }
    return formdata;
}
export let createOptions = (opts = {apiUrl: "/"}) => {
    return opts;
}

export function autoShowToast(msg) {
    showToast(msg);
}


export function showToast(msg) {
    Toast(msg)
}

export function hideToast() {
    Toast.clear()
}


/**
 * 显示loading动画
 */
export function showLoading() {
    Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
    });
}




