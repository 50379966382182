<template>
  <div class="train-list" @click="gotoDitial">
    <div class="list-card">
      <div class="top-state">{{task.unlockType|fillterTask()}}</div>
      <div class="title-bg">
        <div class="train-name">{{task.title}}</div>
         <i-circle :percent="task.percentage|fillterPercent()" :stroke-color="'#13D1BE'" style="margin-right:10px;">
            <span class="demo-Circle-inner" style="font-size:12px">{{task.percentage}}%</span>
        </i-circle>
      </div>
      <div class="title-bg">
        <div class="conten-left">
          <div class="coach-name">{{task.totalStudyCount}}个人已学习</div>
          <div class="coach-name">视频:{{task.myStudyCount}}/{{task.totalCount}}  作业:{{task.homeworkCommit}}/{{task.homeworkCount}}</div>
        </div>
        <div class="btn">
        继续学习
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
  props: {
    task: Object
  },
  data() {
    return {};
  },
  components: {},
  methods: {
    gotoDitial() {
      let that = this;
      this.$emit("gotoDitial", that.task);
    }
  },
  mounted() {
    // this.$refs.sexSelect.style = "display:none"
  },
  created() {},
  filters: {
    fillterPercent(val) {
      return parseInt(val);
    },
    fillterTask(val) {
      if (val == 1) {
        return "已开启";
      }
      return "未开启";
    }
  },
  destoryed() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../../../common/stylus/CusMinit.css";
.train-list {
  width: 100%;
}

.list-card {
  /*width: 35.5rem;*/
  height: 130px;
  margin: 15px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.top-state {
  width: 4rem;
  text-align: center;
  color: #fff;
  background: #fd4e23;
  border-radius: 5px 0px 5px 0px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
}
.title-bg {
  padding: 0 1rem;
  font-size: 14px;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
}
.train-name {
  margin: 10px 0 0 0;
  font-size: 15px;
  color: #333;
  font-weight: bold;
}
.coach-name {
  margin: 5px 0;
  color: #666;
}
.btn {
  width: 6.5rem;
  background: #13d1be;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  border-radius: 15px;
  font-size: 14px;
  margin: 2rem 0 0 0;
}
</style>
