<template>
    <div>
        <div>分销首页</div>
        <foot/>
    </div>

</template>

<script>
    import Foot from "../components/public/d_foot.vue";
    export default {
        name: "HomePage",
        data(){
            return{

            }
        },
        components:{
            Foot
        }
    }
</script>

<style scoped>

</style>